// @format
// @flow
import React from 'react';
import AsyncStorage from '@callstack/async-storage';
import { useHistory } from "react-router-dom";

import { Container, Visual, Content } from './styled';
import { Button } from '../../styled-common';

import vip from "../../vip";

// Assets
import mainVisual from '../../assets/onboarding/visual.svg';

const Onboarding = () => {
  const history = useHistory();

  return (
    <Container>
      <Visual>
        <img src={vip.visuals['login']} alt="" />
      </Visual>
      <Content>
        <h1>Donnez votre avis et voyez ce qu’on pense autour de vous</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
        <Button
          large
          onClick={async () => {
            await AsyncStorage.setItem('@OnbordingAlreadyViewed', 'true');
            history.push('loginChoice');
          }}
          title="Passer"
        >
          Passer
        </Button>
      </Content>
    </Container>
  );
};

export default Onboarding;