//@format
//@flow
import React, { useState } from "react";
import { ExternalWrapper, Wrapper, ContainerWrapper, Container, Visual, Content } from "./styled.js";
import Breadcrumb from "../breadcrumb";
import type { Node } from 'react';
import * as userHelper from "../../fetch/user";

type Props={
	name:string, 
	shortDescription:string, 
	description:string,
	startDate:string,
	endDate:string,
	pictureUrl:string,
	channel:Channel,
}


const ChannelHeader = (props: Props): Node => {
	const {channel} = props;
	const [showFullDescription, setShowFullDescription] = useState(false);
	return (
		<ExternalWrapper>
			<Wrapper >
				<ContainerWrapper>
					<Container>
						<Content>
							<Breadcrumb
								textColor={channel.textColor}
								items={[
									{
										title: "Home",
										link: "home",
									},
									{
										title: channel.name,
									},
								]}
							/>
							

							<h1 style={{color: channel.textColor}}>{channel.name}</h1>

							<div>
								{showFullDescription || !channel.shortDescription ? (
									<>
										<div className="description" style={{color: channel.textColor}} dangerouslySetInnerHTML={{ __html: channel.description }} />
										{channel.shortDescription && channel.shortDescription !== channel.description && (
											<button style={{color: channel.textColor}} className="description-button" onClick={() => setShowFullDescription(false)}>
												Lire moins
											</button>
										)}
									</>
								) : (
									<>
										<div className="description" style={{color: channel.textColor}} dangerouslySetInnerHTML={{ __html: channel.shortDescription }} />
										{channel.shortDescription && channel.shortDescription !== channel.description && (
											<button style={{color: channel.textColor}} className="description-button" onClick={() => setShowFullDescription(true)}>
												Lire plus
											</button>
										)}
									</>
								)}

								{props.startDate || props.endDate ? (
									<div className="availability">
										{props.startDate ? <p>Publié le {props.startDate}</p> : null}
										{props.endDate ? <p>Fin le {props.endDate}</p> : null}
									</div>
								) : null}
							</div>
						</Content>
						<Visual>
							<div className="visual-wrapper">
								<img src={channel.pictureUrl} alt="" />
							</div>
						</Visual>
					</Container>
				</ContainerWrapper>
			</Wrapper>
		</ExternalWrapper>
	);
};

export default ChannelHeader;
