//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";

import { Button } from "../../styled-common";

type Props = {
    username: string,
    handleClose: Function,
};

const ModalUsername = (props: Props): Node => {
    const [stateUsername, setStateUsername] = useState(props.username);
    const [stateErrorMessage, setStateErrorMessage] = useState(null);

    const { register, errors, setValue, handleSubmit, formState } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all",
    });

    const onSubmitForm = async (data) => {
        await userHelper.saveUsername(data.username);
        props.handleClose();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <h2 className="title">Edition du nom d'utilisateur</h2>

                <div className="form-item">
                    <input
                        type="text"
                        name="username"
                        defaultValue={stateUsername}
                        onChange={async (event) => {
                            const text = event.currentTarget.value;
                            setValue("username", text, true);

                            if (!formState.isValid) {
                                setStateErrorMessage("Veuillez remplir un nom d’utilisateur (entre 2 et 20 caractères)");
                            } else {
                                setStateErrorMessage("");
                            }
                        }}
                        ref={register({
                            required: true,
                            maxLength: 20,
                            minLength: 2,
                            validate: {
                                asyncValidate: async (value) => {
                                    const checkUsernameResponse = await userHelper.checkUsername(value);

                                    if (checkUsernameResponse !== "Valid") {
                                        setStateErrorMessage("On est tous unique et ce nom d’utilisateur est déjà pris. Veuillez choisir un autre nom d’utilisateur.");
                                    } else {
                                        setStateErrorMessage("");
                                    }

                                    return checkUsernameResponse === "Valid";
                                },
                            },
                        })}
                    />
                </div>

                <div className="btn-wrapper">
                    {stateErrorMessage ? (
                        <div className="form-error">
                            <p>{stateErrorMessage}</p>
                        </div>
                    ) : null}
                    <Button type="submit" small={true} disabled={!formState.isValid}>
                        Valider
                    </Button>

                    <Button type="reset" ghost={true} small={true} onClick={props.handleClose}>
                        Annuler
                    </Button>
                </div>
            </form>
        </>
    );
};

export default ModalUsername;
