//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import Autocomplete from "react-autocomplete";
import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";
import type { Node } from "react";
import { Button } from "../../styled-common";

type Props = {
    handleClose: Function,
};

const ModalLocality = (props: Props): Node => {
    const [stateUser, setStateUser] = useState(null);
    const [stateUserLoaded, setStateUserLoaded] = useState(false);
    const [stateErrorMessage, setStateErrorMessage] = useState(null);

    const [localitiesList, setLocalitiesList] = useState([]);
    const [localitiesListLoading, setLocalitiesListLoading] = useState(false);

    const [cityValue, setCityValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const getProfileData = async () => {
        if (!stateUserLoaded) {
            const userData = await userHelper.getUser();
            // console.log('userData', userData);
            setStateUser(userData);
            if (userData.locality && userData.postalcode) {
                setCityValue(`${userData.locality}, ${userData.postalcode}`);
            } else {
                setCityValue(``);
            }

            setStateUserLoaded(true);

            getLocalitiesList(userData.locality);
        }
    };

    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        getProfileData();
    }, []);

    const saveGmapField = async (data) => {
        if (selectedValue && selectedValue.zip) {
            await userHelper.setUserPostalcode(selectedValue.locality);
        }
    };

    const onSubmitForm = async (data) => {
        await saveGmapField();
        props.handleClose();
    };

    const getLocalitiesList = async (value) => {
        // /api/v1/DemographicData/GetGeographicPlace/{locality}
        setLocalitiesListLoading(true);
        const locList = await client.get(`/Profile/searchlocality/${value}`);
        if (locList) {
            const newList = locList.data.map((item, index) => {
                return {
                    index: index,
                    label: `${item.locality}, ${item.zip}`,
                    zip: item.zip,
                    locality: item.locality,
                };
            });

            setLocalitiesList(newList);
            setLocalitiesListLoading(false);
        }
    };

    return (
        <>
            {stateUserLoaded ? (
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <h2 className="title">Modifier votre commune</h2>

                    <div className="locality-selector">
                        <Autocomplete
                            value={cityValue}
                            wrapperProps={{ className: "form-item" }}
                            wrapperStyle={{ display: "flex" }}
                            inputProps={{ id: "locality_selector", type: "text" }}
                            items={localitiesList}
                            getItemValue={(item) => item.label}
                            onSelect={(value, state) => {
                                setCityValue(value);
                                setSelectedValue(state);
                            }}
                            onChange={(event, value) => {
                                setCityValue(value);

                                if (value.length > 2) {
                                    getLocalitiesList(value);
                                }
                            }}
                            renderInput={(props) => {
                                return <input className="" {...props} />;
                            }}
                            renderItem={(item, isHighlighted) => (
                                <div className={`item ${isHighlighted ? "item-highlighted" : ""}`} key={item.index}>
                                    {item.label}
                                </div>
                            )}
                            menuStyle={{
                                position: "absolute",
                                top: "40px",
                                left: "0",
                                width: "100%",
                                maxHeight: "150px",
                                overflow: "auto",
                                boxShadow: "0px 7px 30px rgba(6, 10, 74, 0.20)",
                                borderRadius: "8px",
                            }}
                        />
                    </div>

                    {stateErrorMessage ? <p className="message-errors">{stateErrorMessage}</p> : null}

                    <div className="btn-wrapper">
                        <Button type="submit" small={true}>
                            Valider
                        </Button>

                        <Button type="reset" ghost={true} small={true} onClick={props.handleClose}>
                            Annuler
                        </Button>
                    </div>
                </form>
            ) : null}
        </>
    );
};

export default ModalLocality;
