//@format
//@flow
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import type { Node } from 'react';

import * as surveyHelper from "../../fetch/survey";

import { Container, Visual, Content, BackContainer, SuveyQuestionsContainer } from "./styled";
import { Button } from "../../styled-common.js";
import SurveyProgress from "../surveyContent/components/surveyProgress";
import QuestionResult from "./components/questionResult";
import { apiConfig } from "../../fetch/config";

import vip from "../../vip";

let client;

const SurveyDetails = ():Node => {
  const history = useHistory();
  const location = useLocation();
  const surveyId = location.state && location.state.surveyId ? location.state.surveyId : "";
  const questionId = location.state && location.state.questionId ? location.state.questionId : null;
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [channelName, setChannelName] = useState<Survey | null>(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionObject | null>(null);
  const [allowedTime, setAllowedTime] = useState(0);
  const [isLiveShow, setIsLiveShow] = useState(false);

  const vipCopy = vip.copy;

  useEffect(() => {
    if (!location.state || !location.state.surveyId) {
      return history.push("/");
    }

    // Get the survey
    async function fetchData() {
      const result = await surveyHelper.getResults(surveyId);
      const resultChannel = await surveyHelper.getSurveyforUser(surveyId);
      setSurvey(result);
      setChannelName(resultChannel.channelName);
      if (result.diffusionType !== "Live") {
        setCurrentQuestionIndex(0); // non-live survey, set the first question
      } else {
        setCurrentQuestionIndex(0); // live survey, do not set the first quesiton (waiting for live action)
      }
    }

    fetchData();
  }, []);

  /* ------------------------------------------ */
  // Set the currentQuesiotn when index change
  /* ------------------------------------------ */
  useEffect(() => {
    if (survey && survey.questions && survey.questions.length > 0) {
      const question = survey.questions[currentQuestionIndex];
      setCurrentQuestion(question);
    }
  }, [currentQuestionIndex]);

  /* ------------------------------------------ */
  // Move to next question
  /* ------------------------------------------ */
  const moveToNextQuestion = async () => {
    // reload the survye to ensure that responses are taken into account

    // For classic survey, move to next question
    // console.log("++++ currentindex:" + currentQuestionIndex + " q-order:" + currentQuestion.order + "-- squestioncount:" + survey.questions.length);
    if (currentQuestion && survey && survey.questions && currentQuestion.order < survey.questions.length) {
      const currentIndex = currentQuestionIndex;
      // console.log("move to next " + currentIndex);
      setCurrentQuestionIndex((c) => c + 1); // increment question index
      setCurrentQuestion(survey.questions[currentIndex + 1]); // set the current quesiton to the next one
    } else {
      if (survey) {
        history.push("/Thanks", { surveyId: survey.id });
      } else {
        history.push("/Thanks");
      }
    }
  };

  const moveToPrevQuestion = async () => {
    if (currentQuestion && survey && survey.questions && currentQuestion.order > 1) {
      const currentIndex = currentQuestionIndex;
      // console.log("move to prev " + currentIndex);
      setCurrentQuestionIndex((c) => c - 1);
      setCurrentQuestion(survey.questions[currentIndex - 1]);
    }
  };

  /* ------------------------------------------ */
  // Subscribe to channels & messages
  /* ------------------------------------------ */
  useEffect(() => {

    if (survey) {

      setIsLiveShow(false);
      if (survey.questions.length > 0) {
        let firstQuestionOrder = 0;
        if (questionId) {
          firstQuestionOrder = questionId - 1;
        }
        setCurrentQuestionIndex(firstQuestionOrder);
        setCurrentQuestion(survey.questions[firstQuestionOrder]);
      }
    }
  }, [survey]);

  if (survey) {
    return (
      <Container>
        <Content>
          <BackContainer>
            <button
              className="btn-back"
              onClick={() => {
                history.push("/survey-details/"+surveyId, { surveyId: surveyId });
              }}
              style={{color:survey.textColor}}
            >
              <ion-icon name="chevron-back"></ion-icon>
              {vipCopy["back-to-survey-page"]}
            </button>
          </BackContainer>
          <SurveyProgress total={survey.questions.length} textyColor={survey.textColor} currentQuestionIndex={currentQuestionIndex} />
          <SuveyQuestionsContainer>
            {currentQuestion && (
              <QuestionResult question={currentQuestion} onMoveToNextQuestion={moveToNextQuestion} onMoveToPrevQuestion={moveToPrevQuestion} survey={survey} channelName={channelName} />
            )}
          </SuveyQuestionsContainer>
        </Content>

        <Visual>{currentQuestion && currentQuestion.imageUri ? <img src={currentQuestion.imageUri} /> : null}</Visual>
      </Container>
    );
  }
  return null;
};

export default SurveyDetails;
