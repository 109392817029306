//@format
//@flow
import React, { useState, useEffect, Suspense } from "react";
import type {Node} from "react";
import AsyncStorage from "@callstack/async-storage";
import * as userHelper from "../../fetch/user";
import * as user from "../../fetch/user";
import { useHistory, useLocation, useParams } from "react-router-dom";

// import components
import CategoryStrip from "./categoryStrip";
import ChannelList from "../../components/channelList";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { gaSend } from "../../components/gaSend";

import Modal from "../../components/modal";
import { Button } from "../../styled-common";

import vip from "../../vip";

// import API fetcher
import * as channelsFetchHelper from "../../fetch/channel";

import { Container, HomeWrapperContent } from "./styled";

const Home = () : Node => {
	const history = useHistory();
	const [userPepio, setUserPepio] = useState<PepioUser | null>(null);
	const [channels, setChannels] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [showSpinner, setShowSpinner] = useState(true);
	// const [refreshing, setRefreshing] = useState(false);
	const [showCategoriesStrip, setShowCategoriesStrip] = useState(vip.showCategoriesStripe);

	const [showNewsModal, setShowNewsModal] = useState(false);

	const fetchDataCategories = async () => {
		const categoriessResults = await channelsFetchHelper.getCategories();
		if (categoriessResults) {
			const categoriesEnabled = categoriessResults.filter((item) => item.enabled);
			if (categoriesEnabled.length < 2) {
				setShowCategoriesStrip(false);
			} else {
				setCategories(categoriesEnabled);
			}
		} else {
			setShowCategoriesStrip(false);
		}
	};

	const fetchUser = async () => {
		const pepioUser = await user.getUser();
		setUserPepio(pepioUser);
	}

	const fetchDataChannels = async (categoryId) => {
		let channelsResults = [];

		// console.log('selectedCategoryId', selectedCategoryId, categoryId);

		if (categoryId) {
			channelsResults = await channelsFetchHelper.getChannelsByCategory(categoryId);
			const currentCategory = categories.filter(category => category.id === categoryId);
			gaSend(`Homepage | category: ${currentCategory[0].name}`);
		} else {
			channelsResults = await channelsFetchHelper.getAllChannels();
			gaSend('Homepage');
		}

		setChannels(channelsResults);
		setShowSpinner(false);
	};

	const checkNewsMessage = async () => {
		const newsMessageDateViewed = await AsyncStorage.getItem("@NewsMessageDateViewed");

		if (!newsMessageDateViewed) {
			setShowNewsModal(true);
		}
	};

	// fetch data on screen load
	useEffect(() => {
		fetchUser();
		checkNewsMessage();
		setShowSpinner(true);
		if (showCategoriesStrip) {
			fetchDataCategories();
		}
		fetchDataChannels();
	}, []);

	const selectCategoryFilter = (categoryId) => {
		if (!showSpinner) {
			setShowSpinner(true);
			const newCategoryId = categoryId !== selectedCategoryId ? categoryId : undefined;
			setSelectedCategoryId(newCategoryId);
			fetchDataChannels(newCategoryId);
		}
	};

	const lockReasonMap = {
		"Vulgarity":"pour raison de vulgarité",
		"Aggressiveness":"pour raison d'aggressivité",
		"Discrimination":"pour raison de propos discriminants",
		"Harassment":"pour raison d'harcèlement",
		"Other":"",
	};

	

	const lockPeriodMap = {
		"OneHour":"pendant 1 heure",
		"OneDay":"pendant 24 heures",
		"OneWeek":"pendant 1 semaine",
		"Indefinitely":"pour toujours",
	}

	return (
		<>
			<Header />
			<HomeWrapperContent>
				{categories && showCategoriesStrip && <CategoryStrip categories={categories} setSelectedCategoryId={selectCategoryFilter} selectedCategoryId={selectedCategoryId} />}
				{/* {console.log('userPepio', userPepio)} */}
				{userPepio && userPepio.accountBlockedChannelName ? (
					<div className="blocked-user">
						<div className="blocked-user-inner">
							<p>Nous vous informons que vous avez été désactivé.e par "{userPepio.accountBlockedChannelName}" {lockReasonMap[userPepio.accountLockReason]} et ce, {lockPeriodMap[userPepio.accountLockPeriod]}.<br />Les discussions vous seront ouvertes une fois le temps de désactivation passé.</p>
						</div>
					</div>
				) : null}
				<ChannelList channels={channels} loading={showSpinner} />
			</HomeWrapperContent>
			<Footer />
			
			

			{vip.showNewsModal && showNewsModal ? (
				<Modal
					visible={true}
					handleClose={async () => {
						setShowNewsModal(false);
						await AsyncStorage.setItem("@NewsMessageDateViewed", new Date().getTime());
					}}
				>
					<div className="modal-news">
						<h2 className="title">Opinio fait peau neuve 🎉</h2>
						<p>
							Si vous rencontrez des problèmes ou avez des feedbacks,
							<br />
							contactez-nous directement via
							<br />
							<a href="support@opinio.media">support@opinio.media</a>
							<br />
							ou dans les paramètres > signaler un problème.
							<br />
							Toute l’équipe vous remercie d’avance !
						</p>

						<Button
							buttonStyle="text"
							onClick={async () => {
								setShowNewsModal(false);
								await AsyncStorage.setItem("@NewsMessageDateViewed", new Date().getTime());
							}}
						>
							Fermer
						</Button>
					</div>
				</Modal>
			) : null}
		</>
	);
};

export default Home;
