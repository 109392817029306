import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const ContainerWrapper = styled.div`
overflow:hidden;
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;

  .title {
    font-family: ${fontFamily};
    font-size: 24px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
    line-height: 28px;
    letter-spacing: -0.02em;
  }

  .title-bar {
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      color: ${colors.blue};
    }
  }

  .list-load-more {
    text-align: center;
    margin: 20px 0 70px;

    button {
      width: auto;
      padding: 12px 24px;
    }
  }

  .videos-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    flex-wrap: wrap;

    ${mq('s')} { 
      justify-content: space-between;
    }

    li {
      position: relative;
      display: flex;
      width: 100%;
      max-width: 280px;
      max-height: 200px;
      margin: 0 20px 20px 0;
      ${mq('s')} {
        max-width: 100%;
      }

      a {
        display: block;
        width: cacl(100% - 40px);
      }

      img {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
      }

      .sharing-popup {
        width: calc(30% - 10px);
        margin-left: 10px;

        a {
          color: ${colors.blue};
        }

        button {
          background: transparent none;
          border: 0;
          padding: 0;
        }

        svg {
          color: ${colors.blue};
          width: 30px;
          margin-bottom: 10px;
        }
      }

      .video-in-progress {

      }

      .video-message {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff82 none;
        width: calc(70% - 60px);
        height: calc(100% - 60px);
        padding: 30px calc(30% + 10px) 30px 30px;
        font-weight: 600;
        color: ${colors.darkBlue100};
      }
    }

  }
`;

export const List = styled.div`
  display: flex;
  margin: 0 -30px;
  flex-wrap: wrap;

  .list-item {
    /* width: 295px; */
    margin: 30px;
    width: calc(100% / 4 - 60px);

    ${mq('xl')} {  
      width: calc(100% / 3 - 60px);
    }

    ${mq('l')} {  
      width: calc(100% / 3 - 60px);
    }

    ${mq('m')} {  
      width: calc(100% / 2 - 60px);
    }

    ${mq('s')} {  
      width: calc(100% - 60px);
    }
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
  padding: 20px 0 20px 100px;

  ${mq('m')} {
    padding-left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top:0;
    width: 100px;
    height: 100%;
    /* background: linear-gradient(90deg, ${colors.white} 50%, transparent); */
    background: linear-gradient(90deg, rgba(255,255,255, 1) 50%, rgba(255,255,255,0.001));
    z-index: 500;
    
    ${mq('m')} {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 100%;
    top:0;
    width: 9999px;
    height: 100%;
    background: ${colors.white};
    z-index: 500;

    ${mq('m')} {
      display: none;
    }
  }

  .slick-initialized .slick-slide {
    display: flex;
  }

  .slick-list {
    overflow:visible;
    z-index: 450;
  }
  .slick-track {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }
  .slick-slide {
    height: auto;
  }

  .slick-arrow.slick-disabled {
    ion-icon {
      color: ${colors.darkBlue20};
      cursor: default;
    }
  }

  &.slider-disabled {
    padding-left: 0;

    &::before,
    &::after {
      display: none;
    }

    .slick-arrow {
      ${mq('m', true)} {
        display: none !important;
      }
    }
  }
`;

export const SliderNext = styled.div`
  left: -100px;
  top: calc(100% - 100px);
  color: ${colors.blue};
  width: 50px;
  height: 50px;
  z-index: 550;

  ${mq('m')} {
    top: -50px;
    left: auto;
    right: 0;
  }

  ion-icon {
    font-size: 50px;
    color: ${colors.blue};
  }

  &::before {
    display: none;
  }
`;
export const SliderPrev = styled.div`
  left: -100px;
  top: calc(100% - 50px);
  color: ${colors.blue};
  width: 50px;
  height: 50px;
  z-index: 550;

  ${mq('m')} {
    top: -50px;
    left: auto;
    right: 50px;
  }

  ion-icon {
    font-size: 50px;
    color: ${colors.blue};
  }

  &::before {
    display: none;
  }
`;