//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import AsyncStorage from "@callstack/async-storage";
import * as userHelper from "../../fetch/user";

// import components
import Header from "../../components/header";
import Footer from "../../components/footer";
import SurveyCard from "../../components/surveyCard";
import ChannelHeader from "../../components/channelHeader";
import ModalVideoRequest from "../../components/modalVideoRequest";
import { gaSend } from "../../components/gaSend";

// import API fetcher
import * as channelsFetchHelper from "../../fetch/channel";

import { ContainerWrapper, Container, List, SliderWrapper, SliderNext, SliderPrev } from "./styled";
import { Button } from "../../styled-common.js";

import vip from "../../vip";

const vipCopy = vip.copy;

const ChannelContent = (): Node => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [cognitoUserIdState, setCognitoUserIdState] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userPepio, setUserPepio] = useState<PepioUser | null>(null);
    const [channel, setChannel] = useState(null);
    const [loadMoreNeeded, setLoadMoreNeeded] = useState(false);
    const stepLoadSurvey = 8;
    const [maxSurvey, setMaxSurvey] = useState(stepLoadSurvey);
    const [showModalVideoRequest, setShowModalVideoRequest] = useState(false);
    const [userVideosList, setUserVideosList] = useState(false);
    const [videosThemes, setVideosThemes] = useState([]);
    const [videosThemesObj, setVideosThemesObj] = useState({});

    const [copiedMessage, setCopiedMessage] = useState(false);

    const channelId = params && params.id ? parseInt(params.id) : location.state && location.state.channelId ? location.state.channelId : null;

    if (channelId && !(params && params.id) && location.state && location.state.channelId) {
        history.push("/channel-content/" + channelId);
    }

    const getData = async () => {
        let cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
        setCognitoUserIdState(cognitoUserId);

        if (!cognitoUserId) {
            await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
            cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
            setCognitoUserIdState(cognitoUserId);

            // user is not logged in, we store the current survey Id for later redirection
            await AsyncStorage.setItem("@LoginFromChannel", channelId);
        } else {
            // clear the redirections to avoid loop
            await AsyncStorage.removeItem("@LoginFromChannel");
        }

        const pepioUserId = await userHelper.getUserId();
        const pepioUser = await userHelper.getUser();

        setUserId(pepioUserId);
        setUserPepio(pepioUser);

        const result = await channelsFetchHelper.getChannel(channelId);
        const channelDetail = result;
        // console.log('channelDetail', stepLoadSurvey, maxSurvey, channelDetail);
        channelDetail.surveys = await channelsFetchHelper.getSurveys(channelId, maxSurvey);

        if (channelDetail.surveys.length < channelDetail.surveyCount) {
            setLoadMoreNeeded(true);
        } else {
            setLoadMoreNeeded(false);
        }

        setChannel(channelDetail);

        if (channelDetail.allowVideo) {
            const userIdResponse = await userHelper.getUserId();
            setUserId(userIdResponse);
            const videosThemesResponse = await userHelper.getVideoThemes(channelId);
            // console.log('videosThemesResponse', videosThemesResponse);
            setVideosThemes(videosThemesResponse);
            const videoThemesObjResponse = {};
            videosThemesResponse.map((theme) => {
                videoThemesObjResponse[theme.key] = theme.value;
            });
            setVideosThemesObj(videoThemesObjResponse);

            const userVideos = await userHelper.getUserVideo(channelDetail.id);
            setUserVideosList(userVideos);
            // console.log('userVideos', userVideos);
        }
    };

    // Component start
    useEffect(() => {
        if (!channelId) {
            return history.push("/");
        }

        getData();
        // console.log('use effect channel');
    }, [maxSurvey]);

    if (channel) {
        return (
            <>
                <Header />
                <ChannelHeader channel={channel} name={channel.name} pictureUrl={channel.pictureUrl} description={channel.description} shortDescription={channel.shortDescription} />
                <ContainerWrapper>
                    <Container>
                        {channel.allowVideo ? (
                            <div>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis molestie diam, vitae ultrices mauris pulvinar a.</p> */}

                                {cognitoUserIdState && userPepio ? (
                                    <>
                                        {userVideosList ? (
                                            <>
                                                <h2 className="title" style={{ color: channel.textColor }}>
                                                    Vos vidéos
                                                </h2>
                                                <ul className="videos-list">
                                                    {userVideosList.map((video, index) => {
                                                        return (
                                                            <li key={`video-list-${index}`}>
                                                                {console.log("video", video)}
                                                                {video.processCompleted ? (
                                                                    <>
                                                                        <a href={`/video/${video.requestId}`}>
                                                                            <img src={videosThemesObj[video.theme]} alt="" />
                                                                        </a>
                                                                        <div className="sharing-popup">
                                                                            <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(channel.shortDescription)}&url=${encodeURIComponent(video.shareUrl)}`} target="_blank">
                                                                                <svg enableBackground="new 0 0 32 32" height="32px" viewBox="0 0 32 32" width="32px">
                                                                                    <g>
                                                                                        <circle clipRule="evenodd" cx="16" cy="16" fill="#55ACEE" fillRule="evenodd" r="16" />
                                                                                        <path
                                                                                            d="M26.013,9.896c-0.737,0.323-1.527,0.538-2.359,0.636c0.85-0.498,1.5-1.289,1.807-2.232   c-0.793,0.462-1.672,0.798-2.608,0.979c-0.748-0.784-1.816-1.275-2.996-1.275c-2.269,0-4.105,1.807-4.105,4.039   c0,0.315,0.036,0.625,0.104,0.923c-3.412-0.172-6.436-1.78-8.461-4.222c-0.354,0.597-0.559,1.29-0.559,2.031   c0,1.4,0.728,2.636,1.828,3.363c-0.672-0.023-1.306-0.204-1.858-0.507c-0.001,0.017-0.001,0.033-0.001,0.051   c0,1.958,1.415,3.591,3.293,3.963c-0.343,0.09-0.706,0.141-1.082,0.141c-0.264,0-0.52-0.024-0.771-0.071   c0.521,1.603,2.038,2.771,3.834,2.806c-1.402,1.082-3.174,1.73-5.099,1.73c-0.33,0-0.659-0.019-0.979-0.058   c1.816,1.146,3.976,1.814,6.295,1.814c7.552,0,11.682-6.156,11.682-11.495c0-0.173-0.006-0.351-0.014-0.521   C24.766,11.417,25.462,10.706,26.013,9.896z"
                                                                                            fill="#FFFFFF"
                                                                                        />
                                                                                    </g>
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                </svg>
                                                                            </a>
                                                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(video.shareUrl)}&quote=${encodeURIComponent(channel.shortDescription)}`} target="_blank">
                                                                                <svg viewBox="0 0 1000 1000">
                                                                                    <g>
                                                                                        <path fill="#3B579D" d="M500,1000L500,1000C223.9,1000,0,776.1,0,500v0C0,223.9,223.9,0,500,0h0c276.1,0,500,223.9,500,500v0   C1000,776.1,776.1,1000,500,1000z" />
                                                                                        <path
                                                                                            fill="#FFFFFF"
                                                                                            d="M630,1000V612.7h130l19.5-150.9H630v-96.4c0-43.7,12.1-73.5,74.8-73.5l79.9,0V157   c-13.8-1.8-61.3-5.9-116.5-5.9c-115.2,0-194.1,70.3-194.1,199.5v111.3H343.8v150.9h130.3V1000H630z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </a>
                                                                            {copiedMessage === video.requestId ? (
                                                                                <svg height="24" viewBox="0 0 24 24" width="24">
                                                                                    <path
                                                                                        fill={copiedMessage === video.requestId ? "#265E94" : "#132F4A"}
                                                                                        d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M10,13.5857864 L15.2928932,8.29289322 L16.7071068,9.70710678 L10,16.4142136 L6.29289322,12.7071068 L7.70710678,11.2928932 L10,13.5857864 Z"
                                                                                        fillRule="evenodd"
                                                                                    />
                                                                                </svg>
                                                                            ) : (
                                                                                <button
                                                                                    onClick={async () => {
                                                                                        await navigator.clipboard.writeText(video.shareUrl);
                                                                                        setCopiedMessage(video.requestId);
                                                                                        setTimeout(() => {
                                                                                            setCopiedMessage(false);
                                                                                        }, 5000);
                                                                                    }}
                                                                                >
                                                                                    <svg viewBox="0 0 512 512">
                                                                                        <path
                                                                                            fill={copiedMessage === video.requestId ? "#132F4A" : "#265E94"}
                                                                                            d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                            <a href={video.videoUrl} target="_blank" download onClick={(e) => {
                                                                                e.preventDefault();
                                                                                const url = video.videoUrl;
                                                                                var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
                                                                                var xhr = new XMLHttpRequest();
                                                                                xhr.responseType = 'blob';
                                                                                xhr.onload = function() {
                                                                                  var a = document.createElement('a');
                                                                                  a.href = window.URL.createObjectURL(xhr.response);
                                                                                  a.download = filename;
                                                                                  a.style.display = 'none';
                                                                                  document.body.appendChild(a);
                                                                                  a.click();
                                                                                };
                                                                                xhr.open('GET', url);
                                                                                xhr.send();
                                                                            }}>
                                                                                <svg fill="none" height="30" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                                                                    <polyline points="7 10 12 15 17 10" />
                                                                                    <line x1="12" x2="12" y1="15" y2="3" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="video-in-progress">
                                                                            <img src={videosThemesObj[video.theme]} alt="" />
                                                                        </span>
                                                                        <div className="sharing-popup">
                                                                            {console.log("channel", channel)}
                                                                            <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(channel.description)}&url=${encodeURIComponent(video.shareUrl)}`} target="_blank">
                                                                                <svg enableBackground="new 0 0 32 32" height="32px" viewBox="0 0 32 32" width="32px">
                                                                                    <g>
                                                                                        <circle clipRule="evenodd" cx="16" cy="16" fill="#55ACEE" fillRule="evenodd" r="16" />
                                                                                        <path
                                                                                            d="M26.013,9.896c-0.737,0.323-1.527,0.538-2.359,0.636c0.85-0.498,1.5-1.289,1.807-2.232   c-0.793,0.462-1.672,0.798-2.608,0.979c-0.748-0.784-1.816-1.275-2.996-1.275c-2.269,0-4.105,1.807-4.105,4.039   c0,0.315,0.036,0.625,0.104,0.923c-3.412-0.172-6.436-1.78-8.461-4.222c-0.354,0.597-0.559,1.29-0.559,2.031   c0,1.4,0.728,2.636,1.828,3.363c-0.672-0.023-1.306-0.204-1.858-0.507c-0.001,0.017-0.001,0.033-0.001,0.051   c0,1.958,1.415,3.591,3.293,3.963c-0.343,0.09-0.706,0.141-1.082,0.141c-0.264,0-0.52-0.024-0.771-0.071   c0.521,1.603,2.038,2.771,3.834,2.806c-1.402,1.082-3.174,1.73-5.099,1.73c-0.33,0-0.659-0.019-0.979-0.058   c1.816,1.146,3.976,1.814,6.295,1.814c7.552,0,11.682-6.156,11.682-11.495c0-0.173-0.006-0.351-0.014-0.521   C24.766,11.417,25.462,10.706,26.013,9.896z"
                                                                                            fill="#FFFFFF"
                                                                                        />
                                                                                    </g>
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                    <g />
                                                                                </svg>
                                                                            </a>
                                                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(video.shareUrl)}&quote=${encodeURIComponent(channel.description)}`} target="_blank">
                                                                                <svg viewBox="0 0 1000 1000">
                                                                                    <g>
                                                                                        <path fill="#3B579D" d="M500,1000L500,1000C223.9,1000,0,776.1,0,500v0C0,223.9,223.9,0,500,0h0c276.1,0,500,223.9,500,500v0   C1000,776.1,776.1,1000,500,1000z" />
                                                                                        <path
                                                                                            fill="#FFFFFF"
                                                                                            d="M630,1000V612.7h130l19.5-150.9H630v-96.4c0-43.7,12.1-73.5,74.8-73.5l79.9,0V157   c-13.8-1.8-61.3-5.9-116.5-5.9c-115.2,0-194.1,70.3-194.1,199.5v111.3H343.8v150.9h130.3V1000H630z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </a>
                                                                            {copiedMessage === video.requestId ? (
                                                                                <svg height="24" viewBox="0 0 24 24" width="24">
                                                                                    <path
                                                                                        fill={copiedMessage === video.requestId ? "#265E94" : "#132F4A"}
                                                                                        d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M10,13.5857864 L15.2928932,8.29289322 L16.7071068,9.70710678 L10,16.4142136 L6.29289322,12.7071068 L7.70710678,11.2928932 L10,13.5857864 Z"
                                                                                        fillRule="evenodd"
                                                                                    />
                                                                                </svg>
                                                                            ) : (
                                                                                <button
                                                                                    onClick={async () => {
                                                                                        await navigator.clipboard.writeText(video.shareUrl);
                                                                                        setCopiedMessage(video.requestId);
                                                                                        setTimeout(() => {
                                                                                            setCopiedMessage(false);
                                                                                        }, 5000);
                                                                                    }}
                                                                                >
                                                                                    <svg viewBox="0 0 512 512">
                                                                                        <path
                                                                                            fill={copiedMessage === video.requestId ? "#132F4A" : "#265E94"}
                                                                                            d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                            <a href={video.videoUrl} download onClick={(e) => {
                                                                                e.preventDefault();
                                                                                const url = video.videoUrl;
                                                                                console.log('ICI', url);
                                                                                // var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
                                                                                // var xhr = new XMLHttpRequest();
                                                                                // xhr.responseType = 'blob';
                                                                                // xhr.onload = function() {
                                                                                //   var a = document.createElement('a');
                                                                                //   a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
                                                                                //   a.download = filename; // Set the file name.
                                                                                //   a.style.display = 'none';
                                                                                //   document.body.appendChild(a);
                                                                                //   a.click();
                                                                                // //   delete a;
                                                                                // };
                                                                                // xhr.open('GET', url);
                                                                                // xhr.send();
                                                                            }}>
                                                                                <svg fill="none" height="30" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                                                                    <polyline points="7 10 12 15 17 10" />
                                                                                    <line x1="12" x2="12" y1="15" y2="3" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                        <div className="video-message">Vidéo en cours de traitement</div>
                                                                    </>
                                                                )}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </>
                                        ) : null}

                                        <Button
                                            style={{ backgroundColor: channel.isCustomized ? channel.textColor : "", borderColor: channel.isCustomized ? channel.textColor : "" }}
                                            onClick={(e) => {
                                                setShowModalVideoRequest(true);
                                            }}
                                        >
                                            Créez votre vidéo
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            style={{ backgroundColor: channel.isCustomized ? channel.textColor : "", borderColor: channel.isCustomized ? channel.textColor : "" }}
                                            onClick={async (e) => {
                                                await AsyncStorage.setItem("@LoginFromChannel", channel.id);
                                                history.push("/loginHome");
                                            }}
                                        >
                                            Créez votre vidéo
                                        </Button>
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div className="title-bar">
                            {gaSend(`channel: ${channel.name} ${maxSurvey > stepLoadSurvey ? `| show more - 0 to ${maxSurvey}` : ''}`)}
                            <h2 className="title" style={{ color: channel.textColor }}>
                                {channel.surveys && channel.surveys.length > 0 ? "Le contenu" : channel.allowVideo ? null : vipCopy["no-result"]}
                            </h2>
                            {/* {surveys.length > 6 ? (
								<button
									onClick={() => {
										history.push("/channel-all-content", { channelId: channelId });
									}}
								>
									<span>Voir tous les contenus</span>
									<ion-icon name="chevron-forward"></ion-icon>
								</button>
							) : null} */}
                        </div>
                        {/* <SliderWrapper className={surveys.length > 6 ? 'slider-activated' : 'slider-disabled'}>
							<Slider {...settings}>{surveys && surveys.slice(0, 6).map((item) => <SurveyCard id={item.id.toString()} key={item.id.toString()} survey={item} />)}</Slider>
						</SliderWrapper> */}
                        {channel.surveys && channel.surveys.length > 0 ? (
                            <List>
                                {channel.surveys &&
                                    channel.surveys.map((item) => (
                                        <div className="list-item" key={item.id.toString()}>
                                            <SurveyCard id={item.id.toString()} survey={item} channel={channel} />
                                        </div>
                                    ))}
                            </List>
                        ) : null}

                        {loadMoreNeeded ? (
                            <div className="list-load-more">
                                <Button
                                    style={{ backgroundColor: channel.isCustomized ? channel.textColor : "", borderColor: channel.isCustomized ? channel.textColor : "" }}
                                    onClick={(e) => {
                                        setMaxSurvey(maxSurvey + stepLoadSurvey);
                                        e.currentTarget.blur();
                                    }}
                                >
                                    Charger plus de contenus
                                </Button>
                            </div>
                        ) : null}
                    </Container>
                </ContainerWrapper>

                <Footer />

                <ModalVideoRequest
                    show={showModalVideoRequest}
                    videosThemes={videosThemes}
                    handleClose={() => {
                        setShowModalVideoRequest(false);
                        getData();
                    }}
                    channel={channel}
                />
            </>
        );
    }
    return null;
};

export default ChannelContent;
