import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep3 = (props) => {
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    validateCriteriaMode: 'all',
  });

  const submitForm = async (data) => {
    // console.log('WizardStep3 data', data);
    const response = await userHelper.setUserBirthYear(data.birthYear);
    // console.log('WizardStep3 response', response);
    if(response) {
      props.nextStep();
    }
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
    <div className="step-content">
      <div className="step-content-inner">
        <h2>Quelle est votre année de naissance?</h2>

        <div className="form-item">
          <label>Votre année de naissance</label>
          <div className="form-item-field">
            <input 
              type="number" 
              name="birthYear" 
              placeholder="ex. : 1985"
              min={new Date().getFullYear() - 100} 
              max={new Date().getFullYear() - 14}
              ref={register({ min: new Date().getFullYear() - 100, max: new Date().getFullYear() - 14 })} 
            />
          </div>
          {errors.birthYear ? <div className="form-error"><p>Vous devez entrer une valeur entre {new Date().getFullYear() - 100} et {new Date().getFullYear() - 14}</p></div> : null}
        </div>
      </div>
      <div className="step-content-footer">
        <Button type="submit" disabled={!formState.isValid}>Suivant</Button>
      </div>
    </div>
    </form>
  );
};

export default WizardStep3;