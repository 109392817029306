//@flow
//@format
import React from "react";
import type {Node} from "react";
import { Wrapper, Container, QuestionList, QuestionListNotVisible, QuestionSpacer } from "./styled";
import SurveyQuestionCard from "../surveyQuestionCard";

type Props = {
	survey:Survey,
	color:string,
	showModalNoResults:boolean,
	setCheckParticipation:boolean,
	showQuestions: Boolean,
}

const SurveyQuestions = (props: Props): Node => {
	const { survey, color, showModalNoResults, showQuestions } = props;
	return (
		<Wrapper backgroundColor={survey.buttonColor}>
			<Container>
				{showQuestions ? (<>
					<h1 style={{color: 'white'}}>Les questions</h1>
					{(survey.diffusionType !== "Live" || (survey.diffusionType === "Live" && survey.liveShowStatus === 'Closed')) ? (
					<QuestionList>
						{survey.questions.map((item) => (
							<SurveyQuestionCard key={item.id} question={item} survey={survey} setCheckParticipation={props.setCheckParticipation} showModalNoResults={showModalNoResults} />
						))}
					</QuestionList>
					) : (
						<QuestionListNotVisible>Les questions ne sont pas encore visibles</QuestionListNotVisible>
					)}
				</>) : <QuestionSpacer />}

			</Container>
		</Wrapper>
	);
};

export default SurveyQuestions;
