//@format
//@flow
import React, { useState, useEffect, useRef } from "react";
import { Wrapper, SurveyProgressBar, SurveyCurrentProgress } from "./styled";
import type { Node } from 'react';

type Props={
	total:number, 
	currentQuestionIndex:any, 
	textColor:string,
}

const SurveyProgress = (props: Props): Node => {
	const { total, currentQuestionIndex, textColor } = props;

	/* ------------------------------------------ */
	// Calculate the survey progress
	/* ------------------------------------------ */
	const calcProgress = (index, total) => {
		if (total === 0) {
			return "0%";
		}
		const progress = Math.round(((index + 1) / total) * 100);
		return `${progress}%`;
	};

	return (
		<Wrapper>
			<SurveyProgressBar>
				<SurveyCurrentProgress progress={calcProgress(currentQuestionIndex, total)} textColor={textColor} ></SurveyCurrentProgress>
			</SurveyProgressBar>
		</Wrapper>
	);
};

export default SurveyProgress;
