//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";
import { Button } from "../../styled-common";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";

type Props = {
    handleClose: Function,
    professionnalSituationTexts: Array<string>,
    professionnalSituationValue: number,
};
const ModalProfessionnal = (props: Props): Node => {
    const [stateErrorMessage, setStateErrorMessage] = useState(null);
    const [stateProfessionnalSituationValue, setStateProfessionnalSituationValue] = useState(props.professionnalSituationValue);

    const { register, setValue, handleSubmit, formState } = useForm();

    const onSubmitForm = async (data) => {
        // console.log('data', data);
        await userHelper.SetUserProfessionnalSituation(stateProfessionnalSituationValue);
        props.handleClose();
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <h2 className="title">Modifier votre situation professionnelle</h2>

            <div className="form-item">
                <input
                    type="radio"
                    name="profile_professionnal_situation"
                    id="profile_professionnal_situation_0"
                    value="1"
                    checked={stateProfessionnalSituationValue === 1}
                    onChange={(e) => {
                        setStateProfessionnalSituationValue(parseInt(e.currentTarget.value, 10));
                    }}
                />
                <label htmlFor="profile_professionnal_situation_0">{props.professionnalSituationTexts[1]}</label>
            </div>

            <div className="form-item">
                <input
                    type="radio"
                    name="profile_professionnal_situation"
                    id="profile_professionnal_situation_1"
                    value="2"
                    checked={stateProfessionnalSituationValue === 2}
                    onChange={(e) => {
                        setStateProfessionnalSituationValue(parseInt(e.currentTarget.value, 10));
                    }}
                />
                <label htmlFor="profile_professionnal_situation_1">{props.professionnalSituationTexts[2]}</label>
            </div>

            <div className="form-item">
                <input
                    type="radio"
                    name="profile_professionnal_situation"
                    id="profile_professionnal_situation_2"
                    value="3"
                    checked={stateProfessionnalSituationValue === 3}
                    onChange={(e) => {
                        setStateProfessionnalSituationValue(parseInt(e.currentTarget.value, 10));
                    }}
                />
                <label htmlFor="profile_professionnal_situation_2">{props.professionnalSituationTexts[3]}</label>
            </div>

            <div className="form-item">
                <input
                    type="radio"
                    name="profile_professionnal_situation"
                    id="profile_professionnal_situation_3"
                    value="4"
                    checked={stateProfessionnalSituationValue === 4}
                    onChange={(e) => {
                        setStateProfessionnalSituationValue(parseInt(e.currentTarget.value, 10));
                    }}
                />
                <label htmlFor="profile_professionnal_situation_3">{props.professionnalSituationTexts[4]}</label>
            </div>
            <div className="form-item">
                <input
                    type="radio"
                    name="profile_professionnal_situation"
                    id="profile_professionnal_situation_4"
                    value="5"
                    checked={stateProfessionnalSituationValue === 5}
                    onChange={(e) => {
                        setStateProfessionnalSituationValue(parseInt(e.currentTarget.value, 10));
                    }}
                />
                <label htmlFor="profile_professionnal_situation_4">{props.professionnalSituationTexts[5]}</label>
            </div>
            <div className="form-item">
                <input
                    type="radio"
                    name="profile_professionnal_situation"
                    id="profile_professionnal_situation_5"
                    value="6"
                    checked={stateProfessionnalSituationValue === 6}
                    onChange={(e) => {
                        setStateProfessionnalSituationValue(parseInt(e.currentTarget.value, 10));
                    }}
                />
                <label htmlFor="profile_professionnal_situation_5">{props.professionnalSituationTexts[6]}</label>
            </div>

            {stateErrorMessage ? <p className="message-errors">{stateErrorMessage}</p> : null}

            <div className="btn-wrapper">
                <Button type="submit" small={true}>
                    Valider
                </Button>

                <Button type="reset" ghost={true} small={true} onClick={props.handleClose}>
                    Annuler
                </Button>
            </div>
        </form>
    );
};

export default ModalProfessionnal;
