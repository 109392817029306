import axios from "axios";
import AsyncStorage from "@callstack/async-storage";
import { apiConfig } from "./config";
import * as userHelper from "./user";


// Authorization: "Bearer " + yourJWTToken

const client = axios.create({
	baseURL: apiConfig.baseUrl,
	headers: {
		Accept: "application/json",
		"X-API-KEY": apiConfig.apiKey,
		"VIP-Key": apiConfig.vipkey,
		"content-type": "application/json",
	},
	data: {
		scope: "",
		id: 34,
	},
});

export default client;

function getUrl(config) {
	if (config.baseURL) {
		return config.url.replace(config.baseURL, "");
	}
	return config.url;
}

// Intercept all requests
client.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("@PepioToken");
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		// console.log(`%c ${config.method.toUpperCase()} - ${getUrl(config)}:`, "color: #0086b3; font-weight: bold", config);
		return config;
	},
	(error) => Promise.reject(error)
);

// Intercept all responses
client.interceptors.response.use(
	async (response) => {
		// console.log(`%c ${response.status} - ${getUrl(response.config)}:`, "color: #008000; font-weight: bold", response);
		return response;
	},
	(error) => {
		// console.log("%c ERROR", "color: #a71d5d; font-weight: bold", error);

		// console.log('error.response', error.response, error.response.config.url.startsWith('Feed/ReportMessage'));
		
		if (error.response.status === 401 && (!error.response.config.url.startsWith('Feed/ReportMessage'))) {
			userHelper.signOutUser();
		} else if(error.response.config.url.startsWith('Feed/ReportMessage')) {
			return error.response;
		}
		
		return Promise.reject(error);
	}
);
