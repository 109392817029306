export const breakpoints = {
  's': 690,
  'm': 960,
  'l': 1024,
  'xl': 1180,
  'xxl': 1440,
  'xxxl': 1920,
};

export const mq = (breakpoint, min) => `@media screen and (${(min === true) ? 'min' : 'max'}-width: ${breakpoints[breakpoint]}px)`;

// REACT_APP_VIP_KEY