//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";

import { useHistory, useLocation } from "react-router-dom";
import * as surveyHelper from "../../fetch/survey";
import { usePubNub } from "pubnub-react";

import { Container, Content, BackContainer, SuveyQuestionsContainer, SurveyProgressContainer } from "./styled";
import SurveyProgress from "./components/surveyProgress";
import Question from "./components/question";
import Visual from "./components/visual";
import * as userHelper from "../../fetch/user";
import vip from "../../vip";

const SurveyDetails = (): Node => {
    const history = useHistory();
    const pubnub = usePubNub();
    const location = useLocation();
    const surveyId = location.state && location.state.surveyId ? location.state.surveyId : "";
    const [survey, setSurvey] = useState<Survey | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState<QuestionObject | null>(null);
    const [isLiveShow, setIsLiveShow] = useState(false);
    const [questionClosed, setQuestionClosed] = useState(false);

    const vipCopy = vip.copy;

    useEffect(() => {
        if (!location.state || !location.state.surveyId) {
            return history.push("/");
        }
        // Get the survey
        async function fetchData() {
            const result = await surveyHelper.getSurveyforUser(surveyId);

            // debug
            // result.hideQuestionsList = true;
            // result.skipIntro = true;
            // result.surveyCustomIdentifier = 'frite';

            setSurvey(result);
            if (result.diffusionType === "Live" && result.liveShowStatus === "Started" && result.liveSurveyCurrentQuestion) {
                console.log("Activating Question =>", result.liveSurveyCurrentQuestion);
                activateQuestion(result.liveSurveyCurrentQuestion, result);
            } else {
                setCurrentQuestionIndex(0); // live survey, do not set the first quesiton (waiting for live action)
            }
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* ------------------------------------------ */
    // Set the currentQuesiotn when index change
    /* ------------------------------------------ */
    useEffect(() => {
        if (survey && survey.questions && survey.questions.length > 0) {
            const question = survey.questions[currentQuestionIndex];
            setCurrentQuestion(question);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestionIndex]);

    /* ------------------------------------------ */
    // Move to next question
    /* ------------------------------------------ */
    const moveToNextQuestion = async () => {
        // reload the survye to ensure that responses are taken into account
        const result = await surveyHelper.getSurveyforUser(surveyId);
        setSurvey(result);

        // For live shows, don't move to next quesiton but wait for admin action
        if (isLiveShow) {
            setCurrentQuestionIndex(-1);
            setCurrentQuestion(null);
            return;
        }
        // For classic survey, move to next question

        if (survey && survey.questions && currentQuestion && currentQuestion.order < survey.questionCount) {
            const currentIndex = currentQuestionIndex;
            setCurrentQuestionIndex((c) => c + 1); // increment question index
            setCurrentQuestion(survey.questions[currentIndex + 1]); // set the current quesiton to the next one
        } else {
            if (survey) {
                history.push("/Thanks", { surveyId: survey.id });
            }
        }
    };

    /* ------------------------------------------ */
    // Subscribe to channels & messages
    /* ------------------------------------------ */
    useEffect(() => {
        async function startContent() {
            if (survey) {
                if (survey.diffusionType !== "Live") {
                    setIsLiveShow(false);
                    if (survey.questions && survey.questions.length > 0) {
                        setCurrentQuestion(survey.questions[lastQuestionAnswered()]);
                    }
                } else {
                    setIsLiveShow(true);
                    setCurrentQuestionIndex(-1);
                    setCurrentQuestion(null);
                }
            }
        }

        startContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [survey]);

    useEffect(() => {
        let listener;

        async function startpubSub() {
            console.log("Starting Pubsub");
            // We need to make sure that PubNub is defined and connect the user only if survey diffusionType is Live
            if (pubnub && survey && survey.diffusionType === "Live") {
                // Set the UUID of our user to their chosen emoji
                const userid = await userHelper.getUserId();
                pubnub.setUUID(`User-${userid}`);

                console.log(`Starting Pubsub => UUID:${userid}`);

                // Create a listener that will push new messages to our `messages` variable
                // using the `setMessages` function.
                listener = {
                    message: (envelope) => {
                        //console.log(`message recieved => ${envelope.message.content}`);
                        const jsonData = JSON.parse(envelope.message.content);
                        //console.log(envelope.message.content)
                        switch (jsonData.Action) {
                            case "StartQuestion":
                            case "ShowQuestionResults":
                                activateQuestion(jsonData.QuestionId);
                                break;
                            case "CloseQuestion":
                                closeQuestion(jsonData.QuestionId);
                                break;
                            case "CloseSurvey":
                                stopLive();
                                break;
                            default:
                                break;
                        }
                    },
                };

                // Add the listener to pubnub instance and subscribe to `chat` channel.
                pubnub.addListener(listener);
                console.log(`Subsribing to => :${survey.id}`);
                pubnub.subscribe({
                    channels: [`LiveSurvey-${survey.id}`],
                    withPresence: true,
                });
                console.log(`Subsribing to => :${survey.id} Done.`);

                // We need to return a function that will handle unsubscription on unmount
            }
        }
        startpubSub();

        return () => {
            if (pubnub && listener && survey && survey.diffusionType === "Live") pubnub.removeListener(listener);
            pubnub.unsubscribeAll();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pubnub, survey]);

    /* ------------------------------------------ */
    // set the last answered question
    /* ------------------------------------------ */
    const lastQuestionAnswered = (): number => {
        if (survey && survey.questions) {
            for (let i = 0; i < survey.questions.length; i++) {
                if (survey.questions[i].alreadyAnswered === false) {
                    return i;
                }
            }
        }
        return 0;
    };

    /* ------------------------------------------ */
    // activate a question
    /* ------------------------------------------ */
    const activateQuestion = (data, surveyData) => {
        setQuestionClosed(false);

        let _survey = survey;
        if (_survey === null && surveyData !== null) {
            _survey = surveyData;
        }

        console.log("Actinvating question done 1 =>", data);
        const quesionId = parseInt(data, 10);
        if (_survey && _survey.questions) {
            const questionToActivateIndex = _survey.questions.findIndex((p) => p && p.id === quesionId);
            setCurrentQuestionIndex(questionToActivateIndex);
            setCurrentQuestion(_survey?.questions[questionToActivateIndex]);
            console.log("Actinvating question done =>", data);
        }
    };

    /* ------------------------------------------ */
    // close questions
    /* ------------------------------------------ */
    const closeQuestion = (quesionId) => {
        setCurrentQuestionIndex(-1);
        setCurrentQuestion(null);
        setQuestionClosed(true);
    };

    /* ------------------------------------------ */
    // close questions
    /* ------------------------------------------ */
    const stopLive = () => {
        if (survey) {
            history.push("/Thanks", { surveyId: survey.id });
        } else {
            history.push("/Thanks");
        }

        // reset question status
        setCurrentQuestionIndex(-1);
        setCurrentQuestion(null);
    };

    if (survey) {
        return (
            <Container>
                <Content>
                    <BackContainer>
                        <button
                            className="btn-back"
                            onClick={() => {
                                history.push("/survey-details/" + surveyId, { surveyId: surveyId });
                            }}
                            style={{ color: survey.textColor }}
                        >
                            <ion-icon name="chevron-back"></ion-icon>
                            {vipCopy["back-to-survey-page"]}
                        </button>
                    </BackContainer>

                    {currentQuestion && (
                        <SurveyProgressContainer>
                            <SurveyProgress total={survey.questions.length} currentQuestionIndex={currentQuestionIndex} textColor={survey.textColor} />
                        </SurveyProgressContainer>
                    )}
                    <SuveyQuestionsContainer>
                        <Question question={currentQuestion} onMoveToNextQuestion={moveToNextQuestion} survey={survey} questionClosed={questionClosed} currentQuestionIndex={currentQuestionIndex} />
                    </SuveyQuestionsContainer>
                </Content>

                <Visual survey={survey} question={currentQuestion} />
            </Container>
        );
    }
    return null;
};

export default SurveyDetails;
