import ReactGA from "react-ga4";

export const gaInit = () =>{
    if(process && process.env && process.env.REACT_APP_GA) {
    
        ReactGA.initialize([
            {
              trackingId: process.env.REACT_APP_GA,
              gaOptions: {},
              gtagOptions: {}
            }
        ]);
    }
}

export const gaSend = (title) => {
    if(process && process.env && process.env.REACT_APP_GA) {
        console.log('gaSend', title);
        ReactGA.send({ hitType: "pageview", title: title });
    }
};
