//@format
//@flow
import { categoriesLayers, categoriesMap, defaultAvatar } from "./components/labSettings";
import React, { useEffect, useState, useRef, useCallback } from "react";
import avatarPlaceholder from "../../assets/avatar-lab-placeholder.png";
import AvatarCategories from "./components/avatarCategories";
import { useHistory } from "react-router-dom";
import AsyncStorage from "@callstack/async-storage";
import * as userHelper from "../../fetch/user";
import { Button } from "../../styled-common";
import { useForm } from "react-hook-form";
import type { Node } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    saveAvatar?: Function,
    handleClose: Function,
    fromSignUp: boolean,
};

const ModalAvatarLab = (props: Props): Node => {
    const history = useHistory();
    const [stateUser, setStateUser] = useState<PepioUser | null>(null);
    const [stateUserLoaded, setStateUserLoaded] = useState(false);
    const [stateLoaded, setStateLoaded] = useState(false);
    const [editor, setEditor] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const avatarForm = useRef(null);

    const [allAssets, setAllAssets] = useState(null);
    const [avatarData, setAvatarData] = useState({});
    const [currentCategory, setCurrentCategory] = useState("faces");

    const setAvatarFromNames = (existingAvatar, allAssets) => {
        let existingAvatarArray = Object.entries(existingAvatar);
        const newAvatarData = {};

        try {
            existingAvatarArray.map((selectedItem, index) => {
                allAssets[selectedItem[0]].map((item) => {
                    if (item.name === selectedItem[1]) {
                        newAvatarData[selectedItem[0]] = item;
                    }
                });
            });
        } catch {}
        return newAvatarData;
    };

    const renderAvatar = () => {
        let avatarDataArray = Object.entries(avatarData);
        avatarDataArray = avatarDataArray.sort((a, b) => categoriesLayers.indexOf(a[0]) - categoriesLayers.indexOf(b[0]));
        const renderOutput = avatarDataArray.map((item, index) => {
            return <img key={`avatar-item-${index}`} src={item[1].fullPicture} style={{ zIndex: categoriesMap[item[0].layerNumber], borderRadius: "150px" }} />;
        });
        return <div className="assets-wrapper">{renderOutput}</div>;
    };

    const generatedAvatarToSend = () => {
        let avatarDataToSend = {};
        let avatarDataArray = Object.entries(avatarData);
        avatarDataArray.map((item) => {
            avatarDataToSend[item[0]] = item[1].name;
        });
        return JSON.stringify(avatarDataToSend);
    };

    const { handleSubmit } = useForm();

    const fetchData = useCallback(async () => {
        if (!stateUserLoaded) {
            const userData = await userHelper.getUser();
            // console.log('userData', userData);
            setStateUser(userData);
            setStateUserLoaded(true);

            if (userData.hasAvatar || props.fromSignUp === true) {
                setEditor(true);
            } else {
                setEditor(false);
            }

            const avatarAssets = await userHelper.getAvatarAssets();
            console.log("avatarAssets", avatarAssets);

            setAllAssets(avatarAssets);

            if (userData && userData.hasAvatar && userData.avatarCode) {
                setAvatarData(setAvatarFromNames(JSON.parse(userData.avatarCode), avatarAssets));
            } else {
                setAvatarData(setAvatarFromNames(defaultAvatar, avatarAssets));
            }

            setStateLoaded(true);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSubmitForm = async (data) => {
        if (editor) {
            const responseAvatar = await userHelper.saveAvatarCode(generatedAvatarToSend());
            if (responseAvatar) {
                setEditor(false);
                setConfirmation(true);
            } else {
            }
        } else if (!editor && confirmation) {
            // await AsyncStorage.removeItem("@AnnonymousLogin");
            const loginFromSurvey = await AsyncStorage.getItem("@LoginFromSurvey");
            if (loginFromSurvey) {
                history.push("/survey-details/" + loginFromSurvey, { surveyId: parseInt(loginFromSurvey), fromLogin: true });
            } else {
                // history.push("/home");
                handleClose();
            }
        } else {
            setEditor(true);
        }
    };

    const handleClose = () => {
        props.handleClose();
        setAvatarData({});
        setEditor(false);
        setConfirmation(false);
        setStateUserLoaded(false);
    };

    const renderCategoryItems = () => {
        const renderData = JSON.parse(JSON.stringify(allAssets[currentCategory]));
        if (categoriesMap[currentCategory].showRemove === true) {
            renderData.unshift({ removeButton: true });
        }

        return renderData.map((item, index) => {
            if (item.removeButton === true) {
                return (
                    <div className="item" key={`data-item-rmv-${index}`}>
                        <button
                            className="item-btn-remove"
                            onClick={(e) => {
                                e.preventDefault();
                                const newAvatarData = JSON.parse(JSON.stringify(avatarData));
                                delete newAvatarData[currentCategory];
                                setAvatarData(newAvatarData);
                            }}
                        >
                            <ion-icon name="close" />
                        </button>
                    </div>
                );
            } else {
                return (
                    <div className="item" key={`data-item-${index}`}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                const newElement = {};
                                newElement[currentCategory] = item;
                                setAvatarData({ ...avatarData, ...newElement });
                            }}
                        >
                            <LazyLoadImage src={item.thumb} width={64} height={64} />
                        </button>
                    </div>
                );
            }
        });
    };

    return (
        <>
            {stateUserLoaded && stateLoaded ? (
                <form onSubmit={handleSubmit(onSubmitForm)} ref={avatarForm}>
                    <div className="visual">{!editor && !confirmation ? <img src={avatarPlaceholder} alt="" /> : <div className="editor-visual">{renderAvatar()}</div>}</div>
                    <div className="content">
                        {/* <form onSubmit={handleSubmit(onSubmitForm)} ref={avatarForm}> */}
                        {!editor && !confirmation ? (
                            <div className="content-inner explaination">
                                <h2 className="title">Créez un avatar</h2>
                                <p>Vous pouvez créer et personnaliser votre propre avatar. Cet avatar remplacera la photo de profil.</p>
                            </div>
                        ) : !confirmation ? (
                            allAssets ? (
                                <div className="content-inner editor">
                                    <div className="categories-strip">
                                        <AvatarCategories setCurrentCategory={setCurrentCategory} />
                                    </div>
                                    <div className="items">{renderCategoryItems()}</div>
                                </div>
                            ) : null
                        ) : (
                            <div className="content-inner confirmation">
                                <h2>Votre avatar a bien été créé</h2>
                                <Button
                                    type="button"
                                    buttonStyle="text"
                                    onClick={() => {
                                        setConfirmation(false);
                                        setEditor(true);
                                    }}
                                >
                                    Editer
                                </Button>
                            </div>
                        )}

                        <div className="btn-wrapper">
                            <Button type="submit" block>
                                {!confirmation ? (!editor ? "Créer mon avatar" : "Sauvegarder") : "Terminer"}
                            </Button>
                        </div>
                        {/* </form> */}
                    </div>
                </form>
            ) : null}
        </>
    );
};

export default ModalAvatarLab;
