//@format
//@flow
import React, { useState } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import AsyncStorage from "@callstack/async-storage";

import * as userHelper from "../../fetch/user";

import { useHistory, useLocation } from "react-router-dom";

import { Container, Visual, Content } from "./styled";
import { Button } from "../../styled-common";

import avatarPlaceholder from "../../assets/avatar-lab-placeholder.png";

import vip from "../../vip";

const SignUpAvatar = (): Node => {
    const history = useHistory();

    const onPressGoToAvatar = async () => {
        await AsyncStorage.setItem("@ModalAvatarAlreadyShowed", "true");
        history.push("/my-profile", { avatar: true });
        history.go();
    };

    const onPressClose = async () => {
        // await AsyncStorage.removeItem("@AnnonymousLogin");
        await AsyncStorage.setItem("@ModalAvatarAlreadyShowed", "true");
        const loginFromSurvey = await AsyncStorage.getItem("@LoginFromSurvey");
        const loginFromChannel = await AsyncStorage.getItem("@LoginFromChannel");
        if (loginFromSurvey) {
            history.push("/survey-details/" + loginFromSurvey, { surveyId: parseInt(loginFromSurvey), fromLogin: true });
        } else if (loginFromChannel) {
            history.push("/channel-content/" + loginFromChannel, { channelId: parseInt(loginFromChannel), fromLogin: true });
        } else {
            history.push("/home");
        }
    };

    return (
        <Container>
            <Visual>
                <img src={vip.visuals["login"]} alt="" />
            </Visual>
            <Content>
                <img src={vip.logo} alt={vip.name} className="logo" />
                <h1>Créez un avatar</h1>
                <p>Vous pouvez créer et personnaliser votre propre avatar. Cet avatar remplacera la photo de profil.</p>
                <img src={avatarPlaceholder} alt=" " className="placeholder" />
                <div className="inner">
                    <Button large block onClick={onPressGoToAvatar}>
                        Créer mon avatar
                    </Button>

                    <Button buttonStyle="text" onClick={onPressClose}>
                        Passer
                    </Button>
                </div>
            </Content>
        </Container>
    );
};

export default SignUpAvatar;
