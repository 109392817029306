import styled from "styled-components";
import { colors, fontFamily } from "../../../../styled-common.js";
import { mq } from "../../../../variables.js";
import vip from "../../../../vip.js";

export const VisualContainer = styled.div`
	position: relative;
	width: calc(50% + 120px);
	text-align: center;
	background-color: ${colors.lightgrey};
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) => props.diffusionType !== "Live"
	? `
		background-image: url(${vip.visuals["background-survey"]});
		background-size: cover;
		`
	: `
		background-image: url(${vip.visuals["background-quiz"]});
		background-size: contain;
		background-repeat:no-repeat;
	`}
	



	${mq("m")} {
		width: 100%;

		&::before {
			content: "";
			position: relative;
			width: 100%;
			padding-bottom: 52%;
		}
	}

	img {
		display: block;
		width: 70%;
		padding-top: 5%;
		max-height: 95%;
		object-fit: contain;

		${mq("m")} {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding-top: 0;
			height: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}
`;
