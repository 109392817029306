import styled from 'styled-components';
import { colors, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  min-height: 677px;

  ${mq('l')} {
    min-height: auto;
  }

  p {
    width: 590px;
    max-width: 100%;
    font-size: 40px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue80};
    margin: 0;
    padding-top: 30px;
  }

  .visual {
    position: absolute;
    z-index: -1;
    right: 0;
    height: 677px;
    margin-top: -30px;

    ${mq('l')} {
      display: none;
    }
  }

  button {
    margin-top: 60px;
    width: auto;
    padding-left: 35px;
    padding-right: 35px;
  }
`;