import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../../../styled-common.js";

export const AnswerQuestionsContainer = styled.div`
	display: block;
	// height: calc(100vh - 480px);
	overflow-y: scroll;
	overflow-x: hidden;
`;

export const Answer = styled.div`
	border-bottom: 1px dashed #c8cada;
	padding-top: 10px;
	padding-bottom: 10px;
`;

export const AnswerContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 10px;
	&.active {
		background-color: ${colors.darkBlue10};
	}
`;

export const QuestionsTextContainer = styled.div`
	min-height: 150px;
	h1 {
		font-family: ${fontFamily};
		font-size: 24px;
		font-weight: ${fontFamilyWeightBold};
		color: ${colors.darkblue100};
	}
`;

export const AnswerText = styled.div`
	display: flex;
	p {
		font-family: ${fontFamily};
		font-size: 16px;
		font-weight: 400;
		color: ${colors.darkblue100};
		margin-bottom: 0;

		span {
			margin-left: 20px;
			font-size: 14px;
			color: ${colors.darkblue60};
		}
	}
`;

export const Result = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const ResultProgress = styled.div`
	background-color: ${(props) => (props.textColor  ? props.textColor : colors.darkBlue80)};
	height: 10px;
	border-radius: 5px;
	${(props) => (props.percentage ? `width:${props.percentage !== "0%" ? props.percentage : "10px;"};` : `width: 10px;`)}
	opacity:.5;
	&.active {
		opacity:1;
	}
`;
export const ResultPercentage = styled.div`
	text-align: end;
	font-family: ${fontFamily};
	font-weight: ${fontFamilyWeightBold};
	font-size: 20px;
	color: ${colors.darkBlue80};

	&.active {
		color: ${colors.blue};
	}
`;

export const Button = styled.button`
	display: block;
	align-items: center;
	justify-content: center;
	height: 44px;
	margin: 40px 0 20px 0 !important;
	border-radius: 8px;
	line-height: 44px;
	font-weight: ${fontFamilyWeightBold};
	cursor: pointer;
	transition: all 0.4s ease-out;
	border: 2px solid ${colors.blue};
	background: ${colors.blue};
	color: ${colors.white};
	width: 215px !important;

	&:disabled,
	&[disabled] {
		background-color: ${colors.darkBlue40};
		border: 2px solid ${colors.darkBlue40};

		color: ${colors.darkBlue60};
	}

	${(props) =>
		props.buttonStyle === "text"
			? `
    background-color: transparent;
    color: ${colors.blue};
    text-decoration: none;
    border-color: transparent;
    border-width: 1px;

    &:hover {
      background-color: transparent;
      color: ${colors.darkBlue80};
      border-color: transparent;
    }
  `
			: null}
`;
