import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";

export const Card = styled.div`
    position: relative;
    border-radius: 8px;
    background: ${colors.white};
    box-shadow: 0px 7px 30px rgba(6, 10, 74, 0.2);

    overflow: hidden;
    transition: all 0.3s ease-out;
    cursor: pointer;

    .visual {
        position: relative;
        max-width: 100%;
        padding: 0 0 55% 0;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-out;
        }
    }

    .content {
        position: relative;
        padding: 20px 20px 70px;

        .category {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            color: ${colors.blue};
            letter-spacing: 0.17em;
        }

        .title {
            font-family: ${fontFamily};
            font-size: 20px;
            font-weight: ${fontFamilyWeightBold};
            color: ${colors.darkBlue100};
        }

        .description {
            color: ${colors.darkBlue80};
        }
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 109px;
        max-width: 50%;
        height: 52px;
        background: ${colors.darkBlue10};
        border-radius: 0 16px 0 0;

        img {
            display: block;
            width: 80%;
            height: 80%;
            object-fit: contain;
        }
    }

    .follow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 12px;
        width: 109px;
        max-width: 50%;
        height: 52px;
        background: transparent;
        color: ${colors.blue};
        font-weight: ${fontFamilyWeightBold};

        ion-icon {
            margin-left: 10px;
            font-size: 20px;
        }

        &:hover {
            color: ${colors.darkBlue100};
        }
    }

    &:hover {
        box-shadow: 0px 7px 30px rgba(6, 10, 74, 0.05);

        .visual img {
            transform: scale(1.05);
        }
    }
`;

export const Container = styled.div``;
