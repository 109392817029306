import React, {useState, useRef} from 'react';
import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep2 = (props) => {
  const selectOption = async (genderValue) => {
    const response = await userHelper.setUserGender(genderValue);
    // console.log('response', response);

    if(response) {
      props.nextStep();
    }
  }

  return (
    <div>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>
            Merci de mentionner votre genre
          </h2>
        </div>
        <div className="step-content-footer">
          <Button onClick={() => {
            selectOption(1);
          }}>Homme</Button>
          
          <Button type="button" onClick={() => {
            selectOption(2);
          }}>Femme</Button>
          
          <Button onClick={() => {
            selectOption(3);
          }}>Autre</Button>
        </div>
      </div>
    </div>
  );
};

export default WizardStep2;