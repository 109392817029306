import React from "react";
import client from "./guestClient";
import toast from "react-toastify";
import * as userHelper from "./user";

export const getAllChannels = () => {
	return client
		.get("Channel/List")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getChannel = (channelId) => {
	return client
		.get("Channel/GetChannelDetails/" + channelId)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getSurveys = async (channelId, maxSurveys) => {
	// Api/V1/Survey/GetLastSurveysforUser/{channelId}/{pepioUserId}/{max}/{language}

	const userId = await userHelper.getUserId();
	const userLanguage = await userHelper.getLanguage();

	return client
		.get("Survey/GetLastSurveysforUser/" + channelId + "/" + userId + "/" + maxSurveys + "/" + userLanguage)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getCategories = () => {
	return client
		.get("Channel/GetCategories")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getCategory = (id: number) => {
	return client
		.get("Channel/GetCategory/" + id)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getChannelsByCategory = (categoriId) => {
	return client
		.get("Channel/ListByCategory/" + categoriId)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const subscribeToChannel = async (channelid, channelName) => {
	const userId = await userHelper.getUserId();
	const data = {
		userProfileId: userId,
		channelId: channelid,
	};

	// post the like
	const response = await client.post("Channel/Subscribe", data);
	if (response.status === 200) {
		// alert('Vous êtes maintenant abonné a la chaine "' + channelName + '"');
		// Toast.show({
		//   text: 'Vous êtes maintenant abonné a la chaine "' + channelName + '"',
		//   buttonText: 'Ok',
		//   duration: 2000,
		//   type: 'success',
		// });
	}

	return response;
};

export const unsubscribeToChannel = async (channelid, channelName) => {
	const userId = await userHelper.getUserId();
	const data = {
		userProfileId: userId,
		channelId: channelid,
	};

	// post the like
	const response = await client.post("Channel/Unsubscribe", data);
	if (response.status === 200) {
		// alert("Vous n'êtes plus abonné a la chaine \"" + channelName + '"');
		// Toast.show({
		//   text: 'Vous n\'êtes plus abonné a la chaine "' + channelName + '"',
		//   buttonText: 'Ok',
		//   duration: 2000,
		//   type: 'default',
		// });
	}

	return response;
};

// -------------------------------------------------------------
// Get channel from shortcode
// if the channel is private, the user is automaticaly subscribed to the channel
// -------------------------------------------------------------
export const getChannelForUserByShortCode = async (shortCode) => {
	let pepioUserId = await userHelper.getUserId();

	if(!pepioUserId || isNaN(pepioUserId)) {
		await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
		pepioUserId = await userHelper.getUserId();
	}

	return client
		.get("channel/getChannelForUserByShortCode/" + shortCode + "/" + pepioUserId)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch((error) => {
			return false;
		});
};
