import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../../../styled-common.js";
import { mq } from '../../../../variables.js';

export const Wrapper = styled.div`
	min-height: 150px;
	text-align: center;
	height: calc(100vh - 400px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px;

	${mq('m')} {
		height: auto;
		padding: 0;
	}

	h1 {
		font-family: ${fontFamily};
		font-size: 36px;
		font-weight: ${fontFamilyWeightBold};
		line-height: 40px;
		color: ${colors.darkblue100};
	}

	p {
		font-family: ${fontFamily};
		font-size: 24px;
		font-weight: ${fontFamilyWeightBold};
		line-height: 30px;
		color: ${colors.darkblue80};
		padding-left: 70px;
		padding-right: 70px;
		margin-top: 15px;
		margin-bottom: 15px;

		&.small {
			font-size: 16px;
			font-weight: 400;
			color: ${colors.darkblue80};
		}
	}
`;
