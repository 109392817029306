import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep8 = (props) => {

  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    validateCriteriaMode: 'all',
  });

  const submitForm = async (data) => {
    // console.log('WizardStep3 data', data);
    const response = await userHelper.setUserChildsAmount(data.childsAmount);
    // console.log('WizardStep3 response', response);
    if(response) {
      props.nextStep();
    }
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>Combien avez-vous d’enfants ?</h2>

          <div className="form-item">
            <label>Votre nombre d'enfants</label>
            <div className="form-item-field">
              <input type="number" name="childsAmount" ref={register({required:true , min: 0, max: 35 })} />
            </div>
            {errors.childsAmount ? <div className="form-error"><p>Vous devez entrer une valeur entre 0 et 35</p></div> : null}
          </div>
        </div>
        <div className="step-content-footer">
          <Button type="submit" disabled={!formState.isValid}>Suivant</Button>
        </div>
      </div>
    </form>
  );
};

export default WizardStep8;