// @format
// @flow
import React, { useEffect } from "react";
import type { Node } from "react";
import { useHistory } from "react-router-dom";
import * as surveyHelper from "../../fetch/survey";
import * as userHelper from "../../fetch/user";
import AsyncStorage from "@callstack/async-storage";
import { gaSend } from "../../components/gaSend";

const SurveyCode = (): Node => {
    const history = useHistory();

    // console.log('SurveyCode');

    useEffect(() => {
        async function fetchData() {
            const cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
            console.log("SurveyCode", cognitoUserId);
            if (!cognitoUserId) {
                console.log("Survey code Store Annonymous");
                await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
            } else {
                // console.log('Survey code Have cognito user id');
            }

            const url = document.location.toString() + "";
            const surveyCode = url.substring(url.lastIndexOf("/") + 1);
            // console.log('surveyCode', surveyCode);
            const survey = await surveyHelper.getSurveyForUserByShortCode(surveyCode);
            console.log('survey code survey', survey);
            gaSend(`Survey code: ${surveyCode} | Channel: ${survey.channelName} | Survey: ${survey.title}`);
            history.push("/survey-details/" + survey.id, { surveyId: survey.id });
        }

        fetchData();
    });

    return null;
};

export default SurveyCode;
