import React, {useState} from 'react';
import { useForm } from "react-hook-form";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Autocomplete from 'react-autocomplete';
import * as userHelper from '../../../../fetch/user';
import client from '../../../../fetch/guestClient';

import { Button } from '../../../../styled-common';

const WizardStep4 = (props) => {

  const [localitiesList, setLocalitiesList] = useState([]);
  const [localitiesListLoading, setLocalitiesListLoading] = useState(false);

  const [cityValue, setCityValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);

  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    validateCriteriaMode: 'all',
  });

  const saveGmapField = async (data) => {
    if(selectedValue && selectedValue.zip) {
      await userHelper.setUserPostalcode(selectedValue.locality);
      props.nextStep();
    }
  }

  const submitForm = async (data) => {
    await saveGmapField();
  }

  const getLocalitiesList = async (value) => {
    // /api/v1/DemographicData/GetGeographicPlace/{locality}
    setLocalitiesListLoading(true);
    const locList = await client.get(`/Profile/searchlocality/${value}`);
    // console.log('locList', locList);
    if(locList) {
      const newList = locList.data.map((item, index) => {
        return {
          index: index,
          label: `${item.locality}, ${item.zip}`,
          zip: item.zip,
          locality: item.locality,
        };
      });
  
      setLocalitiesList(newList);
      setLocalitiesListLoading(false);
    }
    
  }


  return (

    <form onSubmit={handleSubmit(submitForm)}>
    <div className="step-content">
      <div className="step-content-inner">
        <h2>Dans quelle commune habitez-vous?</h2>

        <div className="form-item">
          <label>Votre commune</label>

          <div className="locality-selector">
            <Autocomplete
              value={cityValue}
              wrapperProps={{className: "form-item-field"}}
              wrapperStyle={{display: 'flex'}}
              inputProps={{ id: 'locality_selector', type: 'text', placeholder: 'Encodez le nom de votre commune (minimum 3 caractères)' }}
              items={localitiesList}
              getItemValue={(item) => item.label}
              onSelect={(value, state) => {
                // console.log('item selected', value, state);
                setCityValue(value);
                setSelectedValue(state);
              }}
              onChange={(event, value) => {
                
                setCityValue(value);

                if(value.length > 2) {
                  getLocalitiesList(value);
                }
              }}
              renderInput={(props) => {
                return <input className="" {...props} />
              }}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                  key={item.index}
                >{item.label}</div>
              )}
              menuStyle={{
                'position': 'absolute',
                'top': '55px',
                'left': '0',
                'width': '100%',
                'maxHeight': '180px',
                'overflow': 'auto',
                'boxShadow': '0px 7px 30px rgba(6, 10, 74, 0.20)',
                'borderRadius': '8px'
              }}
              // renderMenu={(items, value) => (
              //   <div className="menu">
              //     {value === '' || value.length < 3 ? (
              //       <div className="item">Encodez le nom de votre commune (minimum 3 caractères)</div>
              //     ) : localitiesListLoading ? (
              //       <div className="item">Chargement...</div>
              //     ) : items.length === 0 ? (
              //       <div className="item">Aucun résultat pour {value}</div>
              //     ) : items}
              //   </div>
              // )}
              isItemSelectable={(item) => !item.header}
            />
          </div>
          
        </div>
      </div>
      <div className="step-content-footer">
        <Button type="submit" disabled={!formState.isValid}>Suivant</Button>
      </div>
    </div>
    </form>
  );
};

export default WizardStep4;