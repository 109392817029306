//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import AsyncStorage from "@callstack/async-storage";

import client from "../../fetch/guestClient";
import * as userHelper from "../../fetch/user";

import { useHistory } from "react-router-dom";

import { Container, Visual, Content } from "./styled";
import { Button } from "../../styled-common";

import vip from "../../vip";

const SignUpTermsAndConditions = (): Node => {
    const history = useHistory();

    const { register, handleSubmit, formState } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all",
    });
    const [stateErrorMessage, setStateErrorMessage] = useState(null);
    const [termsLink, setTermsLink] = useState(null);

    //api/v1/WebRedirection/{redirectionReference}

    const getTermsLink = async () => {
        const linkResponse = await client.get("WebRedirection/TermsOfUse");
        setTermsLink(linkResponse.data);
    };

    useEffect(() => {
        getTermsLink();
    }, []);

    const setAcceptTerms = async () => {
        const user = await userHelper.getUser();
        console.log("signupterms user", user);
        const responseData = await client.post("Profile/AcceptTerms/", {
            userId: user.id,
            accept: true,
        });

        console.log("signupterms responseData", responseData);
        // console.log('responseData', responseData);

        if (responseData.status === 200) {
            // console.log('----> user', user.userName);
            const annonymousLogin = await AsyncStorage.getItem("@AnnonymousLogin");
            if (!user.userName && !annonymousLogin) {
                // console.log('update username');
                history.push("/signup-username");
            } else {
                // await AsyncStorage.removeItem("@AnnonymousLogin");
                const loginFromSurvey = await AsyncStorage.getItem("@LoginFromSurvey");
                const loginFromChannel = await AsyncStorage.getItem("@LoginFromChannel");
                if (loginFromSurvey) {
                    history.push("/survey-details/" + loginFromSurvey, { surveyId: parseInt(loginFromSurvey), fromLogin: true });
                } else if (loginFromChannel) {
                    history.push("channel-content/" + loginFromChannel, { channelId: parseInt(loginFromChannel), fromLogin: true });
                } else {
                    history.push("/home");
                }
            }
        }

        return responseData;
    };

    const onSubmitForm = async (data) => {
        // const username = stateUsername;
        const acceptTerms = data.acceptTerms;
        let responseData ;
        if (acceptTerms) {
            responseData = await setAcceptTerms();
            if (responseData.status === 200) {
            }
        }
    };

    return (
        <Container>
            <Visual>
                <img src={vip.visuals["login-terms"]} alt="" />
            </Visual>
            <Content>
                <img src={vip.logo} alt={vip.name} className="logo" />

                <h1>La sécurité de vos données est notre priorité</h1>
                <p>Vos données personnelles sont protégées et sécurisées. Si vos témoignages et opinions sont utilisées dans le cadre de notre activité, rassurez-vous, celles-ci sont entièrement anonymisées.</p>
                <p>Chez {vip.name}, il est impensable que vos données personnelles soient partagées ou revendues à des organisations ou des entreprises tierces. Croix de bois, croix de fer !</p>
                <p>
                    En procédant à votre inscription, vous acceptez{" "}
                    <a href={termsLink} target="_blank">
                        les termes et conditions d’utilisation
                    </a>{" "}
                    de {vip.name}.
                </p>
                <div className="inner">
                    <form onSubmit={handleSubmit(onSubmitForm)}>
                        <div className="field">
                            <div className="field-wrapper">
                                <input
                                    type="checkbox"
                                    name="acceptTerms"
                                    id="acceptTerms"
                                    onChange={(event) => {
                                        const text = event.currentTarget.value;
                                        // setValue('acceptTerms', text, true);
                                        // // console.log('acceptTerms', text, formState.isValid);
                                    }}
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <label htmlFor="acceptTerms">J’accepte les conditions d’utilisation de mes données privées</label>
                            </div>
                        </div>

                        {stateErrorMessage ? <p className="message-errors">{stateErrorMessage}</p> : null}

                        <Button large block type="submit" disabled={!formState.isValid}>
                            Valider
                        </Button>
                    </form>
                </div>
            </Content>
        </Container>
    );
};

export default SignUpTermsAndConditions;
