import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";
import { mq } from "../../variables.js";

import noDataVisual from "../../assets/myprofile-nodata.svg";
import rangeIcon from "../../assets/range.svg";

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.visible ? "1" : "0")};
    pointer-events: ${(props) => (props.visible ? "all" : "none")};

    ul {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 30px;
    }

    .label-main {
        font-weight: 600;
    }

    .message-errors {
        font-size: 14px;
        color: ${colors.errorColor};
    }

    .modal-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }

    .modal-content {
        position: relative;
        width: calc(100% - 20px);
        max-height: calc(100% - 120px);
        max-width: 450px;
        margin: 70px 10px;
        padding: 20px 25px 25px;
        overflow: auto;
        background: ${colors.white};
        border-radius: 8px;

        .title {
            margin-top: 0;
            margin-right: 50px;
            font-size: 20px;
            color: ${colors.darkBlue80};
            font-weight: ${fontFamilyWeightBold};
        }

        .btn-wrapper {
            margin: 30px 0 0;

            button {
                display: inline-flex;
                width: auto;
                padding: 16px 12px;

                + button {
                    margin-left: 15px;
                }
            }
        }
    }

    .modal-close {
        display: flex;
        position: absolute;
        top: 25px;
        right: 20px;
        background: transparent none;
        border: 0;
        color: ${colors.blue};
        cursor: pointer;

        ion-icon {
            font-size: 24px;
        }

        &:hover {
            color: ${colors.darkblue100};
        }
    }

    .form-error {
        margin: 0 0 20px;
        p {
            margin: 0;
            font-size: 12px;
            color: red;

            + p {
                margin-top: 5px;
            }
        }
    }

    .form-item {
        label {
            font-size: 16px;
            color: ${colors.blue};
        }

        input[type="text"],
        input[type="number"] {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            font-size: 16px;
            color: ${colors.blue};
            border: 1px solid ${colors.darkBlue20};
            border-radius: 4px;
            width: calc(100% - 12px);
            font-family: ${fontFamily};
        }

        input[type="radio"] {
            position: absolute;
            left: -9999px;

            + label {
                display: inline-block;
                position: relative;
                padding-left: 25px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid ${colors.darkBlue20};
                    transition: all 0.2s ease-in;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 3px;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: transparent;
                    transition: all 0.2s ease-in;
                }
            }

            &:checked + label {
                &::before {
                    border-color: ${colors.blue};
                }
                &::after {
                    content: "";
                    background-color: ${colors.blue};
                }
            }
        }
        + .form-item {
            margin-top: 15px;
        }

        &-avatar {
            max-width: calc(50% - 20px);
            margin-right: 20px;

            canvas {
                width: 100% !important;
                height: auto !important;
            }

            .avatar-existing {
                width: 140px;
                height: 140px;
                object-fit: cover;
                border-radius: 50%;
                margin: 13px;
                cursor: pointer;
            }
        }
    }

    .group-skin {
        display: flex;
        li {
            width: calc(25% - 10px);
            max-width: 90px;
            margin: 0;
            + li {
                margin-left: 10px;
                margin-top: 0 !important;
            }
        }
    }
    .form-item-color {
        label {
            width: 100%;
            height: 40px;
            border: 2px solid ${colors.darkBlue20};
            border-radius: 8px;
            padding: 0 !important;

            &::before,
            &::after {
                display: none !important;
            }

            span {
                display: none !important;
            }
        }

        input[type="radio"]:checked + label {
            border-color: ${colors.blue} !important;
        }
    }

    .avatar-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }

    .avatar-input-file {
        &-label {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed ${colors.blue};
            padding: 7px 10px;
            border-radius: 4px;
            color: ${colors.blue};
            font-size: 14px;
            font-weight: ${fontFamilyWeightBold};
            cursor: pointer;

            ion-icon {
                margin-right: 10px;
            }
        }
        &-field {
            display: none !important;
        }
    }

    .avatar-rotate {
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;

        label {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }
        button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: ${colors.blue};
            border: 0;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            padding: 0;

            svg {
                width: 12px;
                height: 14px;
            }
        }
    }

    .avatar-zoom {
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;

        label {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }

        input[type="range"] {
            -webkit-appearance: none;
            margin: 10px 0;
            width: 100%;
        }
        input[type="range"]:focus {
            outline: none;
        }
        input[type="range"]::-webkit-slider-runnable-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            background: ${colors.darkBlue10};
            border-radius: 3px;
            border: 0px solid ${colors.darkBlue10};
        }
        input[type="range"]::-webkit-slider-thumb {
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: ${colors.blue} url(${rangeIcon}) no-repeat 50% 50%;
            background-size: 70% auto;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -9px;
        }
        input[type="range"]:focus::-webkit-slider-runnable-track {
            background: ${colors.darkBlue20};
        }
        input[type="range"]::-moz-range-track {
            animate: 0.2s;
            width: 100%;
            height: 6px;
            cursor: pointer;
            background: ${colors.darkBlue10};
            border-radius: 3px;
            border: 0px solid ${colors.darkBlue10};
        }
        input[type="range"]::-moz-range-thumb {
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: ${colors.blue} url(${rangeIcon}) no-repeat 50% 50%;
            background-size: 70% auto;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -9px;
        }
        input[type="range"]::-ms-track {
            animate: 0.2s;
            color: transparent;
            width: 100%;
            height: 6px;
            cursor: pointer;
            background: ${colors.darkBlue10};
            border-radius: 3px;
            border: 0px solid ${colors.darkBlue10};
        }
        input[type="range"]::-ms-fill-lower {
            background: ${colors.darkBlue10};
            border: 0px solid #000101;
            border-radius: 50px;
        }
        input[type="range"]::-ms-fill-upper {
            background: ${colors.darkBlue10};
            border: 0px solid #000101;
            border-radius: 50px;
        }
        input[type="range"]::-ms-thumb {
            border: 0px solid #000000;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: ${colors.blue} url(${rangeIcon}) no-repeat 50% 50%;
            background-size: 70% auto;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -9px;
        }
        input[type="range"]:focus::-ms-fill-lower {
            background: #ac51b5;
        }
        input[type="range"]:focus::-ms-fill-upper {
            background: #ac51b5;
        }
    }

    .video-themes {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        padding: 0;

        li {
            max-width: 250px;
            width: calc(50% - 10px);

            label {
                cursor: pointer;

                img {
                    display: block;
                    width: calc(100% - 4px);
                    height: auto;
                    border: 2px solid ${colors.darkBlue20};
                    border-radius: 8px;
                    overflow: hidden;
                    opacity: 0.6;
                }
            }

            input {
                opacity: 0;
                position: absolute;
                left: -9999px;
            }

            input:checked + label {
                img {
                    border: 2px solid ${colors.blue};
                    opacity: 1;
                }
            }
        }
    }

    .photooverlay {
        width: 166px;
        height: 166px;
        position: absolute;
        top: 0;
        left: 0;
    }
`;
