import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

import noDataVisual from '../../assets/myprofile-nodata.svg';
import rangeIcon from '../../assets/range.svg';

export const Container = styled.div`
`;

export const Visual = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(50% - 60px);
  margin: 0 30px 0 0;
  background-size: auto 80%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: ${colors.purple};

  img {
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
    height: auto;
    min-height: 50%;
    min-width: 100%;
    transform: translate(0, -40%);
  }
`;

export const ContentHeader = styled.div`
  max-width: 100%;
  padding: 64px 0;
  background: ${colors.darkBlue10};

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  .inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
  }

  h1.title {
    max-width: 610px;
    margin-left: 100px;
    margin-left: auto;
    margin-right: auto;
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    max-width: 610px;
    margin-left: 100px;

    ${mq('m')} {
      margin-left: 0;
    }

    .avatar {
      position: relative;
      width: 120px;
      height: 120px;
      margin-right: 22px;
      border-radius: 50%;
      cursor: pointer;
      background: ${colors.darkBlue20};

      ${mq('m')} {
        width: 60px;
        height: 60px;
      }

      .btn-round {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${colors.white};
        border-radius: 50%;
        width: 30px;
        height: 30px;
        border: 1px solid ${colors.darkBlue10};
        color: ${colors.blue};
        cursor: pointer;

        
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
      }

      &:hover {
        .btn-round {
          background-color: ${colors.blue};
          color: ${colors.white};
        }
      }
    }

    .username {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      flex: 1;

      font-size: 36px;
      color: ${colors.darkBlue100};

      ${mq('m')} {
        width: calc(100% - 100px);

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      input[type="text"] {
        font-size: 36px;
        color: ${colors.darkBlue60};
        border: 1px solid ${colors.darkBlue20};
        max-width: calc(100% - 80px);
        font-family: ${fontFamily};
      }

      ion-icon {
        font-size: 20px;
      }

      .btns {        
        display: flex;
        width: 80px;
      }

      button {
        width: auto;
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
`;

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 70px auto;

  .inner {
    max-width: 610px;
    margin-left: 70px;
    padding: 0 30px;

    ${mq('m')} {
      margin-left: 0;
    }
  }

  h2.title {
    font-family: ${fontFamily};
    font-size: 24px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: ${colors.darkBlue80};
    max-width: 500px;
    margin-bottom: 64px;
  }

  

  .btn-text {
    background: transparent none;
    border: 0;
    color: ${colors.blue};
    font-size: 16px;
    font-weight: ${fontFamilyWeightBold};
    cursor: pointer;

    &:hover {
      color: ${colors.darkBlue100};
    }
  }

  .profile-item {

    &-label {
      margin: 0 0 10px;
      color: ${colors.darkBlue60};
      font-size: 12px;
      line-height: 1.2;
    }

    &-value {
      max-width: auto;
      font-size: 16px;
      line-height: 1.2;
      color: ${colors.darkBlue80};

      &-null {
        color: ${colors.darkBlue40};
      }
    }

    .btn-text {
      float: right;
    }

    + .profile-item {
      padding: 15px 0 0;
      margin: 15px 0 0;
      border-top: 1px solid ${colors.darkBlue20};
    }
  }

  .btn-wrapper {
    margin: 40px 0;
    text-align: center;

    button {
      width: auto;
    }
  }

  .message-errors {
    font-size: 14px;
    color: ${colors.errorColor};
  }

  .nodata {
    padding: 95px 0 110px;
    background: transparent url(${noDataVisual}) no-repeat 50% 50%;
    background-size: contain;

    p {
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
      font-size: 20px;
      font-weight: ${fontFamilyWeightBold};
      color: ${colors.darkBlue80};
      text-align: center;
      line-height: 1.3em;
    }
  }

  
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: ${props => props.visible ? '1' : '0'};
  pointer-events: ${props => props.visible ? 'all' : 'none'};

  .message-errors {
    font-size: 14px;
    color: ${colors.errorColor};
  }

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);

  }

  .modal-content {
    position: relative;
    width: calc(100% - 20px);
    max-height: calc(100% - 120px);
    max-width: 450px;
    margin: 70px 10px;
    padding: 20px 25px 25px;
    overflow: auto;
    background: ${colors.white};
    border-radius: 8px;

    .title {
      margin-top: 0;
      margin-right: 50px;
      font-size: 20px;
      color: ${colors.darkBlue80};
      font-weight: ${fontFamilyWeightBold};
    }

    .btn-wrapper {
      margin: 30px 0 0;

      button {
        display: inline-flex;
        width: auto;
        padding: 16px 12px;

        + button {
          margin-left: 15px;
        }
      }
    }
  }

  .modal-close {
    display: flex;
    position: absolute;
    top: 25px;
    right: 20px;
    background: transparent none;
    border: 0;
    color: ${colors.blue};
    cursor: pointer;

    ion-icon {
      font-size: 24px;
    }

    &:hover {
      color: ${colors.darkblue100};
    }
  }

  .form-error {
    margin: 0 0 20px;
    p {
      margin: 0;
      font-size: 12px;
      color: red;

      + p {
        margin-top: 5px;
      }
    }
  }

  .form-item {

    label {
      font-size: 16px;
      color: ${colors.blue};
    }

    input[type="text"],
    input[type="number"] {
      padding: 5px 0 5px 10px;
      line-height: 24px;
      font-size: 16px;
      color: ${colors.blue};
      border: 1px solid ${colors.darkBlue20};
      border-radius: 4px;
      width: calc(100% - 12px);
      font-family: ${fontFamily};
    }

    input[type="radio"] {
      position: absolute;
      left: -9999px;

      + label {
        display: inline-block;
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid ${colors.darkBlue20};
          transition: all .2s ease-in;
        }
        
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 3px;
          top: 50%;
          transform: translate(0, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          overflow: hidden;
          background: transparent;
          transition: all .2s ease-in;
        }
      }

      &:checked + label{
        &::before {
          border-color: ${colors.blue};
        }
        &::after {
          content: '';
          background-color: ${colors.blue};
        }
      }
    }
    + .form-item {
      margin-top: 15px;
    }

    &-avatar {
      max-width: 100%;

      .avatar-existing {
        width: 140px;
        height: 140px;
        object-fit: cover;
        border-radius: 50%;
        margin: 13px;
        cursor: pointer;
      }
    }
  }

  .avatar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 

    ${mq('m')} {
      flex-direction: column;
    }
  }

  .avatar-input-file {
    &-label {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed ${colors.blue};
      padding: 7px 10px;
      border-radius: 4px;
      color: ${colors.blue};
      font-size: 14px;
      font-weight: ${fontFamilyWeightBold};
      cursor: pointer;

      ion-icon {
        margin-right: 10px;
      }
    }
    &-field {
      display: none !important;
    }
  }

  .avatar-rotate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    label {
      display: inline-flex;
      align-items: center;
      margin-right: 10px;
    }
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: ${colors.blue};
      border: 0;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      padding: 0;

      svg {
        width: 12px;
        height: 14px;
      }

    }
  }

  .avatar-zoom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    label {
      display: inline-flex;
      align-items: center;
      margin-right: 10px;
    }

    input[type=range] {
      -webkit-appearance: none;
      margin: 10px 0;
      width: 100%;
    }
    input[type=range]:focus {
      outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      cursor: pointer;
      background: ${colors.darkBlue10};
      border-radius: 3px;
      border: 0px solid ${colors.darkBlue10};
    }
    input[type=range]::-webkit-slider-thumb {
      border: 0px solid #000000;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: ${colors.blue} url(${rangeIcon}) no-repeat 50% 50%;
      background-size: 70% auto;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -9px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
      background: ${colors.darkBlue20};
    }
    input[type=range]::-moz-range-track {
      animate: 0.2s;
      width: 100%;
      height: 6px;
      cursor: pointer;
      background: ${colors.darkBlue10};
      border-radius: 3px;
      border: 0px solid ${colors.darkBlue10};
    }
    input[type=range]::-moz-range-thumb {
      border: 0px solid #000000;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: ${colors.blue} url(${rangeIcon}) no-repeat 50% 50%;
      background-size: 70% auto;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -9px;
    }
    input[type=range]::-ms-track {
      animate: 0.2s;
      color: transparent;
      width: 100%;
      height: 6px;
      cursor: pointer;
      background: ${colors.darkBlue10};
      border-radius: 3px;
      border: 0px solid ${colors.darkBlue10};
    }
    input[type=range]::-ms-fill-lower {
      background: ${colors.darkBlue10};
      border: 0px solid #000101;
      border-radius: 50px;
    }
    input[type=range]::-ms-fill-upper {
      background: ${colors.darkBlue10};
      border: 0px solid #000101;
      border-radius: 50px;
    }
    input[type=range]::-ms-thumb {
      border: 0px solid #000000;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: ${colors.blue} url(${rangeIcon}) no-repeat 50% 50%;
      background-size: 70% auto;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -9px;
    }
    input[type=range]:focus::-ms-fill-lower {
      background: #ac51b5;
    }
    input[type=range]:focus::-ms-fill-upper {
      background: #ac51b5;
    }
  }

  .locality-selector {
    position: relative;
    z-index: 9999;
    min-height: 200px;

    .menu {
      position: absolute;
      top: 40px;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      background: ${colors.white};
    }

    .item {
      color: ${colors.darkBlue80};
      padding: 6px 10px;
    }

    .item-highlighted {
      color: ${colors.darkBlue100};
      background: ${colors.darkBlue20};
      cursor: pointer;
    }
  }


  &.avatar-lab {
    z-index: 99999;

    .modal-content {
      max-width: 1020px;
      padding: 0;
      overflow: hidden;
      display: flex;
      height: calc(100% - 70px);

      form {
        display: flex;
        height: 100%;
        width: 100%;

        ${mq('l')} {
          flex-direction: column;
          width: 100%;
        }
      }

      .btn-wrapper {
        button {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .visual,
      .visual-preview {
        width: 50%;
        height: 100%;
        background: ${colors.darkBlue10} none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;

        ${mq('l')} {
          width: calc(100% - 40px);
          height: calc(30% - 40px);
          padding: 20px;
        }

        > img {
          max-width: 292px;
          max-height: 70%;

          ${mq('l')} {
            max-width: 200px;
            max-height: 200px;
          }
        }

        .editor-visual {
          position: relative;
          background: ${colors.darkBlue20};
          width: 80%;
          max-width: 292px;
          border-radius: 50%;

          ${mq('l')} {
              max-width: 200px;
              max-height: 200px;
          }

          &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
          }

          .assets-wrapper {
            width: 100%;
            height: 100%;
          }

          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        width: calc(50% - 120px);
        height: calc(100% - 120px);
        padding: 60px;
        justify-content: center;

        ${mq('l')} {
          width: calc(100% - 40px);
          height: calc(70% - 40px);
          padding: 20px;
        }

        h2 {
          color: ${colors.darkBlue100};
          font-weight: 600;
          font-size: 24px;
        }

        .content-inner {
          flex: 1;
          height: calc(100% - 74px);
        }

        .explaination {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          text-align: center;

            h2 {
              margin-right: 0 !important;
              text-align: center;
            }
        }

        .editor {
          
        }

        .confirmation {
          text-align: center;
        }

        .categories-strip {
          overflow: auto;
          width: 100%;
          white-space: nowrap;
          padding: 20px 60px;
          margin-left: -60px;
          margin-right: -60px;

          ${mq('l')} {
            padding: 20px 20px;
            margin-left: -20px;
            margin-right: -20px;
          }

          &-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 63px;
            height: 36px;
            background: transparent none;
            margin-right: 10px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
            border-radius: 18px;
            border: 0;

            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }
        }

        .items {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 0 50px;
          margin: 20px -60px;
          overflow: auto;
          max-height: calc(100% - 77px);

          .item {
            width: calc(100% / 3 - 20px);
            margin: 0 10px 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
            border-radius: 13px;
            
            button {
              height: 100%;
              width: 100%;
              background: #E9EFF4 none;
              border: 1px solid ${colors.darkBlue20};
              border-radius: 13px;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              
            

              ion-icon {

              }

              &.item-btn-remove {
                font-size: 30px;
                color: ${colors.darkBlue80};
              }
            }
          }
        }
      }
    }
  }
`;