import React from "react";
import client from "./guestClient";
import * as userHelper from "./user";

export const getSurvey = (surveyId) => {
	return client
		.get("survey/" + surveyId)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getSurveyforUser = async (surveyId) => {
	const pepioUserId = await userHelper.getUserId();

	return client
		.get("survey/getSurveyforUser/" + surveyId + "/" + pepioUserId + "/fr") //TODO : Add multilanguage here
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getQuestionForUser = (questionId, pepioUserId) => {
	return client
		.get("Question/GetQuestionsForUser/" + questionId + "/" + pepioUserId + "/fr") //TODO : Add multilanguage here
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getQuestionResults = async (questionId) => {
	const userId = await userHelper.getUserId();

	return client
		.get("Question/GetQuestionsResult/" + questionId + "/" + userId + "/fr") //TODO : Add multilanguage here
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getResults = async (surveyId) => {
	const userId = await userHelper.getUserId();

	return client
		.get("Survey/getResults/" + surveyId + "/" + userId) //TODO : Add multilanguage here
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getQuestionTotalResults = (questionId) => {
	return client
		.get("Question/GetQuestionsTotalResult/" + questionId) //TODO : Add multilanguage here
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const postUserResponse = async (answerId, questionId, surveyId, TenantGuid, tooLate) => {
	const userId = await userHelper.getUserId();

	const data = {
		answerId: answerId,
		surveyId: surveyId,
		userProfileId: userId,
		questionId: questionId,
		tenantGuid: TenantGuid,
		tooLate: tooLate,
	};

	return client
		.post("Question/PostUserResponse", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};

export const getLeaderBoard = async (surveyId) => {
	// Get the client

	try {
		const response = await client.get(`Survey/getLeaderBoard/${surveyId}`);
		if (response.status === 200) {
			return response.data;
		} else {
			// console.log(response);
			return null;
		}
	} catch (error) {
		return null;
	}
};

export const getUserScorePosition = async (surveyId) => {
	// Get the client

	try {
		const currentUserid = await userHelper.getUserId();
		const response = await client.get(`Survey/GetUserScorePosition/${surveyId}/${currentUserid}`);
		if (response.status === 200) {
			return response.data;
		} else {
			// console.log(response);
			return null;
		}
	} catch (error) {
		return null;
	}
};

// -------------------------------------------------------------
// Get survey details for a user, query by shortcode
// -------------------------------------------------------------
// TODO: check to use userid on backend to check if user as alredy responded
export const getSurveyForUserByShortCode = async (shortCode) => {
	const pepioUserId = await userHelper.getUserId();

	return client
		.get("survey/getSurveyForUserByShortCode/" + shortCode + "/" + pepioUserId)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};
