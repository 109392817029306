import React from "react";
import client from "./guestClient";
import * as userHelper from "./user";

// -------------------------------------------------------------
// Get public video
// -------------------------------------------------------------
export const getPublicVideo = async (videoId) => {
	return client
		.get("video/GetVideo/" + videoId + "/")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return false;
		});
};