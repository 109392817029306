import React from 'react';

const search = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.4073 19.7527L16.9969 15.3422C18.0587 13.9286 18.6319 12.208 18.63 10.44C18.63 5.92406 14.9559 2.25 10.44 2.25C5.92406 2.25 2.25 5.92406 2.25 10.44C2.25 14.9559 5.92406 18.63 10.44 18.63C12.208 18.6319 13.9286 18.0587 15.3422 16.9969L19.7527 21.4073C19.9759 21.6069 20.2671 21.7135 20.5664 21.7051C20.8658 21.6967 21.1506 21.574 21.3623 21.3623C21.574 21.1506 21.6967 20.8658 21.7051 20.5664C21.7135 20.2671 21.6069 19.9759 21.4073 19.7527ZM4.59 10.44C4.59 9.28298 4.9331 8.15194 5.5759 7.18991C6.21871 6.22789 7.13235 5.47808 8.2013 5.03531C9.27025 4.59253 10.4465 4.47668 11.5813 4.70241C12.7161 4.92813 13.7584 5.48529 14.5766 6.30343C15.3947 7.12156 15.9519 8.16393 16.1776 9.29872C16.4033 10.4335 16.2875 11.6098 15.8447 12.6787C15.4019 13.7476 14.6521 14.6613 13.6901 15.3041C12.7281 15.9469 11.597 16.29 10.44 16.29C8.88906 16.2881 7.40217 15.6712 6.30548 14.5745C5.2088 13.4778 4.59186 11.9909 4.59 10.44Z"/>
  </svg>
);

const notifications = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6289 15.9989C20.551 15.9052 20.4746 15.8114 20.3996 15.7209C19.3684 14.4736 18.7445 13.7208 18.7445 10.1897C18.7445 8.36156 18.3071 6.86156 17.4451 5.73656C16.8095 4.90547 15.9503 4.275 14.8178 3.80906C14.8032 3.80096 14.7902 3.79032 14.7793 3.77766C14.372 2.41359 13.2573 1.5 12.0001 1.5C10.7429 1.5 9.6287 2.41359 9.22136 3.77625C9.21049 3.78845 9.19765 3.79875 9.18339 3.80672C6.54058 4.89469 5.2562 6.98203 5.2562 10.1883C5.2562 13.7208 4.63323 14.4736 3.60105 15.7195C3.52605 15.81 3.44964 15.9019 3.37183 15.9975C3.17083 16.2399 3.04348 16.5348 3.00485 16.8473C2.96622 17.1598 3.01793 17.4769 3.15386 17.7609C3.44308 18.3703 4.05948 18.7486 4.76308 18.7486H19.2423C19.9426 18.7486 20.5548 18.3708 20.845 17.7642C20.9815 17.4801 21.0337 17.1628 20.9954 16.8499C20.9571 16.537 20.8299 16.2417 20.6289 15.9989Z"/>
    <path d="M12 22.5C12.6774 22.4994 13.342 22.3156 13.9233 21.9679C14.5046 21.6202 14.981 21.1217 15.3019 20.5252C15.317 20.4966 15.3245 20.4646 15.3236 20.4322C15.3226 20.3999 15.3134 20.3684 15.2967 20.3407C15.28 20.313 15.2564 20.2901 15.2282 20.2742C15.2001 20.2583 15.1683 20.25 15.1359 20.25H8.86501C8.83263 20.2499 8.80077 20.2582 8.77254 20.274C8.74431 20.2899 8.72066 20.3128 8.70391 20.3405C8.68715 20.3682 8.67786 20.3997 8.67693 20.4321C8.676 20.4645 8.68346 20.4965 8.6986 20.5252C9.01948 21.1216 9.49578 21.6201 10.077 21.9678C10.6582 22.3155 11.3227 22.4994 12 22.5Z"/>
  </svg>
);

const icons = {
  'search': search,
  'notifications': notifications
};

const Icon = (props) => {
  return (icons[props.name]) ? icons[props.name](props) : null;
};

export default Icon;