import React, {useState, useEffect} from 'react';

import { useHistory } from "react-router-dom";

import * as userHelper from '../../fetch/user';

import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';
import Step6 from './steps/step6';
import Step7 from './steps/step7';
import Step8 from './steps/step8';
import EndWizard from './steps/endWizard';

import { Backdrop, Container, Visual, Content, StepBar, StepBarItem } from './styled.js';

// import Illu from '../../assets/wizard.svg';

import vip from '../../vip';


const stepsMap = [
  {
    userProperty: 'parentalConsent',
    step: Step1,
    emptyValue: false
  },
  {
    userProperty: 'genderValue',
    step: Step2,
    emptyValue: 0
  },
  {
    userProperty: 'birthYear',
    step: Step3,
    emptyValue: null
  },
  {
    userProperty: 'postalcode',
    step: Step4,
    emptyValue: null
  },
  {
    userProperty: 'professionnalSituationValue',
    step: Step5,
    emptyValue: 0
  },
  {
    userProperty: 'educationLevelValue',
    step: Step6,
    emptyValue: 0
  },
  {
    userProperty: 'familyContextValue',
    step: Step7,
    emptyValue: 0
  },
  {
    userProperty: 'childsAmount',
    step: Step8,
    emptyValue: null
  }
];

const missingProperties = (user) => {
  const stepsTemp = [];
  stepsMap.map((item) => {
    if((user[item.userProperty] === undefined || user[item.userProperty] === null || user[item.userProperty].length <= 0) || user[item.userProperty] === item.emptyValue) {
      stepsTemp.push(item.step);
    }
  });

  return stepsTemp;
}

const Wizard = (props) => {
  const history = useHistory();
  const vipCopy = vip.copy;
  const vipVisuals = vip.visuals;
  // console.log('vip', vip);

  const [userData, setUserData] = useState([]);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [adultOrParentalConsent, setAdultOrParentalConsent] = useState(true);

  const createSteps = (user) => {
    const stepsTemp = missingProperties(user);

    if(stepsTemp.length < 1) {
      props.handleClose();
    }

    stepsTemp.push(EndWizard);

    setSteps(stepsTemp);
  }

  useEffect(() => {
    async function fetchUserData() {
      await userHelper.refreshProfileFromBackEnd();
      const user = await userHelper.getUser();
      setUserData(user);
      createSteps(user);
    }
    fetchUserData();
  }, []);

  const activeStepComponent = (WrappedComponent) => {
    return class extends React.Component {
      // componentDidMount() {
      // console.log('Props : ', this.props);
      // }
      render() {
        return <WrappedComponent {...this.props} />;
      }
    }
  }

  const ActiveStep = activeStepComponent(steps[activeStep-1]);

  const nextStep = () => {
    if(activeStep < steps.length) {
      goStep(activeStep+1);
    }
  }

  const prevStep = () => {
    if(activeStep > 1) {
      goStep(activeStep-1);
    } else if(activeStep === 1) {
      setAdultOrParentalConsent(true);
    }
  }

  const goStep = (targetStep) => {
    setActiveStep(targetStep);
  }

  const handleClose = () => {
    if(activeStep < steps.length && props.closeBeforeEnd) {
      props.closeBeforeEnd();
    } else {
      props.handleClose();
    }
  }

  return (
    steps.length > 1 ? (
    <>
      
      <Backdrop visible={props.visible} />
      <Container visible={props.visible}>
        <button className="btn-close" onClick={handleClose}><ion-icon name="close-outline"></ion-icon></button>
        <Visual>
          <img src={vipVisuals['wizard']} />
        </Visual>
        
          <Content>
            <div className="back-container">
              <button
                className={`${activeStep === 1 && adultOrParentalConsent ? 'hide' : 'show'}`}
                onClick={prevStep}
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
                Précédent
              </button>
            </div>
            <div className={`${(activeStep === steps.length || steps.length < 3) ? 'stepbar-container hide' : 'stepbar-container'}`}>
              
                <StepBar onClick={nextStep}>
                  <ol>
                    {steps.map((step, index) => {
                      if(index < steps.length - 1) {
                        return (<StepBarItem key={index} active={activeStep === index+1}>
                          {index + 1}
                        </StepBarItem>);
                      }
                      
                      })}
                  </ol>
                </StepBar>
              
            </div>
            <div className="step-container">
              
              {adultOrParentalConsent ? 
                <ActiveStep 
                  userData={userData}
                  nextStep={nextStep} 
                  handleClose={handleClose}
                  setAdultOrParentalConsent={setAdultOrParentalConsent} 
                />
              : <>
                  <h2>Désolé, vous ne pouvez pas compléter votre profil.</h2>
                  <p>Les mineurs de moins de 16 ans doivent obtenir le consentement parental pour créer un profil.
                  Vous pourrez cependant continuer à répondre aux différents sondages</p>
                </>
              }
              

            </div>
          </Content>
        
      </Container>
      
    </>
    ) : null
  );
};

export default Wizard;