//@format
//@flow
import React, {useState} from 'react';
import type {Node} from "react";
import {useForm} from 'react-hook-form';
import * as userHelper from '../../fetch/user';
import { useHistory } from "react-router-dom";
import { Container, Visual, Content } from './styled';
import { Button } from '../../styled-common';
import vip from "../../vip";


const SignUpUsername = () : Node => {
  const history = useHistory();

  const {register, setValue, handleSubmit, formState} = useForm({
    mode: 'onChange',
    validateCriteriaMode: 'all',
  });
  const [stateErrorMessage, setStateErrorMessage] = useState(null);

  const onPressSave = async (data) => {

    const responseData = await userHelper.saveUsername(data.username);

    if(responseData) {
      history.push("/signup-avatar");
    }
  };

  return (
    <Container>
      <Visual>
        <img src={vip.visuals['login']} alt="" />
      </Visual>
      <Content>
      <img src={vip.logo} alt={vip.name} className="logo" />
            <h1>Choisissez un nom d’utilisateur.trice</h1>
            {vip.visuals['username'] ? <img src={vip.visuals['username']} className="mobile-only" alt="" /> : null}
            <p>
              Ce nom d’utilisateur.trice doit être unique. Vous serez prévenu.e si le nom que vous choisissez est déjà utilisé par quelqu’un d’autre.
            </p>
            <div className="inner">
            <form onSubmit={handleSubmit(onPressSave)}>
              {/* <label>Nom d'utilisateur</label> */}
              <div className="field">
                <div className="field-wrapper">
                  <input
                    type="text"
                    name="username"
                    onChange={async (event) => {
                      const text = event.currentTarget.value;
                      setValue('username', text, true);
                      // console.log('username', text, formState.isValid);

                      if(!formState.isValid) {
                        setStateErrorMessage('Veuillez remplir un nom d’utilisateur (entre 2 et 20 caractères)');
                      } else {
                        setStateErrorMessage('');
                      }
                      // if(text.length > 1 && text.length < 20) {
                      //   const checkUsernameResponse = await userHelper.checkUsername(text);
                      // console.log('checkUsernameResponse', checkUsernameResponse, checkUsernameResponse === "Valid");

                      //   if(checkUsernameResponse !== "Valid"){
                      //     setStateErrorMessage('On est tous unique et ce nom d’utilisateur est déjà pris. Veuillez choisir un autre nom d’utilisateur.');
                      //   } else {
                      //     setStateErrorMessage('');
                      //   }
                      // }
                      
                    }}
                    ref={register(
                      {
                        required: true,
                        maxLength: 20,
                        minLength: 2,
                        validate: {
                          asyncValidate: async value => {
                            const checkUsernameResponse = await userHelper.checkUsername(value);

                            if(checkUsernameResponse !== "Valid"){
                              setStateErrorMessage('On est tous unique et ce nom d’utilisateur est déjà pris. Veuillez choisir un autre nom d’utilisateur.');
                            } else {
                              setStateErrorMessage('');
                            }

                            return checkUsernameResponse === "Valid";
                          }
                        }
                        
                      },
                    )}
                  />
                </div>
              </div>

              {stateErrorMessage ? <p className="message-errors">{stateErrorMessage}</p> : null}

              <Button large block type="submit" disabled={!formState.isValid}>Sauvegarder</Button>

            </form>
            </div>
          
      </Content>
    </Container>
  );
};

export default SignUpUsername;
