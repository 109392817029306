//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";

import visualCatFaces from "../../../assets/lab-categories/faces.png";
import visualCatDress from "../../../assets/lab-categories/dress.png";
import visualCatEyes from "../../../assets/lab-categories/eyes.png";
import visualCatEyebrows from "../../../assets/lab-categories/eyebrows.png";
import visualCatHair from "../../../assets/lab-categories/hair.png";
import visualCatMouth from "../../../assets/lab-categories/mouth.png";
import visualCatNose from "../../../assets/lab-categories/nose.png";
import visualCatEarings from "../../../assets/lab-categories/earings.png";
import visualCatFacialhair from "../../../assets/lab-categories/facialhair.png";
import visualCatAccessories from "../../../assets/lab-categories/accessories.png";


type Props = {
    setCurrentCategory : Function,
}

const categoriesLayers = [
    "faces",
    "dress",
    "eyes",
    "eyebrows",
    "hair",
    "mouth",
    "earings",
    "nose",
    "facialhair",
    "accessories",
];

const categoriesMap = {
    faces: {
        label: "Visage",
        visual: visualCatFaces,
        layerNumber: 1,
        showRemove: false,
    },
    dress: {
        label: 'Dress',
        visual: visualCatDress,
        layerNumber: 2,
        showRemove: false,
      },
    eyes: {
        label: "Yeux",
        visual: visualCatEyes,
        layerNumber: 3,
        showRemove: false,
    },
    eyebrows: {
        label: "Sourcils",
        visual: visualCatEyebrows,
        layerNumber: 4,
        showRemove: false,
    },
    hair: {
        label: "Cheveux",
        visual: visualCatHair,
        layerNumber: 5,
        showRemove: true,
    },
    earings: {
        label: "Oreilles",
        visual: visualCatEarings,
        layerNumber: 6,
        showRemove: true,
    },
    facialhair: {
        label: "Barbes",
        visual: visualCatFacialhair,
        layerNumber: 7,
        showRemove: true,
    },
    mouth: {
        label: "Bouche",
        visual: visualCatMouth,
        layerNumber: 8,
        showRemove: false,
    },
    accessories: {
        label: "Accessoires",
        visual: visualCatAccessories,
        layerNumber: 9,
        showRemove: true,
    },
    nose: {
        label: "Nez",
        visual: visualCatNose,
        layerNumber: 10,
        showRemove: false,
    },
};

const AvatarCategories = (props: Props): Node => {
const {setCurrentCategory} = props
    return categoriesLayers.map((item, index) => {
        return (
            <button
                className="categories-strip-btn"
                key={`cat-item-${index}`}
                onClick={(e) => {
                    e.preventDefault();
                    setCurrentCategory(item);
                }}
            >
                <img src={categoriesMap[item].visual} alt={categoriesMap[item].label} />
            </button>
        );
    });

}

export default AvatarCategories;