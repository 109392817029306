import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
`;

export const Visual = styled.div`
  position: relative;
  display: flex;
  width: calc(50% - 60px);
  margin: 0 30px 0 0;
  background-size: auto 80%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-color: ${colors.loginVisual};
  overflow: hidden;

  ${mq('m')} {
    display: none;
  }

  img {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 100%;
    max-width: calc(100% - 20px);
  }
`;

export const Content = styled.div`
  max-width: calc(50% - 30px);
  width: calc(1280px / 2 - 100px);
  /* margin: 0 0 0 30px; */
  margin: 0 auto;
  padding: 100px 50px;
  text-align: center;

  ${mq('m')} {
    width: 100%;
    max-width: 350px;
    padding: 70px 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    max-width: 140px;
  }

  h1 {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.darkBlue80};
    margin-bottom: 65px;
  }

  button {
    margin-left: 85px;
    margin-right: 85px;
    width: calc(100% - 85px * 2);
    font-size: 16px;
    font-weight: ${fontFamilyWeightBold};

    ${mq('m')} {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;
