import styled from "styled-components";
import { colors, fontFamilyWeightBold } from "../../styled-common.js";

export const BreadcrumbStyled = styled.div`
    ol {
        display: inline-block;
        list-style-type: none;
        padding: 0;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        li {
            display: inline;

            button {
                font-size: 14px;
                font-weight: ${fontFamilyWeightBold};
                color: ${colors.blue};
                border: 0;
                background: transparent;
                margin: 0;
                padding: 0;
            }

            span {
                font-size: 14px;
                font-weight: ${fontFamilyWeightBold};
                color: ${colors.darkBlue40};
                border: 0;
                background: transparent;
            }

            + li::before {
                content: "/";
                font-size: 14px;
                font-weight: ${fontFamilyWeightBold};
                color: ${colors.darkBlue40};
                margin: 0 10px;
            }
        }
    }
`;
