import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold  } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Wrapper = styled.div`
  padding: 17px 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;

  .space-right {
    display: flex;

    > button {
        margin-left: 15px !important;
    }
  }

  .notifications,
  .search,
  .settings {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 0;
    background: transparent none;
    border: 0;
    outline: none;
    cursor: pointer;
    color: ${colors.blue};
    font-size: 20px;
    transition: all .3s ease-out;

    ion-icon {
      transition: all .3s ease-out;
    }
    
    svg,
    svg path {
      fill: ${colors.darkBlue100};
      transition: all .3s ease-out;
    }

    &:hover svg path {
      fill: ${colors.darkBlue100};
    }

    &:hover ion-icon {
      color: ${colors.blue};
    }
  }

  .notifications {
    &.active {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #D91544;
      }
    }
  }

  .notifications,
  .search {
    ${mq('m', true)} {
      display: none;
    }
  }

  .settings {
    color: ${colors.darkBlue60};

    &:hover {
      color: ${colors.blue};
    }
  }
`;

export const Logo = styled.div`
  width: 113px;
  height: 40px;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const MenuTrigger = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;
  border: 0;
  background: transparent none;
  outline: none;
  cursor: pointer;

  ${mq('m', true)} {
    display: none;
  }

  svg {
    path {
      fill: ${colors.blue};
      transition: all .2s;

      ${props => props.opened ? `
        &:nth-child(1) {
          transform: rotate(45deg) translate(calc(100% + 7px),-11px) scaleX(-1);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(-14px,0px);
        }
      ` : null}
    }
  }

  &:hover {
    svg {
      path {
        fill: ${colors.darkBlue100};
      }
    }

    ion-icon {
      color: ${colors.blue};
    }
  }
`;

export const Menu = styled.div`

  ${mq('m')} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh);
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    background: ${colors.white};
    transition: all .4s ease-in-out;
    margin: 0;

    .profil-trigger-annonymous {
      display: none !important;
    }

    &.opened {
      opacity: 1;
      pointer-events: all;
      z-index: 1000;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    align-items: center;

    ${mq('m')} {
      flex-direction: column;
      padding-left: 0;
    }

    li {
      position: relative;

      ${mq('m')} {
        margin-bottom: 20px;

        &.login-button-wrapper {
          width: calc(100% - 60px);

          button {
            margin: 0 auto;
          }
        }
      }

      button {
        margin-bottom: 0;

        ${mq('m')} {
          color: ${colors.blue};

          text-align: center;          
        }

        svg {
          path {
            fill: ${colors.darkBlue100};
          }
        }

        .mobile-only {
          display: none;

          ${mq('m')} {
            display: inline;
            font-size: 16px;
            line-height: 24px;
            color: ${colors.blue};
          }
        }

        .desktop-only {
          display: inline-flex;

          ${mq('m')} {
            display: none;
          }
        }

        ion-icon {
          color: ${colors.darkBlue100};
        }

        &.settings {
          ion-icon {
            color: ${colors.darkBlue60};
          }
        }

        &:hover {
          svg {
            path {
              fill: ${colors.blue};
            }
          }

          ion-icon {
            color: ${colors.blue};
          }
        }

      }
    }

    > li {
      &.separated-before {
        padding-left: 30px;

        ${mq('m')} {
          padding-left: 0;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          display: block;
          width: 1px;
          height: 12px;
          background: ${colors.darkBlue20};

          ${mq('m')} {
            display: none;
          }
        }
      }
    }

    li + li {
      margin-left: 30px;

      ${mq('m')} {
        margin-left: 0;
      }
    }
  }

  .notifications,
  .search {
    ${mq('m')} {
      display: none;
    }
    ${mq('m', true)} {
      display: flex;
    }
  }
`;

export const Profile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: ${fontFamilyWeightBold};
  border: 0;
  background: transparent none;
  outline: none;
  cursor: pointer;
  color: ${colors.blue};
  transition: all .3s ease-in-out;

  ${mq('l')} {
    flex-direction: column;
    align-items: flex-start;
  }

  .profil-trigger {
    
      display: flex;
      align-items: center;
    
  }

  .chevron {
    display: inline-flex;
    color: ${colors.blue};
    margin-left: 10px;
    transition: all .3s ease-in-out;

    ${mq('m')} {
      display: none;
    }
  }

  .avatar {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: ${colors.lightgrey};
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .profile-menu {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px 0 0;
    cursor: default;
    opacity: 0;
    z-index: 5000;
    pointer-events: none;
    transform: translate(0, -10px);
    transition: all .4s ease-in-out;

    ${mq('m')} {
      position: relative;
      opacity: 1;
      pointer-events: all;
      transform: translate(0, 0);
      box-shadow: none;
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
      background: ${colors.white};
      box-shadow: 0px 7px 30px rgba(6, 10, 74, 0.1);

      ${mq('m')} {
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        justify-content: flex-start;
      }

      li {
        margin: 0;
        width: 100%;
        cursor: pointer;

        button {
          display: flex;
          align-items: center;
          background: transparent;
          padding: 12px 15px;
          border: 0;
          width: 100%;
          white-space: nowrap;
          font-size: 14px;
          color: ${colors.darkBlue80};
          font-weight: ${fontFamilyWeightBold};

          ${mq('m')} {
            padding-left: 0;
          }

          ion-icon {
            margin-right: 10px;
            font-size: 20px;
          }

          * {
            position: relative;
            transition: all .3s ease-in-out;
          }
          
          &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            background: ${colors.blue};
            transition: opacity .3s ease-in-out;
          }

          &:hover {
            color: ${colors.blue};
            background: rgba(${colors.blue}, .2);

            &::before {
              opacity: .2;
            }

            ion-icon {

            }
          }
        }
      }
    }
  }

  &:hover .profile-menu {
    opacity: 1;
    pointer-events: all;
    transform: translate(0, 0);
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 500;
  width: 345px;
  max-width: 100vw;
  transform: translate(-50%, 0);
  transition: all .4s ease-in-out;

  border-radius: 8px;
  border: 1px solid ${colors.darkBlue20};
  padding: 14px;
  background: ${colors.white};

  ${props => props.visible ? `
    opacity: 1;
    pointer-events: all;
    transform: translate(-50%, 0);
  ` : `
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, -20px);
  `}

  ${mq('m')} {
    display: none;
  }
`;

export const DropdownBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 490;
  background: transparent;

  ${props => props.visible ? `
    opacity: 1;
    pointer-events: all;
  ` : `
    opacity: 0;
    pointer-events: none;
  `}

${mq('m')} {
    display: none;
  }
`;

export const SearchContent = styled.div`

  form {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;

    .field {
      flex: 1;
      width: 100%;

      label {
        position: absolute;
        left: -9999px;
        
      }
      input {
        position: relative;
        padding: 12px 18px;
        outline: 0;
        width: 100%;
        border: 0;
        background: transparent;
        color: ${colors.darkBlue60};
        font-size: 14px;
        border-radius: 8px;
        font-family: ${fontFamily};
        z-index: 502;

        &::placeholder {
          color: ${colors.inputPlaceholder};
          font-family: ${fontFamily};
        }

        + .border {
          position: absolute;
          z-index: 501;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border: 1px solid ${colors.darkBlue20};
          border-radius: 8px;
          transition: all .4s ease-in-out;
        }

        &:focus + .border {
          border-color: ${colors.blue};
        }
      }
    }

    button {
      position: relative;
      z-index: 502;
      display: flex;
      align-items: center;
      border: 0;
      background: transparent;
      outline: 0;
      color: ${colors.blue};

      ion-icon {
        font-size: 20px;
        color: ${colors.blue} !important;
      }

      &:hover {
        color: ${colors.darkBlue100} !important;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style-type: none;
    max-height: 400px;
    overflow: auto;
    margin-top: 25px;

    ${mq('m')} {
      margin-left: -25px;
      margin-right: -25px;
      padding: 0 25px;
    }

    li {
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;
      cursor: pointer;

      + li {
        border-top: 1px solid ${colors.darkBlue20};
        padding: 10px 0 0 0;
        margin: 10px 0 0 0;
      }

      .search-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    
  }

  .picture {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    background: ${colors.darkBlue10};

    img {
      display: block;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }

  .message {
    flex: 1;
    color: ${colors.darkBlue80};
    font-size: 14px;

    .title {
      color: ${colors.darkBlue80};
      font-size: 14px;
      margin: 0;
    }

    .description {
      color: ${colors.darkBlue40};
      font-size: 12px;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    font-family: ${fontFamily};
    color: ${colors.darkBlue100};
    font-size: 20px;
    font-weight: ${fontFamilyWeightBold};

    ion-icon {
      color: ${colors.darkBlue100};
      margin-right: 12px;
      font-size: 28px;
    }
  }

  p {
    font-family: ${fontFamily};
    color: ${colors.darkBlue80};
    font-size: 14px;
    font-weight: 400;
  }

  .search-qr {
    display: none;
    
    ${mq('m')} {
      display: block;
    }
  }

  ${props => props.wrapper === 'dropdown' ? `
  ` : null}

  ${props => props.wrapper === 'modal' ? `
    padding-top: 40px;
  ` : null}
`;

export const NotificationsContent = styled.div`

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style-type: none;
    max-height: 400px;
    overflow: auto;

    li {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      width: 100%;
      cursor: pointer;

      ${mq('m')} {
        flex-wrap: wrap;
      }

      + li {
        border-top: 1px solid ${colors.darkBlue20};
        padding: 10px 0 0 0;
        margin: 10px 0 0 0;
      }
    }
  }

  .picture {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    background: ${colors.darkBlue10};

    img {
      display: block;
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }

  .message {
    flex: 1;
    color: ${colors.darkBlue80};
    font-size: 14px;
  }

  .age {
    color: ${colors.darkBlue40};
    font-size: 12px;
    margin-left: 10px;

    ${mq('m')} {
      width: 100%;
      text-align: right;
      margin-left: 0;
      margin-top: 10px;
    }
  }



  ${props => props.wrapper === 'dropdown' ? `
    h2 {
      display: none;
    }
  ` : null}

  ${props => props.wrapper === 'modal' ? `
    padding-top: 40px;
    
    h2 {
      display: none;
    }
  ` : null}
`;

export const Separator = styled.div`
  position: relative;
  margin: 15px 0;
  text-align: center;

  ${mq('m')} {
    margin-left: 0;
    margin-right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: ${colors.darkBlue20};
  }

  span {
    position: relative;
    display: inline-block;
    padding: 3px 10px;
    background: white;
    color: ${colors.darkBlue40};
    font-size: 14px;
    line-height: 18px;
  }
`;
