//@flow
//@format
import React, { useState, useEffect, Node } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AsyncStorage from "@callstack/async-storage";
import * as userHelper from "../../fetch/user";
import * as channelHelper from "../../fetch/channel";
import * as videoHelper from "../../fetch/video";
import { Button } from "../../styled-common.js";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container, ContentHeader, Content } from "./styled.js";

const Video = (): Node => {
    const history = useHistory();
    const params = useParams();
    const [videoData, setVideoData] = useState(false);
    const [channelDetail, setChannelDetail] = useState(false);
    const [channel, setChannel] = useState(null);
    const [copiedMessage, setCopiedMessage] = useState(false);
    console.log("video params", params);

    useEffect(() => {
        console.log("video params UE", params);

        async function fetchData() {
            const cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
            if (!cognitoUserId) {
                await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
            }

            const videoReponse = await videoHelper.getPublicVideo(params.id);
            console.log("videoReponse", videoReponse);
            if (!videoReponse) {
                history.push("/channel-content/" + channelDetail.id, { channelId: channelDetail.id });
            } else {
                setVideoData(videoReponse);
                console.log("videoReponse", videoReponse);
                const channelDetailResponse = await channelHelper.getChannel(videoReponse.channelId);
                console.log("channelDetailResponse", channelDetailResponse);
                if (channelDetailResponse) {
                    setChannelDetail(channelDetailResponse);
                } else {
                }
            }
        }

        fetchData();
    }, []);

    // if(videoData && videoData.videoUrl && channelDetail && channelDetail.title){
    return (
        <>
            <Header />
            <Container>
                <ContentHeader>
                    <div className="inner">
                        <h1 className="title">{channelDetail.name}</h1>
                        <p className="description">{channelDetail.description}</p>
                        <p>
                            <Button
                                style={{ backgroundColor: channelDetail.isCustomized ? channelDetail.textColor : "", borderColor: channelDetail.isCustomized ? channelDetail.textColor : "" }}
                                onClick={(e) => {
                                    history.push("/channel-content/" + channelDetail.id, { channelId: channelDetail.id });
                                }}
                            >
                                Créer ma vidéo
                            </Button>
                        </p>
                    </div>
                </ContentHeader>
                <Content>
                    <div className="inner">
                        <video autoPlay controls src={videoData.videoUrl} preload={"auto"} type={"video/mp4"} />

                        <div className="sharing-popup">
                            <h2>Partager</h2>
                            <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(channelDetail.description)}&url=${encodeURIComponent(videoData.shareUrl)}`} target="_blank">
                                <svg enableBackground="new 0 0 32 32" height="32px" viewBox="0 0 32 32" width="32px">
                                    <g>
                                        <circle clipRule="evenodd" cx="16" cy="16" fill="#55ACEE" fillRule="evenodd" r="16" />
                                        <path
                                            d="M26.013,9.896c-0.737,0.323-1.527,0.538-2.359,0.636c0.85-0.498,1.5-1.289,1.807-2.232   c-0.793,0.462-1.672,0.798-2.608,0.979c-0.748-0.784-1.816-1.275-2.996-1.275c-2.269,0-4.105,1.807-4.105,4.039   c0,0.315,0.036,0.625,0.104,0.923c-3.412-0.172-6.436-1.78-8.461-4.222c-0.354,0.597-0.559,1.29-0.559,2.031   c0,1.4,0.728,2.636,1.828,3.363c-0.672-0.023-1.306-0.204-1.858-0.507c-0.001,0.017-0.001,0.033-0.001,0.051   c0,1.958,1.415,3.591,3.293,3.963c-0.343,0.09-0.706,0.141-1.082,0.141c-0.264,0-0.52-0.024-0.771-0.071   c0.521,1.603,2.038,2.771,3.834,2.806c-1.402,1.082-3.174,1.73-5.099,1.73c-0.33,0-0.659-0.019-0.979-0.058   c1.816,1.146,3.976,1.814,6.295,1.814c7.552,0,11.682-6.156,11.682-11.495c0-0.173-0.006-0.351-0.014-0.521   C24.766,11.417,25.462,10.706,26.013,9.896z"
                                            fill="#FFFFFF"
                                        />
                                    </g>
                                    <g />
                                    <g />
                                    <g />
                                    <g />
                                    <g />
                                    <g />
                                </svg>
                            </a>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(videoData.shareUrl)}&quote=${encodeURIComponent(channelDetail.description)}`} target="_blank">
                                <svg viewBox="0 0 1000 1000">
                                    <g>
                                        <path fill="#3B579D" d="M500,1000L500,1000C223.9,1000,0,776.1,0,500v0C0,223.9,223.9,0,500,0h0c276.1,0,500,223.9,500,500v0   C1000,776.1,776.1,1000,500,1000z" />
                                        <path fill="#FFFFFF" d="M630,1000V612.7h130l19.5-150.9H630v-96.4c0-43.7,12.1-73.5,74.8-73.5l79.9,0V157   c-13.8-1.8-61.3-5.9-116.5-5.9c-115.2,0-194.1,70.3-194.1,199.5v111.3H343.8v150.9h130.3V1000H630z" />
                                    </g>
                                </svg>
                            </a>
                            {copiedMessage === videoData.requestId ? (
                                <span>
                                    <svg height="24" viewBox="0 0 24 24" width="24">
                                        <path
                                            fill={copiedMessage === videoData.requestId ? "#265E94" : "#132F4A"}
                                            d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M10,13.5857864 L15.2928932,8.29289322 L16.7071068,9.70710678 L10,16.4142136 L6.29289322,12.7071068 L7.70710678,11.2928932 L10,13.5857864 Z"
                                            fillRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            ) : (
                                <button
                                    onClick={async () => {
                                        await navigator.clipboard.writeText(videoData.shareUrl);
                                        setCopiedMessage(videoData.requestId);
                                        setTimeout(() => {
                                            setCopiedMessage(false);
                                        }, 5000);
                                    }}
                                >
                                    <svg viewBox="0 0 512 512">
                                        <path
                                            fill={copiedMessage === videoData.requestId ? "#132F4A" : "#265E94"}
                                            d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"
                                        />
                                    </svg>
                                </button>
                            )}
                            <a href={videoData.videoUrl} download target="_blank" onClick={(e) => {
                                e.preventDefault();
                                const url = videoData.videoUrl;
                                var filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
                                var xhr = new XMLHttpRequest();
                                xhr.responseType = 'blob';
                                xhr.onload = function() {
                                    var a = document.createElement('a');
                                    a.href = window.URL.createObjectURL(xhr.response);
                                    a.download = filename;
                                    a.style.display = 'none';
                                    document.body.appendChild(a);
                                    a.click();
                                };
                                xhr.open('GET', url);
                                xhr.send();
                            }}>
                                <svg fill="none" height="30" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                    <polyline points="7 10 12 15 17 10" />
                                    <line x1="12" x2="12" y1="15" y2="3" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </Content>
            </Container>
            <Footer />
        </>
    );
    // }

    return <>No video</>;
};

export default Video;
