//@format
//@flow
import React, { useEffect, useState, useRef } from 'react';
import {useForm} from 'react-hook-form';
import * as userHelper from '../../fetch/user';
import client from '../../fetch/guestClient';
import { Button } from '../../styled-common';
import avatarPlaceholder from '../../assets/avatar-placeholder.png';

const ModalFamilyContext = (props) => {
  const [stateFamilyContextValue, setStateFamilyContextValue] = useState(props.familyContextValue);

  const {register, handleSubmit, errors} = useForm();


  const onSubmitForm = async (data) => {
    // console.log('data', data);
    await userHelper.SetUserFamilyContext(stateFamilyContextValue);
    props.handleClose();
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <h2 className="title">Modifier votre situation familiale</h2>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_family_context" 
          id="profile_family_context_0" 
          value="1" 
          ref={register({ required: true })}
          checked={stateFamilyContextValue === 1}
          onChange={(e) => {
            setStateFamilyContextValue(parseInt(e.currentTarget.value, 10));
          }} 
        />
        <label htmlFor="profile_family_context_0">{props.familyContextTexts[1]}</label>
      </div>
      <div className="form-item">
        <input  
          type="radio" 
          name="profile_family_context" 
          id="profile_family_context_1" 
          value="2" 
          ref={register({ required: true })}
          checked={stateFamilyContextValue === 2}
          onChange={(e) => {
            setStateFamilyContextValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_family_context_1">{props.familyContextTexts[2]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_family_context" 
          id="profile_family_context_2" 
          value="3" 
          ref={register({ required: true })}
          checked={stateFamilyContextValue === 3}
          onChange={(e) => {
            setStateFamilyContextValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_family_context_2">{props.familyContextTexts[3]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_family_context" 
          id="profile_family_context_3" 
          value="4" 
          ref={register({ required: true })}
          checked={stateFamilyContextValue === 4}
          onChange={(e) => {
            setStateFamilyContextValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_family_context_3">{props.familyContextTexts[4]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_family_context" 
          id="profile_family_context_4" 
          value="5" 
          ref={register({ required: true })}
          checked={stateFamilyContextValue === 5}
          onChange={(e) => {
            setStateFamilyContextValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_family_context_4">{props.familyContextTexts[5]}</label>
      </div>

      <div className="btn-wrapper">
        {errors.profile_family_context ? <div className="form-error"><p>Veuillez cocher une option</p></div> : null}
        <Button 
          type="submit"
          small={true}
        >
          Valider
        </Button>

        <Button 
          type="reset"
          ghost={true}
          small={true}
          onClick={props.handleClose}
        >
          Annuler
        </Button>
      </div>

    </form>
  );
};

export default ModalFamilyContext;
