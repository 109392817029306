import React, {useState} from 'react';

import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep6 = (props) => {

  const selectOption = async (selectedOption) => {
    const response = await userHelper.setUserEducationLevel(selectedOption);
    // console.log('response', response);

    if(response) {
      props.nextStep();
    }
  }

  return (
    <div>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>
            Quel est votre niveau d’étude ?
          </h2>
        </div>
        <div className="step-content-footer">

          <Button onClick={() => {
            selectOption(1);
          }}>Primaire</Button>
          
          <Button type="button" onClick={() => {
            selectOption(2);
          }}>Secondaire inférieur</Button>
          
          <Button onClick={() => {
            selectOption(3);
          }}>Secondaire supérieur</Button>

          <Button onClick={() => {
            selectOption(4);
          }}>Supérieur court (BAC, candidature, graduat)</Button>

          <Button onClick={() => {
            selectOption(5);
          }}>Supérieur long (MASTER, licence, maitrise)</Button>

          <Button onClick={() => {
            selectOption(6);
          }}>Doctorat</Button>
        </div>
      </div>
    </div>
  );
};

export default WizardStep6;