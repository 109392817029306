import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep1 = (props) => {

  const {register, handleSubmit, errors} = useForm();

  const submitForm = (data) => {
    // console.log('submit form', data);
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>
            Avez-vous au minimum 16 ans? Dans le cas contraire, avez-vous un accord parental?
          </h2>
        </div>
        <div className="step-content-footer">
          <Button onClick={async() => {
            const response = await userHelper.setParentalConsent(true);
            // console.log('response', response);
            if(response) {
              props.nextStep();
            }
          }}>
            Oui
          </Button>
          <Button onClick={async () => {
            const response = await userHelper.setParentalConsent(false);
            // console.log('response', response);
            props.setAdultOrParentalConsent(false)
          }}>
            Non
          </Button>
        </div>
      </div>
    </form>
  );
};

export default WizardStep1;