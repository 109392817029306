import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold, fontFamilyWeightRegular } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
`;

export const ContentHeader = styled.div`
  max-width: 100%;
  padding: 64px 0;
  background: ${colors.darkBlue10};

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  .inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
  }

  h1.title {
    max-width: 610px;
    margin-left: 100px;
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};

    ${mq('m')} {
      margin-left: 0;
    }
  }
`;

export const Content = styled.div`
  max-width: calc(100% - 60px);
  width: 1280px;
  margin: 70px auto;
  min-height: calc(100vh - 400px);
  line-height: 1.4em;

  .inner {
    max-width: 610px;
    margin-left: 100px;

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  h1 {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  h2 {
    font-family: ${fontFamily};
    font-size: 24px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
  }

  p {
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: ${fontFamilyWeightRegular};
    color: ${colors.darkBlue100};
    margin: 0 0 15px 0;

    a {
      color: ${colors.blue};

      &:hover {
        color: ${colors.purple};
      }
    }
  }

  ol, ul {
    margin: 0 0 15px 0;
    
    li {
      font-family: ${fontFamily};
      font-size: 14px;
      font-weight: ${fontFamilyWeightRegular};
      color: ${colors.darkBlue100};
      margin: 0 0 10px 0;
    }
  }

`;