import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";
import { mq } from "../../variables.js";

import vip from '../../vip.js';

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	min-height: 100vh;

	${mq('m')} {
		flex-direction: column-reverse;
		justify-content: flex-end;
	}
`;

export const Content = styled.div`
	position: relative;
	//display: flex;
	width: calc(50% - 240px);
	margin: 0 30px 0 0;
	background-color: ${colors.white};
	overflow: hidden;
	padding: 120px;

	${mq('m')} {
		padding: 30px;
		width: calc(100% - 60px);
	}

	.btn-back {
		display: inline-flex;
		align-items: center;
		margin: 0 0 35px;
		font-size: 16px;
		color: ${colors.blue};
		background: transparent none;
		border: 0;
		font-family: ${fontFamily};
		font-weight: ${fontFamilyWeightBold};
		font-size: 14px;
		font-size: 0.875rem;

		&:hover {
      color: ${colors.darkBlue80};
    }
	}

	h1 {
		font-family: ${fontFamily};
		font-size: 36px;
		font-weight: ${fontFamilyWeightBold};
		color: ${colors.darkBlue100};
		margin-bottom: 40px;
	}

	p {
		font-size: 16px;
		line-height: 32px;
		color: ${colors.darkBlue80};
		margin-bottom: 65px;
	}

	button {
		margin-left: 85px;
		margin-right: 85px;
		width: calc(100% - 85px * 2);
		font-size: 16px;
		font-weight: ${fontFamilyWeightBold};
		line-height: 16px;
		font-family: ${fontFamily};

		${mq("m")} {
			width: 100% !important;
			margin-left: 0;
			margin-right: 0;
		}
	}
`;

export const BackContainer = styled.div`
	display: block;
`;

export const SuveyQuestionsContainer = styled.div`
	display: block;
`;

export const Visual = styled.div`
	width: calc(50% + 120px);
	text-align: center;
	background-color: ${colors.lightgrey};
	background-image: url(${vip.visuals['background-survey']});
	background-size: cover;

	${mq("m")} {
		position: relative;
		width: 100%;
	}

	/* img {
		position: absolute;
		display: block;
		bottom: 0;
		right: 0;
		height: auto;
		max-height: 100%;
		max-width: calc(50% + 27px);
	} */

	img {
		display: inline-block;
		width: 70%;
		padding-top: 5%;
		max-height: 95%;
  	object-fit: contain;

		${mq('m')} {
			display: block;
			width: 100%;
			padding-top: 0;
			object-fit: cover;
		}
	}
`;

export const Validate = styled.div`
	position: relative;
`;
