import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";
import vip from '../../vip';
import { mq } from "../../variables.js";
import background from "../../assets/survey/thanks.png";
import backgroundFrites from "../../assets/logo-friterie.png";
import backgroundTelevie from "../../assets/logo-televie.png";

export const Container = styled.div`
	display: flex;
	justify-content: flex-start;
	min-height: 100vh;

	${mq('m')} {

		${vip.visuals['thanks'] ? `
			background: ${colors.white} url(${vip.visuals['thanks']}) no-repeat 50% 100%;
			background-size: 70% auto;
		` : ``}


		${(props) => props.visual === "frite"
			? `
			background: ${colors.white} url(${backgroundFrites}) no-repeat 50% calc(100% - 25px);
			background-size: auto calc(45% - 60px);
  			` : null}

		${(props) => props.visual === "televie"
			? `
			background: ${colors.white} url(${backgroundTelevie}) no-repeat 50% calc(100% - 25px);
			background-size: auto calc(45% - 60px);
  			` : null}

	}
`;

export const Content = styled.div`
	position: relative;
	display: flex;
	width: calc(50% - 240px);
	margin: 0;
	/* background-color: ${colors.white}; */
	overflow: hidden;
	padding: 120px;

	${mq('m')} {
		width: 100%;
		padding: 15vh 30px 30px 30px;
		align-items: flex-start;
	}

	.btn-back {
		display: inline-flex;
		align-items: center;
		margin: 0 0 35px;
		font-size: 16px;
		color: ${colors.blue};
		background: transparent none;
		border: 0;
		font-family: ${fontFamily};
		font-weight: ${fontFamilyWeightBold};
		font-size: 14px;
		font-size: 0.875rem;

		&:hover {
		color: ${colors.darkBlue80};
		}
	}

	h1 {
		font-family: ${fontFamily};
		font-size: 36px;
		font-weight: ${fontFamilyWeightBold};
		color: ${colors.darkBlue100};
		margin-bottom: 40px;
		text-align: center;

		${mq('m')} {
			font-size: 22px !important;

		}

		& + p {
			margin-top: -25px;
		}
	}

	p {
		font-size: 16px;
		line-height: 32px;
		color: ${colors.darkBlue80};
		margin-bottom: 65px;
		text-align: center;

		& + h1 {
			margin-top: -55px;
    		margin-bottom: 55px;
		}
	}

	button {
		margin-left: 85px;
		margin-right: 85px;
		width: calc(100% - 85px * 2);
		font-size: 16px;
		font-weight: ${fontFamilyWeightBold};

		${mq("m")} {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}

	a.btn {
		margin-left: 85px;
		margin-right: 85px;
		font-size: 16px;
		font-weight: ${fontFamilyWeightBold};

		${mq("m")} {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
`;

export const BackContainer = styled.div`
	display: block;
`;

export const Visual = styled.div`
	width: calc(50% + 120px);
	text-align: center;
	background-color: ${colors.lightgrey};
	background-image: url(${background});
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: bottom;

	${(props) => props.visual === "frite"
	? `
		background-image: url(${backgroundFrites}) !important;
		background-size: auto calc(90% - 50px);
		background-position-x: center;
		background-position-y: center;
	` : null}

	${(props) => props.visual === "televie"
	? `
		background-image: url(${backgroundTelevie}) !important;
		background-size: calc(90% - 50px) auto;
		background-position-x: center;
		background-position-y: center;
	` : null}

	${mq("m")} {
		display: none;
	}

	img {
		position: absolute;
		display: block;
		bottom: 0;
		right: 0;
		height: auto;
		max-height: 100%;
		max-width: calc(50% + 27px);
	}
`;

export const Message = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	h1 {
		font-family: ${fontFamily};
		font-size: 36px;
		font-weight: ${fontFamilyWeightBold};
		letter-spacing: -0.02em;
		color: ${colors.darkBlue100};
		text-align: center;
	}

	button,
	.btn {
		margin-left: 85px;
		margin-right: 85px;
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: ${fontFamilyWeightBold};
		display: block;
		align-items: center;
		justify-content: center;
		height: 44px;
		border-radius: 8px;
		font-weight: ${fontFamilyWeightBold};
		cursor: pointer;
		width: 295px;
		transition: all 0.4s ease-out;
		border: 2px solid ${colors.blue};
		background: ${colors.blue};
		color: ${colors.white};
		text-align: center;
		text-decoration: none;
		line-height: 42px;

		${mq("m")} {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
`;

export const Score = styled.div`
	font-family: ${fontFamily};
	font-size: 132px;
	font-weight: ${fontFamilyWeightBold};
	letter-spacing: -0.02em;
	color: ${colors.darkBlue100};
	text-align: center;

	span {
		font-size: 40px;
	}
`;


export const ModalContent = styled.div`
	h1 {
		font-family: ${fontFamily};
		font-size: 20px;
		font-weight: ${fontFamilyWeightBold};
		letter-spacing: -0.02em;
		color: ${colors.darkBlue80};
	}
	p {
		font-family: ${fontFamily};
		font-size: 16px;
		font-weight: 400;
		letter-spacing: -0.02em;
		color: ${colors.darkBlue80};
	}
	label {
		font-family: ${fontFamily};
		font-size: 14px;
		font-weight: 400;
		letter-spacing: -0.02em;
		color: ${colors.darkBlue80};
	}
	input[type="text"] {
		width: calc(100% - 42px);
		height: 52px;
		border: 1px solid ${colors.darkBlue20};
		border-radius: 8px;
		padding: 0 20px;
		color: ${colors.darkBlue60};
		font-size: 14px;
		font-family: ${fontFamily};
	}

	::placeholder {
		color: ${colors.inputPlaceholder};
	}
`;