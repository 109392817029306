//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";
import { Button } from "../../styled-common";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";

type Props = {
    genderValue: number,
    genderTexts: Array<string>,
    handleClose: Function,
};

const ModalGender = (props: Props): Node => {
    const [stateGenderValue, setStateGenderValue] = useState(null);

    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        setStateGenderValue(props.genderValue);
    }, []);

    const onSubmitForm = async (data) => {
        // console.log('data', data);
        await userHelper.setUserGender(stateGenderValue);
        props.handleClose();
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <h2 className="title">Modifier votre genre</h2>

            <div className="">
                <div className="form-item">
                    <input
                        type="radio"
                        name="profile_gender"
                        id="profile_gender_m"
                        value="1"
                        ref={register({ required: true })}
                        checked={stateGenderValue === 1}
                        onChange={(e) => {
                            setStateGenderValue(parseInt(e.currentTarget.value, 10));
                        }}
                    />
                    <label htmlFor="profile_gender_m">{props.genderTexts[1]}</label>
                </div>
                <div className="form-item">
                    <input
                        type="radio"
                        name="profile_gender"
                        id="profile_gender_f"
                        value="2"
                        ref={register({ required: true })}
                        checked={stateGenderValue === 2}
                        onChange={(e) => {
                            // console.log('change gender value', e.currentTarget.value);
                            setStateGenderValue(parseInt(e.currentTarget.value, 10));
                        }}
                    />
                    <label htmlFor="profile_gender_f">{props.genderTexts[2]}</label>
                </div>
                <div className="form-item">
                    <input
                        type="radio"
                        name="profile_gender"
                        id="profile_gender_o"
                        value="3"
                        ref={register({ required: true })}
                        checked={stateGenderValue === 3}
                        onChange={(e) => {
                            // console.log('change gender value', e.currentTarget.value);
                            setStateGenderValue(parseInt(e.currentTarget.value, 10));
                        }}
                    />
                    <label htmlFor="profile_gender_o">{props.genderTexts[3]}</label>
                </div>
            </div>

            <div className="btn-wrapper">
                {errors.profile_gender ? (
                    <div className="form-error">
                        <p>Veuillez cocher une option</p>
                    </div>
                ) : null}
                <Button type="submit" small={true}>
                    Valider
                </Button>

                <Button type="reset" ghost={true} small={true} onClick={props.handleClose}>
                    Annuler
                </Button>
            </div>
        </form>
    );
};

export default ModalGender;
