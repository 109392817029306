//@format
//@flow
import React, { useEffect, useState, useRef } from 'react';
import {useForm} from 'react-hook-form';
import * as userHelper from '../../fetch/user';
import client from '../../fetch/guestClient';
import { Button } from '../../styled-common';
import avatarPlaceholder from '../../assets/avatar-placeholder.png';

const ModalEducationLevel = (props) => {
  
  const [stateEducationLevelValue, setStateEducationLevelValue] = useState(props.educationLevelValue);

  const {register, handleSubmit, errors} = useForm();

  // console.log('errors', errors);


  const onSubmitForm = async (data) => {
    // console.log('data', data);
    await userHelper.setUserEducationLevel(stateEducationLevelValue);
    props.handleClose();
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <h2 className="title">Modifier votre niveau d'étude</h2>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_education_level" 
          id="profile_education_level_0" 
          value="1" 
          ref={register({ required: true })}
          checked={stateEducationLevelValue === 1}
          onChange={(e) => {
            setStateEducationLevelValue(parseInt(e.currentTarget.value, 10));
          }} 
        />
        <label htmlFor="profile_education_level_0">{props.educationLevelTexts[1]}</label>
      </div>
      <div className="form-item">
        <input  
          type="radio" 
          name="profile_education_level" 
          id="profile_education_level_1" 
          value="2" 
          ref={register({ required: true })}
          checked={stateEducationLevelValue === 2}
          onChange={(e) => {
            setStateEducationLevelValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_education_level_1">{props.educationLevelTexts[2]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_education_level" 
          id="profile_education_level_2" 
          value="3" 
          ref={register({ required: true })}
          checked={stateEducationLevelValue === 3}
          onChange={(e) => {
            setStateEducationLevelValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_education_level_2">{props.educationLevelTexts[3]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_education_level" 
          id="profile_education_level_3" 
          value="4" 
          ref={register({ required: true })}
          checked={stateEducationLevelValue === 4}
          onChange={(e) => {
            setStateEducationLevelValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_education_level_3">{props.educationLevelTexts[4]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_education_level" 
          id="profile_education_level_4" 
          value="5" 
          ref={register({ required: true })}
          checked={stateEducationLevelValue === 5}
          onChange={(e) => {
            setStateEducationLevelValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_education_level_4">{props.educationLevelTexts[5]}</label>
      </div>
      <div className="form-item">
        <input 
          type="radio" 
          name="profile_education_level" 
          id="profile_education_level_5" 
          value="6" 
          ref={register({ required: true })}
          checked={stateEducationLevelValue === 6}
          onChange={(e) => {
            setStateEducationLevelValue(parseInt(e.currentTarget.value, 10));
          }}
        />
        <label htmlFor="profile_education_level_5">{props.educationLevelTexts[6]}</label>
      </div>
      
      

      <div className="btn-wrapper">
        {errors.profile_education_level ? <div className="form-error"><p>Veuillez cocher une option</p></div> : null}
        <Button 
          type="submit"
          small={true}
        >
          Valider
        </Button>

        <Button 
          type="reset"
          ghost={true}
          small={true}
          onClick={props.handleClose}
        >
          Annuler
        </Button>
      </div>

    </form>
  );
};

export default ModalEducationLevel;
