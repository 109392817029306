//@format
//@flow
import React from "react";
import type { Node } from "react";

import { BreadcrumbStyled } from "./styled";

import { useHistory } from "react-router-dom";
type Props = {
    items: Array<any>,
    textColor: string,
};

const Breadcrumb = (props: Props): Node => {
    const history = useHistory();

    return (
        <BreadcrumbStyled>
            <ol>
                {props.items.map((item, index) => {
                    //console.log('Breadcrumb', item, index);
                    return (
                        <li key={index}>
                            {item.link ? (
                                <button
                                    style={{ color: props.textColor }}
                                    onClick={() => {
                                        history.push("/" + item.link, item.linkParams || null);
                                    }}
                                >
                                    {item.title}
                                </button>
                            ) : (
                                <span style={{ color: props.textColor, opacity: 0.5 }}>{item.title}</span>
                            )}
                        </li>
                    );
                })}
            </ol>
        </BreadcrumbStyled>
    );
};

export default Breadcrumb;
