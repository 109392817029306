import styled from "styled-components";
import { colors, fontFamily } from "../../../../styled-common.js";

export const Badge = styled.div``;

export const BadgePill = styled.span`
	background-color: ${colors.purple};
	font-family: ${fontFamily};
	font-size: 12px;
	font-weight: 400;
	color: ${colors.white};
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 2px;
	padding-bottom: 1px;
	height: 20px;
	border-radius: 10px;
`;

export const BadgePillLive = styled.span`
	background-color: ${colors.warningColor};
	font-family: ${fontFamily};
	font-size: 12px;
	font-weight: 400;
	color: ${colors.darkBlue100};
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 2px;
	padding-bottom: 1px;
	height: 20px;
	border-radius: 10px;
`;
