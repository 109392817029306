import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../../../styled-common.js";
import { mq } from '../../../../variables.js';

export const ExternalWrapper = styled.div`
  max-height: 560px;
  overflow: hidden;

	${mq('m')} {
    max-height: none;
  }
`;

export const Wrapper = styled.div`
	padding: 0 0 30px;
  background: ${colors.lightgrey};
  margin: 0 auto 0;
	min-height: 370px;

  ${mq('m')} {
    margin-bottom: 0;
		min-height: 0;
  }
`;

export const ContainerWrapper = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Container = styled.div`
	display: flex;
	max-width: 1280px;
	justify-content: space-between;
	margin: 0 auto;
	padding: 0 30px;

	${mq('m')} {
		flex-direction: column-reverse;
	}
`;

export const Visual = styled.div`
	width: 50%;
	margin: 0; //0 0 -130px 0;

	margin: 0 -60px -15% 0;

  position: absolute;
  right: 0;
  width: calc(50% - 50px);

	${mq('m')} {
		position: relative;
		width: calc(100% + 60px);
		margin: 0 -30px;
	}

	.visual-wrapper {
		position: relative;
		overflow: hidden;
		width: 100%;

		&::before {
			content: "";
			display: block;
			width: 100%;
			padding-bottom: 60%;
		}

		img {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
`;

export const Content = styled.div`
	width: 50%;
	//max-width: 380px;
	padding-top: 30px;
	padding-right: 60px;

	${mq('m')} {
		width: 100%;
		padding-right: 0;
	}

	.btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
		font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

	h1 {
		font-family: ${fontFamily};
		font-size: 34px;
		font-weight: ${fontFamilyWeightBold};
		letter-spacing: -0.02em;
		color: ${colors.darkBlue100};
		margin-bottom: 30px;
	}

	.message-errors {
    font-size: 14px;
    color: ${colors.errorColor};
  }

	.description {
		margin: 0 0 20px;
		font-family: ${fontFamily};
		font-size: 16px;
		color: ${colors.darkBlue80};
	}

	.description-button {
		background: transparent;
		border: 0;
		padding: 0;
		color: ${colors.blue};
		font-weight: ${fontFamilyWeightBold};
		cursor: pointer;
		outline: none;
		margin-bottom: 80px;
	}

	.button-container {
		display: flex;
		margin-bottom: 50px;

		${mq('m')} {
			flex-direction: column;
			align-items: center;
		}

		> div + div {
			padding-left: 30px;

			${mq('m')} {
				padding-left: 0;
			}
		}
	}
	.survey-button {
		width: 215px;

		${mq('m')} {
			width: 100%;
		}
	}
	.feed-button {
		width: 215px;

		${mq('m')} {
			width: 100%;
		}
	}
`;

export const ModalContent = styled.div`
	h1 {
		font-family: ${fontFamily};
		font-size: 20px;
		font-weight: ${fontFamilyWeightBold};
		letter-spacing: -0.02em;
		color: ${colors.darkBlue80};
	}
	p {
		font-family: ${fontFamily};
		font-size: 16px;
		font-weight: 400;
		letter-spacing: -0.02em;
		color: ${colors.darkBlue80};
	}
	label {
		font-family: ${fontFamily};
		font-size: 14px;
		font-weight: 400;
		letter-spacing: -0.02em;
		color: ${colors.darkBlue80};
	}
	input[type="text"] {
		width: calc(100% - 42px);
		height: 52px;
		border: 1px solid ${colors.darkBlue20};
		border-radius: 8px;
		padding: 0 20px;
		color: ${colors.darkBlue60};
		font-size: 14px;
		font-family: ${fontFamily};
	}

	::placeholder {
		color: ${colors.inputPlaceholder};
	}
`;

export const ButtonContainer = styled.div`
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
	button {
		width: 150px;
		margin-left: 10px;
	}
`;
