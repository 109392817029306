//@format
//@flow
import React, { useState, useEffect } from "react";
import { AnswerQuestionsContainer, Input, Button, Answer, AnswerContent, QuestionsTextContainer, BtnsWrapper } from "./styled";
import WaitingMessage from "../waitingMessage";
import WaitingMessageAfterFirstQuestion from "../waitingMessageAfterFirstQuestion";
import * as surveyHelper from "../../../../fetch/survey";
import type { Node } from "react";

import { gaSend } from "../../../../components/gaSend";

type Props = {
    question: QuestionObject | null,
    onMoveToNextQuestion: Function,
    survey: Survey,
    currentQuestionIndex: number,
    questionClosed: boolean,
};
const Question = (props: Props): Node => {
    const { question, onMoveToNextQuestion, survey, questionClosed } = props;
    const [currentChoiceId, setCurrentChoiceId] = useState(-1);
    const [working, setWorking] = useState(false);

    /* ------------------------------------------------------------- */
    // Check if user already responded to the question & set the answer
    /* ------------------------------------------------------------- */
    useEffect(() => {
        if (question && question.alreadyAnswered) {
            setCurrentChoiceId(question.userAnswerId);
        } else {
            setCurrentChoiceId(-1);
        }
    }, [question]);

    useEffect(() => {
        if(question && !question.alreadyAnswered && survey) {
            gaSend(`channel: ${survey.channelName} | ${survey.type}: ${survey.title} | Question ${question.order}`)
        }
    }, [question, survey]);
    /* ------------------------------------------------------------- */
    // Post user response and mark the current question as answered
    /* ------------------------------------------------------------- */
    const validateQuestion = async () => {
        if (currentChoiceId !== -1 && survey && question) {
            setWorking(true); // disable button
            await surveyHelper.postUserResponse(currentChoiceId, question.id, survey.id, survey.tenantGUID, false); // post response
            setCurrentChoiceId(-1); // reset user choice
            onMoveToNextQuestion(); // move to next question
            setWorking(false);
        }
    };

    /* ------------------------------------------------------------- */
    // get the user choice
    /* ------------------------------------------------------------- */
    const selectAnswer = (event) => {
        const choiceId = parseInt(event.target.value, 10);
        setCurrentChoiceId(choiceId);
    };
    return (
        <>
            {question && (
                <>
                    {/* <p style={{ marginBottom: 0 }}>Question {question.order}</p> */}
                    <QuestionsTextContainer>
                        <h1 style={{ color: survey.textColor }}>
                            {question.order}&nbsp;-&nbsp;{question.text}
                        </h1>
                    </QuestionsTextContainer>
                    {question.answers && (
                        <AnswerQuestionsContainer fixed={currentChoiceId !== -1 && !working}>
                            {question.answers.map((answer) => (
                                <Answer key={answer.id}>
                                    <AnswerContent className={currentChoiceId === answer.id ? "active" : ""} htmlFor={`question-id-${answer.id}`}>
                                        <div>
                                            <p style={{ marginBottom: "20px", color: survey.textColor }}>{answer.text}</p>
                                        </div>
                                        <div style={{ alignContent: "flex-end" }}>
                                            <Input type="radio" id={`question-id-${answer.id}`} name="react-tips" value={answer.id} checked={currentChoiceId === answer.id ? true : false} disabled={question.alreadyAnswered} onChange={selectAnswer} />
                                            <div className="styled-radio" style={{ borderColor: survey.textColor }}>
                                                <ion-icon name="checkmark-outline"></ion-icon>
                                            </div>
                                        </div>
                                    </AnswerContent>
                                </Answer>
                            ))}
                        </AnswerQuestionsContainer>
                    )}
                    {(survey.diffusionType !== "Live" || !question.alreadyAnswered) && (
                        <BtnsWrapper fixed={currentChoiceId !== -1 && !working}>
                            <Button
                                onClick={validateQuestion}
                                style={{ backgroundColor: currentChoiceId === -1 || working ? "lightgrey" : survey.buttonColor, borderColor: currentChoiceId === -1 || working ? "lightgrey" : survey.buttonColor }}
                                disabled={currentChoiceId === -1 || working}
                                fixed={currentChoiceId !== -1 && !working}
                            >
                                {question.alreadyAnswered ? question.isLast ? <>Terminer</> : <>Question suivante </> : <>Valider</>}
                            </Button>
                        </BtnsWrapper>
                    )}
                </>
            )}

            {!question && survey.diffusionType === "Live" && survey.liveShowStatus === "Started" && (
                <>{survey.questions.filter((q) => q.alreadyAnswered === true).length > 0 ? <WaitingMessageAfterFirstQuestion survey={survey} questionClosed={questionClosed} /> : <WaitingMessage survey={survey} />}</>
            )}
            {!question && survey.diffusionType === "Live" && survey.liveShowStatus === "Undefined" && <WaitingMessage survey={survey} />}
        </>
    );
};

export default Question;
