//@format
//@flow
import React, { useEffect } from "react";
import type { Node } from 'react';
import { useHistory } from "react-router-dom";
import { AnswerQuestionsContainer, Button, Answer, AnswerContent, Result, ResultProgress, ResultPercentage, QuestionsTextContainer, AnswerText } from "./styled";
import vip from '../../../../vip';
import { gaSend } from "../../../../components/gaSend";

type Props= {
question: QuestionObject, 
onMoveToNextQuestion: Function,
onMoveToPrevQuestion: Function, 
survey : Survey,
channelName : String,
}

const Question = (props: Props):Node => {
	const history = useHistory();
	const vipCopy = vip.copy;

	const { question, onMoveToNextQuestion, onMoveToPrevQuestion, survey, channelName } = props;

	const calcPercentage = (total, responseAmount) => {
		const result = Math.round((responseAmount / total) * 100);
		return isNaN(result) ? 0 + "%" : result + "%";
	};

	useEffect(() => {
		gaSend(`channel: ${channelName} | ${survey.type}: ${survey.title} | Result question ${question.order}`)
	}, []);

	return (
		<>
			{question && (
				<>
					
					<QuestionsTextContainer>
						<h1 style={{color:survey.textColor}} >{question.order}&nbsp;-&nbsp;{question.text}</h1>
					</QuestionsTextContainer>
					{question.answers && (
						<AnswerQuestionsContainer>
							{question.answers.map((answer) => (
								<Answer key={answer.id}>
									<AnswerContent>
										<AnswerText>
											<p style={{color:survey.textColor}} >
												{answer.text} {survey.type === "BlindTest" && <span>{answer.responseAmount} réponses</span>}
											</p>
										</AnswerText>
										<Result>
											<div style={{ width: "90%" }}>
												<ResultProgress surveyType={survey.type} textColor={survey.textColor}  percentage={calcPercentage(question.totalResponse, answer.responseAmount)} className={answer.userResponse === 1 ? "active" : ""}></ResultProgress>
											</div>

											<ResultPercentage style={{color:survey.textColor}} className={answer.userResponse === 1 ? "active" : ""}>{survey.type === "BlindTest" ? <>{answer.score} pt.</> : <>{calcPercentage(question.totalResponse, answer.responseAmount)}</>}</ResultPercentage>
										</Result>
									</AnswerContent>
								</Answer>
							))}
						</AnswerQuestionsContainer>
					)}
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						
						{(question.order  > 1) ? (
							<Button
							style={{color:survey.textColor}}
								buttonStyle="text"
								onClick={onMoveToPrevQuestion}
							>
								Question précédente
							</Button>
						) : null}

						{question.isLast ? (
							<Button
							style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}}
								onClick={() => {
									history.push("/survey-details/"+survey.id, { surveyId: survey.id });
								}}
							>
								{question.isLast ? <>{vipCopy['back-to-survey']}</> : <>Question suivante</>}
							</Button>
						) : (
							<Button onClick={onMoveToNextQuestion} style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}}>Question suivante</Button>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default Question;
