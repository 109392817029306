import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";
import { mq } from "../../variables.js";

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	border-radius: 8px;
	background: ${colors.white};
	box-shadow: 0px 7px 30px rgba(6, 10, 74, 0.2);
	overflow: hidden;
	transition: all 0.3s ease-out;
	cursor: pointer;
	width: 100%;
	// max-width: calc(100% - 30px);

	${mq("l")} {
		width: auto;
	}

	.visual {
		position: relative;
		max-width: 100%;
		padding: 0 0 55% 0;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.3s ease-out;
		}
	}

	.content {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 20px;
		text-align: center;
		height: 100%;

		&-card-header {
			display: flex;
			justify-content: space-between;
		}

		.type {
			color: ${colors.white};
			border-radius: 30px;
			padding: 3px 13px;
			font-size: 12px;

			&-quizz,
			&-blindtest {
				background: ${colors.purple};
			}
			&-survey {
				background: ${colors.purple};
			}
			&-live {
				color: ${colors.darkBlue100};
				background: ${colors.warningColor};
			}
		}

		.status {
			text-align: center;

			> span {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-family: ${fontFamily};
				font-size: 12px;
				color: ${colors.darkBlue100};

				&::before {
					content: "";
					display: inline-block;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					margin-right: 10px;
				}

				&.open::before {
					background-color: #00d3b9;
				}

				&.close::before {
					background-color: #f00;
				}
			}
		}

		.title {
			font-family: ${fontFamily};
			font-size: 20px;
			font-weight: ${fontFamilyWeightBold};
			color: ${colors.darkBlue100};
			line-clamp: 3;

			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.description {
			font-family: ${fontFamily};
			font-size: 14px;
			line-height: 20px;
			color: ${colors.darkBlue100};
			margin-bottom: 30px;
			flex: 1;
			height: 100%;
		}

		button {
			margin-bottom: 0;
		}
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 52px;
		height: 52px;
		background: ${colors.darkBlue20};
		border-radius: 16px 0 0 0;

		img {
			display: block;
			width: 80%;
			height: 80%;
			object-fit: contain;
		}
	}

	&:hover {
		box-shadow: 0px 7px 30px rgba(6, 10, 74, 0.05);

		.visual img {
			transform: scale(1.05);
		}
	}
`;
