import React from "react";
import { Container } from "./styled";

const SurveyTypeBadge = (props) => {
	const { survey } = props;
	return (
		<Container>
			<ion-icon name="calendar"></ion-icon>
			Publié le {survey.startDate}
			{survey.endDate ? (
				(survey.startDate !== survey.endDate) ? (
					<>
						<ion-icon name="arrow-forward-outline"></ion-icon>Fin le {survey.endDate}
						{survey.startDate === survey.endDate ? ` ${survey.endTime}` : null}
					</>
				) : (
					(survey.startTime ? (
						<>
						<span style={{marginLeft: '5px'}}> 
						 {`${survey.startTime}`}
						 {(survey.endTime ? (
							` > ${survey.endTime}`
						 ) : null)}
						 </span>
						</>
					): null)
				)
				
			) : null}
		</Container>
	);
};

export default SurveyTypeBadge;
