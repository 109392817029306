// @format
// @flow
import React, { useEffect } from "react";
import type { Node } from "react";
import { useHistory } from "react-router-dom";
import * as channelHelper from "../../fetch/channel";
import { gaSend } from "../../components/gaSend";

const SurveyCode = (): Node => {
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            const url = document.location.toString() + "";
            const channelCode = url.substring(url.lastIndexOf("/") + 1);
            const channel = await channelHelper.getChannelForUserByShortCode(channelCode);

            console.log('channel', channel);
            gaSend(`Channel code: ${channelCode} | channel: ${channel.name}`);

            history.push("/channel-content/" + channel.id, { channelId: channel.id });
        }

        fetchData();
    });

    return null;
};

export default SurveyCode;
