import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Wrapper = styled.div`
  padding: 30px;
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;

  ${mq('m')} {
    padding: 0;
  }

  h1 {
    font-family: ${fontFamily};
    font-size: 24px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
    margin-bottom: 22px;
  }

  .loading-wrapper {
    text-align: center;
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -15px;

  > div {
    width: calc(100% / 3 - 30px);
    margin: 0 15px 30px;

    ${mq('m')} {
      width: 100%;
    }
  }
`;

export const NoResult = styled.div`
  min-height: 677px;

  ${mq('l')} {
    min-height: auto;
  }

  p {
    width: 590px;
    max-width: 100%;
    font-size: 40px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue80};
    margin: 0;
    padding-top: 30px;
  }

  .visual {
    position: absolute;
    z-index: -1;
    right: 0;
    height: 677px;
    margin-top: -30px;

    ${mq('l')} {
      display: none;
    }
  }
`;