import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
`;

export const Visual = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(50% - 60px);
  margin: 0 30px 0 0;
  background-size: auto 80%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: ${colors.purple};

  img {
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
    height: auto;
    min-height: 50%;
    min-width: 100%;
    transform: translate(0, -40%);
  }
`;

export const ContentHeader = styled.div`
  max-width: 100%;
  padding: 64px 0;
  background: ${colors.darkBlue10};

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  .inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
  }

  h1.title {
    max-width: 610px;
    margin-left: 100px;
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};

    ${mq('m')} {
      margin-left: 0;
    }
  }
`;

export const Content = styled.div`
  max-width: calc(100% - 60px);
  width: 1280px;
  margin: 70px auto;
  min-height: calc(100vh - 400px);

  .inner {
    max-width: 610px;
    margin-left: 100px;

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  h1.title {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  .expendable {
    &-list {
      margin: 0 0 50px;
      list-style-type: none;
      padding: 0;
    }

    &-item {
      border-bottom: 1px solid ${colors.darkBlue20};

      a {
        text-decoration: none;
      }
    }

    &-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      font-size: 16px;
      color: ${colors.blue};
      line-height: 24px;
      cursor: pointer;

      ion-icon {
          transition: all .4s ease-in;
        }

      &-opened {
        ion-icon {
          transform: rotate(90deg);
        }
      }
    }

    &-item-content {
      max-height: 0;
      overflow: hidden;
      transition: all .4s ease-in;

      &-inner {
        padding: 0 15px 30px;
      }

      &-opened {
        max-height: 1000px;
      }
    }
  }

  .email-account {
    font-size: 14px;
    margin-bottom: 15px;

    span {
      font-weight: ${fontFamilyWeightBold};

      &.provider {
        font-weight: 400;
        color: ${colors.darkBlue80};
        ion-icon {
          font-size: 18px;
        }
      }
    }
  }

  .password-account {
    margin-bottom: 15px;

    button {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      background: transparent;
      border: 0;
      padding: 0;
      color: ${colors.blue};
    }
  }

  .delete-account {
    &-header {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      span {
        display: inline-block;
        width: calc(100% - 60px);
      }


      button {
        width: auto;
        background: transparent;
        border: 0;
        font-size: 18px;
        color: ${colors.blue};
        margin-bottom: 0;
      }
    }

    p {
      font-size: 12px;
    }
  }

  .form-report {
    .field {
      margin-bottom: 10px;

      label {
        display: block;
        color: ${colors.darkBlue60};
        font-size: 14px;
        margin-bottom: 10px;
      }

      input[type="text"],
      input[type="email"],
      textarea {
        padding: 5px 0 5px 10px;
        line-height: 24px;
        font-size: 16px;
        color: ${colors.darkBlue100};
        border: 1px solid ${colors.darkBlue60};
        border-radius: 4px;
        width: calc(100% - 12px);
        font-family: ${fontFamily};
      }

      &.field-error {
        label {
          color: ${colors.errorColor};
        }
        input[type="text"],
        input[type="email"],
        textarea {
          border-color: ${colors.errorColor};
        }
      }
    }

    
  }

  .confirmation-message {
    display: flex;
    border-radius: 8px;
    margin: 10px 0;
    padding: 12px;

    &-text {
      flex: 1;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: ${colors.darkBlue100};
    }

    ion-icon {
      margin-right: 15px;
      font-size: 20px;
      color: ${colors.darkBlue100};
    }

    &-success {
      background-color: ${colors.successBackground};

      ion-icon {
        color: ${colors.successColor};
      }
    }

    &-error {
      background-color: ${colors.errorBackground};

      ion-icon {
        color: ${colors.errorColor};
      }
    }
  }
`;

export const ContentUpdatePassword = styled.div`

  .message-errors {
    font-size: 14px;
    color: ${colors.errorColor};
  }

  .field {
    margin-bottom: 15px;

    &-wrapper {
      display: flex;
      position: relative;
      border: 1px solid ${colors.darkBlue20};
      border-radius: 8px;

      button {
        background: transparent none;
        border: 0;
        cursor: pointer;
        padding: 0 10px;
        outline: none;

        svg {
          fill: ${colors.blue};
        }

        &:hover {
          svg {
            fill: ${colors.darkblue100};
          }
        }
      }
    }

    label {
      display: inline-block;
      margin: 0 0 10px;
      color: ${colors.darkBlue80};
      font-size: 16px;
    }

    input[type="text"],
    input[type="password"] {
      width: calc(100% - 42px);
      height: 52px;
      padding: 0 20px;
      color: ${colors.darkBlue60};
      font-size: 14px;
      border: 0;
      background: transparent;
      font-family: ${fontFamily};
    }

    ::placeholder {
      color: ${colors.inputPlaceholder};
    }
  }

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    button,
    button + button {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .password-rules {
    margin-bottom: 15px;
  }

  .password-rule {
    display: flex;
    align-items: center;
    margin: 0 0 5px;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.darkBlue60};

    svg {
      display: inline-block;
      margin-left: 10px;
      

      &.valid {
        fill: ${colors.successColor};
      }

      &.not-valid {
        fill: ${colors.errorColor};
        stroke: ${colors.errorColor};
        color: ${colors.errorColor};
      }
    }
  }
`;