import React from "react";
import { Wrapper } from "./styled";

const WaitingMessage = (props) => {
	const { survey } = props;
	return (
		<Wrapper>
			<h1>{survey.title}</h1>
			<p>Veuillez patienter pour le début du sondage...</p>
		</Wrapper>
	);
};

export default WaitingMessage;
