//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type {Node} from "react";
import { useForm } from "react-hook-form";
import * as userHelper from "../../fetch/user";
import AvatarEditor from "react-avatar-editor";
import { Button } from "../../styled-common";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";

type Props = {
    saveAvatar: Function,
    handleClose: Function,
};

const ModalAvatar = (props: Props): Node => {
    const [stateUser, setStateUser] = useState<PepioUser | null>(null);
    const [stateUserLoaded, setStateUserLoaded] = useState(false);
    const [stateErrorMessage, setStateErrorMessage] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState(false);
    const [avatarScale, setAvatarScale] = useState(1.2);
    const [avatarRotate, setAvatarRotate] = useState(0);
    const [editor, setEditor] = useState(null);
    const inputFile = useRef(null);
    const avatarForm = useRef(null);

    const getProfileData = async () => {
        if (!stateUserLoaded) {
            const userData = await userHelper.getUser();
            // console.log('userData', userData);
            setStateUser(userData);
            setStateUserLoaded(true);
        }
    };

    const { handleSubmit } = useForm();

    useEffect(() => {
        getProfileData();
    });

    const onSubmitForm = async (data) => {
        if (editor) {
            //const canvas = editor.getImage();
            // console.log('canvas', canvas);
            const canvasScaled = editor.getImageScaledToCanvas();
            props.saveAvatar(canvasScaled.toDataURL());
            const uploadResponse = await userHelper.uploadPhoto({ data: canvasScaled.toDataURL() });
            if (uploadResponse && uploadResponse !== '') {
                const newStateUser = stateUser;
                if (newStateUser) {
                    newStateUser.pictureUrl = canvasScaled.toDataURL();
                }
                setStateUser(newStateUser);
                handleClose(canvasScaled.toDataURL());
                setStateErrorMessage(false);
            } else {
                setStateErrorMessage(true);
            }
        } else {
            handleClose();
        }
    };

    const handleClose = (sendedPicture) => {
        setAvatarScale(1.2);
        setAvatarRotate(0);
        setAvatarPreview(false);
        props.handleClose(sendedPicture);
        setStateErrorMessage(false);
        if (avatarForm && avatarForm.current) {
            avatarForm.current.reset();
        }
    };

    return (
        <>
            {stateUserLoaded ? (
                <form onSubmit={handleSubmit(onSubmitForm)} ref={avatarForm}>
                    <h2 className="title">Modifier votre avatar</h2>
                    <div className="avatar-wrapper">
                        <div className="form-item form-item-avatar">
                            {avatarPreview ? (
                                <>
                                    <AvatarEditor
                                        ref={setEditor}
                                        // image={(stateUser.pictureUrl.indexOf('null') > -1 || stateUser.pictureUrl === null) ? avatarPlaceholder : stateUser.pictureUrl}
                                        image={avatarPreview}
                                        width={140}
                                        height={140}
                                        border={13}
                                        borderRadius={70}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        scale={parseFloat(avatarScale)}
                                        rotate={avatarRotate}
                                        style={{
                                            width: "166px",
                                            height: "166px",
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    {stateUser && (
                                        <>
                                            {stateUser.pictureUrl.indexOf("null") > -1 || stateUser.pictureUrl === null ? (
                                                <img
                                                    src={avatarPlaceholder}
                                                    className="avatar-existing"
                                                    onClick={() => {
                                                        if (inputFile.current) {
                                                            inputFile.current.click();
                                                        }
                                                    }}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src={stateUser.pictureUrl}
                                                    className="avatar-existing"
                                                    onClick={() => {
                                                        if (inputFile.current) {
                                                            inputFile.current.click();
                                                        }
                                                    }}
                                                    alt=""
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="avatar-controls">
                            {avatarPreview ? (
                                <>
                                    <div className="avatar-zoom">
                                        <label htmlFor="avatar_scale">Zoom</label>
                                        <div className="avatar-zoom-field">
                                            <input
                                                type="range"
                                                step="0.01"
                                                min="1"
                                                max="2"
                                                id="avatar_scale"
                                                name="avatar_scale"
                                                defaultValue={avatarScale}
                                                onChange={(e) => {
                                                    // console.log('scale', e.currentTarget.value);
                                                    setAvatarScale(e.currentTarget.value);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="avatar-rotate">
                                        <label htmlFor="avatar_scale">Rotation</label>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setAvatarRotate(avatarRotate + 90);
                                            }}>
                                            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M6.47145 0.341106C6.2111 0.0807561 5.78899 0.0807561 5.52864 0.341106C5.26829 0.601455 5.26829 1.02357 5.52864 1.28391L6.77736 2.53264C6.55111 2.52498 6.29342 2.52084 6.00004 2.52084C4.87928 2.52084 3.78369 2.85319 2.85181 3.47585C1.91993 4.09851 1.19362 4.98352 0.764726 6.01897C0.335829 7.05442 0.22361 8.1938 0.44226 9.29302C0.66091 10.3922 1.20061 11.402 1.9931 12.1944C2.7856 12.9869 3.79531 13.5266 4.89453 13.7453C5.99376 13.9639 7.13313 13.8517 8.16858 13.4228C9.20403 12.9939 10.089 12.2676 10.7117 11.3357C11.3344 10.4039 11.6667 9.30827 11.6667 8.18751C11.6667 7.81932 11.3682 7.52084 11 7.52084C10.6319 7.52084 10.3334 7.81932 10.3334 8.18751C10.3334 9.04456 10.0792 9.88237 9.60308 10.595C9.12692 11.3076 8.45015 11.863 7.65834 12.191C6.86652 12.519 5.99524 12.6048 5.15465 12.4376C4.31407 12.2704 3.54194 11.8577 2.93591 11.2516C2.32989 10.6456 1.91718 9.87349 1.74997 9.0329C1.58277 8.19232 1.66859 7.32103 1.99657 6.52922C2.32454 5.7374 2.87996 5.06063 3.59257 4.58448C4.30518 4.10832 5.14299 3.85418 6.00004 3.85418C6.41266 3.85418 6.73807 3.86275 6.99323 3.87651L5.52864 5.34111C5.26829 5.60145 5.26829 6.02356 5.52864 6.28391C5.78899 6.54426 6.2111 6.54426 6.47145 6.28391L8.97145 3.78391C9.23179 3.52357 9.23179 3.10146 8.97145 2.84111L6.47145 0.341106Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>

                                        <button
                                            type="button"
                                            onClick={() => {
                                                setAvatarRotate(avatarRotate - 90);
                                            }}>
                                            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M5.52855 0.341106C5.7889 0.0807561 6.21101 0.0807561 6.47136 0.341106C6.73171 0.601455 6.73171 1.02357 6.47136 1.28391L5.22264 2.53264C5.44889 2.52498 5.70658 2.52084 5.99996 2.52084C7.12072 2.52084 8.21631 2.85319 9.14819 3.47585C10.0801 4.09851 10.8064 4.98352 11.2353 6.01897C11.6642 7.05442 11.7764 8.1938 11.5577 9.29302C11.3391 10.3922 10.7994 11.402 10.0069 12.1944C9.2144 12.9869 8.20469 13.5266 7.10547 13.7453C6.00624 13.9639 4.86687 13.8517 3.83142 13.4228C2.79597 12.9939 1.91096 12.2676 1.2883 11.3357C0.665636 10.4039 0.333291 9.30827 0.333291 8.18751C0.333291 7.81932 0.631768 7.52084 0.999958 7.52084C1.36815 7.52084 1.66662 7.81932 1.66662 8.18751C1.66662 9.04456 1.92077 9.88237 2.39692 10.595C2.87308 11.3076 3.54985 11.863 4.34166 12.191C5.13348 12.519 6.00476 12.6048 6.84535 12.4376C7.68593 12.2704 8.45806 11.8577 9.06409 11.2516C9.67011 10.6456 10.0828 9.87349 10.25 9.0329C10.4172 8.19232 10.3314 7.32103 10.0034 6.52922C9.67546 5.7374 9.12004 5.06063 8.40743 4.58448C7.69482 4.10832 6.85701 3.85418 5.99996 3.85418C5.58734 3.85418 5.26193 3.86275 5.00677 3.87651L6.47136 5.34111C6.73171 5.60145 6.73171 6.02356 6.47136 6.28391C6.21101 6.54426 5.7889 6.54426 5.52855 6.28391L3.02855 3.78391C2.76821 3.52357 2.76821 3.10146 3.02855 2.84111L5.52855 0.341106Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </>
                            ) : null}
                            <div className="avatar-input-file">
                                <label className="avatar-input-file-label" htmlFor="avatar_upload_field">
                                    <ion-icon name="image"></ion-icon>
                                    {avatarPreview ? <>Autre fichier</> : <>Choisir un fichier</>}
                                </label>
                                <input
                                    id="avatar_upload_field"
                                    name="avatar_upload_field"
                                    type="file"
                                    ref={inputFile}
                                    accept="image/*;capture=camera"
                                    className="avatar-input-file-field"
                                    onChange={(e) => {
                                        setAvatarPreview(e.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="btn-wrapper">
                        {stateErrorMessage === true ? (
                            <div className="form-error">
                                <p>Vous devez choisir une image</p>
                            </div>
                        ) : null}
                        <Button type="submit" small={true}>
                            Valider
                        </Button>

                        <Button type="reset" ghost={true} small={true} onClick={handleClose}>
                            Annuler
                        </Button>
                    </div>
                </form>
            ) : null}
        </>
    );
};

export default ModalAvatar;
