import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const HomeWrapperContent = styled.div`
  min-height: calc(100vh - 110px - 60px - 105px);

  .blocked-user {
    max-width: 1280px;
    margin: 30px auto 0px;

    .blocked-user-inner {
      border-radius: 8px;
      background: ${colors.errorBackground};
      color: ${colors.errorColor};
      padding: 10px 20px;
    }
  }
`;
export const Wrapper = styled.div`
  padding: 30px;
`;

export const WrapperCategories = styled.div`
  background: ${colors.darkBlue10};
  padding: 50px 30px 65px;

  ${mq('m')} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  h2 {
    font-family: ${fontFamily};
    font-size: 24px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue80};
    margin-bottom: 22px;

    ${mq('m')} {
      margin-top: 0;
    }
  }
`;

export const CategoryButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 71px;
  padding: 10px;
  margin: 0 10px 10px 0;
  border-radius: 8px;
  background: ${colors.blue};
  color: ${colors.white};
  font-size: 14px;
  font-weight: ${fontFamilyWeightBold};
  cursor: pointer;
  transition: background-color .3s ease-out;
  outline: none;
  border: 0;

  &:focus {
    background: ${colors.blue};
    border-color: transparent;
  }

  ${mq('m')} {
    width: calc(50% - 10px);
  }

  span {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin-bottom: 10px;
    width: 20px;
    height: 20px;
    fill: ${colors.white};
  }

  .icon-placeholder {
    margin-bottom: 10px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    ${mq('m', true)} {
      background-color: ${colors.darkBlue100};
      border-color: ${colors.darkBlue100};
    }
  }

  
  
  ${props => props.selected ? `
    background-color: ${colors.purple};
    border-color: ${colors.purple};

    &:focus,
    &:hover {
      background-color: ${colors.purple};
      border-color: ${colors.purple};
    }
  ` : ``}
`;