import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../../../styled-common.js";
import { mq } from '../../../../variables.js';

export const Wrapper = styled.div`
	width: 33%;
	display: flex;

	${mq('m')} {
		width: 100%;
	}
`;
export const Card = styled.div`
	margin: 10px;
	background-color: ${colors.white};
	width: 100%;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	overflow: hidden;
	cursor: pointer;

	&.no-click {
		cursor: default;
	}

	${mq('m')} {
		padding: 0;
	}
`;

export const CardBody = styled.div`
	position: relative;
	display: flex;
	flex-grow: 1;

	${mq('m')} {
		flex-direction: column-reverse;
	}
`;
export const CardTitle = styled.div`
	display: flex;
	flex: 1;
	font-family: ${fontFamily};
	font-size: 16px;
	font-weight: ${fontFamilyWeightBold};
	color: ${colors.darkBlue80};
	padding-top: 5px;
	margin-top: 0;
	flex-direction: column;
	justify-content: space-between;
	min-height: 30px;

	span {
		color: ${colors.darkBlue80};
	}

	${props => props.withoutPicture ? `
		padding-right: 40px;
	` : ``}

	${mq('m')} {
		padding: 15px 25px;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
`;

export const CardPicture = styled.div`
	width: 40%;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	padding-bottom: 40px;

	${mq('m')} {
		width: 100%;
		margin-left: 0;
		padding-bottom: 0;
	}

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
		border-radius: 4px;
		overflow: hidden;
	}
`;

export const CardCheck = styled.div`
	position: absolute;
	bottom: 5px;
	right: 5px;

	${mq('m')} {
		position: relative;
		bottom: auto;
		right: auto;
		padding-right: 15px;
	}

	ion-icon {
		color: ${colors.darkBlue20};
		font-size: 24px;
		display: block;
		
	}

	&.active ion-icon {
		color: ${colors.successColor};
	}
`;

export const CardFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	align-self: flex-end;
	ion-icon {
		color: ${colors.successColor};
		font-size: 24px;
		padding-top: 15px;
	}
`;
