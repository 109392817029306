import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Wrapper = styled.div`
  padding: 58px 0 68px;
  background: ${colors.footerBackground};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;

  ${mq('l')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Logo = styled.div`
  color: ${colors.footerColor};

  img {
    display: block;
    max-width: 140px;
  }

  h3 {
    font-family: ${fontFamily};
    font-weight: ${fontFamilyWeightBold};
    font-size: 14px;
    font-size: .875rem;

    ${mq('l')} {
      text-align: center;
    }
  }
  ul {
    display: flex;
    align-items: center;
    padding: 0;
    list-style-type: none;
    margin: 0;

    ${mq('l')} {
      flex-wrap: wrap;
    }

    li {
      + li {
        margin-left: 20px;
      }
    }
  }
  a {
    color: ${colors.footerColorLink};
    g {
      opacity: .6;
      transition: opacity .2s ease;
    }

    &:hover {
      g {
        opacity: 1;
      }
    }
  }
`;

export const Menu = styled.div`
  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    ${mq('l')} {
      flex-direction: column;
      padding-left: 0;
      margin-top: 30px;
    }

    li {
      margin-left: 30px;
      color: ${colors.footerColor};
      font-family: ${fontFamily};
      font-weight: ${fontFamilyWeightBold};
      font-size: 14px;
      font-size: .875rem;

      ${mq('l')} {
        margin-left: 0;
      }

      a {
        color: ${colors.footerColorLink};
        font-family: ${fontFamily};
        font-weight: ${fontFamilyWeightBold};
        font-size: 14px;
        font-size: .875rem;
        text-decoration: none;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background: ${colors.footerColorLink};
          transform: scaleX(0);
          transform-origin: right;
          transition: transform .3s ease;
        }

        &:hover {
          color: ${colors.footerColorLinkHover};
          &::after {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }

      + li {
        margin-left: 30px;

        ${mq('l')} {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
`;

export const Profile = styled.button`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: ${fontFamilyWeightBold};
  border: 0;
  background: transparent none;
  outline: none;
  cursor: pointer;

  .avatar {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: ${colors.lightgrey};
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

