import PepioLogo from "./assets/logo.svg";
import PepioNoResult from "./assets/visual-no-result-pepio.svg";
import PepioSurveyBackground from "./assets/survey/survey-background-pepio.png";
import PepioThanks from "./assets/survey/thanks.png";
import PepioQuizBackground from "./assets/survey/quiz-background-pepio.png";
import PepioLogin from "./assets/login-choice/visual-pepio.png";
import PepioVerification from "./assets/login-choice/visual-validation.png";
import PepioTerms from "./assets/login-choice/visual-terms.png";
//import PepioUsername from './assets/login-choice/pepio-username.svg';
import PepioWizard from "./assets/wizard.svg";

import PepioFaqData from "./data/pepio-faq";
import PepioTermsData from "./data/pepio-terms";
import PepioPrivacyData from "./data/pepio-privacy";

import OpinioLogo from "./assets/logo-opinio.svg";
import OpinioNoResult from "./assets/visual-no-result-opinio.svg";
import OpinioSurveyBackground from "./assets/survey/survey-background-opinio.png";
import OpinioQuizBackground from "./assets/survey/survey-background-opinio.png";
import OpinioLogin from "./assets/login-choice/visual-opinio.png";
// import OpinioWizard from './assets/opinio-wizard.svg';
import OpinioWizard from "./assets/wizard.svg";

import OpinioFaqData from "./data/opinio-faq";
import OpinioTermsData from "./data/opinio-terms";
import OpinioPrivacyData from "./data/opinio-privacy";

export const vipTable = {
	pepio: {
		name: "Pepio",
		logo: PepioLogo,
		fontFamily: '"Poppins", sans-serif',
		fontFamilyWeightRegular: 400,
		fontFamilyWeightBold: 500,
		showCategoriesStripe: true,
		showNewsModal: false,
		gtm: "GTM-PFZXW43",
		showCookieBanner: false,
		useProfilePicture: true,
		useProfileAvatar: true,
		colors: {
			blue: "#005CCA",
			darkBlue100: "#060A4A",
			darkBlue80: "#393D7B",
			darkBlue60: "#6B6D9C",
			darkBlue40: "#9C9EBD",
			darkBlue20: "#C8CADA",
			darkBlue10: "#ECEDF2",
			white: "#fff",
			black: "#000",
			lightgrey: "#ECEDF2",
			purple: "#7455DA;",
			footerBackground: "#060A4A",
			footerColor: "#fff",
			footerColorLink: "#fff",
			footerColorLinkHover: "#ECEDF2",
			questionsList: "#7455DA",
			loginVisual: "#ECEDF2",
			inputPlaceholder: "#9C9EBD",
			wizardBackdrop: "#373B99",
		},
		copy: {
			creators: "Les Channels",
			survey: "Sondage",
			"survey-long": "Sondage d'opinion",
			"back-to-survey-page": "Retour à la page du sondage",
			"back-to-survey": "Retour au sondage",
			"go-to-survey": "Aller au sondage",
			"to-survey": "au sondage",
			"search-text": "Trouvez un sondage par des mots-clés",
			"thanks-title": "Vous avez répondu à toutes les questions du sondage",
			"thankslive-title": "Merci d’avoir participé au sondage.",
			"no-result": "Aucun sondage actuellement",
			"survey-closed": "Ce sondage est actuellement fermé",
			"already-answered": "Vous avez déjà répondu à ce sondage"
		},
		visuals: {
			"no-result": PepioNoResult,
			"background-survey": PepioSurveyBackground,
			"background-quiz": PepioQuizBackground,
			thanks: PepioThanks,
			login: PepioLogin,
			"login-verification": PepioVerification,
			"login-terms": PepioTerms,
			username: null,
			wizard: PepioWizard,
		},
		faqData: PepioFaqData,
		termsData: PepioTermsData,
		privacyData: PepioPrivacyData,
	},
	"4D27E250-3D52-4909-9D03-398969F16654": {
		name: "Opinio",
		logo: OpinioLogo,
		fontFamily: '"Montserrat", sans-serif',
		fontFamilyWeightRegular: 500,
		fontFamilyWeightBold: 600,
		showCategoriesStripe: true,
		showNewsModal: false,
		gtm: "GTM-N56WJJ3",
		showCookieBanner: true,
		useProfilePicture: false,
		useProfileAvatar: true,
		colors: {
			blue: "#265E94",
			darkBlue100: "#132F4A",
			darkBlue80: "#42596E",
			darkBlue60: "#718292",
			darkBlue40: "#A8BFD4",
			darkBlue20: "#D4DFEA",
			darkBlue10: "#E9EFF4",
			white: "#fff",
			black: "#000",
			lightgrey: "#F4F7FA",
			purple: "#42596E;",
			footerBackground: "#D4DFEA",
			footerColor: "#265E94",
			footerColorLink: "#265E94",
			footerColorLinkHover: "#265E94",
			questionsList: "#265E94",
			loginVisual: "#265E94",
			inputPlaceholder: "#A1ACB7",
			wizardBackdrop: "#265E94",
		},
		copy: {
			creators: "Les Emissions",
			survey: "Enquête",
			"survey-long": "Enquête",
			"back-to-survey-page": "Retour à la page de l'enquête",
			"back-to-survey": "Retour à l'enquête",
			"go-to-survey": "Aller à l'enquête",
			"to-survey": "à l'enquête",
			"search-text": "Trouvez une émission ou une enquête par des mots-clés",
			"thanks-title": "Vous avez répondu à toutes les questions de l’enquête.",
			"thankslive-title": "Merci d’avoir participé au sondage.",
			"no-result": "Aucune enquête actuellement",
			"survey-closed": "Cette enquête est actuellement fermée",
			"already-answered": "Vous avez déjà répondu à cette enquête"
		},
		visuals: {
			"no-result": OpinioNoResult,
			"background-survey": OpinioSurveyBackground,
			"background-quiz": OpinioQuizBackground,
			thanks: null,
			login: OpinioLogin,
			username: null,
			wizard: OpinioWizard,
		},
		faqData: OpinioFaqData,
		termsData: OpinioTermsData,
		privacyData: OpinioPrivacyData,
	},
};

const vip = () : Any => {
	return vipTable[process.env.REACT_APP_VIP_KEY] || vipTable.pepio;
};

export default vip();
