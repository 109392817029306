//@format
//@flow
import React, { useState, useEffect } from "react";
import { VisualContainer } from "./styled";
import WaitingPicture from "../../../../assets/survey/quiz-waiting.png";
import type { Node } from 'react';

type Props={
	survey:any, 
	question:any, 
}

const Visual = (props:Props): Node => {
	const { survey, question } = props;


	return (
		
		<VisualContainer diffusionType={survey.diffusionType}>
			{survey.diffusionType === "Live" && !question && <img src={WaitingPicture} />}
			{question && question.imageUri ? <img src={question.imageUri} /> : null}
		</VisualContainer>
	);
};

export default Visual;
