// @flow
import React from "react";
import type { Node } from 'react';
import { Wrapper } from "./styled";

type Props = {
  survey: Survey,
  questionClosed: boolean,
};

const WaitingMessageAfterFirstQuestion = (props: Props) : Node => {
  const { survey, questionClosed } = props;
  return (
    <Wrapper>
      <h1>{survey.title}</h1>
      {questionClosed ? <p>La question a été fermée.</p> : <p>Votre réponse a bien été enregistrée !</p>}
      <p className="small">Veuillez patienter...</p>
    </Wrapper>
  );
};

export default WaitingMessageAfterFirstQuestion;
