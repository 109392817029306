import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";
import { mq } from "../../variables.js";

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    min-height: 100vh;

    ${mq("m")} {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
`;

export const Content = styled.div`
    position: relative;
    //display: flex;
    width: calc(50% - 240px);
    margin: 0 30px 0 0;
    background-color: ${colors.white};
    overflow: hidden;
    padding: 120px;

    ${mq("m")} {
        width: calc(100% - 60px);
        padding: 0 30px;
        margin-right: 0;
    }

    .btn-back {
        display: inline-flex;
        align-items: center;
        margin: 35px 0;
        font-size: 16px;
        color: ${colors.blue};
        background: transparent none;
        border: 0;
        font-family: ${fontFamily};
        font-weight: ${fontFamilyWeightBold};
        font-size: 14px;
        font-size: 0.875rem;
        padding: 0;

        &:hover {
            color: ${colors.darkBlue80};
        }
    }

    h1 {
        font-family: ${fontFamily};
        font-size: 36px;
        font-weight: ${fontFamilyWeightBold};
        color: ${colors.darkBlue100};
        margin-bottom: 40px;
    }

    p {
        font-size: 16px;
        line-height: 32px;
        color: ${colors.darkBlue80};
        margin-bottom: 65px;
    }

    button {
        margin-left: 85px;
        margin-right: 85px;
        width: calc(100% - 85px * 2);
        font-size: 16px;
        font-weight: ${fontFamilyWeightBold};

        ${mq("m")} {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
`;

export const BackContainer = styled.div`
    display: block;
`;

export const SuveyQuestionsContainer = styled.div`
    display: block;
`;

export const SurveyProgressContainer = styled.div`
	display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
`;
export const Validate = styled.div`
    position: relative;
`;

export const Timer = styled.div`
    background-color: ${colors.lightgrey};
    width: 105px;
    height: 65px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${fontFamily};
    font-weight: ${fontFamilyWeightBold};
    font-size: 24px;
    text-align: center;
    color: ${colors.darkBlue100};
`;
