import React, {useState} from 'react';

import { Button } from '../../../../styled-common';

import { EndStep } from '../../styled';

import Illu from '../../../../assets/wizard-finish.svg';

const WizardEnd = (props) => {
  // console.log('props', props);
  return (
    <EndStep>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>
            Merci!<br />
            Vos données sont désormais enregistrées
          </h2>
          <img src={Illu} />
        </div>
        <div className="step-content-footer">
          <Button onClick={() => { props.handleClose()}}>
            Fermer
          </Button>
        </div>
      </div>
    </EndStep>
  );
};

export default WizardEnd;