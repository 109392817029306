import React, {useEffect} from 'react';
import AsyncStorage from '@callstack/async-storage';

import { useHistory, useLocation } from "react-router-dom";

const Wait = (props) => {
  const history = useHistory();
  const location = useLocation();

  // console.log('location', location);

  useEffect(() => {
    async function checkUserStatus() {
      const OnbordingAlreadyViewed = await AsyncStorage.getItem('@OnbordingAlreadyViewed');
      const pepioId = await AsyncStorage.getItem('@PepioUserId');
      const pepioProfile = await AsyncStorage.getItem('@PepioUser');

      // console.log('pepioId', pepioId);
      // console.log('pepioProfile', pepioProfile);

      // console.log('wait');

      if (pepioId) {
        // User is logged and has viewed the onbording screen --> Go to Home
        clearTimeout(timer);
        // console.log('Navigate to Home (disabled)');
        history.push("/home");
      } else {// if (OnbordingAlreadyViewed === 'true') {
        // User is not but has viewed the onbording screen --> Go to Login choice
        clearTimeout(timer);
        // console.log('Navigate to loginChoice (disabled)');
        history.push("/loginChoice");
      // } else {
      //   // otherwise Go to Onboarding
      //   clearTimeout(timer);
      // console.log('Navigate to Onboarding (disabled)');
      //   history.push("/onboarding");
      }
    }
    checkUserStatus();

    const timer = setTimeout(() => {
      // console.log('This will run after 3 second!');
      // console.log('Navigate to Onboarding (timer)');
      // RootNavigation.reset('Onboarding');
      // history.push("/onboarding");
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // console.log('wait');

  return (
    <div>
      Wait screen
    </div>
  );
};

export default Wait;
