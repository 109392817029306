import React, { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import AsyncStorage from "@callstack/async-storage";
import * as Sentry from "@sentry/react";
import { gaInit } from "./components/gaSend";

import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import vip from "./vip";
import awsconfig from "./aws-exports";

import * as userHelper from "./fetch/user";

import Wait from "./screens/wait";
import Page404 from "./screens/page404";
import PageError from "./screens/pageError";
import Home from "./screens/home";
import Onboarding from "./screens/onboarding";
import LoginChoice from "./screens/loginChoice";
import LoginHome from "./screens/loginHome";
import SignIn from "./screens/signIn";
import SignUp from "./screens/signup";
import SignUpVerification from "./screens/signup-verification";
import ResetPassword from "./screens/resetPassword";
import ChannelByCategory from "./screens/channelByCategory";
import ChannelContent from "./screens/channelContent";
import SurveyDetails from "./screens/surveyDetails";
import SurveyContent from "./screens/surveyContent";
import SurveyResults from "./screens/surveyResults";
import Thanks from "./screens/thanks";
import MyProfile from "./screens/myProfile";
import Settings from "./screens/settings";
import SignupTermsAndConditions from "./screens/signupTermsAndConditions";
import SignupUsername from "./screens/signupUsername";
import SignupAvatar from "./screens/signup-avatar";
import SurveyCode from "./screens/redirection/surveyCode";
import ChannelCode from "./screens/redirection/channelCode";
import Faq from "./screens/faq";
import TermsAndConditions from "./screens/terms-and-conditions";
import PrivacyPolicy from "./screens/privacy-policy";
import Video from "./screens/video";

// console.log("APP TOP");

const resultAmplifyConfigure = Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = "DEBUG";
window.LOG_LEVEL = "DEBUG";

if(process && process.env && process.env.REACT_APP_GA) {
    gaInit();
}

// console.log("resultAmplifyConfigure", resultAmplifyConfigure);

// const history = useHistory();
// // console.log('history', history);

// Listen to amplify signIn/singOut events

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
        //logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Vous pouvez afficher n'importe quelle UI de repli.
            this.setState({ hasError: false });
            return <PageError />;
        } else {
            return this.props.children;
        }
    }
}

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   componentDidCatch(error, info) {
//     // Display fallback UI
//     this.setState({ hasError: true });
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return <PageError />;
//     }
//     return this.props.children;
//   }
// }

function App() {
    // console.log("APP FUNC");
    const history = useHistory();
    const [authState, setAuthState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showCookieBanner, setShowCookieBanner] = useState(false);

    let errorTimeout;

    const checkCookie = async () => {
        const acceptedCookie = await AsyncStorage.getItem("@PepioCookieBanner");
        if (!acceptedCookie) {
            setShowCookieBanner(true);
        }
    };

    useEffect(() => {
        checkCookie();

        Hub.listen("auth", async (data) => {
            // console.log("Hub listen", data);
            switch (data.payload.event) {
                case "signIn":
                    setLoading(true);
                    try {
                        const user = await Auth.currentAuthenticatedUser();
                        // console.log('Hub user ===> credentials', user);
                        if (user) {
                            // console.log("Hub listener ===> signin user", user);
                            await AsyncStorage.removeItem("@AnnonymousLogin");
                            await userHelper.getandStoreAuthenticatedProfile(user, () => {
                                // console.log("Hub listener ===> signin user callback", user);

                                userHelper.refreshProfileFromBackEnd((response) => {
                                    // console.log("Callback user logged", response.data);
                                    setAuthState(response.data);

                                    Auth.currentUserInfo().then(async (data) => {
                                        console.log("currentUserInfo data", data);
                                        AsyncStorage.setItem("@CognitoUserId", data.sub);
                                        setLoading(false);
                                        clearTimeout(errorTimeout);

                                        const loginFromSurvey = await AsyncStorage.getItem("@LoginFromSurvey");
                                        const loginFromChannel = await AsyncStorage.getItem("@LoginFromChannel");

                                        if (!response.data.acceptTermsOfUse) {
                                            // console.log('(auth) redirect terms');
                                            history.push("/signup-terms-and-conditions");
                                        } else if (!response.data.userName) {
                                            // console.log('(auth) redirect username');
                                            history.push("/signup-username");
                                        } else {
                                            // console.log('(auth) redirect home');

                                            // console.log('loginFromSurvey', loginFromSurvey);
                                            if (loginFromSurvey) {
                                                history.push("/survey-details/" + loginFromSurvey, { surveyId: parseInt(loginFromSurvey), fromLogin: true });
                                            } else if (loginFromChannel) {
                                                history.push("/channel-content/" + loginFromChannel, { channelId: parseInt(loginFromChannel), fromLogin: true });
                                            } else {
                                                history.push("/home");
                                            }
                                        }
                                    });
                                });
                            });
                        } else {
                            // console.log("Hub listener ===> signin no user", user);
                            await userHelper.getAndStoreAnnonymousProfileFromBackEnd(() => {
                                // console.log("Hub listener ===> signin no user callback", history);
                                console.log("app > no user > getAndStoreAnnonymousProfileFromBackEnd");
                                setLoading(false);
                                clearTimeout(errorTimeout);
                                history.push("/home");
                            });
                        }
                    } catch (error) {
                        setLoading(false);
                        // console.log("Hub listener ===> Navigate to Error", error);
                        errorTimeout = setTimeout(() => {
                            if (process.env.REACT_APP_SENTRY_DEBUG === "true") {
                                Sentry.captureException("Cognito > signIn error");
                            }
                            setLoading(false);
                            //history.push("/LoginHome", { error: "retry" });
                            console.log("===> ERROR (1)");
                        }, 3000);
                    }
                    break;
                case "signOut":
                    await AsyncStorage.removeItem("@PepioUserId");
                    await AsyncStorage.removeItem("@PepioUser");
                    await AsyncStorage.removeItem("@CognitoUserId");
                    // RootNavigation.navigate('LoginHome');
                    // console.log("Hub listener ===> Navigate to LoginHome", history);
                    history.push("/");
                    break;
                case "customState_failure":
                    setLoading(true);
                    errorTimeout = setTimeout(() => {
                        if (process.env.REACT_APP_SENTRY_DEBUG === "true") {
                            Sentry.captureException("Cognito > customState_failure");
                        }
                        setLoading(false);
                        //history.push("/LoginHome", { error: "retry" });
                        console.log("===> ERROR (2)");
                    }, 3000);
                    break;
                default:
                    break;
            }
        });
    }, []);

    return (
        <ErrorBoundary>
            {/* {showCookieBanner && vip.showCookieBanner ? 
		<div id="hs-eu-cookie-confirmation" className="hs-cookie-notification-position-bottom" data-nosnippet="">
			<div id="hs-eu-cookie-confirmation-inner">
				<div id="hs-eu-policy-wording"><p>Ce site web stocke des cookies sur votre ordinateur. Ces cookies permettent d'améliorer votre expérience de site web et de fournir des services plus personnalisés, à la fois sur ce site et via d'autres médias. Pour obtenir plus d'informations sur les cookies que nous utilisons, consultez notre politique de confidentialité.</p></div>
				<p id="hs-eu-cookie-disclaimer">Vos informations personnelles ne feront l'objet d'aucun suivi lors de votre visite sur notre site. Cependant, en vue de respecter vos préférences, nous devrons utiliser un petit cookie pour que nous n'ayons pas à vous les redemander.</p>
				<div id="hs-en-cookie-confirmation-buttons-area">
					
					<a href="javascript:void(0);" id="hs-eu-confirmation-button" role="button" onClick={async () => {
						await AsyncStorage.setItem('@PepioCookieBanner', true);
						setShowCookieBanner(false);
					}}>
Accepter
</a>
					<a href="javascript:void(0);" id="hs-eu-decline-button" role="button">Refuser</a>
				</div>
			</div>
		</div>
		: null} */}

            {loading ? (
                <div className="loading-fullscreen">
                    <svg version="1.0" width="64px" height="64px" viewBox="0 0 128 128">
                        <rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" />
                        <g>
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#000000" fillOpacity="1" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(45 64 64)" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(90 64 64)" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(135 64 64)" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(180 64 64)" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(225 64 64)" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(270 64 64)" />
                            <path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#c0c0c0" fillOpacity="0.25" transform="rotate(315 64 64)" />
                            <animateTransform attributeName="transform" type="rotate" values="0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64" calcMode="discrete" dur="720ms" repeatCount="indefinite"></animateTransform>
                        </g>
                    </svg>
                </div>
            ) : (
                <Switch>
                    <Route exact path="/">
                        <Wait />
                    </Route>
                    <Route path="/home">
                        <Home />
                    </Route>
                    <Route path="/channel-by-category">
                        <ChannelByCategory />
                    </Route>
                    <Route path="/channel-content/:id">
                        <ChannelContent />
                    </Route>
                    <Route path="/channel-content">
                        <ChannelContent />
                    </Route>
                    <Route path="/survey-details/:id">
                        <SurveyDetails />
                    </Route>
                    <Route path="/survey-details">
                        <SurveyDetails />
                    </Route>
                    <Route path="/survey-content">
                        <SurveyContent />
                    </Route>
                    <Route path="/thanks">
                        <Thanks />
                    </Route>
                    <Route path="/survey-results">
                        <SurveyResults />
                    </Route>
                    <Route path="/onboarding">
                        <Onboarding />
                    </Route>
                    <Route path="/loginChoice">
                        <LoginChoice />
                    </Route>
                    <Route path="/loginHome">
                        <LoginHome />
                    </Route>
                    <Route path="/signin">
                        <SignIn />
                    </Route>
                    <Route path="/signup">
                        <SignUp />
                    </Route>
                    <Route path="/signup-verification">
                        <SignUpVerification />
                    </Route>
                    <Route path="/signup-terms-and-conditions">
                        <SignupTermsAndConditions />
                    </Route>
                    <Route path="/signup-username">
                        <SignupUsername />
                    </Route>
                    <Route path="/signup-avatar">
                        <SignupAvatar />
                    </Route>
                    <Route path="/reset-password">
                        <ResetPassword />
                    </Route>
                    <Route path="/my-profile">
                        <MyProfile />
                    </Route>
                    <Route path="/settings">
                        <Settings />
                    </Route>
                    <Route path="/SurveyCode/:id">
                        <SurveyCode />
                    </Route>
                    <Route path="/ChannelCode">
                        <ChannelCode />
                    </Route>
                    <Route path="/video/:id">
                        <Video />
                    </Route>
                    <Route path="/faq">
                        <Faq />
                    </Route>
                    <Route path="/terms-and-conditions">
                        <TermsAndConditions />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy />
                    </Route>
                    <Route path="*">
                        <Page404 />
                    </Route>
                </Switch>
            )}
        </ErrorBoundary>
    );
}

export default App;
