import React, {useState} from 'react';

import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep7 = (props) => {

  const selectOption = async (selectedOption) => {
    const response = await userHelper.SetUserFamilyContext(selectedOption);
    // console.log('response', response);

    if(response) {
      props.nextStep();
    }
  }

  return (
    <div>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>
            Quelle est votre situation familiale ?
          </h2>
        </div>
        <div className="step-content-footer">

          <Button onClick={() => {
            selectOption(1);
          }}>Célibataire</Button>
          
          <Button type="button" onClick={() => {
            selectOption(2);
          }}>Marié(e) ou en cohabitation légale</Button>
          
          <Button onClick={() => {
            selectOption(3);
          }}>En couple sans mariage et sans cohabitation légale</Button>

          <Button onClick={() => {
            selectOption(4);
          }}>Divorcé(e) ou séparé(e)</Button>

          <Button onClick={() => {
            selectOption(5);
          }}>Veuf/veuve</Button>
        </div>
      </div>
    </div>
  );
};

export default WizardStep7;