//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AsyncStorage from "@callstack/async-storage";
import { ExternalWrapper, Wrapper, Container, ContainerWrapper, Visual, Content, ModalContent, ButtonContainer } from "./styled.js";
import Breadcrumb from "../../../../components/breadcrumb";
import SurveyTypeBadge from "../surveyTypeBadge";
import SurveyDates from "../surveyDates";
import { Button } from "../../../../styled-common.js";
import Modal from "../../../../components/modal";
import * as userHelper from "../../../../fetch/user";
import Wizard from "../../../../components/wizard";

import vip from "../../../../vip";

type Props = {
  survey: Survey,
  checkParticipation: boolean,
  setCheckParticipation: Function,
  cognitoUserIdState: number,
  feedHtmlElement: any,
  showModalNoResults: Function,
};
const vipCopy = vip.copy;

const stepsMap = [
  {
    userProperty: "parentalConsent",
    emptyValue: false,
  },
  {
    userProperty: "genderValue",
    emptyValue: 0,
  },
  {
    userProperty: "birthYear",
    emptyValue: null,
  },
  {
    userProperty: "postalcode",
    emptyValue: null,
  },
  {
    userProperty: "professionnalSituationValue",
    emptyValue: 0,
  },
  {
    userProperty: "educationLevelValue",
    emptyValue: 0,
  },
  {
    userProperty: "familyContextValue",
    emptyValue: 0,
  },
  {
    userProperty: "childsAmount",
    emptyValue: null,
  },
];

const missingProperties = (user) => {
  const stepsTemp = [];
  stepsMap.map((item) => {
    if (
      // $FlowFixMe
      user[item.userProperty] === undefined ||
      user[item.userProperty] === null ||
      // $FlowFixMe
      user[item.userProperty].length <= 0 ||
      user[item.userProperty] === item.emptyValue
    ) {
      stepsTemp.push(item.userProperty);
    }
    return true;
  });

  return stepsTemp;
};

const SurveyHeader = (props: Props) : Node => {
  const { survey } = props;
  const history = useHistory();
  const boutonParticiper = useRef(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showUserNameModal, setShowUserNameModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [stateErrorMessage, setStateErrorMessage] = useState(null);

  const [showModalNeedQualifiedAccount, setShowModalNeedQualifiedAccount] = useState(null);
  const [showModalNeedQualifiedData, setShowModalNeedQualifiedData] = useState(null);
  const [showModalQuitWizard, setShowModalQuitWizard] = useState(false);

  const [showWizard, setShowWizard] = useState(null);

  const { register, setValue, handleSubmit } = useForm({
    mode: "onChange",
    validateCriteriaMode: "all",
  });

  const SurveyParticipation = (fromLogin, questionId) => {
    // console.log('SurveyParticipation', survey, fromLogin, questionId);
    if (survey != null) {
      if (userName === "" && survey.type === "BlindTest") {
        setShowUserNameModal(true);
      } else {
        checkUser(fromLogin, survey, async () => {
          await AsyncStorage.removeItem("@LoginFromSurvey");
          if (questionId) {
            history.push("/survey-content", { surveyId: survey.id, questionId: questionId });
          } else {
            history.push("/survey-content", { surveyId: survey.id });
          }
        });
      }
    }
  };

  const checkUser = async (fromLogin, survey, cb) => {
    const user = await userHelper.getUser();
    const cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
    user.annonymous = cognitoUserId ? false : true;

    // console.log("checkUser => ", user);

    if (survey.requireCompleteProfile) {
      // console.log("CheckUser => Survey Require profile");
      if (!user.accountId || user.annonymous) {
        // console.log("checkUser => ", "user NOT logged prompt for login");
        await AsyncStorage.setItem("@LoginFromSurvey", survey.id);
        setShowModalNeedQualifiedAccount(true);
      } else {
        const missingUserProperties = missingProperties(user);
        if (missingUserProperties.length > 0) {
          if (fromLogin) {
            setShowWizard(true);
          } else {
            setShowModalNeedQualifiedData(true);
          }
        } else {
          cb();
        }
      }
    } else {
      // console.log("CheckUser => Survey is open to all");
      cb();
    }
  };

  const handleCloseWizard = () => {
    setShowWizard(false);
    SurveyParticipation();
  };

  /* ------------------------------------------ */
  // Save the user name
  /* ------------------------------------------ */
  const onPressSave = async (data) => {
    if (userName && userName !== "") {
      const responseData = await userHelper.saveUsername(userName);
      setStateErrorMessage("");
      if (responseData) {
        setShowUserNameModal(false);
        history.push("/survey-content", { surveyId: survey.id });
      }
    } else {
      setStateErrorMessage("Le nom d'utilisateur est obligatoire pour participer aux quiz");
    }
  };

  const goResults = () => {
    // console.log('survey header', survey);
    if(survey.allowViewResult === false) {
      props.showModalNoResults(true);
    } else {
      history.push("/survey-results", { surveyId: survey.id });
    }
  }

  /* ------------------------------------------ */
  // Get the username on screen load
  /* ------------------------------------------ */
  useEffect(() => {
    async function fetchData() {
      const user = await userHelper.getUser();
      setUserName(user.userName);

      const loginFromSurvey = await AsyncStorage.getItem("@LoginFromSurvey");
      if (loginFromSurvey && parseInt(loginFromSurvey, 10) === props.survey.id) {
        await AsyncStorage.removeItem("@LoginFromSurvey");
      }
    }
    fetchData();


    if (props.checkParticipation) {
      SurveyParticipation(null, props.checkParticipation);
      props.setCheckParticipation(false);
    }
  }, [props.checkParticipation]);
  return (
    <ExternalWrapper>
      <Wrapper>
        <ContainerWrapper>
          <Container>
            <Content>
              <Breadcrumb
              textColor={survey.textColor}
                items={[
                  {
                    title: "Home",
                    link: "home",
                  },
                  {
                    title: survey.channelName,
                    link: "channel-content",
                    linkParams: { channelId: survey.channelId },
                  },
                  {
                    title: survey.title,
                  },
                ]}
              />

              <SurveyTypeBadge survey={survey} />

              <SurveyDates survey={survey} />

              <h1 style={{color:survey.textColor}}>{survey.title}</h1>

              <div>
                {showFullDescription || !survey.shortDescription ? (
                  <>
                    <div className="description" style={{color:survey.textColor}} dangerouslySetInnerHTML={{ __html: survey.description }} />
                    {survey.shortDescription && survey.shortDescription !== survey.description && (
                      <button className="description-button" style={{color:survey.textColor}} onClick={() => setShowFullDescription(false)}>
                        Lire moins
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <div className="description" style={{color:survey.textColor}} dangerouslySetInnerHTML={{ __html: survey.shortDescription }} />
                    {survey.shortDescription && survey.shortDescription !== survey.description && (
                      <button className="description-button" style={{color:survey.textColor}} onClick={() => setShowFullDescription(true)}>
                        Lire plus
                      </button>
                    )}
                  </>
                )}

                {(survey.status === "Closed") ? (
                  <p style={{color:survey.textColor, fontStyle: 'italic'}}>
                    {survey.type === "Survey" ? vipCopy["survey-closed"] : <>Quizz</>}
                  </p>
                ) : null}
              </div>

              <div className="button-container">
                <div className="survey-button">
                {console.log('survey', survey)}
                  {(survey.diffusionType !== "Live" && survey.status === "Active" && !survey.alreadyAswered) ||
                  (survey.diffusionType === "Live" && survey.liveShowStatus !== "Closed") ? (
                    <Button ref={boutonParticiper} style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}} onClick={() => SurveyParticipation()}>
                      Participer {survey.type === "Survey" ? vipCopy["to-survey"] : <>au quizz</>}
                    </Button>
                  ) : (
                    ((survey.allowViewResult === true || ( survey.resultMessage !== "" && survey.resultMessage !== null))) ? (
                      <Button onClick={goResults} style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}}>Voir les résultats</Button>
                    ) : (
                      (survey.alreadyAswered === true) ? <>
                        <p style={{color:survey.textColor, fontStyle: 'italic', whiteSpace: 'nowrap'}}>{ [vipCopy['already-answered']] }</p>
                      </> : null
                    )
                  )}
                </div>
                {props.cognitoUserIdState && survey.allowComments ? (
                  <div className="feed-button">
                    <Button
                      style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}}
                      onClick={() => {
                        const feedHtmlElementPosition = props.feedHtmlElement.current.getBoundingClientRect();
                        const elHtml = document.querySelector("html");
                        if (elHtml) {
                          // $FlowFixMe
                          elHtml.scroll(0, feedHtmlElementPosition.top - 90);
                        }
                      }}
                    >
                      <ion-icon name="chatbox"></ion-icon>&nbsp;&nbsp;Discussion
                    </Button>
                  </div>
                ) : null}
              </div>
            </Content>
            <Visual>
              <div className="visual-wrapper">
                <img src={survey.pictureUrl} alt="" />
              </div>
            </Visual>
          </Container>
        </ContainerWrapper>
        <Modal
          visible={showUserNameModal}
          handleClose={() => {
            setShowUserNameModal(false);
          }}
        >
          <ModalContent>
            <h1>Avant de commencer</h1>
            <p>Afin d’être repris dans le classement du quizz, nous avons besoin d’un nom de participant</p>
            <label>Nom d’utilisateur (obligatoire)</label>
            <form onSubmit={handleSubmit(onPressSave)}>
              <input
                type="text"
                name="username"
                onChange={(event) => {
                  const text = event.currentTarget.value;
                  setValue("username", text, true);
                  setUserName(text);
                }}
                ref={register({
                  required: true,
                })}
              />

              {stateErrorMessage ? <p className="message-errors">{stateErrorMessage}</p> : null}
              <ButtonContainer>
                <Button type="reset" ghost={true} onClick={() => setShowUserNameModal(false)}>
                  Annuler
                </Button>
                <Button type="submit" disabled={userName === ""}>
                  Continuer
                </Button>
              </ButtonContainer>
            </form>
          </ModalContent>
        </Modal>

        {showModalNeedQualifiedAccount ? (
          <Modal
            visible={showModalNeedQualifiedAccount}
            handleClose={() => {
              setShowModalNeedQualifiedAccount(false);
            }}
          >
            <ModalContent>
              <h1 className="title margin-top">Pour participer à cette enquête il vous faut un compte et remplir vos données personnelles</h1>

              <h2 className="subtitle">Pourquoi ?</h2>
              <p>Pour nous permettre de créer des données démographiques.</p>

              <h2 className="subtitle">Mes données seront-elles revendues ?</h2>
              <p>Non</p>

              <h2 className="subtitle">Pourra-t-on m’identifier grâce à ces données ?</h2>
              <p>Non</p>

              <Button
                className="centered-button"
                onClick={async () => {
                  await AsyncStorage.setItem("@LoginFromSurvey", survey.id);
                  history.push("/loginHome");
                }}
              >
                Continuer
              </Button>
              <Button
                className="centered-button"
                buttonStyle="text"
                onClick={async () => {
                  setShowModalNeedQualifiedAccount(false);
                  await AsyncStorage.removeItem("@LoginFromSurvey");
                }}
              >
                Annuler
              </Button>
            </ModalContent>
          </Modal>
        ) : null}

        {showModalQuitWizard ? (
          <Modal
            visible={showModalQuitWizard}
            handleClose={() => {
              setShowModalQuitWizard(false);
            }}
          >
            <ModalContent>
              <h1 className="title margin-top">Etes-vous sûr de vouloir abandonner ?</h1>

              <p>Si vous abandonnez la rentrée des données vous ne pourrez pas participer au sondage</p>

              <Button
                className="centered-button"
                onClick={async () => {
                  setShowModalQuitWizard(false);
                  await AsyncStorage.removeItem("@LoginFromSurvey");
                }}
              >
                Abandonner
              </Button>
              <Button
                className="centered-button"
                buttonStyle="text"
                onClick={async () => {
                  setShowModalQuitWizard(false);
                  setShowWizard(true);
                }}
              >
                Annuler
              </Button>
            </ModalContent>
          </Modal>
        ) : null}

        {showModalNeedQualifiedData ? (
          <Modal
            visible={showModalNeedQualifiedData}
            handleClose={() => {
              setShowModalNeedQualifiedData(false);
            }}
          >
            <ModalContent>
              <h1 className="title margin-top">Pour participer à cette enquête il faut compléter vos informations personnelles</h1>

              <h2 className="subtitle">Pourquoi ?</h2>
              <p>Pour nous permettre de créer des données démographiques.</p>

              <h2 className="subtitle">Mes données seront-elles revendues ?</h2>
              <p>Non</p>

              <h2 className="subtitle">Pourra-t-on m’identifier grâce à ces données ?</h2>
              <p>Non</p>

              <Button
                className="centered-button"
                onClick={async () => {
                  await AsyncStorage.setItem("@LoginFromSurvey", survey.id);
                  setShowModalNeedQualifiedData(false);
                  setShowWizard(true);
                }}
              >
                Continuer
              </Button>
              <Button className="centered-button" buttonStyle="text" onClick={() => setShowModalNeedQualifiedData(false)}>
                Annuler
              </Button>
            </ModalContent>
          </Modal>
        ) : null}

        {showWizard ? (
          <Wizard
            visible={showWizard}
            handleClose={handleCloseWizard}
            closeBeforeEnd={() => {
              setShowWizard(false);
              setShowModalQuitWizard(true);
            }}
          />
        ) : null}

      </Wrapper>
    </ExternalWrapper>
  );
};

export default SurveyHeader;
