//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";

import { Button } from "../../styled-common";

type Props = {
    handleClose: Function,
    birthYear: string,
};

const ModalBirthYear = (props: Props): Node => {
    const [stateBirthYear, setStateBirthYear] = useState(props.birthYear);
    const [stateErrorMessage, setStateErrorMessage] = useState(null);

    const { register, handleSubmit, errors } = useForm();

    const onSubmitForm = async (data) => {
        await userHelper.setUserBirthYear(data.birthYear);
        props.handleClose();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <h2 className="title">Modifier votre année de naissance</h2>

                <div className="form-item">
                    <input type="number" name="birthYear" min={new Date().getFullYear() - 100} max={new Date().getFullYear() - 14} defaultValue={stateBirthYear} ref={register({ min: new Date().getFullYear() - 100, max: new Date().getFullYear() - 14 })} />
                </div>

                <div className="btn-wrapper">
                    {errors.birthYear ? (
                        <div className="form-error">
                            <p>
                                Vous devez entrer une valeur entre {new Date().getFullYear() - 100} et {new Date().getFullYear() - 14}
                            </p>
                        </div>
                    ) : null}
                    <Button type="submit" small={true}>
                        Valider
                    </Button>

                    <Button type="reset" ghost={true} small={true} onClick={props.handleClose}>
                        Annuler
                    </Button>
                </div>
            </form>
        </>
    );
};

export default ModalBirthYear;
