import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../../../styled-common.js";
import { mq } from '../../../../variables.js';

export const Wrapper = styled.div`
	${(props) =>
		props.backgroundColor
			? `
    background-color: ${props.backgroundColor};
  `
			: `
  	background-color: ${colors.darkblue100};`}

	margin: 0 auto 0;
	padding-bottom: 170px;
`;
export const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 30px;

	h1 {
		font-family: ${fontFamily};
		font-size: 20px;
		font-weight: ${fontFamilyWeightBold};
		color: ${colors.white};
		padding-top: 30px;
		margin-top: 0;
	}
`;

export const QuestionList = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	padding-bottom: 30px;

	${mq('m')} {
		flex-direction: column;
		
	}
`;
export const QuestionSpacer = styled.div`
	min-height: 50px;
`;

export const QuestionListNotVisible = styled.div`
	display: flex;
	text-align:center;
	font-family: ${fontFamily};
	font-size: 18px;
	font-weight: 300;
	color: ${colors.white};
	justify-content:center;
	margin-bottom:60px;
	margin-top:60px;
`;
