import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold  } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: ${props => props.visible ? '1' : '0'};
  pointer-events: ${props => props.visible ? 'all' : 'none'};
  z-index: ${props => props.visible ? '5000' : '-1'};

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: ${props => props.visible ? '4995' : '-1'};
  }

  .modal-content {
    position: relative;
    width: calc(100% - 20px);
    z-index: ${props => props.visible ? '5005' : '-1'};
    ${props => props.fullSize ? `
      height: calc(100% - 70px);
      padding: 0;
      max-width: 500px;
      overflow: hidden;
    ` : `
      max-height: calc(100% - 70px);
      padding: 20px 25px 25px;
      max-width: 450px;
      overflow: auto;
    `}
    
    margin: 70px 10px;
    background: ${colors.white};
    border-radius: 8px;

    .title {
      margin-top: 0;
      margin-right: 50px;
      font-size: 20px;
      color: ${colors.darkBlue100};

      &.margin-top {
        margin-top: 30px;
      }
    }

    .subtitle {
      font-size: 18px;
      font-weight: ${fontFamilyWeightBold};
      line-height: 24px;
      color: ${colors.purple};
      margin: 20px 0 10px;

      + p {
        margin: 0 0 0;
      }
    }

    p + button {
      margin-top: 30px;
    }

    .centered-button {
      display: flex;
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn-wrapper {
      margin: 30px 0 0;

      button {
        display: inline-flex;
        width: auto;

        &.warning {
          background-color: ${colors.errorBackground};
          color: ${colors.errorColor};
          border-color: ${colors.errorColor};
        }

        + button {
          margin-left: 15px;
        }
      }
    }
  }

  .modal-close {
    display: flex;
    position: absolute;
    z-index: 5005;
    top: 10px;
    right: 10px;
    background: transparent none;
    border: 0;
    color: ${colors.blue};
    cursor: pointer;

    ion-icon {
      font-size: 24px;
    }

    &:hover {
      color: ${colors.darkblue100};
    }
  }

  .modal-emotion-visual {
    max-width: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .form-error {
    margin: 0 0 10px;
    p {
      margin: 0;
      font-size: 12px;
      color: red;

      + p {
        margin-top: 5px;
      }
    }
  }

  .form-item {

    label {
      font-size: 16px;
      color: ${colors.blue};
    }

    input[type="text"],
    input[type="number"] {
      padding: 5px 0 5px 10px;
      line-height: 24px;
      font-size: 16px;
      color: ${colors.blue};
      border: 1px solid ${colors.darkBlue10};
      border-radius: 4px;
      width: calc(100% - 12px);
      font-family: ${fontFamily};
    }

    input[type="radio"] {
      position: absolute;
      left: -9999px;

      + label {
        display: inline-block;
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid ${colors.darkBlue10};
          transition: all .2s ease-in;
        }
        
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 3px;
          top: 50%;
          transform: translate(0, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          overflow: hidden;
          background: transparent;
          transition: all .2s ease-in;
        }
      }

      &:checked + label{
        &::before {
          border-color: ${colors.blue};
        }
        &::after {
          content: '';
          background-color: ${colors.blue};
        }
      }
    }
    + .form-item {
      margin-top: 15px;
    }
  }

  .modal-news {
    text-align: center;

    .title {
      margin-right: 50px;
      margin-left: 50px;
    }

    p {
      color: ${colors.darkBlue80};
      font-size: 16px;
      line-height: 24px;

      a {
        color: ${colors.blue};
        text-decoration: none;
        font-weight: ${fontFamilyWeightBold};
      }
    }

    p + button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;