//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";

import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import AsyncStorage from "@callstack/async-storage";
import * as userHelper from "../../fetch/user";
import { Auth } from "aws-amplify";

import vip from "../../vip";

import { Container, Visual, Content, Separator } from "./styled.js";
import { Button } from "../../styled-common";

import mainVisual from "../../assets/login-choice/visual-pepio.png";

const LoginHome = (): Node => {
    const history = useHistory();
    const location = useLocation();
    const [stateUsername, setStateUsername] = useState("");

    // setStateLoginError(location.state && location.state.error ? location.state.error : null);

    const errorLogin = location.state && location.state.error ? location.state.error : null;

    const { register, setValue, handleSubmit, errors, formState } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all",
    });

    useEffect(() => {
        register("email");
    }, [register]);

    const onPressCheckUser = async (data) => {
        // console.log('data', data);
        const username = stateUsername; // lower case email to fix keyboard issue (some devices force first letter to uppercase)
        const password = "LA_Niche!P455w0rD"; // Fake password to check if user exist
        if (username !== "") {
            Auth.signIn({ username, password })
                .then((user) => {
                    // console.log(user);
                    setStateUsername(user.username);
                })
                .catch(async (err) => {
                    if (err.name === "UserNotFoundException") {
                        // console.log('User not found, redirect to SignUp');
                        // RootNavigation.navigate('SignUp', {username: stateUsername});
                        let importedUser = false;
                        if (vip.name === "Opinio") {
                            importedUser = await userHelper.checkOpinioProfile(stateUsername);
                            if (importedUser) {
                                // AsyncStorage.setItem('@PepioUserId', JSON.stringify(importedUser));
                                AsyncStorage.setItem("@PepioUserId", importedUser.id);
                            }
                        }
                        history.push("signup", { username: stateUsername, importedUser: importedUser });
                    } else if (err.name === "NotAuthorizedException") {
                        // console.log('User found, redirect to SignIn');
                        // RootNavigation.navigate('SignIn', {username: stateUsername});
                        history.push("/signin", { username: stateUsername });
                    } else if (err.name === "UserNotConfirmedException") {
                        // console.log('User found, but not validated');
                        history.push("/signup-verification", { username: stateUsername });
                    } else {
                        // console.log('Error>>>' + err);
                    }

                    return false;
                });
        }
    };

    return (
        <Container>
            <Visual>
                <img src={vip.visuals["login"]} alt="" />
            </Visual>
            <Content>
                <button
                    className="btn-back"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <ion-icon name="chevron-back"></ion-icon>
                    Retour
                </button>

                <img src={vip.logo} alt={vip.name} className="logo" />

                <h1>Connexion</h1>

                {errorLogin !== null ? <div className="errorMessage">Une erreur s'est produite, veuillez réessayer.</div> : null}

                <Button
                    name="facebook"
                    large
                    buttonStyle="facebook"
                    onClick={() => {
                        Auth.federatedSignIn({ provider: "Facebook" })
                            .then((cred) => {
                                // console.log('cred', cred);
                                return Auth.currentAuthenticatedUser();
                            })
                            .catch((e) => {
                                // console.log(e)
                            });
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.875 6H16.125C16.332 6 16.5 5.832 16.5 5.625V3.375C16.5 3.168 16.332 3 16.125 3H13.875C11.601 3 9.75 4.85025 9.75 7.125V9.75H7.125C6.918 9.75 6.75 9.918 6.75 10.125V12.375C6.75 12.582 6.918 12.75 7.125 12.75H9.75V20.625C9.75 20.832 9.918 21 10.125 21H12.375C12.582 21 12.75 20.832 12.75 20.625V12.75H15.375C15.5363 12.75 15.6795 12.6465 15.7313 12.4935L16.4813 10.2435C16.5195 10.1295 16.5 10.0035 16.4295 9.90525C16.3582 9.80775 16.2458 9.75 16.125 9.75H12.75V7.125C12.75 6.50475 13.2547 6 13.875 6Z"
                            fill="white"
                        />
                    </svg>
                    <span>Connexion avec facebook</span>
                </Button>
                <Button name="google" large buttonStyle="google" onClick={() => Auth.federatedSignIn({ provider: "Google" })}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.40771 13.7209L6.83408 15.864L4.73613 15.9091C4.10449 14.7457 3.75 13.4148 3.75 12C3.75 10.6304 4.08193 9.34132 4.67168 8.20694L6.54082 8.54854L7.35938 10.4048C7.18857 10.9043 7.09512 11.4393 7.09512 12C7.09512 12.6059 7.20469 13.1859 7.40771 13.7209Z"
                            fill="#FBBB00"
                        />
                        <path
                            d="M20.105 10.4596C20.1984 10.9591 20.25 11.4747 20.25 12C20.25 12.5897 20.1888 13.1666 20.0695 13.7241C19.6667 15.6158 18.6193 17.2658 17.1659 18.4356L14.8134 18.3164L14.4815 16.2378C15.445 15.6706 16.1991 14.7876 16.5955 13.7273H12.1805V10.4596H16.6568H20.105Z"
                            fill="#518EF8"
                        />
                        <path d="M17.1659 18.4324C15.7512 19.57 13.9562 20.25 12 20.25C8.85791 20.25 6.1251 18.4936 4.73291 15.9091L7.40771 13.7209C8.10381 15.5804 9.89883 16.9049 12 16.9049C12.9023 16.9049 13.7499 16.6599 14.4782 16.2346L17.1659 18.4324Z" fill="#28B446" />
                        <path d="M17.2658 5.64814L14.5942 7.83633C13.8434 7.36582 12.9539 7.09512 12 7.09512C9.8505 7.09512 8.02325 8.48086 7.35939 10.4048L4.67169 8.20371C6.04454 5.55791 8.8128 3.75 12 3.75C14.0013 3.75 15.8382 4.46221 17.2658 5.64814Z" fill="#F14336" />
                    </svg>
                    <span>Connexion avec Google</span>
                </Button>
                <Button name="apple" large buttonStyle="apple" onClick={() => Auth.federatedSignIn({ provider: "SignInWithApple" })}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.0844 7.64277C13.5878 7.64277 12.9553 8.35694 11.913 8.35694C10.8443 8.35694 10.0292 7.64797 8.73226 7.64797C7.46279 7.64797 6.10906 8.42301 5.24939 9.74333C4.04228 11.6052 4.24718 15.1118 6.20223 18.0992C6.90155 19.1686 7.83546 20.3679 9.06039 20.3809H9.08266C10.1472 20.3809 10.4635 19.6838 11.9286 19.6756H11.9508C13.394 19.6756 13.6836 20.3768 14.7437 20.3768H14.7659C15.9909 20.3638 16.9749 19.0349 17.6742 17.9696C18.1775 17.2035 18.3646 16.8189 18.7507 15.9522C15.9226 14.8787 15.4682 10.8695 18.2651 9.33242C17.4114 8.2634 16.2117 7.64425 15.0807 7.64425L15.0844 7.64277Z"
                            fill="white"
                        />
                        <path d="M14.7548 3.75002C13.8639 3.81053 12.8246 4.3777 12.2159 5.11823C11.6635 5.78934 11.2092 6.78487 11.3874 7.75033H11.4586C12.4074 7.75033 13.3784 7.17907 13.9456 6.44708C14.492 5.75036 14.9062 4.763 14.7548 3.75002Z" fill="white" />
                    </svg>
                    <span>Connexion avec Apple</span>
                </Button>

                <Separator>
                    <span>ou</span>
                </Separator>

                <form onSubmit={handleSubmit(onPressCheckUser)}>
                    <label>Votre adresse email</label>
                    <div className="field">
                        <input
                            type="text"
                            name="email"
                            placeholder="votre-email@mail.com"
                            onChange={(event) => {
                                const text = event.currentTarget.value;
                                setValue("email", text.toLowerCase().trim());
                                setStateUsername(text.toLowerCase().trim());
                            }}
                            ref={register({
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })}
                        />
                    </div>

                    <Button large type="submit">
                        Continuer
                    </Button>
                </form>
            </Content>
        </Container>
    );
};

export default LoginHome;
