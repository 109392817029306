import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
`;

export const ContentHeader = styled.div`
  max-width: 100%;
  padding: 64px 0;
  background: ${colors.darkBlue10};

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  .inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
  }

  h1.title {
    max-width: 610px;
    margin-left: 100px;
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};

    ${mq('m')} {
      margin-left: 0;
    }
  }
`;

export const Content = styled.div`
  max-width: calc(100% - 60px);
  width: 1280px;
  margin: 70px auto;
  min-height: calc(100vh - 400px);

  .inner {
    max-width: 610px;
    margin-left: 100px;

    ${mq('m')} {
      margin-left: 0;
    }
  }

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

  h1.title {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  h2 {
    font-family: ${fontFamily};
    font-size: 24px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
    margin: 0 0 25px 0;
  }

  .expendable {
    &-list {
      margin: 0 0 50px;
      list-style-type: none;
      padding: 0;
    }

    &-item {
      border-bottom: 1px solid ${colors.darkBlue20};

      a {
        text-decoration: none;
      }
    }

    &-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px;
      font-size: 16px;
      color: ${colors.blue};
      line-height: 24px;
      font-weight: ${fontFamilyWeightBold};
      cursor: pointer;

      ion-icon {
          transition: all .4s ease-in;
        }

      &-opened {
        ion-icon {
          transform: rotate(90deg);
        }
      }
    }

    &-item-content {
      max-height: 0;
      overflow: hidden;
      transition: all .4s ease-in;

      font-family: ${fontFamily};
      font-size: 16px;
      color: ${colors.darkBlue80};
      line-height: 1.4em;

      &-inner {
        padding: 0 15px 30px;
      }

      &-opened {
        max-height: 1000px;
      }
    }
  }

  
`;