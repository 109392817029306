import React from 'react';
import { useHistory } from "react-router-dom";

import Header from '../../components/header';
import Footer from '../../components/footer';
import { Container, ContentHeader, Content } from './styled.js';

import vip from '../../vip';

const TermsAndConditions = () => {

  const termsData = vip.termsData;
  
  const history = useHistory();

  return (
    <>
      <Header/>
      <Container>
        <ContentHeader>
          <div className="inner">
            <button
              className="btn-back"
              onClick={() => {
                history.push('home');
              }}
            >
              <ion-icon name="chevron-back"></ion-icon>
              Retour
            </button>
            <h1 className="title" dangerouslySetInnerHTML={{__html: termsData.title}}/>
          </div>
        </ContentHeader>
        <Content>
          <div className="inner">
            
            <div className="content-html" dangerouslySetInnerHTML={{__html: termsData.content}}></div>
          </div>
        </Content>
      </Container>

      <Footer />

    </>
  );
};

export default TermsAndConditions;