import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../styled-common.js";
import { mq } from "../../variables.js";

export const CommentsArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 714px;
    margin: -167px auto 70px;

    background: ${colors.darkBlue10};
    border-radius: 8px;
    border: 1px solid ${colors.darkBlue20};

    ${mq("m")} {
        margin-bottom: 0;
        border-radius: 0;
    }

    button {
        background: transparent none;
        border: 0;
    }

    .title {
        color: ${colors.darkBlue100};
        font-size: 20px;
        font-weight: ${fontFamilyWeightBold};
        padding: 0 25px 0;
    }
`;

export const CommentsWrapper = styled.div`
    width: calc(100% - 50px);
    overflow: auto;
    flex: 1;
    padding: 20px 25px 10px;

    .comments-wrapper {
        max-height: 707px;
        overflow: auto;

        ${mq("m")} {
            max-height: none;
        }
    }

    .comment {
        &-sticky {
            position: relative;
        }

        &-wrapper {
            background: ${colors.white} none;
            margin-bottom: 15px;
            padding: 10px;
            border-radius: 8px;
        }

        &-from-me {
            background-color: ${colors.darkBlue20};
        }

        &-child {
            margin-left: 20px;
        }

        &-author {
            display: flex;
            align-items: center;

            &-avatar {
                width: 40px;
                height: 40px;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
                background: ${colors.darkBlue20};

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
            }

            &-placeholder {
                background: ${colors.darkBlue40};
            }

            &-username {
                flex: 1;
                span {
                    font-weight: 600;
                    color: ${colors.darkBlue100};
                }
            }
        }

        &-meta {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-left,
            &-right {
                display: flex;
                align-items: center;
            }
        }

        &-report button {
            color: ${colors.darkBlue40};
            font-size: 16px;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: ${colors.darkBlue80};
            }
        }

        &-reported button {
            color: ${colors.errorColor};
            cursor: default;
            outline: none;

            &:hover {
                color: ${colors.errorColor};
            }
        }

        &-delete button {
            color: ${colors.darkBlue60};
            font-size: 16px;
            transition: all 0.3s ease-in-out;
            outline: none;

            &:hover {
                color: ${colors.darkBlue80};
            }
        }

        &-edit button {
            color: ${colors.darkBlue60};
            font-size: 16px;
            transition: all 0.3s ease-in-out;
            outline: none;

            &:hover {
                color: ${colors.darkBlue80};
            }
        }

        &-date {
            color: ${colors.darkBlue60};
            font-size: 12px;
            /* margin-left: 12px; */
        }

        &-message {
            padding: 10px 0 15px 50px;
            display: flex;
        }

        &-message-emotion {
            padding: 0 10px 0 0;
            img {
                max-width: 120px;
            }
        }

        &-message-text {
            flex: 1;

            font-size: 14px;
            color: ${colors.darkBlue80};
        }

        .comment-message-emotion + .comment-message-text {
            padding-top: 5px;
        }

        &-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                outline: none;
                font-size: 14px;
                /* color: ${colors.blue}; */
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                    /* color: ${colors.darkBlue80}; */
                }
            }
        }

        &-reply {
            color: ${colors.blue};

            &:hover {
                color: ${colors.darkBlue80};
            }
        }

        &-like,
        &-dislike {
            display: inline-flex;
            margin-left: 10px;

            color: ${colors.darkBlue40};

            ion-icon {
                margin-right: 5px;
                font-size: 16px;
                color: ${colors.darkBlue40};
            }

            span {
                display: inline-block;
                color: ${colors.darkBlue60};
                font-size: 12px;
            }

            &:hover {
                span {
                    transition: all 0.3s ease-in-out;
                    color: ${colors.darkBlue60};
                }
                ion-icon {
                    color: ${colors.darkBlue60};
                    transition: all 0.3s ease-in-out;
                }
            }

            &.active {
                ion-icon,
                span {
                    color: ${colors.blue};
                }
            }
            &.inactive {
                ion-icon {
                    color: ${colors.darkBlue40};
                }
                span {
                    color: ${colors.darkBlue60};
                }
                &:hover {
                    ion-icon {
                        color: ${colors.darkBlue60};
                    }
                }
            }
        }
    }
`;

export const CommentForm = styled.div`
    width: calc(100% - 50px);
    padding: 20px 25px 25px;
    position: sticky;
    bottom: 0;
    background: ${colors.darkBlue10};

    .comment-form-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0.5;
        z-index: 300;
    }

    form {
        position: relative;
        z-index: 305;
    }

    .blocked-message {
        p {
            margin-top: 0;
            color: ${colors.darkBlue80};
            font-size: 14px;
        }
    }

    .closed-message {
        p {
            text-align: center;
            margin-top: 0;
            color: ${colors.darkBlue80};
            font-size: 14px;
        }
    }

    .in-reply {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        p {
            display: inline-block;
            margin: 0;
            font-size: 14px;
            color: ${colors.darkBlue80};
        }

        button {
            display: inline-flex;
            align-items: center;
            font-size: 20px;
            color: ${colors.blue};
        }
    }

    .field {
        display: flex;
        border: 1px solid ${colors.darkBlue20};
        background: ${colors.white};

        ${(props) =>
            props.hasFocus
                ? `
      border-radius: 25px;
    `
                : `
      border-radius: 58px;
    `};

        textarea {
            resize: none;
            font-family: ${fontFamily};
            font-size: 14px;
            color: ${colors.darkBlue100};
            width: 100%;
            flex: 1;
            border: 0;
            outline: 0;
            padding: 15px 22px;

            ${(props) =>
                props.hasFocus
                    ? `
        border-radius: 25px;
        height: 90px;
      `
                    : `
        border-radius: 58px;
        height: 22px;
      `};
        }

        button {
            display: flex;
            align-items: center;
            font-size: 50px;
            color: ${colors.blue};
            padding: 0;
            margin: 0 3px 0 10px;
        }
    }

    .blocked-message + .field {
        background: ${colors.darkBlue20};

        textarea {
            background: ${colors.darkBlue20};
            border-color: ${colors.darkBlue20};
        }

        button {
            color: ${colors.darkBlue40};
            cursor: default;
        }
    }

    &.not-connected {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            text-align: center;
            font-size: 14px;
            color: ${colors.darkBlue80};
            margin: 0 0 10px 0;
        }

        button.login {
            background-color: ${colors.blue};
            width: auto;
            margin-top: 20px;
        }
    }

    .emotions-strip {
        padding-bottom: 15px;

        button {
            position: relative;

            img {
                display: block;
                width: 28px;
            }

            .icon-remove {
                position: absolute;
                top: -7px;
                right: -7px;
                border-radius: 50%;
                width: 13px;
                height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                color: ${colors.darkBlue100};
                background: white;
                border: 1px solid ${colors.darkBlue100};
            }
        }
    }
    .emotions-strip-list {
    }
`;

export const ReportMessageError = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: ${colors.errorColor};
    color: ${colors.white};
    padding: 20px 80px 20px 20px;
    border-radius: 8px;
    max-width: 520px;
    transition: all 0.8s ease-in-out;

    ${mq("m")} {
        left: 30px;
    }

    p {
        margin: 0;
    }

    &.hide {
        transform: translate(calc(100% + 30px), 0);
    }

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        outline: none;
        color: ${colors.white};
        font-size: 25px;
        background: transparent;
        border: 0;
        padding: 0;
        height: 25px;
        display: flex;
        width: 25px;
        align-items: center;
        justify-content: center;
    }
`;

export const ModalContent = styled.div`
    h1 {
        font-family: ${fontFamily};
        font-size: 20px;
        font-weight: ${fontFamilyWeightBold};
        letter-spacing: -0.02em;
        color: ${colors.darkBlue80};
    }
    p {
        font-family: ${fontFamily};
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.02em;
        color: ${colors.darkBlue80};
    }
    label {
        font-family: ${fontFamily};
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.02em;
        color: ${colors.darkBlue80};
    }
    input[type="text"] {
        width: calc(100% - 42px);
        height: 52px;
        border: 1px solid ${colors.darkBlue20};
        border-radius: 8px;
        padding: 0 20px;
        color: ${colors.darkBlue60};
        font-size: 14px;
        font-family: ${fontFamily};
    }

    ::placeholder {
        color: ${colors.inputPlaceholder};
    }
`;
