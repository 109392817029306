import styled from "styled-components";
import { colors, fontFamily } from "../../../../styled-common.js";

export const Wrapper = styled.div`
	height: 20px;
	display: flex;
	align-items: center;
	width: 100%;
`;
export const SurveyProgressBar = styled.div`
	width: 50%;
	background-color: ${colors.lightgrey};
	height: 4px;
	border-radius: 2px;
`;

export const SurveyCurrentProgress = styled.div`
	
	height: 4px;
	border-radius: 2px;

	${(props) =>
		props.buttonColor
			? `
			background-color:${props.buttonColor};
  `
			: `
			background-color: ${colors.blue}`}

	${(props) =>
		props.progress
			? `
			width:${props.progress};
  `
			: `
			width: 0%;`}
`;
