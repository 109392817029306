//@format
//@flow
import React, { useEffect, useState } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import AsyncStorage from "@callstack/async-storage";
import { useHistory, useLocation } from "react-router-dom";
import * as userHelper from "../../fetch/user";
import vip from "../../vip";
import Modal from "./modal";
import ModalLocality from "./modalLocality";
import ModalAvatar from "./modalAvatar";
import ModalAvatarLab from "./modalAvatarLab";
import ModalUsername from "./modalUsername";
import ModalGender from "./modalGender";
import ModalBirthYear from "./modalBirthYear";
import ModalEducationLevel from "./modalEducationLevel";
import ModalChilds from "./modalChilds";
import ModalProfessionnal from "./modalProfessionnal";
import ModalFamilyContext from "./modalFamilyContext";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Wizard from "../../components/wizard";
import { Container, ContentHeader, Content } from "./styled.js";
import { Button } from "../../styled-common";

import avatarPlaceholder from "../../assets/avatar-placeholder.png";

const stepsMap = [
    // {
    //   userProperty: 'parentalConsent',
    //   emptyValue: false
    // },
    {
        userProperty: "genderValue",
        emptyValue: 0,
        step: 1,
    },
    {
        userProperty: "birthYear",
        emptyValue: null,
        step: 2,
    },
    {
        userProperty: "postalcode",
        emptyValue: null,
        step: 3,
    },
    {
        userProperty: "professionnalSituationValue",
        emptyValue: 0,
        step: 4,
    },
    {
        userProperty: "educationLevelValue",
        emptyValue: 0,
        step: 5,
    },
    {
        userProperty: "familyContextValue",
        emptyValue: 0,
        step: 6,
    },
    {
        userProperty: "childsAmount",
        emptyValue: null,
        step: 7,
    },
];

const missingProperties = (user) => {
    const stepsTemp = [];
    stepsMap.map((item) => {
        //$FlowFixMe
        if (user[item.userProperty] === undefined || user[item.userProperty] === null || user[item.userProperty].length <= 0 || user[item.userProperty] === item.emptyValue) {
            stepsTemp.push(item.step);
        }
    });

    return stepsTemp;
};

const MyProfile = (): Node => {
    const history = useHistory();
    const location = useLocation();

    const [stateUser, setStateUser] = useState<null | PepioUser>(null);
    const [stateUserLoaded, setStateUserLoaded] = useState(false);

    const [annonymousUser, setAnnonymousUser] = useState(null);
    const [stateAvatar, setStateAvatar] = useState(null);
    const [stateAvatarLab, setStateAvatarLab] = useState(null);

    const [stateUsername, setStateUsername] = useState(false);
    const [stateUsernameEdit, setStateUsernameEdit] = useState(false);
    const [stateAvatarOpened, setStateAvatarOpened] = useState(false);
    const [stateAvatarLabAlreadyOpened, setStateAvatarLabAlreadyOpened] = useState(false);
    const [stateAvatarLabOpened, setStateAvatarLabOpened] = useState(!stateAvatarLabAlreadyOpened && location.state && location.state.avatar ? true : false);
    const [stateGenderOpened, setStateGenderOpened] = useState(false);
    const [stateBirthYearOpened, setStateBirthYearOpened] = useState(false);
    const [stateEducationLevelOpened, setStateEducationLevelOpened] = useState(false);
    const [stateFamilyContextOpened, setStateFamilyContextOpened] = useState(false);
    const [stateLocalityOpened, setStateLocalityOpened] = useState(false);
    const [stateChildsOpened, setStateChildsOpened] = useState(false);
    const [stateProfessionnalSituationOpened, setStateProfessionnalSituationOpened] = useState(false);

    const [wizardOpened, setWizardOpened] = useState(false);
    const [pepioAlreadyUsedWizard, setPepioAlreadyUsedWizard] = useState(true);
    const [noUserdata, setNoUserdata] = useState(false);
    const { register, setValue, handleSubmit, formState } = useForm();

    const [showModalSelectAvatar, setShowModalSelectAvatar] = useState(false);

    const getProfileData = async (force) => {
        const cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
        const pepioAlreadyWizard = await AsyncStorage.getItem("@PepioAlreadyWizard");
        // console.log('cognitoUserId', cognitoUserId);

        if (!cognitoUserId) {
            setAnnonymousUser(true);
        } else {
            setAnnonymousUser(false);
        }

        if ((!stateUserLoaded || force) && cognitoUserId) {
            const userData = await userHelper.getUser();

            userData.pictureUrl = userData.pictureUrl + "?" + new Date().getTime();
            setStateUser(userData);
            setStateUsername(userData.userName);
            setStateUserLoaded(true);

            const missingUserProperties = missingProperties(userData);

            if (!pepioAlreadyWizard || pepioAlreadyWizard === false) {
                setPepioAlreadyUsedWizard(false);
                //setWizardOpened(true);
            }

            // console.log('missingUserProperties.length === stepsMap.length', missingUserProperties.length === stepsMap.length, missingUserProperties.length , stepsMap.length)
            if (missingUserProperties.length === stepsMap.length) {
                setNoUserdata(true);
            } else if (missingUserProperties.length < stepsMap.length) {
                setNoUserdata(false);
            }

            const avatar = userData.pictureUrl.indexOf("null") > -1 ? avatarPlaceholder : userData.pictureUrl;
            setStateAvatar(avatar);
        }
        return null;
    };

    const genderMap = ["Non spécifié", "Masculin", "Féminin", "Autre"];

    const educationLevelMap = ["Non spécifié", "Primaire", "Secondaire inférieur", "Secondaire supérieur", "Supérieur court (BAC, candidature, graduat)", "Supérieur long (MASTER, licence, maitrise)", "Doctorat"];

    const professionnalSituationMap = ["Non spécifié", "Employé(e) ou ouvrier/ouvrière", "Indépendant(e)", "Demandeur/demandeuse d'emploi", "Homme/femme au foyer", "Etudiant(e)", "Pensionné(e)"];

    const familyContextMap = ["Non spécifié", "Célibataire", "Marié(e) ou en cohabitation légale", "En couple sans mariage et sans cohabitation légale", "Divorcé(e) ou séparé(e)", "Veuf/veuve"];

    useEffect(() => {
        getProfileData();
    });

    const handleCloseWizard = async () => {
        await AsyncStorage.setItem("@PepioAlreadyWizard", true);
        setWizardOpened(false);

        getProfileData(true);
    };

    return (
        <>
            <Header username={stateUser && stateUser.userName ? stateUser.userName : false} avatar={stateAvatar} />
            <Container>
                {stateUserLoaded && !annonymousUser ? (
                    <ContentHeader>
                        <div className="inner">
                            <button
                                className="btn-back"
                                onClick={() => {
                                    history.push("home");
                                }}
                            >
                                <ion-icon name="chevron-back"></ion-icon>
                                Retour
                            </button>

                            {stateUserLoaded && !annonymousUser ? (
                                <>
                                    <div className="header">
                                        <div
                                            className="avatar"
                                            onClick={() => {
                                                if (vip.useProfileAvatar && vip.useProfilePicture) {
                                                    setShowModalSelectAvatar(!showModalSelectAvatar);
                                                } else if (!vip.useProfileAvatar && vip.useProfilePicture) {
                                                    setStateAvatarOpened(!stateAvatarOpened);
                                                } else if (vip.useProfileAvatar && !vip.useProfilePicture) {
                                                    setStateAvatarLabOpened(!stateAvatarOpened);
                                                }
                                            }}
                                        >
                                            <img src={stateAvatar} alt="" />
                                            <button className="btn-round">
                                                <ion-icon name="pencil"></ion-icon>
                                            </button>
                                        </div>

                                        <div className="username">
                                            <>
                                                <span>{stateUser && stateUser.userName}</span>
                                                <Button
                                                    small={true}
                                                    ghost={true}
                                                    onClick={() => {
                                                        setStateUsernameEdit(true);
                                                    }}
                                                >
                                                    <ion-icon name="pencil"></ion-icon>
                                                </Button>
                                            </>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <h1 className="title">Mon profil</h1>
                            )}
                        </div>
                    </ContentHeader>
                ) : null}
                <Content>
                    <div className="inner">
                        {stateUserLoaded && !annonymousUser ? (
                            <>
                                <h2 className="title">Vos données</h2>
                                <p className="title-text">
                                    Vos données personnelles sont essentielles pour permettre des analyses représentatives de vos opinions.
                                    <br />
                                    Et un rappel entre nous : {vip.name} vous garantit que vos données personnelles sont totalement protégées et ne sont jamais transmises à des entreprises ou des organisations tierces.
                                </p>

                                {noUserdata ? (
                                    <div className="nodata">
                                        <p>Vous n’avez pas encore rempli vos données personnelles. </p>

                                        <div className="btn-wrapper">
                                            <Button
                                                onClick={() => {
                                                    setWizardOpened(true);
                                                }}
                                            >
                                                Remplir mes données
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="profile-item">
                                            <p className="profile-item-label">Genre</p>
                                            <span className={`profile-item-value${stateUser?.genderValue === 0 ? " profile-item-value-null" : ""}`}>{stateUser && genderMap[stateUser.genderValue]}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateGenderOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                        <div className="profile-item">
                                            <p className="profile-item-label">Année de naissance</p>
                                            <span className={`profile-item-value${!stateUser?.birthYear ? " profile-item-value-null" : ""}`}>{stateUser?.birthYear ? stateUser.birthYear : "Non spécifié"}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateBirthYearOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                        <div className="profile-item">
                                            <p className="profile-item-label">Commune</p>
                                            <span className={`profile-item-value${!stateUser?.locality ? " profile-item-value-null" : ""}`}>{stateUser?.locality && stateUser?.postalcode ? `${stateUser.locality}, ${stateUser.postalcode}` : "Non spécifié"}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateLocalityOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                        <div className="profile-item">
                                            <p className="profile-item-label">Situation professionnelle</p>
                                            <span className={`profile-item-value${stateUser?.professionnalSituationValue === 0 ? " profile-item-value-null" : ""}`}>{stateUser && professionnalSituationMap[stateUser.professionnalSituationValue]}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateProfessionnalSituationOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                        <div className="profile-item">
                                            <p className="profile-item-label">Niveau d'étude</p>
                                            <span className={`profile-item-value${stateUser?.educationLevelValue === 0 ? " profile-item-value-null" : ""}`}>{stateUser && educationLevelMap[stateUser?.educationLevelValue]}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateEducationLevelOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                        <div className="profile-item">
                                            <p className="profile-item-label">Situation familiale</p>
                                            <span className={`profile-item-value${stateUser?.familyContextValue === 0 ? " profile-item-value-null" : ""}`}>{stateUser && familyContextMap[stateUser?.familyContextValue]}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateFamilyContextOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>

                                        <div className="profile-item">
                                            <p className="profile-item-label">Nombre d'enfants</p>
                                            <span className={`profile-item-value${stateUser?.childsAmount === null ? " profile-item-value-null" : ""}`}>{stateUser?.childsAmount === null ? "Non spécifié" : stateUser?.childsAmount}</span>
                                            <button
                                                className="btn-text"
                                                onClick={() => {
                                                    setStateChildsOpened(true);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                        </div>
                                    </>
                                )}

                                <div className="btn-wrapper">
                                    <Button ghost={true} onClick={userHelper.signOutUser}>
                                        Se déconnecter
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                {stateUserLoaded ? (
                                    <div>
                                        <h1 className="title">C'est dommage, vous utilisez {vip.name} sans compte</h1>
                                        <p>La communauté {vip.name} aimerait vous connaître.</p>
                                        <p>Créer votre profil, cela prend 30 secondes.</p>

                                        <div className="btn-wrapper" style={{ textAlign: "left" }}>
                                            <Button
                                                onClick={() => {
                                                    history.push("/loginHome");
                                                }}
                                            >
                                                Je crée mon compte
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                </Content>
            </Container>

            <Footer />

            <Modal
                visible={stateAvatarOpened}
                handleClose={(sendedPicture) => {
                    setStateAvatarOpened(false);

                    // getProfileData(true);
                }}
            >
                <ModalAvatar
                    saveAvatar={(avatar) => {
                        setStateAvatar(avatar);
                        // console.log('save avatar');
                    }}
                    handleClose={(sendedPicture) => {
                        setStateAvatarOpened(false);
                        // getProfileData(true);
                    }}
                />
            </Modal>
            {stateAvatarLabOpened ? (
                <Modal
                    visible={stateAvatarLabOpened}
                    className="avatar-lab"
                    handleClose={(sendedPicture) => {
                        getProfileData(true);
                        setStateAvatarLabAlreadyOpened(true);
                        setStateAvatarLabOpened(false);
                    }}
                >
                    <ModalAvatarLab
                        fromSignUp={!stateAvatarLabAlreadyOpened && location.state && location.state.avatar}
                        handleClose={() => {
                            setStateAvatarLabAlreadyOpened(true);
                            setStateAvatarLabOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            <Modal
                visible={stateLocalityOpened}
                handleClose={() => {
                    setStateLocalityOpened(false);
                    getProfileData(true);
                }}
            >
                <ModalLocality
                    handleClose={() => {
                        setStateLocalityOpened(false);
                        getProfileData(true);
                    }}
                />
            </Modal>
            {stateUser ? (
                <Modal
                    visible={stateGenderOpened}
                    handleClose={() => {
                        setStateGenderOpened(false);
                        getProfileData(true);
                    }}
                >
                    <ModalGender
                        genderValue={stateUser.genderValue}
                        genderTexts={genderMap}
                        handleClose={() => {
                            setStateGenderOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {stateUser ? (
                <Modal
                    visible={stateBirthYearOpened}
                    handleClose={() => {
                        setStateBirthYearOpened(false);
                        getProfileData(true);
                    }}
                >
                    <ModalBirthYear
                        birthYear={stateUser.birthYear}
                        handleClose={() => {
                            setStateBirthYearOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {stateUser ? (
                <Modal
                    visible={stateEducationLevelOpened}
                    handleClose={() => {
                        setStateEducationLevelOpened(false);
                        getProfileData(true);
                    }}
                >
                    <ModalEducationLevel
                        educationLevelValue={stateUser.educationLevelValue}
                        educationLevelTexts={educationLevelMap}
                        handleClose={() => {
                            setStateEducationLevelOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {stateUser ? (
                <Modal
                    visible={stateFamilyContextOpened}
                    handleClose={() => {
                        setStateFamilyContextOpened(false);
                        getProfileData(true);
                    }}
                >
                    <ModalFamilyContext
                        familyContextValue={stateUser.familyContextValue}
                        familyContextTexts={familyContextMap}
                        handleClose={() => {
                            setStateFamilyContextOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {stateUser ? (
                <Modal
                    visible={stateChildsOpened}
                    handleClose={() => {
                        setStateChildsOpened(false);
                        getProfileData(true);
                    }}
                >
                    <ModalChilds
                        childsAmount={stateUser.childsAmount}
                        handleClose={() => {
                            setStateChildsOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {stateUser ? (
                <Modal
                    visible={stateProfessionnalSituationOpened}
                    handleClose={() => {
                        setStateProfessionnalSituationOpened(false);
                        getProfileData(true);
                    }}
                >
                    <ModalProfessionnal
                        professionnalSituationValue={stateUser.professionnalSituationValue}
                        professionnalSituationTexts={professionnalSituationMap}
                        handleClose={() => {
                            setStateProfessionnalSituationOpened(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {stateUser ? (
                <Modal
                    visible={stateUsernameEdit}
                    handleClose={() => {
                        setStateUsernameEdit(false);
                        getProfileData(true);
                    }}
                >
                    <ModalUsername
                        username={stateUser.userName}
                        handleClose={() => {
                            setStateUsernameEdit(false);
                            getProfileData(true);
                        }}
                    />
                </Modal>
            ) : null}

            {wizardOpened ? <Wizard visible={wizardOpened} handleClose={handleCloseWizard} /> : null}

            {stateUser ? (
                <Modal
                    visible={showModalSelectAvatar}
                    hideClose
                    handleClose={() => {
                        //getProfileData(true);
                    }}
                >
                    <p style={{ textAlign: "center" }}>Voulez-vous utiliser une photo de profil ou créer un avatar personnalisé?</p>
                    <Button
                        block
                        large
                        onClick={() => {
                            setShowModalSelectAvatar(false);
                            setStateAvatarOpened(true);
                        }}
                    >
                        Photo de profil
                    </Button>
                    <Button
                        block
                        large
                        onClick={() => {
                            setShowModalSelectAvatar(false);
                            setStateAvatarLabOpened(true);
                        }}
                    >
                        Créer un avatar
                    </Button>
                </Modal>
            ) : null}
        </>
    );
};

export default MyProfile;
