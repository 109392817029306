import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import * as userHelper from '../../fetch/user';

import vip from "../../vip";

import { Wrapper, Container, Logo, Menu, Profile } from './styled';

const Footer = (props) => {
  const history = useHistory();

  return (
    <footer>
      <Wrapper>
        <Container>
          <Logo>
            {vip.name === 'Pepio' ? (
              <>
                <h3 className="h5">Un partenariat</h3>
                <ul className="partners">
                  <li className="partner">
                    <a aria-label="Aller sur le site de Miil" href="https://www.miil.be/" target="_blank" rel="noopener noreferrer">
                      <svg width="68" height="36" viewBox="0 0 68 36" fill="none">
                        <g>
                          
                          <path d="M52.9707 0H57.4534C57.9234 0 58.303 0.376963 58.303 0.84368V24.377H67.151C67.6209 24.377 68.0005 24.7539 68.0005 25.2206V29.3852H53.8203C53.3503 29.3852 52.9707 29.0082 52.9707 28.5415V0Z" fill="white"></path>
                          <path d="M6.58852 8.65234H0V28.5596C0 29.0263 0.379585 29.4033 0.849548 29.4033H5.1244V16.0749L9.94152 28.8468C10.068 29.1789 10.3844 29.3943 10.7368 29.3943H14.9755L7.38384 9.19086C7.25731 8.86775 6.94099 8.65234 6.58852 8.65234Z" fill="white"></path>
                          <path d="M30.1045 8.65234H23.5159C23.1635 8.65234 22.8471 8.86775 22.7206 9.19984L15.1289 29.4033H19.3676C19.7201 29.4033 20.0364 29.1879 20.1629 28.8558L24.9891 16.0749V29.4033H29.2639C29.7339 29.4033 30.1135 29.0263 30.1135 28.5596V8.65234H30.1045Z" fill="white"></path>
                          <path d="M35.2018 16.9541H38.591C39.061 16.9541 39.4405 17.3311 39.4405 17.7978V35.165C39.4405 35.6317 39.061 36.0087 38.591 36.0087H34.3613V17.7978C34.3613 17.3311 34.7319 16.9541 35.2018 16.9541Z" fill="white"></path>
                          <path d="M44.3117 11.1743H48.5414V31.1624C48.5414 31.6291 48.1618 32.006 47.6918 32.006H44.3027C43.8327 32.006 43.4531 31.6291 43.4531 31.1624V12.018C43.4622 11.5513 43.8417 11.1743 44.3117 11.1743Z" fill="white"></path>
                          <path d="M48.2703 4.03027H43.7333C43.4803 4.03027 43.2634 4.23671 43.2634 4.49699C43.2634 4.82908 43.2634 5.17014 43.2634 5.50222C43.2543 6.25615 43.1911 7.06393 42.7844 7.7281C41.8444 9.32571 39.3952 9.78345 37.6871 9.78345H34.6233C34.3702 9.78345 34.1533 9.98988 34.1533 10.2502V13.4095C34.1533 13.6608 34.3612 13.8762 34.6233 13.8762H39.1422C39.3952 13.8762 39.6121 13.6698 39.6121 13.4095C39.6121 12.7812 39.5037 12.135 39.6573 11.5247C39.8561 10.7348 40.3713 10.2322 40.9678 9.71165C41.3293 9.39751 41.7179 9.1103 42.1427 8.88592C43.9502 7.93454 46.038 8.12302 48.0172 8.12302C48.1076 8.12302 48.198 8.12302 48.2793 8.12302C48.5324 8.12302 48.7493 7.91658 48.7493 7.6563V4.49699C48.7403 4.23671 48.5324 4.03027 48.2703 4.03027Z" fill="white"></path>
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li className="partner">
                    <a aria-label="Aller sur le site de La Niche startup" href="https://startups.laniche.com/fr/" target="_blank" rel="noopener noreferrer">
                      <svg width="118" height="52" viewBox="0 0 118 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path d="M28.5833 9.47275C27.5864 9.46956 26.5911 9.55107 25.6082 9.71639C24.6252 9.55171 23.63 9.4702 22.6331 9.47275C18.282 9.47275 15.054 10.8756 12.912 13.4671C10.7699 16.0586 9.69141 19.8609 9.69141 24.6822V43.9893H16.9135V24.7929C16.9135 17.2473 19.9481 12.426 25.571 11.1783C31.2386 12.3891 34.3104 17.2104 34.3029 24.756V43.9893H41.5176V24.6822C41.5176 19.8609 40.4465 16.0586 38.3045 13.4671C36.1624 10.8756 32.9344 9.47275 28.5833 9.47275ZM21.8149 11.0232C17.6944 13.2899 15.6193 18.0077 15.6193 24.6822V42.7341H11.0153V24.756C11.0153 20.5328 11.9599 17.1734 13.7822 14.8034C15.6044 12.4334 18.2969 11.0749 21.7629 10.846H22.1794L21.8149 11.0232ZM40.2234 42.6382V42.7341H35.5971V24.6822C35.5971 18.0373 33.5443 13.2973 29.4238 11.0232L29.0668 10.8239H29.4758C32.9418 11.0528 35.6417 12.4113 37.4565 14.7813C39.2714 17.1513 40.2234 20.5107 40.2234 24.7339V42.6382Z" fill="white"></path>
                          <path d="M58.848 16.3979H57.3232V16.5751V26.5425H58.848V16.3979Z" fill="white"></path>
                          <path d="M64.3511 19.1447C63.9512 19.1412 63.5522 19.1808 63.161 19.2628C62.6273 19.354 62.1372 19.6128 61.7627 20.0012C61.4307 20.3949 61.2607 20.8985 61.2867 21.4114V21.5885H62.9305V21.4114C62.9242 21.2508 62.9573 21.0912 63.0271 20.9462C63.0893 20.818 63.1874 20.7103 63.3098 20.6361C63.6511 20.4435 64.0407 20.3513 64.4329 20.3703C64.6145 20.3511 64.7981 20.368 64.973 20.42C65.148 20.472 65.3107 20.558 65.4518 20.673C65.7209 21.0198 65.846 21.4558 65.8014 21.8913V22.5188C65.1589 22.3179 64.4892 22.2158 63.8155 22.2161C63.108 22.1907 62.4097 22.3814 61.8148 22.7625C61.5488 22.9353 61.3328 23.174 61.188 23.4549C61.0433 23.7359 60.9747 24.0494 60.9892 24.3646C60.9837 24.6797 61.0474 24.9921 61.1759 25.2803C61.3043 25.5684 61.4944 25.8254 61.733 26.0332C62.2683 26.4971 62.9647 26.7355 63.6742 26.6977C64.1916 26.708 64.7032 26.5886 65.1618 26.3507C65.4323 26.1989 65.6679 25.9927 65.8535 25.7453V26.5427H67.2964V21.9356C67.324 21.5497 67.2672 21.1626 67.1298 20.8006C66.9925 20.4387 66.7778 20.1105 66.5006 19.8387C65.8934 19.3548 65.1289 19.1079 64.3511 19.1447ZM65.251 24.9258C65.0765 25.1017 64.868 25.2409 64.6379 25.3348C64.4078 25.4286 64.161 25.4754 63.9122 25.4721C63.5519 25.5038 63.1923 25.4041 62.9007 25.1916C62.7908 25.0849 62.7058 24.9555 62.6518 24.8126C62.5978 24.6697 62.5761 24.5168 62.5883 24.3646C62.5851 24.2186 62.6183 24.074 62.685 23.9438C62.7588 23.825 62.861 23.7261 62.9825 23.6558C63.3481 23.4799 63.7517 23.3962 64.1577 23.4122C64.7112 23.4251 65.261 23.5069 65.794 23.6558C65.7836 24.1326 65.5893 24.5872 65.251 24.9258Z" fill="white"></path>
                          <path d="M78.1931 26.5796H79.6807V22.3269C79.7243 21.4924 79.4599 20.6707 78.9369 20.0159C78.6762 19.7249 78.3532 19.4956 77.9915 19.3449C77.6298 19.1941 77.2387 19.1257 76.8469 19.1447C76.3073 19.13 75.7751 19.2711 75.3147 19.5508C74.9849 19.7596 74.7096 20.043 74.5114 20.3777V19.3145H73.1875V26.5796H74.6751V22.9914C74.6534 22.3321 74.8435 21.6831 75.218 21.1382C75.3636 20.9375 75.556 20.7749 75.7789 20.6643C76.0017 20.5537 76.2482 20.4984 76.4973 20.5032C76.7233 20.489 76.9499 20.5216 77.1625 20.5992C77.3751 20.6767 77.569 20.7974 77.732 20.9536C78.0456 21.3404 78.1974 21.8321 78.1559 22.3269L78.1931 26.5796Z" fill="white"></path>
                          <path d="M82.9165 18.0447C83.1694 18.0589 83.4177 17.974 83.6082 17.8084C83.6973 17.7256 83.7674 17.6247 83.8136 17.5126C83.8598 17.4004 83.8811 17.2797 83.876 17.1587C83.8806 17.0377 83.8591 16.9171 83.8129 16.8051C83.7667 16.693 83.6969 16.592 83.6082 16.5089C83.4158 16.3467 83.1688 16.2623 82.9165 16.2727C82.7919 16.2664 82.6673 16.2847 82.5498 16.3265C82.4324 16.3684 82.3244 16.4329 82.2322 16.5163C82.0738 16.6933 81.9863 16.9219 81.9863 17.1587C81.9863 17.3954 82.0738 17.624 82.2322 17.801C82.3235 17.8859 82.4313 17.9513 82.5489 17.9932C82.6666 18.0351 82.7917 18.0526 82.9165 18.0447Z" fill="white"></path>
                          <path d="M83.6894 19.2998H82.1572V19.4844V26.5427H83.6894V19.2998Z" fill="white"></path>
                          <path d="M99.0858 22.3267V26.5794H100.573V22.3267C100.617 21.4922 100.353 20.6705 99.8296 20.0157C99.5686 19.7252 99.2455 19.4961 98.8839 19.3454C98.5223 19.1947 98.1313 19.126 97.7396 19.1445C97.2175 19.1336 96.7027 19.2665 96.2521 19.5284C95.9969 19.6926 95.7729 19.9001 95.5901 20.1412V16.3979H94.1025V26.5794H95.5901V23.1167C95.5483 22.4317 95.7281 21.7513 96.1033 21.1749C96.2426 20.9619 96.4349 20.7881 96.6616 20.6704C96.8883 20.5527 97.1417 20.4951 97.3975 20.503C97.6248 20.4872 97.8528 20.5192 98.0669 20.5968C98.2809 20.6744 98.476 20.7959 98.6396 20.9534C98.9647 21.335 99.1251 21.8284 99.0858 22.3267Z" fill="white"></path>
                          <path d="M92.124 24.3646L92.1687 24.1652L90.6365 24.0323V24.1726C90.5375 24.5073 90.3408 24.8053 90.0712 25.0291C89.8397 25.2211 89.5473 25.3257 89.2456 25.3244C88.9824 25.3348 88.7202 25.2842 88.4801 25.1765C88.24 25.0689 88.0284 24.9072 87.8622 24.7042C87.4742 24.1995 87.2815 23.5734 87.3193 22.9396V22.8879C87.2802 22.254 87.473 21.6274 87.8622 21.1233C88.0292 20.9208 88.2407 20.7589 88.4805 20.6501C88.7203 20.5413 88.9821 20.4885 89.2456 20.4958C89.5487 20.4939 89.8421 20.6015 90.0712 20.7985C90.3408 21.0222 90.5375 21.3203 90.6365 21.6549V21.7878L92.1687 21.6549L92.124 21.463C91.9757 20.8177 91.6208 20.2375 91.1125 19.8091C90.5156 19.3404 89.7679 19.1017 89.0076 19.1373C88.573 19.1381 88.1433 19.2292 87.7462 19.4046C87.3491 19.5801 86.9934 19.8361 86.7019 20.1561C86.0266 20.9034 85.6764 21.8854 85.7276 22.8879V22.947C85.6775 23.9472 86.0278 24.9264 86.7019 25.6714C87.0754 26.1115 87.5651 26.4394 88.1161 26.6184C88.667 26.7973 89.2573 26.8202 89.8206 26.6845C90.3839 26.5487 90.8979 26.2597 91.3048 25.8498C91.7117 25.44 91.9954 24.9257 92.124 24.3646Z" fill="white"></path>
                          <path d="M107.044 19.4473C106.618 19.2359 106.145 19.1319 105.668 19.1445C105.199 19.1263 104.732 19.21 104.299 19.3898C103.866 19.5696 103.478 19.8412 103.162 20.1856C102.51 20.944 102.174 21.9214 102.225 22.9174C102.177 23.9139 102.509 24.8918 103.154 25.6565C103.454 25.9933 103.825 26.2609 104.24 26.4407C104.655 26.6205 105.104 26.7082 105.557 26.6976H105.616C106.064 26.7039 106.508 26.6141 106.917 26.4344C107.327 26.2548 107.692 25.9894 107.989 25.6565C108.226 25.3865 108.417 25.0797 108.554 24.7484L107.104 24.4752C107.048 24.596 106.978 24.7099 106.896 24.8149C106.734 25.0072 106.531 25.1619 106.302 25.268C106.074 25.3742 105.824 25.4292 105.572 25.4292C105.319 25.4292 105.07 25.3742 104.841 25.268C104.612 25.1619 104.41 25.0072 104.248 24.8149C103.934 24.3853 103.765 23.8686 103.764 23.3382H108.547V23.1832C108.582 22.9386 108.599 22.6919 108.599 22.4448C108.614 21.7015 108.41 20.9699 108.011 20.3406C107.775 19.9626 107.441 19.6542 107.044 19.4473ZM103.801 22.1495C103.862 21.7084 104.048 21.2935 104.337 20.9534C104.496 20.7748 104.694 20.6341 104.915 20.5421C105.137 20.45 105.376 20.4089 105.616 20.4218C105.872 20.4131 106.127 20.4637 106.36 20.5695C106.622 20.7118 106.831 20.9346 106.955 21.2045C107.077 21.5053 107.145 21.8252 107.156 22.1495H103.801Z" fill="white"></path>
                          <path d="M60.3502 34.118L59.2494 33.7562C58.9792 33.6911 58.7258 33.5703 58.5056 33.4018C58.4324 33.3434 58.3735 33.2691 58.3336 33.1847C58.2936 33.1002 58.2736 33.0078 58.2751 32.9145C58.2826 32.8069 58.3138 32.7022 58.3665 32.6078C58.4192 32.5134 58.4921 32.4316 58.58 32.3682C58.8299 32.1883 59.1347 32.0996 59.4428 32.1171C60.1866 32.1171 60.6403 32.3682 60.7147 32.8555H61.5998C61.5929 32.6488 61.5368 32.4466 61.4359 32.2656C61.3351 32.0846 61.1924 31.93 61.0196 31.8144C60.5691 31.5249 60.0384 31.3829 59.5023 31.4084C58.947 31.3779 58.3959 31.5198 57.9255 31.8144C57.7444 31.9285 57.5959 32.0868 57.4942 32.2743C57.3926 32.4618 57.3414 32.6721 57.3453 32.885C57.3453 33.6233 57.8288 34.0885 58.7883 34.3616L59.8593 34.716C60.1253 34.774 60.3778 34.8817 60.6031 35.0335C60.6807 35.098 60.7421 35.1797 60.7821 35.2721C60.8222 35.3644 60.8398 35.4648 60.8337 35.5651C60.8243 35.6898 60.7833 35.81 60.7143 35.9146C60.6454 36.0192 60.5508 36.1047 60.4395 36.1631C60.1377 36.3327 59.7969 36.4218 59.4502 36.4218C59.1035 36.4218 58.7627 36.3327 58.461 36.1631C58.3272 36.0831 58.2161 35.9707 58.138 35.8364C58.06 35.7021 58.0175 35.5503 58.0147 35.3953H57.1445C57.1501 35.6386 57.2104 35.8776 57.3211 36.0948C57.4318 36.312 57.59 36.5018 57.7842 36.6504C58.297 36.9617 58.8864 37.1264 59.4874 37.1264C60.0885 37.1264 60.6779 36.9617 61.1907 36.6504C61.3698 36.5338 61.5186 36.3766 61.6246 36.1917C61.7306 36.0069 61.7909 35.7997 61.8006 35.5873C61.8094 35.4159 61.7807 35.2447 61.7165 35.0854C61.6523 34.9261 61.5541 34.7824 61.4287 34.6644C61.1107 34.4108 60.7436 34.2248 60.3502 34.118Z" fill="white"></path>
                          <path d="M65.4521 36.4138C64.9315 36.4138 64.6712 36.1333 64.6712 35.5795V32.2349H66.3967V31.5483H64.7084V30.0273H63.7787V31.5483H62.8936V32.2349H63.7787V35.5943C63.7733 35.8269 63.8163 36.0582 63.9051 36.2736C63.9782 36.4514 64.0912 36.6104 64.2356 36.7383C64.38 36.8663 64.5518 36.9598 64.7381 37.0119C65.3404 37.1825 65.986 37.1137 66.5381 36.8199L66.4116 36.1776C66.1102 36.3173 65.7844 36.3976 65.4521 36.4138Z" fill="white"></path>
                          <path d="M70.1231 31.408C69.8203 31.4042 69.5182 31.4364 69.2231 31.5039C68.8377 31.536 68.4801 31.7158 68.226 32.0053C67.9719 32.2947 67.8413 32.671 67.862 33.0544H68.8661C68.856 32.9104 68.8843 32.7662 68.9483 32.6366C69.0123 32.5069 69.1097 32.3964 69.2306 32.3161C69.5188 32.1497 69.8496 32.0702 70.1826 32.0872C70.3467 32.0746 70.5115 32.0964 70.6666 32.1511C70.8216 32.2058 70.9633 32.2922 71.0826 32.4047C71.3244 32.7043 71.4391 33.0857 71.4024 33.4679V34.1619C70.8622 33.9631 70.2902 33.8631 69.714 33.8666C69.1868 33.8465 68.6665 33.9911 68.2265 34.28C68.0378 34.4034 67.8841 34.5727 67.7799 34.7717C67.6758 34.9707 67.6247 35.1929 67.6315 35.417C67.6266 35.6475 67.672 35.8764 67.7644 36.0879C67.8568 36.2994 67.9941 36.4886 68.167 36.6427C68.5599 36.9802 69.0686 37.1546 69.5876 37.1299C69.96 37.1604 70.3328 37.0778 70.6569 36.8933C70.981 36.7087 71.241 36.4308 71.4024 36.0963L71.4991 37.0044H72.2429V33.49C72.2657 33.2036 72.2265 32.9157 72.1277 32.6457C72.0289 32.3757 71.8729 32.1298 71.6702 31.9248C71.2339 31.5734 70.6846 31.3899 70.1231 31.408ZM70.9189 35.9634C70.7709 36.116 70.5928 36.2367 70.3958 36.318C70.1987 36.3993 69.9869 36.4394 69.7735 36.4359C69.0298 36.4359 68.5984 36.0963 68.5984 35.4097C68.5904 35.2714 68.6217 35.1337 68.6888 35.0123C68.7558 34.8909 68.856 34.7906 68.9777 34.723C69.2827 34.5664 69.6239 34.4925 69.9669 34.5089C70.4513 34.5189 70.932 34.5959 71.395 34.7378C71.4042 34.9635 71.3667 35.1887 71.2848 35.3995C71.2029 35.6103 71.0784 35.8022 70.9189 35.9634Z" fill="white"></path>
                          <path d="M76.4527 31.4453C76.1099 31.4366 75.7745 31.5458 75.5035 31.7543C75.2324 31.9628 75.0422 32.258 74.9652 32.5897V31.5339H74.0801V37.0049H74.9875V34.3469C74.9875 32.9884 75.4709 32.3165 76.3337 32.3165C76.5348 32.3176 76.7348 32.345 76.9287 32.3977V31.5118C76.774 31.4675 76.6138 31.4451 76.4527 31.4453Z" fill="white"></path>
                          <path d="M80.2681 36.4138C79.7475 36.4138 79.5243 36.1333 79.5243 35.5795V32.2349H81.2499V31.5483H79.5243V30.0273H78.6244V31.5483H77.7393V32.2349H78.6244V35.5943C78.619 35.8269 78.662 36.0582 78.7508 36.2736C78.8239 36.4514 78.9369 36.6104 79.0813 36.7383C79.2257 36.8663 79.3975 36.9598 79.5838 37.0119C80.1886 37.1822 80.8364 37.1134 81.3912 36.8199L81.2648 36.1776C80.9513 36.3204 80.6127 36.4006 80.2681 36.4138Z" fill="white"></path>
                          <path d="M86.5385 34.2876C86.551 34.8213 86.3776 35.3428 86.0476 35.7643C85.9053 35.948 85.7213 36.0958 85.5107 36.1959C85.3001 36.2959 85.0687 36.3454 84.8353 36.3402C83.9799 36.3402 83.5485 35.875 83.5485 34.9521V31.5337H82.6411V35.0112C82.6165 35.3004 82.6541 35.5916 82.7516 35.8653C82.849 36.139 83.0041 36.389 83.2064 36.5986C83.6203 36.9476 84.1513 37.1295 84.694 37.108C85.0857 37.1238 85.4726 37.0177 85.8006 36.8046C86.1286 36.5915 86.3814 36.282 86.5236 35.9193V37.0047H87.431V31.5337H86.5236L86.5385 34.2876Z" fill="white"></path>
                          <path d="M92.2281 31.4084C91.8921 31.3989 91.5604 31.4857 91.2728 31.6586C90.9852 31.8314 90.7539 32.083 90.6067 32.383L90.5026 31.5487H89.7588V39.6702H90.6662V36.3478C90.8326 36.6046 91.0652 36.8126 91.3398 36.9502C91.6144 37.0878 91.9211 37.15 92.2281 37.1304C92.5651 37.1365 92.8993 37.0696 93.2075 36.9344C93.5158 36.7993 93.7907 36.5991 94.0132 36.3478C94.5195 35.7792 94.7858 35.0389 94.757 34.2805C94.7851 33.5178 94.5191 32.7732 94.0132 32.1984C93.7914 31.9458 93.5168 31.7442 93.2085 31.6078C92.9002 31.4713 92.5656 31.4033 92.2281 31.4084ZM93.3215 35.7867C93.1259 36.034 92.8559 36.2129 92.5508 36.2974C92.2457 36.3819 91.9214 36.3674 91.6251 36.2562C91.3288 36.145 91.0761 35.9428 90.9036 35.6791C90.7311 35.4153 90.6479 35.1038 90.6662 34.7899V33.7563C90.66 33.3362 90.8203 32.9306 91.1125 32.6267C91.2571 32.488 91.4285 32.3798 91.6165 32.3087C91.8044 32.2377 92.0049 32.2052 92.2058 32.2132C92.4255 32.2129 92.6424 32.2627 92.8397 32.3587C93.037 32.4547 93.2094 32.5943 93.3438 32.7669C93.6643 33.1917 93.8272 33.7133 93.8049 34.2436C93.8495 34.7981 93.6822 35.349 93.3363 35.7867H93.3215Z" fill="white"></path>
                          <path d="M99.2866 34.118L98.1784 33.7563C97.9094 33.6878 97.6566 33.5674 97.4346 33.4019C97.3625 33.3424 97.3045 33.2679 97.2647 33.1837C97.2249 33.0995 97.2041 33.0076 97.204 32.9146C97.2115 32.8069 97.2427 32.7022 97.2954 32.6078C97.3481 32.5134 97.421 32.4316 97.509 32.3682C97.7622 32.1894 98.0689 32.1009 98.3792 32.1172C99.123 32.1172 99.5767 32.3682 99.6436 32.8555H100.529C100.522 32.6488 100.466 32.4466 100.365 32.2657C100.264 32.0847 100.121 31.93 99.9485 31.8145C99.491 31.5153 98.9485 31.3703 98.4015 31.401C97.8485 31.3706 97.2998 31.5126 96.8321 31.8071C96.651 31.9211 96.5025 32.0795 96.4009 32.267C96.2993 32.4545 96.248 32.6648 96.252 32.8777C96.252 33.616 96.728 34.0811 97.6875 34.3543L98.7585 34.7087C99.0246 34.7663 99.2771 34.8741 99.5023 35.0262C99.5786 35.0918 99.639 35.1737 99.6789 35.2657C99.7189 35.3578 99.7373 35.4576 99.7328 35.5578C99.7235 35.6824 99.6824 35.8027 99.6135 35.9073C99.5446 36.0119 99.45 36.0974 99.3386 36.1558C99.0419 36.3371 98.6976 36.427 98.3494 36.4142C97.999 36.4259 97.6527 36.3361 97.3528 36.1558C97.2244 36.0707 97.1192 35.9553 97.0465 35.8201C96.9738 35.6848 96.936 35.5338 96.9363 35.3806H96.0586C96.0656 35.6244 96.1273 35.8636 96.2392 36.0807C96.3512 36.2979 96.5105 36.4875 96.7057 36.6357C97.2185 36.947 97.8079 37.1116 98.4089 37.1116C99.01 37.1116 99.5994 36.947 100.112 36.6357C100.29 36.5178 100.438 36.3603 100.544 36.1757C100.65 35.9911 100.711 35.7846 100.722 35.5725C100.731 35.4004 100.702 35.2284 100.637 35.0688C100.571 34.9093 100.471 34.7661 100.343 34.6496C100.032 34.4017 99.672 34.2207 99.2866 34.118Z" fill="white"></path>
                        </g>
                      </svg>
                    </a>
                  </li>
                </ul>
              </>
            ) : (
              <img src={vip.logo} alt={vip.name} />
            )}
            
          </Logo>
          <Menu>
            <ul>
              <li>
                <a href="/terms-and-conditions">Conditions d'utilisation</a>
              </li>
              <li>
                <a href="/privacy-policy">Politique de confidentialité</a>
              </li>
              <li>
                Copyright © Pepio {new Date().getFullYear()}
              </li>
            </ul>
          </Menu>
        </Container>
      </Wrapper>
    </footer>
  )
};

export default Footer;