import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
`;

export const Visual = styled.div`
  position: relative;
  display: flex;
  width: calc(50% - 60px);
  margin: 0 30px 0 0;
  background-size: auto 80%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-color: ${colors.loginVisual};
  overflow: hidden;

  ${mq('m')} {
    display: none;
  }

  img {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 100%;
    max-width: calc(100% - 20px);
  }
`;

export const Content = styled.div`
  max-width: calc(50% - 30px);
  width: calc(1280px / 2 - 100px);
  /* margin: 0 0 0 30px; */
  margin: 0 auto;
  padding: 100px 50px;
  text-align: center;

  ${mq('m')} {
    width: 100%;
    max-width: 350px;
    padding: 70px 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    max-width: 140px;
  }

  h1 {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  h2 {
    font-family: ${fontFamily};
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkBlue100};
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.darkBlue80};
  }

  .message-errors {
    font-size: 14px;
    color: ${colors.errorColor};
  }

  .inner {
    text-align: left;
    margin-left: 85px;
    margin-right: 85px;

    ${mq('m')} {
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
    }
  }

  .password-rules {
    margin-bottom: 15px;
  }

  .password-rule {
    display: flex;
    align-items: center;
    margin: 0 0 5px;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.darkBlue60};

    svg {
      display: inline-block;
      margin-left: 10px;
      fill: ${colors.successColor};
    }
  }

  form {
    .field {
      margin-bottom: 15px;

      &-wrapper {
        display: flex;
        position: relative;
        border: 1px solid ${colors.darkBlue20};
        border-radius: 8px;

        button {
          background: transparent none;
          border: 0;
          cursor: pointer;
          padding: 0 10px;

          svg {
            fill: ${colors.blue};
          }

          &:hover {
            svg {
              fill: ${colors.darkblue100};
            }
          }
        }
      }
    }

    label {
      display: inline-block;
      margin: 0 0 10px;
      color: ${colors.darkBlue80};
      font-size: 16px;
    }

    input[type="text"],
    input[type="password"] {
      width: calc(100% - 42px);
      height: 52px;
      padding: 0 20px;
      color: ${colors.darkBlue60};
      font-size: 14px;
      border: 0;
      background: transparent;
      font-family: ${fontFamily};
    }

    ::placeholder {
      color: ${colors.inputPlaceholder};
    }
  }
`;

export const Separator = styled.div`
  position: relative;
  margin: 35px 0;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: ${colors.darkBlue20};
  }

  span {
    position: relative;
    display: inline-block;
    padding: 3px 10px;
    background: white;
    color: ${colors.darkBlue40};
    font-size: 14px;
    line-height: 18px;
  }
`;
