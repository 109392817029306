// @format
// @flow
import React from "react";
import type { Node } from "react";
import { useHistory } from "react-router-dom";
import { Container, Visual, Content } from "./styled";
import { Button } from "../../styled-common";
import AsyncStorage from "@callstack/async-storage";

import vip from "../../vip";

import * as userHelper from "../../fetch/user";

// Assets
//import mainVisual from '../../assets/login-choice/visual-pepio.png';

/**
 * *******************************************************************************
 * Login Choice
 * This screen allow the user to choice to use pepio with a user or annonymously
 * *******************************************************************************
 */
const LoginChoice = (): Node => {
    const history = useHistory();

    const withoutAccount = async () => {
        // console.log('withoutAccount');

        await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
        const loginFromSurvey = await AsyncStorage.getItem("@LoginFromSurvey");
        const loginFromChannel = await AsyncStorage.getItem("@LoginFromChannel");
        await AsyncStorage.setItem("@AnnonymousLogin", true);
        if (loginFromSurvey) {
            history.push("/survey-details/" + loginFromSurvey, { surveyId: parseInt(loginFromSurvey), fromLogin: true });
        } else if (loginFromChannel) {
            history.push("/channel-content/" + loginFromChannel, { channelId: parseInt(loginFromChannel), fromLogin: true });
        } else {
            history.push("/signup-terms-and-conditions");
        }
    };

    return (
        <Container>
            <Visual>
                <img src={vip.visuals["login"]} alt="" />
            </Visual>
            <Content>
                <img className="logo" src={vip.logo} alt={vip.name} />

                <h1>Bienvenue sur {vip.name}!</h1>

                <p>Profitez de tous les avantages de {vip.name} en créant ou utilisant en compte déjà existant</p>

                <Button large onClick={() => history.push("/LoginHome")}>
                    Créer un compte
                </Button>

                <Button large ghost onClick={() => history.push("/LoginHome")}>
                    Se connecter
                </Button>

                <Button buttonStyle="text" onClick={withoutAccount}>
                    Je ne veux pas de compte
                </Button>
            </Content>
        </Container>
    );
};

export default LoginChoice;
