import React from "react";
import { Badge, BadgePillLive } from "./styled";
import vip from "../../../../vip";

const SurveyTypeBadge = (props) => {
	const { survey } = props;
	const vipCopy = vip.copy;
	if (survey.diffusionType === "Live") {
		return (
			<Badge>
				<BadgePillLive>
					{vipCopy["survey"]} {survey.diffusionType === "Classic" ? "" : "Live"}
				</BadgePillLive>
			</Badge>
		);
	}

	return null;
};

export default SurveyTypeBadge;
