import client from './guestClient';
import * as userFetchHelper from './user';

// Get all feed message for a survey
export const getFeedMessage = async (surveyId) => {
  const userId = await userFetchHelper.getUserId();
  return client
    .get('Feed/GetFeedMessage/' + surveyId + '/0/1000/' + userId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return false;
    });
};

export const getFeedChildMessage = async (surveyId, fatherMessageId) => {
  const userId = await userFetchHelper.getUserId();
  // /api/v1/Feed/GetFeedChildMessage/{surveyId}/{fatherMessageId}/{offset}/{pagelength}/{currentUserId}  
  return client
    .get('Feed/GetFeedChildMessage/' + surveyId + '/' + fatherMessageId + '/0/1000/' + userId)
    .then((response) => {
      // console.log('getFeedChildMessage', response);
      return response.data;
    })
    .catch((error) => {
      return false;
    });
};

// post a message
export const postFeedMessage = async (surveyId, parentMessageId, message, emotion) => {
  const userId = await userFetchHelper.getUserId();

  const data = {
    userId: userId,
    surveyId: surveyId,
    parentMessageId: parentMessageId,
    message: message,
    emotion: emotion
  };

  // console.log('postFeedMessage', data);

  // post the like
  const response = await client.post('Feed/PostMessage', data);

  // if response is ok , return the posted message
  if (response.status === 200) {
    return response.data;
  }
};

// post a message
export const updateFeedMessage = async (messageId, message, emotion) => {
  const userId = await userFetchHelper.getUserId();

  const data = {
    id: messageId,
    userId: userId,
    message: message,
    emotion: emotion
  };

  // console.log('postFeedMessage', data);

  // post the like
  const response = await client.post('Feed/UpdateMessage', data);

  // if response is ok , return the posted message
  if (response.status === 200) {
    return response.data;
  }
};

// like a message
export const likeFeedMessage = async (pepioUserId, messageId) => {
  const userId: numer = parseInt(pepioUserId, 10);
  const data = {
    userId: userId,
    messageId: messageId,
    likeType: 'Like',
  };

  // post the like
  const response = await client.post('Feed/Like', data);

  // if response is ok , return the updated message
  if (response.status === 200) {
    return response.data;
  }
};

// Dislike a message
export const dislikeFeedMessage = async (pepioUserId, messageId) => {
  const userId: numner = parseInt(pepioUserId, 10);
  const data = {
    userId: userId,
    messageId: messageId,
    likeType: 'Dislike',
  };

  // post the like
  const response = await client.post('Feed/Like', data);
  // if response is ok , return the updated message
  if (response.status === 200) {
    return response.data;
  }
};

// Report a message
export const reportFeedMessage = async (pepioUserId, messageId) => {
  const userId = parseInt(pepioUserId, 10);
  const messageIdInt = parseInt(messageId, 10);
  const data = {
    userId: userId,
    messageId: messageIdInt,
    reason: 'Reported by user',
  };

  // post the like
  const response = await client.post('Feed/ReportMessage', data);
  // console.log('feed report response', response);
  return response;
};

// Delete message
export const deleteFeedMessage = async (pepioUserId, messageId) => {
  const userId = parseInt(pepioUserId, 10);
  const data = {
    userId: userId,
    messageId: messageId,
    reason: ''
  };

  // post the like
  const response = await client.post('Feed/DeleteMessage', data);
  // if response is ok , return the updated message
  if (response.status === 200) {
    return response.data;
  }
};
