import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold, fontFamilyWeightRegular } from '../../styled-common.js';
import { mq } from '../../variables.js';


export const Backdrop = styled.div`
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.wizardBackdrop};
  width: 100vw;
  height: 100vh;
  opacity: .5;

  ${(props) =>
		props.visible
		  ? `
        display: flex;
      `
      : `
        display: none;
      `}
`;

export const Container = styled.div`
  position: fixed;
  z-index: 9001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 60px);
  height: 630px;
  max-height: 90%;
  max-width: 1020px;
  border-radius: 16px;
  overflow: hidden;
  background: ${colors.white};

  font-family: ${fontFamily};
  font-weight: ${fontFamilyWeightRegular};

  ${(props) =>
		props.visible
		  ? `
        display: flex;
      `
      : `
        display: none;
      `}

  ${mq('m')} {
    
  }

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid ${colors.lightgrey};
    color: ${colors.blue};
    font-size: 25px;
    background: ${colors.white};
    transition: all .4s ease-in-out;

    &:hover {
      color: ${colors.white};
      background: ${colors.blue};
    }
  }
`;

export const EndStep = styled.div`
  height: 100%;
  text-align: center;
`;

export const Visual = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  background: ${colors.darkBlue10};
  align-items: center;
  justify-content: center;

  ${mq('m')} {
    display: none;
  }

  img {
    display: block;
    max-height: 80%;
    max-width: 80%;
    object-fit: contain;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 120px);
  background: ${colors.white};
  padding: 40px 60px;
  overflow: auto;

  ${mq('m')} {
    width: calc(100% - 40px);
    padding: 20px;
    min-height: auto;
    border-radius: 16px;
  }

  .stepbar-container {
    margin: 0 0 40px 0;

    &.hide {
      display: none;
    }
  }

  .back-container {
    margin: 0 0 40px 0;

    button {
      display: inline-flex;
      align-items: center;
      background: transparent;
      border: 0;
      cursor: pointer;
      font-size: 12px;
      color: ${colors.blue};
      font-weight: ${fontFamilyWeightBold};

      ion-icon {
        margin-right: 12px;
        color: ${colors.blue};
        font-size: 14px;
      }

      &.hide {
        opacity: 0;
        pointer-events: none;
        cursor: default;
      }
    }
  }
  .step-container {
    flex: 1;

    > div,
    > form {
      height: 100%;
    }

    h2 {
      font-family: ${fontFamily};
      font-weight: ${fontFamilyWeightBold};
      font-size: 20px;
      margin: 0 0 20px;
      color: ${colors.darkBlue100};
    }

    p {
      color: ${colors.darkBlue100};
    }
  }

  .step-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;

    &-inner {
      flex: 1;
    }

    &-footer {
      margin-top: 50px;

      button {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .form-item {
      label {
        display: block;
        color: ${colors.darkBlue80};
        font-size: 16px;
        margin: 12px;
      }
      
      &-field {
        width: 100%;
        border: 1px solid ${colors.darkBlue20};
        border-radius: 8px;
        padding: 0;

        input {
          display: block;
          width: calc(100% - 20px);
          background: transparent;
          border: 0;
          padding: 0 10px;
          height: 50px;
        }
      }
    }

    .locality-selector {
      position: relative;
      z-index: 9999;
      min-height: 200px;

      .menu {
        position: absolute;
        top: 52px;
        width: 100%;
        max-height: 200px;
        overflow: auto;
        background: ${colors.white};
      }

      .item {
        color: ${colors.darkBlue80};
        padding: 6px 10px;
      }

      .item-highlighted {
        color: ${colors.darkBlue100};
        background: ${colors.darkBlue20};
        cursor: pointer;
      }
    }
  }
`;

export const StepBar = styled.div`
  display: block;
  position: relative;
  width: 320px;
  max-width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    height: 4px;
    background: ${colors.darkBlue20};
  }

  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
`;

export const StepBarItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
		props.active
		  ? `
        background: ${colors.blue};
        color: ${colors.white};
      `
      : `
        background: ${colors.darkBlue20};
        color: ${colors.darkBlue100};
      `}
`;

export const FakeRadio = styled.div`
  input[type=radio] {
    position: absolute;
    left: -99999px;
    opacity: 0;
    visibility: hidden;
  }

  label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px 0;
    border-radius: 8px;
    font-weight: ${fontFamilyWeightBold};
    cursor: pointer;
    transition: all 0.4s ease-out;
    border: 2px solid ${colors.blue};
    background: ${colors.blue};
    color: ${colors.white};
    height: 44px;
    width: 100%;
    font-size: 16px;
  }

  input[type=radio]:checked + label {
    background: ${colors.purple};
    border-color: ${colors.purple};
  }
`;