//@format
//@flow
import React from "react";
import type {Node} from "react";
import { Modal } from "./styled.js";
type Props = {
  visible: boolean,
  children: any,
  handleClose: Function,
  className?:string,
  hideClose?: Function,
};

const ModalComponent = (props: Props): Node => {
  const { visible, handleClose, children, className, hideClose } = props;
  return (
    <Modal visible={visible} className={className}>
      <div className="modal-backdrop" onClick={handleClose} />
      <div className="modal-content">
        {!hideClose ? (
          <button className="modal-close" onClick={handleClose}>
            <ion-icon name="close-outline" />
          </button>
        ) : null}
        
        {children}
      </div>
    </Modal>
  );
};

export default ModalComponent;