import styled from "styled-components";
import { colors, fontFamily } from "../../../../styled-common.js";

export const Container = styled.div`
	font-family: ${fontFamily};
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	color: ${colors.darkBlue100};
	height: 36px;
	display: flex;
	align-items: center;
	margin-top: 20px;
	background-color: ${colors.white};

	ion-icon {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 16px;
		color: ${colors.darkBlue60};
	}
`;
