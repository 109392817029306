import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import TagManager from 'react-gtm-module';

import * as Sentry from "@sentry/react";

import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";

import './index.css';
import App from './App';

import vip from './vip';

import * as serviceWorker from './serviceWorker';
import history from "./history.js";

import { fontFamily, fontFamilyWeightRegular } from "./styled-common.js";
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${fontFamily};
    font-weight: ${fontFamilyWeightRegular};

    &.stg,
    &.dev,
    &.local {
      position: relative;
    }

    &.stg::after,
    &.dev::after,
    &.local::after {
      content: '';
      display: block;
      position: fixed;
      z-index: 9999;
      left: 50%;
      top: 0;
      width: 250px;
      transform: translate(-50%, 0);
      color: black;
      font-size: rem(16px);
      padding: 5px 20px;
      border-radius: 0 0 14px 14px;
      box-shadow: 0px 0px 16px rgba(0,0,0, .3);
      text-align: center;
    }

    &.dev::after {
      content: 'QA';
      background-color: aquamarine;
      color: black;
    }

    &.stg::after {
      content: 'Staging';
      background-color: lightsalmon;
      color: white;
    }

    &.local::after {
      content: 'Local';
      background-color: lightseagreen;
      color: black;
    }
  }
  button {
    font-family: ${fontFamily};
  }

  
`

export const historyInst = history;
if(vip.gtm) {
  const tagManagerArgs = {
    gtmId: vip.gtm
  }
  
  TagManager.initialize(tagManagerArgs);
}


if(process.env.REACT_APP_SENTRY_DEBUG === 'true') {
  Sentry.init({ dsn: "https://10bd5c2fc42d410da4c123280c9c453c@o543273.ingest.sentry.io/5663228" });
}

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: "0"
});

ReactDOM.render(
  <>
  <PubNubProvider client={pubnub}>
    <GlobalStyle />
    <Router history={history}>
      <App />
    </Router>
    </PubNubProvider>
  </>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
