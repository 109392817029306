//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Visual, Content } from "./styled";
import { Button } from "../../styled-common";
import mainVisual from "../../assets/login-choice/visual-pepio.png";
import vip from "../../vip";

const PasswordVerification = (): Node => {
    const location = useLocation();
    const history = useHistory();

    const {
        register,
        setValue,
        handleSubmit,
        errors,
        formState: { isValid, isDirty, isSubmitting, touched, submitCount },
    } = useForm({
        mode: "onChange",
        validateCriteriaMode: "All",
    });

    let definedUsername = null;

    if (location.search !== "") {
        const urlParams = location.search.replace("?", "").split("&");
        urlParams.map((item) => {
            const itemDetail = item.split("=");
            if (itemDetail[0] === "email") {
                definedUsername = itemDetail[1];
            }
        });
    } else {
        definedUsername = location.state && location.state.username ? location.state.username : null;
    }

    const [stateErrorMessage, setStateErrorMessage] = useState(null);
    // console.log('stateUsername', stateUsername);

    const [statePassword, setStatePassword] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const [passwordMinLength, setPasswordMinLength] = useState(false);
    const [passwordContainAtLestOneNumber, setPasswordContainAtLestOneNumber] = useState(false);
    const [passwordContainAtLestOneSymbol, setPasswordContainAtLestOneSymbol] = useState(false);

    const [stateResendMessage, setStateResendMessage] = useState(-1);
    const [stateResendMessageError, setStateResendMessageError] = useState(null);

    useEffect(() => {
        if (!definedUsername) {
            return history.push("/");
        }
    }, []);

    const onPressResend = () => {
        // const username = stateUsername;

        // Auth.resendSignUp(username)
        //   .then(() => {
        //     // console.log('code resent successfully');
        //   })
        //   .catch((e) => {
        //     // console.log(e);
        //   });

        if (stateResendMessage === -1) {
            const username = definedUsername;

            Auth.forgotPassword(username)
                .then(() => {
                    // console.log('code resent successfully');
                    setStateResendMessageError(null);
                    let delay = 30;
                    setStateResendMessage(delay);
                    let interval = setInterval(() => {
                        delay = delay - 1;
                        setStateResendMessage(delay);
                        if (delay === 0) {
                            clearInterval(interval);
                            setStateResendMessage(-1);
                        }
                    }, 1000);
                })
                .catch((e) => {
                    // console.log(e);
                    setStateResendMessageError("Nous rencontrons un problème technique, veuillez essayer plus tard.");
                });
        }
    };

    const onPressVerification = (data) => {
        const username = definedUsername;
        // console.log('data', data);
        const code = data.verificationCode;
        const newPassword = data.password;
        Auth.forgotPasswordSubmit(username, code, newPassword)
            .then((dataResponse) => {
                // console.log('then reset password', dataResponse);
                history.push("loginHome", { username: username });
            })
            .catch((err) => {
                // console.log('err', err);
                setStateErrorMessage(err.message);
            });
    };

    return (
        <Container>
            <Visual>
                <img src={vip.visuals["login"]} alt="" />
            </Visual>
            <Content>
                <img src={vip.logo} alt={vip.name} className="logo" />

                <h1>Validation</h1>
                <p>
                    Veuillez entrer le code de validation qui a été envoyé à{"\n"}
                    {definedUsername}
                </p>
                <div className="inner">
                    <form onSubmit={handleSubmit(onPressVerification)}>
                        <label>Code</label>
                        <div className="field">
                            <div className="field-wrapper">
                                <input
                                    type="text"
                                    name="verificationCode"
                                    onChange={(event) => {
                                        const text = event.currentTarget.value;
                                        setValue("verificationCode", text, true);
                                        // console.log('verificationCode', text, isValid);
                                    }}
                                    ref={register({
                                        required: true,
                                        maxLength: 6,
                                        minLength: 6,
                                    })}
                                />
                            </div>
                        </div>

                        <div className="password-rules">
                            <p className="password-rule">
                                Minimum 8 caractères
                                {isDirty && passwordMinLength === true && touched.verificationCode && statePassword !== "" ? (
                                    <svg id="icon-checkmark-outline" className="valid" viewBox="0 0 20 20" width="20" height="20">
                                        <path d="M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM15.66 15.66c1.449-1.449 2.344-3.45 2.344-5.66 0-4.421-3.584-8.004-8.004-8.004-2.21 0-4.211 0.896-5.66 2.344v0c-1.449 1.449-2.344 3.45-2.344 5.66 0 4.421 3.584 8.004 8.004 8.004 2.21 0 4.211-0.896 5.66-2.344v0zM6.7 9.29l2.3 2.31 4.3-4.3 1.4 1.42-5.7 5.68-3.7-3.7 1.4-1.42z"></path>
                                    </svg>
                                ) : null}
                                {isDirty && passwordMinLength === false && touched.verificationCode && statePassword !== "" ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="not-valid" viewBox="0 0 512 512" width="20" height="20">
                                        <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" />
                                    </svg>
                                ) : null}
                            </p>
                            <p className="password-rule">
                                Minimum 1 chiffre
                                {isDirty && passwordContainAtLestOneNumber === true && touched.verificationCode && statePassword !== "" ? (
                                    <svg id="icon-checkmark-outline" className="valid" viewBox="0 0 20 20" width="20" height="20">
                                        <path d="M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM15.66 15.66c1.449-1.449 2.344-3.45 2.344-5.66 0-4.421-3.584-8.004-8.004-8.004-2.21 0-4.211 0.896-5.66 2.344v0c-1.449 1.449-2.344 3.45-2.344 5.66 0 4.421 3.584 8.004 8.004 8.004 2.21 0 4.211-0.896 5.66-2.344v0zM6.7 9.29l2.3 2.31 4.3-4.3 1.4 1.42-5.7 5.68-3.7-3.7 1.4-1.42z"></path>
                                    </svg>
                                ) : null}
                                {isDirty && passwordContainAtLestOneNumber === false && touched.verificationCode && statePassword !== "" ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="not-valid" viewBox="0 0 512 512" width="20" height="20">
                                        <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" />
                                    </svg>
                                ) : null}
                            </p>
                            <p className="password-rule">
                                Minimum 1 symbole
                                {isDirty && passwordContainAtLestOneSymbol === true && touched.verificationCode && statePassword !== "" ? (
                                    <svg id="icon-checkmark-outline" className="valid" viewBox="0 0 20 20" width="20" height="20">
                                        <path d="M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM15.66 15.66c1.449-1.449 2.344-3.45 2.344-5.66 0-4.421-3.584-8.004-8.004-8.004-2.21 0-4.211 0.896-5.66 2.344v0c-1.449 1.449-2.344 3.45-2.344 5.66 0 4.421 3.584 8.004 8.004 8.004 2.21 0 4.211-0.896 5.66-2.344v0zM6.7 9.29l2.3 2.31 4.3-4.3 1.4 1.42-5.7 5.68-3.7-3.7 1.4-1.42z"></path>
                                    </svg>
                                ) : null}
                                {isDirty && passwordContainAtLestOneSymbol === false && touched.verificationCode && statePassword !== "" ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="not-valid" viewBox="0 0 512 512" width="20" height="20">
                                        <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" />
                                    </svg>
                                ) : null}
                            </p>
                        </div>

                        <label>Nouveau mot de passe</label>
                        <div className="field">
                            <div className="field-wrapper">
                                <input
                                    type={passwordVisibility ? "text" : "password"}
                                    name="password"
                                    onChange={(event) => {
                                        const text = event.currentTarget.value;
                                        setValue("password", text, true);
                                        setStatePassword(text);
                                    }}
                                    ref={register({
                                        validate: {
                                            minLength: (value) => {
                                                // console.log('minLength', /.{8,}/.test(value));
                                                setPasswordMinLength(/.{8,}/.test(value));
                                                if (!isDirty) {
                                                    setPasswordMinLength(false);
                                                    setPasswordContainAtLestOneNumber(false);
                                                    setPasswordContainAtLestOneSymbol(false);
                                                }
                                                return /.{8,}/.test(value);
                                            },
                                            containAtLestOneNumber: (value) => {
                                                // console.log('containAtLestOneNumber', /\d/.test(value));
                                                setPasswordContainAtLestOneNumber(/\d/.test(value));
                                                if (!isDirty) {
                                                    setPasswordMinLength(false);
                                                    setPasswordContainAtLestOneNumber(false);
                                                    setPasswordContainAtLestOneSymbol(false);
                                                }
                                                return /\d/.test(value);
                                            },
                                            containAtLestOneSymbol: (value) => {
                                                // console.log('containAtLestOneSymbol', /[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value));
                                                setPasswordContainAtLestOneSymbol(/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value));
                                                if (!isDirty) {
                                                    setPasswordMinLength(false);
                                                    setPasswordContainAtLestOneNumber(false);
                                                    setPasswordContainAtLestOneSymbol(false);
                                                }
                                                return /[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value); /* eslint-disable-line */
                                            },
                                        },
                                    })}
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        setPasswordVisibility(!passwordVisibility);
                                    }}
                                    style={{ outline: "none" }}
                                >
                                    {passwordVisibility ? (
                                        <svg className="icon-eye" id="icon-eye-blocked" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                            <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                                            <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                                            <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                                        </svg>
                                    ) : (
                                        <svg className="icon-eye" id="icon-eye" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                            <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>

                        {stateErrorMessage ? <p className="message-errors">{stateErrorMessage === "Invalid verification code provided, please try again." ? "Code de vérification non valide." : stateErrorMessage}</p> : null}

                        <Button large type="submit" disabled={!isValid}>
                            Valider
                        </Button>
                    </form>

                    <Button buttonStyle="text" onClick={onPressResend} disabled={stateResendMessage > -1}>
                        {stateResendMessage > -1 ? `Nouveau code envoyé (${stateResendMessage})` : "Renvoyer un code"}
                    </Button>
                    {stateResendMessageError ? <p className="message-errors">{stateResendMessageError}</p> : null}
                </div>
            </Content>
        </Container>
    );
};

export default PasswordVerification;
