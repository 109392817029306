export default {
  "title": "Politique de respect de la vie privée",
  "content": `
  <div class="intro"><p><strong>Pepio développe des technologies et des services permettant de créer et de répondre à des sondages d’opinion. Le présent document vous informe de notre politique de respect de la vie privée et des conditions générales qui régissent votre utilisation de Pepio.</strong></p></div>
  
  <h2>Introduction</h2>
  
  <p>Le <strong>MiiL</strong> de l’Université catholique de Louvain (UCLouvain) et <strong>La niche</strong> ont à cœur de protéger vos données personnelles. Lors de votre utilisation de Pepio, nous collectons des informations personnelles. </p>
  
  <p>La présente politique vous informe de la manière dont nous recueillons, traitons et protégeons vos données à caractère personnel. Nous les traitons conformément à la loi du 30 juillet 2018 relative à la protection des personnes physiques à l’égard des données à caractère personne et au Règlement 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données).</p>
  
  <h2>Comment et à quel moment collectons-nous les informations à votre sujet ?</h2>
  
  <p>Nous obtenons des informations sur vous lorsque vous utilisez notre application au moment de votre inscription (création de compte), au moment de vos votes (lorsque vous répondez aux différentes questions de sondage) ainsi qu’au moment de vos interactions avec les autres utilisateurs de Pepio (messagerie feeds). Les informations personnelles que nous obtenons à l’issue de la création de votre compte sont les suivantes :</p>
  <ul>
  <li>Nom d’utilisateur</li>
  <li>Adresse email</li>
  <li>Genre</li>
  <li>Age</li>
  <li>Code postal</li>
  <li>Situation professionnelle</li>
  <li>Dernier niveau d’étude atteint</li>
  <li>Situation familiale</li>
  <li>Nombre d’enfants</li>
  </ul>
  
  <h2>Pourquoi et comment vos données sont-elles utilisées ?</h2>
  
  <p>Nous utilisons ces données pour :</p>
  <ul>
  <li>Valider le statut de sondage du questionnaire en cours (questions de représentativité)</li>
  <li>Relancer les personnes qui sont sous-représentées dans le questionnaire en cours</li>
  <li>Communiquer avec vous sur des modifications importantes, telles que notifier les changements liés à l’application</li>
  </ul>
  <h2>Qui a accès à vos informations?</h2>
  
  <p>Nous ne vendons ni ne louons pas vos informations. Nous ne les partageons pas à des tiers autres que ceux mentionnés ci-dessous.</p>
  <ul>
  <li>Les collaborateurs du projet Pepio : analystes, experts, chercheurs et développeurs de l’UCLouvain, La niche. L’accès à ces données se fait sur la base d’autorisations motivée d’accès individuel et limité. Cet accès est éventuellement octroyé par les responsables du projet Pepio. Les personnes ayant un accès sont soumises à une obligation de confidentialité.</li>
  <li>Sont également susceptibles d’avoir accès à certaines de vos données, nos sous-traitants, les autorités de police ainsi que les autorités judiciaires ou administratives. L’accès à ces données se fait sur la base d’autorisations motivée d’accès individuel et limité. Cet accès est éventuellement octroyé par les responsables du projet Pepio. Les personnes ayant un accès sont soumises à une obligation de confidentialité.</li>
  </ul>
  
  <p>Nous ne partageons pas d’informations permettant de vous identifier directement (telles que votre nom, votre adresse e-mail ou toutes autres coordonnées)</p>
  
  <h2>Vos droits relatifs à vos données personnelles</h2>
  
  <p>Dans le cadre de la protection de vos données personnelles, vous disposez, sous réserve de conditions limitatives, d’un droit d’accès à celles-ci, d’un droit de rectification lorsque ces données sont inexactes ou incomplètes, d’un droit à la limitation du traitement de vos données, d’un droit d’opposition, d’un droit à l’effacement et d’un droit à la portabilité.</p>
  
  <p>Afin d’exercer vos droits ou pour toute question relative à la protection de vos données à caractère personnel, merci d’envoyer un mail à l’adresse <a href="mailto: privacy@pepio.io">privacy@pepio.io</a> en étant le plus précis possible quant à l’objet de votre demande.</p>
  
  <h2>Votre droit à l’effacement</h2>

  <p>Vous pouvez déclencher à n’importe quel moment la suppression définitive de votre compte en utilisant une commande spécifique mise à votre disposition au sein de l’interface “Utilisateurs” moyennant une confirmation réalisée au travers d’un écran spécifique.</p>

  <p>L'exécution de cette opération entraînera :</p>

  <ul>
    <li>La suppression permanente de l’ensemble de tous vos messages rédigés dans les espaces de discussion</li>
    <li>La suppression permanente de vos mentions de type “Like” et “Dislike” effectuées sur les messages rédigés par les membres de la communauté dans les espaces de discussion</li>
    <li>La suppression permanente de votre pseudonyme et de votre avatar</li>
    <li>La suppression permanente de vos données d’identification (email/login ou identifiant social - Facebook, Google ou Apple)</li>
  </ul>

  <p>Il est à noter que vos réponses aux sondages et vos variables sociodémographiques sont conservées après avoir l’objet d’une anonymisation totale et complète.</p>

  <h2>Précautions de sécurité mises en place pour protéger la perte, l’abus ou l’altération de vos informations</h2>
  
  <p>Vos données à caractère personnel sont conservées pendant une durée conforme aux dispositions légales ou conforme aux finalités pour lesquelles elles ont été collectées.</p>
  
  <p>En tant que responsable de traitements, et conformément aux dispositions légales applicables, le MiiL de l’UCLouvain et La niche mettent en œuvre des mesures techniques et organisationnelles appropriées pour protéger vos données personnelles contre l’altération, la perte accidentelle ou illicite, l’utilisation, la divulgation ou l’accès non autorisé, et notamment :</p>
  <ul>
  <li>La nomination d’un délégué à la protection des données</li>
  <li>La constitution d’une cellule consacrée à la sécurité des systèmes d’information</li>
  <li>La sensibilisation aux exigences de confidentialité de nos collaborateurs amenés à accéder à vos données personnelles</li>
  <li>La sécurisation de l’accès à nos locaux et à nos plateformes informatiques</li>
  <li>La mise en œuvre d’une politique générale de sécurité informatique de l’entreprise</li>
  <li>La sécurisation de l’accès, du partage et du transfert des données</li>
  <li>Le haut degré d’exigence en matière de protection des données lorsque que nous sélectionnons nos sous-traitants et partenaires</li>
  </ul>
  
  <h2>Utilisateurs âgés de 16 ans ou moins</h2>
  
  <p>Nous sommes soucieux de protéger la vie privée des enfants de 16 ans et moins. Si vous avez 16 ans ou moins, veuillez obtenir au préalable la permission de votre parent / tuteur lorsque vous nous fournissez des informations personnelles.</p>
  
  <h2>Contact</h2>
  
  <p>Toute question au sujet de cette Politique ou de nos pratiques concernant le respect de la vie privée peut être envoyée par email à l’adresse <a href="mailto: privacy@pepio.io">privacy@pepio.io</a>. Vous pouvez également appeler le numéro de téléphone +32 10 47 45 58.</p>
  <p>Les responsables du traitement sont le MiiL de l’UCLouvain et La niche dont les sièges sont respectivement établis Chemin des Sages 6, B-1348 Louvain-La-Neuve et rue de l'évêché 10, B-5000 Namur.</p>
  
  <p>A cet égard, l’Université catholique de Louvain a désigné un délégué à la protection des données que vous pouvez contacter à l’adresse : <a href="mailto: privacy@uclouvain.be">privacy@uclouvain.be</a></p>
  `
}