//@format
//@flow
import React from "react";
import type { Node } from "react";
import { Modal } from "./styled.js";

type Props = {
    visible: boolean,
    fullSize?: boolean,
    handleClose?: Function,
    submitReport?: Function,
    children: any,
    messageId?: null | number,
    userId?: null | number,
};

const ModalComponent = (props: Props): Node => {
    return (
        <Modal visible={props.visible} fullSize={props.fullSize}>
            <div className="modal-backdrop" onClick={props.handleClose} />
            <div className="modal-content">
                <button className="modal-close" onClick={props.handleClose}>
                    <ion-icon name="close-outline" />
                </button>

                {props.children}
            </div>
        </Modal>
    );
};

export default ModalComponent;
