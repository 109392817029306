//@format
//@flow
import React, { useEffect, useState, useRef } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";

import { Button } from "../../styled-common";

type Props = {
    childsAmount: number,
    handleClose: Function,
};
const ModalChilds = (props: Props): Node => {
    const [stateChildsAmount, setStateChildsAmount] = useState(props.childsAmount);

    const { register, handleSubmit, errors } = useForm();

    const onSubmitForm = async (data) => {
        await userHelper.setUserChildsAmount(data.childsAmount);
        props.handleClose();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <h2 className="title">Modifier votre nombre d'enfants</h2>

                <div className="form-item">
                    <input type="number" name="childsAmount" defaultValue={stateChildsAmount} ref={register({ min: 0, max: 35 })} />
                </div>

                <div className="btn-wrapper">
                    {errors.childsAmount ? (
                        <div className="form-error">
                            <p>Vous devez entrer une valeur entre 0 et 35</p>
                        </div>
                    ) : null}
                    <Button type="submit" small={true}>
                        Valider
                    </Button>

                    <Button type="reset" ghost={true} small={true} onClick={props.handleClose}>
                        Annuler
                    </Button>
                </div>
            </form>
        </>
    );
};

export default ModalChilds;
