import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const ExternalWrapper = styled.div`
  max-height: 560px;
  overflow: hidden;

  ${mq('m')} {
    max-height: none;
  }
`;

export const Wrapper = styled.div`
  padding: 0 30px 30px;
  background: ${colors.lightgrey};
  margin: 0 auto 7%;
  min-height: 370px;

  ${mq('m')} {
    margin-bottom: 0;
    min-height: 0;
  }
`;
export const ContainerWrapper = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  
  max-width: 1280px;
  justify-content: space-between;
  margin: 0 auto;
  /* padding: 0 30px; */

  ${mq('m')} {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const Visual = styled.div`
  // width: 50%;
  margin: 0 -60px -15% 0;

  position: absolute;
  right: 0;
  width: calc(50% - 50px);
  

  ${mq('xxxl', true)} {
    width: 50%;
    
  }

  ${mq('m')} {
    width: calc(100% + 60px);
    margin: 0 -30px;
    position: relative;
  }



	.visual-wrapper {
		position: relative;
		overflow: hidden;
		width: 100%;
    max-height: 530px;

    &::before {
      content: '';
      display: block;
      width: calc(100% + 60px);
      padding-bottom: 60%;
    }

    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
`;

export const Content = styled.div`
	width: 40%;
	max-width: 380px;

  ${mq('m')} {
    width: 100%;
    max-width: 100%;
  }

  .btn-back {
    display: inline-flex;
    align-items: center;
    margin: 0 0 35px;
    font-size: 16px;
    color: ${colors.blue};
    background: transparent none;
    border: 0;
    font-weight: ${fontFamilyWeightBold};

    &:hover {
      color: ${colors.darkBlue80};
    }
  }

	h1 {
		font-family: ${fontFamily};
		font-size: 34px;
		font-weight: ${fontFamilyWeightBold};
		letter-spacing: -0.02em;
		color: ${colors.darkBlue100};
	}

	.description {
		margin: 0 0 20px;
		font-family: ${fontFamily};
		font-size: 16px;
		color: ${colors.darkBlue80};
	}

	.description-button {
		background: transparent;
		border: 0;
		padding: 0;
		color: ${colors.blue};
    font-weight: ${fontFamilyWeightBold};
		cursor: pointer;
		outline: none;
	}
`;
