import React, {useState} from 'react';

import * as userHelper from '../../../../fetch/user';

import { Button } from '../../../../styled-common';

const WizardStep5 = (props) => {

  const selectOption = async (selectedOption) => {
    const response = await userHelper.SetUserProfessionnalSituation(selectedOption);
    // console.log('response', response);

    if(response) {
      props.nextStep();
    }
  }

  return (
    <div>
      <div className="step-content">
        <div className="step-content-inner">
          <h2>
          Quelle est votre situation professionnelle ?
          </h2>
        </div>
        <div className="step-content-footer">

          <Button onClick={() => {
            selectOption(1);
          }}>Employé(e) ou ouvrier/ouvrière</Button>
          
          <Button type="button" onClick={() => {
            selectOption(2);
          }}>Indépendant(e)</Button>
          
          <Button onClick={() => {
            selectOption(3);
          }}>Demandeur/demandeuse d'emploi</Button>

          <Button onClick={() => {
            selectOption(4);
          }}>Homme/femme au foyer</Button>

          <Button onClick={() => {
            selectOption(5);
          }}>Etudiant(e)</Button>

          <Button onClick={() => {
            selectOption(6);
          }}>Pensionné(e)</Button>
        </div>
      </div>
    </div>
  );
};

export default WizardStep5;