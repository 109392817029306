// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    Auth: {
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_PROJECT_REGION,
        identityPoolRegion: process.env.REACT_APP_AWS_PROJECT_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_POOLS_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENTS,
        mandatorySignIn: false,
        signUpVerificationMethod: "code",
        oauth: {
            domain: process.env.REACT_APP_AWS_DOMAIN,
            scope: ["phone", "email", "openid", "profile", "aws.cognito.signin.user.admin"],
            redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGNIN,
            redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGNOUT,
            responseType: "code",
        },
    },
};

export default awsconfig;
