export default {
  "title": "Questions fréquentes Pepio",
  "categories": [
    {
      title: 'Utilisation de Pepio pour répondre aux sondages',
      questions: [
        {
          "title": "Comment installer l’application Pepio sur mon téléphone portable ?",
          "content": `
            <p>Il existe 2 portes d’entrée pour installer l’application Pepio.</p>
            <ul>
            <li>Rendez-vous sur le store de votre smartphone. Ecrivez <em>“Pepio”</em> dans le moteur de recherche. Cliquez sur obtenir pour installer l’application Pepio.</li>
            <li>Rendez-vous sur le site pepio.io. Cliquez depuis le navigateur de votre smartphone sur le lien vers votre store et suivez la procédure précédente.</li>      
            </ul>
            <p>Dès que l’application Pepio sera installée, vous aurez la possibilité de créer un compte et de compléter votre profil.</p>
          `
        },
        {
          "title": "Je veux utiliser Pepio sans utiliser mon téléphone portable. Comment faire ?",
          "content": `
            <p>Pepio est accessible depuis l’ensemble des navigateurs Internet.</p>
            <p>Rendez-vous depuis votre ordinateur, votre tablette et accédez à Pepio via le lien suivant pepio.io. Les fonctionnalités restent identiques.</p>
            <p>Dès que vous aurez accédé à Pepio via votre navigateur Internet, vous aurez la possibilité de créer un compte et de compléter votre profil.</p>
          `
        },
        {
          "title": "Dois-je créer obligatoirement un compte ?",
          "content": `
            <p>L’utilisation de Pepio se fait par défaut en créant un compte. Nous vous demandons compléter votre profil pour participer à des sondages représentatifs. La représentativité d’un sondage ne peut être assurée que si le maximum de personnes a complété son profil.</p>
          `
        },
        {
          "title": "J’ai oublié mon mot de passe.  Comment puis-je le récupérer ?",
          "content": `
            <p>Rendez-vous sur l’écran de connexion de Pepio, encodez votre email, cliquez sur <em>“Continuer”</em>.  Cliquez sur <em>“Mot de passe oublié”</em>. </p>
            <p>Vous recevrez immédiatement par email un code de réinitialisation de votre mot de passe.</p>
            <p>Vous n’avez pas reçu d’email après quelques secondes ? Regardez dans votre boîte de courriers indésirables. Dans de très rares cas, il pourrait s’y trouver. </p>
            <p>Encodez le code reçu dans votre application Pepio. Renouvelez votre mot de passe. C’est fait.</p>
          `
        },
        {
          "title": "Je rencontre un problème technique quand j’installe l’application.  Que puis-je faire ?",
          "content": `
          <p>Les raisons peuvent être diverses, pensez à quelques éléments :</p>
          <ul>
          <li>Votre connexion internet est-elle assez stable en wifi ou 4G ?</li>
          <li>Avez-vous assez de mémoire sur votre smartphone ?</li>
          <li>Votre système IOS ou Androïd est-il assez récent ?</li>
          <li>Désactivez votre VPN si vous en utilisez un</li>
          <li>Redémarrez votre smartphone</li>
          <li>Réinitialisez votre smartphone</li>
          <li>Toujours des problèmes ? Envoyez-nous le screenshot du message d’erreur, nous vous répondrons le plus rapidement possible</li>
          </ul>
          `
        },
        {
          "title": "A quoi sert le scan de QR Code dans Pepio ?",
          "content": `
          <p>Le scan QR Code permet de se rendre directement sur un sondage ou d’accéder à un sondage privé.</p>
          <p>Pour ce faire, le créateur de sondage doit vous faire parvenir le QR code en le diffusant à la télévision, sur un écran, une affiche, etc.</p>
          `
        },
        {
          "title": "Je désire partager un sondage, comment faire ?",
          "content": `
            <p>La fonctionnalité est en cours de développement. Nous y travaillons ardemment.</p>
          `
        }
      ]
    },
    {
      title: 'Données personnelles et confidentialité',
      questions: [
        {
          "title": "Suis-je obligé(e) d’encoder mes données personnelles ?",
          "content": `
            <p>L’utilisation de Pepio se fait par défaut en créant un compte. Nous vous demandons de compléter votre profil si vous désirez participer à des sondages représentatifs. La représentativité d’un sondage ne peut être assurée que si le maximum de personnes a complété son profil.</p>
          `
        },
        {
          "title": "Pourquoi Pepio souhaite obtenir mes données personnelles ?",
          "content": `
            <p>Pepio récolte les informations relatives à votre profil sociodémographique afin de garantir la représentativité des sondages auxquels vous participez. </p>
            <p>Dans le cadre de sondages d’opinion, la représentativité donne plus de crédit aux réponses que vous apportez.</p>
          `
        },
        {
          "title": "Puis-je avoir plusieurs comptes Pepio sur le même appareil ?",
          "content": `
          <p>Vous pouvez disposer de deux comptes au maximum sur le même appareil. </p>
          <p>Nous avons choisi cette option, car le sondage d’opinion et le cœur de l'application Pepio. </p>
          <p>Nous voulons éviter qu’une même personne crée de multiples comptes et influence les réponses au sondage.</p>
          `
        },
        {
          "title": "Quel usage Pepio fait-il de mes réponses aux sondages ?",
          "content": `
            <p>Pepio a pour seule ambition de fournir des visualisations de vos réponses et d’indiquer si ces réponses sont représentatives ou non d’une population de référence. </p>
            <p>Nous n’utilisons et ne traitons pas les réponses aux sondages, ni leurs résultats.</p>
          `
        },
        {
          "title": "Que deviennent mes données personnelles si je supprime mon compte ?",
          "content": `
            <p>Nous nous engageons à respecter la loi relative à la protection des données. Le respect de vos données personnelles est notre priorité. </p>
            <p>Lorsque vous supprimez votre compte, nous supprimons définitivement tous vos messages de l’espace de discussion, tous vos <em>“Like”</em> et <em>“Dislike”</em>, votre pseudonyme, votre avatar ainsi que vos données d’identification. </p>
            <p>Nous ne conservons que les réponses aux sondages et les variables sociodémographiques. Cela nous permet de maintenir la représentativité éventuelle de l'échantillon. Ces données sont entièrement anonymisées et ne sont plus liées à vos informations personnelles.</p>
          `
        },
        {
          "title": "Je désire supprimer un commentaire que j’ai posté, comment faire ?",
          "content": `
            <p>Rendez-vous sur votre commentaire dans l’application. Dans le coin supérieur droit, vous trouverez un pictogramme d’une poubelle.</p>
            <p>Cliquez sur ce pictogramme, cliquez sur <em>“Supprimer”</em>. </p>
          `
        },
        {
          "title": "Je désire signaler un commentaire inapproprié comment faire ?",
          "content": `
            <p>Rendez-vous sur le commentaire. Dans le coin supérieur droit, vous trouverez une icône de drapeau. </p>
            <p>Cliquez dessus et le commentaire sera signalé au modérateur. Ce dernier évaluera si ce commentaire ne respecte pas les règles de Pepio. </p>
            <p>Pour éviter les abus, nous limitons le nombre de signalement successif à 2 signalements par jour. </p>
          `
        }
      ]
    }
  ]
}