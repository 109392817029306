// @format
// @flow
// import React from 'react';
import { Auth } from "aws-amplify";
import AsyncStorage from "@callstack/async-storage";
// import i18n from "i18next";
// import { useTranslation, initReactI18next } from "react-i18next";
import browserID from "browser-id";
// import ubid from "ubid";
import getBrowserFingerprint from 'get-browser-fingerprint';

// import components
import client from "./guestClient";
// import * as RootNavigation from '../navigator/rootNavigation';

/**********************************
 *        User Logins             *
 **********************************/

import { historyInst } from "../index.js";

// retrieve the authenticated user after a successful cognito login
// If no user exist at backend side, a new one is created and linked to the cognito user
// ----------------------------------------------------------------------------------------
export const getandStoreAuthenticatedProfile = async (cognitoUser: any, cb: Function): Promise<any> => {
    const cognitoId = cognitoUser.attributes.sub;
    const identities = cognitoUser.attributes && cognitoUser.attributes.identities ? JSON.parse(cognitoUser.attributes.identities) : [];
    const providerType = identities && identities[0] && identities[0].providerName ? identities[0].providerName : "email";
    const pepioUserId = await AsyncStorage.getItem("@PepioUserId");

    if (cognitoId) {

        const fingerprint = getBrowserFingerprint();

        const browserIdGen = fingerprint || browserID();
        const data = {
            id: parseInt(pepioUserId, 10),
            accountId: cognitoId,
            ProviderType: providerType,
            deviceId: browserIdGen && "1" ? browserIdGen : `noid-${new Date().getTime()}`,
        };

        try {
            const response = await client.post("Profile/GetAuthenticatedProfile", data);

            const pepioProfile = response.data;
            await AsyncStorage.setItem("@PepioUserId", pepioProfile.id.toString());
            await AsyncStorage.setItem("@PepioUser", JSON.stringify(pepioProfile));

            if (response.status === 200) {
                let responseAuthorize = await client.post("Profile/Authorize", {
                    userId: response.data.id,
                    securityStamp: response.data.securityStamp,
                });
                await AsyncStorage.setItem("@PepioToken", responseAuthorize.data.token);

                if (responseAuthorize.status === 200) {
                    await AsyncStorage.setItem("@PepioToken", responseAuthorize.data.token);

                    if (typeof cb === "function") {
                        cb();
                    }
                }
            }
        } catch (error) {
            throw new Error(`1001-Cannot get authenticated User: ${error})`);
        }
    }
    return true;
};

// get an annonymous user when the user wants to continue without login
// This annonymous user only have an ID and is not linked to any identified user
// If no user exist, a new one is created on the backend.
// ----------------------------------------------------------------------------------------
export const getAndStoreAnnonymousProfileFromBackEnd = async (cb: Function): Promise<any> => {
    try {
        // init
        let response = null;

        console.log("getAndStoreAnnonymousProfileFromBackEnd");

        await AsyncStorage.removeItem("@CognitoUserId");
        await AsyncStorage.setItem("@AnnonymousLogin", "true");

        // Check if local user is exist
        const pepioId = await AsyncStorage.getItem("@PepioUserId");

        if (pepioId !== null) {
            // User Id found, get the profile
            response = await client.get("Profile/GetProfile/" + pepioId);

            console.log("getAndStoreAnnonymousProfileFromBackEnd > pepioId", pepioId, response);
        } else {
            // User don't exist : create a new one

            const fingerprint = getBrowserFingerprint();

            const browserIdGen = fingerprint || browserID();

            response = await client.post("Profile/GetAnnonymousProfile/" + (browserIdGen && "1" ? browserIdGen : `noid-${new Date().getTime()}`));
            console.log("getAndStoreAnnonymousProfileFromBackEnd > !pepioId", response);
        }

        // if
        if (response.status === 200) {
            const pepioProfile = response.data;
            await AsyncStorage.setItem("@PepioUserId", pepioProfile.id.toString());
            await AsyncStorage.setItem("@PepioUser", JSON.stringify(pepioProfile));

            let responseAuthorize = await client.post("Profile/Authorize", {
                userId: pepioProfile.id,
                securityStamp: pepioProfile.securityStamp,
            });

            if (responseAuthorize.status === 200) {
                await AsyncStorage.setItem("@PepioToken", responseAuthorize.data.token);

                if (typeof cb === "function") {
                    cb();
                }
            }

            return true;
        }
    } catch (error) {
        throw new Error(`1002-Cannot get anonymous User: ${error})`);
    }
};

// Signout the user and clear local storage
// ----------------------------------------------------------------------------------------
export const signOutUser = async () => {
    const modalEmotionsAlreadyShowed = await AsyncStorage.getItem("@ModalEmotionsAlreadyShowed");
    console.log("modalEmotionsAlreadyShowed", modalEmotionsAlreadyShowed);

    const ModalAvatarAlreadyShowed = await AsyncStorage.getItem("@ModalAvatarAlreadyShowed");
    console.log("ModalAvatarAlreadyShowed", ModalAvatarAlreadyShowed);

    // Removing all locally stired data (privacy)
    await AsyncStorage.clear();
    // But re-record that onboarding has already be viewed
    await AsyncStorage.setItem("@OnbordingAlreadyViewed", "true");

    if (modalEmotionsAlreadyShowed === "true") {
        await AsyncStorage.setItem("@ModalEmotionsAlreadyShowed", "true");
    }

    if (ModalAvatarAlreadyShowed === "true") {
        await AsyncStorage.setItem("@ModalAvatarAlreadyShowed", "true");
    }

    // cognito auth signout
    Auth.signOut({ global: false })
        .then((data) => {
            // RootNavigation.navigate('loginChoice');
        })
        .catch((err) => console.log("err", err));
};

// Check Opinio Profile
// ----------------------------------------------------------------------------------------
export const checkOpinioProfile = async (email: string): Promise<any> => {
    try {
        const response = await client.get("Profile/CheckOpinioProfile/" + email);
        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        //throw new Error(`1007-Cannot save onesignal player id: ${error})`);
    }
};

// Update congito ID for returning user
// -------------------------------------------------------------
export const setcognitoId = async (cognitoId: any) => {
    const userId = await getUserId();
    if (userId) {
        const data = {
            userId: userId,
            cognitoId: cognitoId,
        };
        await client.post("Profile/SetCognitoUserId/", data);
    }
};

// Get user notifications
// ----------------------------------------------------------------------------------------
export const getAppNotifications = async (): Promise<any> => {
    try {
        const pepioId = await AsyncStorage.getItem("@PepioUserId");
        const response = await client.get(`Notification/GetNotifications/${pepioId}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        //throw new Error(`1007-Cannot save onesignal player id: ${error})`);
    }
};

// Check username
// ----------------------------------------------------------------------------------------
export const checkUsername = async (username: string): Promise<any> => {
    try {
        const pepioId = await AsyncStorage.getItem("@PepioUserId");
        const response = await client.get(`Profile/CheckUserName/${pepioId}/${username}`);

        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        //throw new Error(`1007-Cannot save onesignal player id: ${error})`);
    }
};

/**********************************
 *        User Profile            *
 **********************************/

// refresh the local user profile from the backend
// -------------------------------------------------------------
export const refreshProfileFromBackEnd = async (successCb: Function) => {
    try {
        // init
        let response = null;

        // Check if local user is exist
        const pepioId = await AsyncStorage.getItem("@PepioUserId");

        if (pepioId !== null) {
            // User Id found, get the profile
            response = await client.get("Profile/GetProfile/" + pepioId);
            // if
            if (response.status === 200) {
                let pepioProfile = response.data;
                pepioProfile.pictureUrl = pepioProfile.pictureUrl + "?" + Date.now();
                await AsyncStorage.setItem("@PepioUserId", pepioProfile.id.toString());
                await AsyncStorage.setItem("@PepioUser", JSON.stringify(pepioProfile));

                if (typeof successCb === "function") {
                    successCb(response);
                }

                // Check if userEmail is registered
                if (!pepioProfile.email) {
                    try {
                        const user = await Auth.currentAuthenticatedUser();
                        if (user.attributes.email) {
                            await setUserEmail(pepioProfile.id, user.attributes.email);
                        }
                    } catch (error) {
                        if (error === "not authenticated") {
                            console.log(`1010-Cannot set user email: ${error})`);
                        }
                    }
                }
            }
        }
    } catch (error) {
        throw new Error(`1005-Cannot refresh user from backend: ${error})`);
    }
};

// Save the user name on backend and refresh the local profile
// -------------------------------------------------------------
export const saveUsername = async (username: string): Promise<boolean> => {
    try {
        // init
        let response = null;

        // Check if local user is exist
        const userId = await getUserId();
        if (userId !== null) {
            const data = {
                id: userId,
                username: username,
            };
            // User Id found, get the profile
            response = await client.post("Profile/SetUserName", data);

            // if response is ok :
            if (response.status === 200) {
                await refreshProfileFromBackEnd();
                return true;
            }
        }
    } catch (error) {
        throw new Error(`1003-Cannot update user name: ${error})`);
    }
    return false;
};

// -------------------------------------------------------------
// Set email
// -------------------------------------------------------------
export const setUserEmail = async (pepioId: number, email: string): Promise<boolean> => {
    try {
        const data = {
            userId: pepioId,
            email: email,
        };
        const response = await client.post("Profile/SetUserEmail/", data);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        throw new Error(`1008-Cannot save user email: ${error})`);
    }
    return false;
};

// -------------------------------------------------------------
// Set user accept email flag
// -------------------------------------------------------------
export const setUserAcceptEmail = async (acceptEmail: boolean): Promise<boolean> => {
    try {
        const userId = await getUserId();
        const data = {
            userId: userId,
            acceptEmail: acceptEmail,
        };
        const response = await client.post("Profile/SetUserAcceptEmail/", data);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        throw new Error(`1008-Cannot save user accept email state: ${error})`);
    }
    return false;
};

// Set postalcode
// -------------------------------------------------------------
export const setUserPostalcode = async (postalcode: any): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        postalcode: postalcode,
    };
    try {
        const response = await client.post("Profile/setUserPostalcode", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user postal code: ${error})`);
    }
    return false;
};

// Set parental consent
// -------------------------------------------------------------
export const setParentalConsent = async (consent: any): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        consent: consent,
    };
    try {
        const response = await client.post("Profile/setParentalconsent", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the parental consent: ${error})`);
    }
    return false;
};

// Set user gender
// -------------------------------------------------------------
export const setUserGender = async (gender: any): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        gender: gender,
    };
    try {
        const response = await client.post("Profile/SetUserGender", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user gender: ${error})`);
    }
    return false;
};

// Set education level
// -------------------------------------------------------------
export const setUserEducationLevel = async (educationLevelValue: any): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        educationLevel: educationLevelValue,
    };
    try {
        const response = await client.post("Profile/SetUserEducationLevel", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user education level: ${error})`);
    }
    return false;
};

// Set professionnal level
// -------------------------------------------------------------
export const SetUserProfessionnalSituation = async (professionnalSituationValue: any): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        professionnalSituation: professionnalSituationValue,
    };
    try {
        const response = await client.post("Profile/SetUserProfessionnalSituation", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user professionnal situation: ${error})`);
    }
    return false;
};

// Set user family context
// -------------------------------------------------------------
export const SetUserFamilyContext = async (familyContextValue: any): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        familyContext: familyContextValue,
    };
    try {
        const response = await client.post("Profile/SetUserFamilyContext", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user family context: ${error})`);
    }
    return false;
};

// Set user birth year
// -------------------------------------------------------------
export const setUserBirthYear = async (birthYear: string): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        birthYear: parseInt(birthYear, 10),
    };
    try {
        const response = await client.post("Profile/SetUserBirthYear", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user birth year: ${error})`);
    }
    return false;
};

// Set user child amount
// -------------------------------------------------------------
export const setUserChildsAmount = async (childsAmount: string): Promise<boolean> => {
    const userId = await getUserId();
    const data = {
        userId: userId,
        childsAmount: parseInt(childsAmount, 10),
    };
    try {
        const response = await client.post("Profile/SetUserChildAmount", data);

        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
        refreshProfileFromBackEnd();
    } catch (error) {
        throw new Error(`1011-Cannot save the user childs amount: ${error})`);
    }
    return false;
};

// Upload user avatar picture
// -------------------------------------------------------------
export const uploadPhoto = async (image: any): Promise<string> => {
    const userId = await getUserId();

    const data = {
        userId: userId,
        picture: image.data,
    };
    try {
        const avatarUri = await client.post("Profile/UploadAvatar", data);
        // console.log("picture URL : ", avatarUri);
        return avatarUri;
    } catch (error) {
        throw new Error(`1004-Cannot update user picture: ${error})`);
    }
};

// Get userId from local storage
// -------------------------------------------------------------
export const getUserId = async (): Promise<number> => {
    const storedPepioId = await AsyncStorage.getItem("@PepioUserId");
    const pepioUserId = parseInt(storedPepioId, 10);
    return pepioUserId;
};

// Get user profile from local storage
// -------------------------------------------------------------
export const getUser = async (): Promise<PepioUser> => {
    refreshProfileFromBackEnd();
    const jsonuser = await AsyncStorage.getItem("@PepioUser");
    const user = JSON.parse(jsonuser);
    return user;
};

/**********************************
 *        User language           *
 **********************************/

// Get user profile from local storage
// -------------------------------------------------------------
export const getLanguage = async (): Promise<string> => {
    let language = await AsyncStorage.getItem("@PepioUserLanguage");

    // check if language fall in the availaible language, if not default to FR
    if (language !== "fr" && language !== "en") {
        language = "fr";
    }
    return language;
};

// Save user language to local storage
// -------------------------------------------------------------
export const saveLanguage = async (language: string): Promise<boolean> => {
    // save language to local storage
    await AsyncStorage.setItem("@PepioUserLanguage", language);

    // save language to online profile
    try {
        // init
        let response = null;

        // Check if local user is exist
        const pepioUserId = await AsyncStorage.getItem("@PepioUserId");

        if (pepioUserId !== null) {
            const data = {
                id: pepioUserId,
                language: language,
            };
            // User Id found, get the profile
            response = await client.post("Profile/SetUserLanguage", data);

            // if response is ok :
            if (response.status === 200) {
                await refreshProfileFromBackEnd();
                return true;
            }
        }
    } catch (error) {
        throw new Error(`1006-Cannot save user language: ${error})`);
    }
    return false;
};

export const deleteUserProfile = async (): Promise<boolean> => {
    const userId = await getUserId();
    const user = await getUser();
    const data = {
        userId: userId,
        securityStamp: user.securityStamp,
    };
    try {
        const response = await client.post("Profile/deleteUserProfile", data);
        signOutUser();
        // if response is ok :
        if (response.status === 200) {
            await refreshProfileFromBackEnd();
            return true;
        }
    } catch (error) {
        throw new Error(`1011-Cannot delete profile: ${error})`);
    }
    return false;
};

// Get JWT token from local storage
// -------------------------------------------------------------

export const getToken = async (): Promise<string> => {
    let token = await AsyncStorage.getItem("@PepioToken");
    return token;
};

export const getAvatarAssets = async (): Promise<string> => {
    try {
        let response = null;

        response = await client.get("avatar/getelements");
        // console.log("response", response);
        if (response && response.status === 200) {
            return response.data;
        } else {
            throw new Error(`1011-Cannot get assets for avatar)`);
        }
    } catch (error) {
        throw new Error(`1011-Cannot get assets for avatar: ${error})`);
    }
};

export const saveAvatarCode = async (avatarCode: string): Promise<boolean> => {
    try {
        let response = null;
        const pepioUserId = await AsyncStorage.getItem("@PepioUserId");

        if (pepioUserId !== null) {
            console.log("avatarCode", avatarCode);

            response = await client.post("Profile/SetAvatar", {
                id: pepioUserId,
                avatarCode: avatarCode,
            });

            // Clear avatar uri stored locally
            await AsyncStorage.removeItem("@PepioUserEmotions");

            if (response.status === 200) {
                await refreshProfileFromBackEnd();
                return true;
            }
        }
    } catch (error) {
        throw new Error(`1012-Cannot save user avatar: ${error})`);
    }
    return false;
};

// -------------------------------------------------------------
// Get user profile picture for all emotions
// -------------------------------------------------------------
export const getEmotions = async (): Promise<AvatarEmotion | null> => {
    try {
        // Check if emotions are stored locally
        const localEmotion = await AsyncStorage.getItem("@PepioUserEmotions");

        if (localEmotion && localEmotion.none !== "null") {
            const emotionsFromLocalStorage = JSON.parse(localEmotion);
            console.log("emo none:", emotionsFromLocalStorage.none);
            if (emotionsFromLocalStorage.none !== null && emotionsFromLocalStorage.none !== "null") return emotionsFromLocalStorage;
        }

        // init
        let response = null;
        const user = await getUser();
        console.log(user);
        const pictureUrl = user.pictureUrl;
        if (pictureUrl) {
            const strippedPictureUrl = pictureUrl.substring(0, pictureUrl.indexOf("?"));
            const timeStamp = new Date().getTime();
            const emotionsPicture: AvatarEmotion = {
                none: strippedPictureUrl + "?ts=" + timeStamp,
                laught: strippedPictureUrl + "_laught?ts=" + timeStamp,
                love: strippedPictureUrl + "_love?ts=" + timeStamp,
                sad: strippedPictureUrl + "_sad?ts=" + timeStamp,
                smile: strippedPictureUrl + "_smile?ts=" + timeStamp,
                surprise: strippedPictureUrl + "_surprise?ts=" + timeStamp,
                terror: strippedPictureUrl + "_terror?ts=" + timeStamp,
            };

            // Save to local storage
            await AsyncStorage.setItem("@PepioUserEmotions", JSON.stringify(emotionsPicture));

            return emotionsPicture;
        }
        return null;
    } catch (error) {
        throw new Error(`1010-Cannot check user name : ${error}`);
    }
};

// -------------------------------------------------------------
// Get user videos
// -------------------------------------------------------------
export const getUserVideo = async (channelId: Number): any => {
    const userId = await getUserId();

    try {
        let response = null;

        response = await client.get(`Video/GetUserVideo/${userId}/${channelId.toString()}`);
        console.log("getUserVideo response", response);
        if (response && response.status === 200) {
            return response.data;
        } else {
            throw new Error(`1013-Cannot get video for this user or this channel)`);
        }
    } catch (error) {
        throw new Error(`1013-Cannot get video for this user or this channel: ${error})`);
    }
};

// -------------------------------------------------------------
// Get videos themes
// -------------------------------------------------------------
export const getVideoThemes = async (channelId: Number): any => {
    try {
        let response = null;

        response = await client.get(`Video/GetVideoTheme/${channelId.toString()}`);
        console.log("getVideoThemes response", response);
        if (response && response.status === 200) {
            return response.data;
        } else {
            throw new Error(`1013-Cannot get video for this user or this channel)`);
        }
    } catch (error) {
        throw new Error(`1013-Cannot get video for this user or this channel: ${error})`);
    }
};

// -------------------------------------------------------------
// Send video request
// -------------------------------------------------------------
export const requestVideo = async (formData: any): any => {
    try {
        let response = null;
        response = await client.post(`Video/RequestVideo`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        console.log("requestVideo response", response);
        if (response && response.status === 200) {
            return response.status;
        } else {
            throw new Error(`1014-Cannot request video for this user or this channel)`);
        }
    } catch (error) {
        throw new Error(`1014-Cannot request video for this user or this channel: ${error})`);
    }
    //     const client = await getLoginClient();
    //     client.defaults.headers.common.ContentType = 'multipart/form-data';

    //     const data = new FormData();
    //     data.append('UserId', await userHelper.getUserId());
    //     data.append('channelId', channelId);
    //     data.append('gender', gender);
    //     data.append('skin', skin);
    //     data.append('theme', theme);

    //     //$FlowFixMe
    //     data.append('file', {
    //       uri: fileUri,
    //       type: 'image/jpg',
    //       name: `photo_${userÌd}.jpg`,
    //     });

    //     console.log(data);

    //     return client
    //       .post('Video/RequestVideo', data)
    //       .then((response) => {
    //         return response.data;
    //       })
    //       .catch((error) => {
    //         return false;
    //       });
};
