//@format
//@flow
import React from "react";
import type { Node} from "react"
import { useHistory } from "react-router-dom";
import { Button } from "../../styled-common.js";
import { Card } from "./styled.js";
import vip from "../../vip";

type Props = {
	survey:Survey,
	channel:Channel,
}

/*
 * ******************************************************************
 *  Survey Card
 *  this component render the default survey card
 * ******************************************************************
 */
const SurveyCard = (props : Props) : Node => {
	const { survey, channel } = props
	const history = useHistory();
	const vipCopy = vip.copy;
	return (
		// <Card onClick={() => history.push("/survey-details", { surveyId: survey.id })}>
		<Card onClick={() => history.push(`/survey-details/${survey.id}`, { surveyId: survey.id })}>
			<div className="visual">
				<img src={survey.pictureUrl} alt="" />
			</div>
			<div className="content">
				<div className="content-card-header">
					<div style={{color: (channel.isCustomized ? channel.textColor:'')}}>
						{survey.diffusionType === "Live" ? <div className="type type-live">{vipCopy.survey} Live</div> : null}
						{/*
            {survey.type === "Quizz" ? <div className="type type-quizz">Quizz</div> : null}
						{survey.type === "BlindTest" ? <div className="type type-blindtest">Blindtest</div> : null}
            */}
					</div>
					<div className="status">{survey.status === "Active" ? <span className="open">Ouvert</span> : <span className="close">Fermé</span>}</div>
				</div>
				<h3 className="title" style={{color: (channel.isCustomized ? channel.textColor:'')}}>{survey.title}</h3>
				{survey.shortDescription ? <div className="description" style={{color: (channel.isCustomized ? channel.textColor:'')}} dangerouslySetInnerHTML={{ __html: survey.shortDescription }} /> : null}

				{survey.shortDescription === null && survey.description ? <div className="description" style={{color: (channel.isCustomized ? channel.textColor:'')}} dangerouslySetInnerHTML={{ __html: survey.description }} /> : <div className="description"></div>}
				<Button style={{backgroundColor: (channel.isCustomized ? channel.buttonColor:''), borderColor: (channel.isCustomized ? channel.buttonColor:'')}}>{survey.type === "Survey" ? vipCopy["go-to-survey"] : `Voir le contenu`}</Button>
			</div>
		</Card>
	);
};

export default SurveyCard;
