//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";

import { useHistory, useLocation } from "react-router-dom";

// import components
import ChannelList from "../../components/channelList";

// import API fetcher
import * as channelsFetchHelper from "../../fetch/channel";

const ChannelByCategory = (): Node => {
    const location = useLocation();
    const history = useHistory();

    const categoryId = location.state && location.state.categoryId ? location.state.categoryId : null;
    const [channels, setChannels] = useState([]);
    const [showSpinner, setShowSpinner] = useState(true);

    // fetch data on screen load
    useEffect(() => {
        if (!location.state.categoryId) {
            return history.push("/");
        }

        async function fetchData() {
            const category = await channelsFetchHelper.getCategory(categoryId);
            const channelsResults = await channelsFetchHelper.getChannelsByCategory(categoryId);
            setChannels(channelsResults);

            setShowSpinner(false);
        }

        fetchData();
    }, []);

    return <ChannelList channels={channels} loading={showSpinner} />;
};

export default ChannelByCategory;
