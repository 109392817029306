import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AsyncStorage from "@callstack/async-storage";

import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";

import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container, ContentHeader, Content, ContentUpdatePassword } from "./styled.js";
import { Button } from "../../styled-common";

import { Auth } from "aws-amplify";

import Modal from "../../components/modal";

const Settings = () => {
    const password_new = useRef({});

    const history = useHistory();
    const { register, watch, setValue, handleSubmit, errors, formState } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all",
    });

    const { register: registerIssue, errors: errorsIssue, handleSubmit: handleSubmitIssue } = useForm({});

    password_new.current = watch("password_new", "");

    const [formReportConfirmationMessage, setFormReportConfirmationMessage] = useState(null);
    const [userData, setUserData] = useState({ email: "hello@hello.com" });

    const [openedItemAccount, setOpenedItemAccount] = useState(false);
    const [openedItemIssue, setOpenedItemIssue] = useState(false);
    const [openedItemNotification, setOpenedItemNotification] = useState(false);

    const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
    const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);

    const itemIssueContentHeight = useRef();
    const itemAccountContentHeight = useRef();
    const itemNotificationContentHeight = useRef();

    const [authUserData, setAuthUserData] = useState(0);
    const [openedItemAccountHeight, setOpenedItemAccountHeight] = useState(0);
    const [openedItemIssueHeight, setOpenedItemIssueHeight] = useState(0);
    const [openedItemNotificationHeight, setOpenedItemNotificationHeight] = useState(0);

    const [stateDeleteConfirmationModal, setStateDeleteConfirmationModal] = useState(false);

    const [passwordVisibilityOld, setPasswordVisibilityOld] = useState(false);
    const [passwordVisibilityNew, setPasswordVisibilityNew] = useState(false);
    const [passwordVisibilityNewConf, setPasswordVisibilityNewConf] = useState(false);

    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    const [passwordMinLength, setPasswordMinLength] = useState(false);
    const [passwordContainAtLestOneNumber, setPasswordContainAtLestOneNumber] = useState(false);
    const [passwordContainAtLestOneSymbol, setPasswordContainAtLestOneSymbol] = useState(false);

    const [acceptEmails, setAcceptEmails] = useState(false);

    const updatePassword = async (data) => {
        const responseUpdate = await Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, data.password_old, data.password_new);
            })
            .then((responseData) => {
                // console.log('update password responseData', responseData);
                setUpdatePasswordSuccess(true);
                setTimeout(() => {
                    setUpdatePasswordModal(false);
                    setUpdatePasswordSuccess(false);
                    userHelper.signOutUser();
                }, 5000);
            })
            .catch((err) => {
                // console.log('update password error', err.code, err);

                if (err.code === "LimitExceededException") {
                    setPasswordErrorMessage("Vous avez fait trop d'essais erronés, veuillez réessayer plus tard.");
                } else if (err.code === "NotAuthorizedException") {
                    setPasswordErrorMessage("Le mot de passe actuel que vous avez entré ne semble pas correct.");
                } else {
                    setPasswordErrorMessage("Nous avons rencontré un problème veuillez réessayer, si le problème persiste veuillez contacter l'assistance technique.");
                }
            });
    };

    const getUserData = async () => {
        const user = await userHelper.getUser();
        const cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
        user.cognitoUserId = cognitoUserId;
        setUserData(user);
        setAcceptEmails(user.acceptEmail);
        Auth.currentUserInfo().then((data) => {
            const userData = data;
            if (userData && userData.attributes && userData.attributes.identities) {
                userData.attributes.identities = userData && userData.attributes ? JSON.parse(userData.attributes.identities)[0] : null;
            }
            setAuthUserData(data);
        });
    };

    const submitReport = async (data) => {
        // console.log('submit report data', data);
        const userId = await userHelper.getUserId();
        const dataToSubmit = {
            id: userId,
            feedMessageId: 0,
            email: data.email,
            title: data.subject,
            message: data.message,
        };

        const response = await client.post("Settings/SubmitReport", dataToSubmit);

        setOpenedItemIssueHeight(itemIssueContentHeight.current.clientHeight);

        if (response.status === 200) {
            setFormReportConfirmationMessage(true);
        } else {
            setFormReportConfirmationMessage(false);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <>
            <Header />
            <Container>
                <ContentHeader>
                    <div className="inner">
                        <button
                            className="btn-back"
                            onClick={() => {
                                history.push("home");
                            }}
                        >
                            <ion-icon name="chevron-back"></ion-icon>
                            Retour
                        </button>
                        <h1 className="title">Paramètres</h1>
                    </div>
                </ContentHeader>
                <Content>
                    <div className="inner">
                        <ul className="expendable-list">
                            {userData.cognitoUserId ? (
                                <li className={`expendable-item${openedItemAccount ? " expendable-item-opened" : ""}`}>
                                    <div
                                        className={`expendable-item-header${openedItemAccount ? " expendable-item-header-opened" : ""}`}
                                        onClick={() => {
                                            if (!openedItemAccount) {
                                                setOpenedItemAccountHeight(itemAccountContentHeight.current.clientHeight);
                                            } else {
                                                setOpenedItemAccountHeight(0);
                                            }
                                            setOpenedItemAccount(!openedItemAccount);
                                        }}
                                    >
                                        <span>Compte et email</span>
                                        <ion-icon name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <div className={`expendable-item-content`} style={{ maxHeight: openedItemAccountHeight }}>
                                        <div className="expendable-item-content-inner" ref={itemAccountContentHeight}>
                                            {authUserData && authUserData.attributes && authUserData.attributes.email ? (
                                                <div className="email-account">
                                                    Votre adresse email: <span>{authUserData.attributes.email}</span>
                                                </div>
                                            ) : null}
                                            {authUserData && authUserData.attributes && authUserData.attributes.identities && authUserData.attributes.identities.providerType ? null : (
                                                <div className="password-account">
                                                    <button
                                                        onClick={() => {
                                                            setUpdatePasswordModal(true);
                                                        }}
                                                    >
                                                        Modifier le mot de passe <ion-icon name="chevron-forward-outline"></ion-icon>
                                                    </button>
                                                </div>
                                            )}

                                            <div className="delete-account">
                                                <div className="delete-account-header">
                                                    <span>Supprimer votre compte?</span>
                                                    <Button
                                                        small
                                                        onClick={() => {
                                                            setStateDeleteConfirmationModal(true);
                                                        }}
                                                    >
                                                        <ion-icon name="trash-outline"></ion-icon>
                                                    </Button>
                                                </div>
                                                <p>La supression de votre compte entraîne l'effacement définitif de vos données personnelles ainsi que tous vos messages sur les espaces de discussion. Cette action est irréversible!</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ) : null}

                            {userData.cognitoUserId ? (
                                <li className={`expendable-item${openedItemNotification ? " expendable-item-opened" : ""}`}>
                                    <div
                                        className={`expendable-item-header${openedItemNotification ? " expendable-item-header-opened" : ""}`}
                                        onClick={() => {
                                            if (!openedItemNotification) {
                                                setOpenedItemNotificationHeight(itemNotificationContentHeight.current.clientHeight);
                                            } else {
                                                setOpenedItemNotificationHeight(0);
                                            }
                                            setOpenedItemNotification(!openedItemNotification);
                                        }}
                                    >
                                        <span>Notifications</span>
                                        <ion-icon name="chevron-forward-outline"></ion-icon>
                                    </div>
                                    <div className={`expendable-item-content`} style={{ maxHeight: openedItemNotificationHeight }}>
                                        <div className="expendable-item-content-inner" ref={itemNotificationContentHeight}>
                                            <div className="delete-account">
                                                <div className="delete-account-header" style={{ justifyContent: "space-between" }}>
                                                    <div>Notification par email</div>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            checked={acceptEmails}
                                                            onChange={async (event) => {
                                                                await userHelper.setUserAcceptEmail(!acceptEmails);
                                                                setAcceptEmails((val) => !val);
                                                                await userHelper.refreshProfileFromBackEnd();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ) : null}

                            <li className="expendable-item">
                                <a href="/faq" className="expendable-item-header">
                                    <span>Questions fréquentes</span>
                                    <ion-icon name="chevron-forward-outline"></ion-icon>
                                </a>
                            </li>
                            <li className={`expendable-item${openedItemIssue ? " expendable-item-opened" : ""}`}>
                                <div
                                    className={`expendable-item-header${openedItemIssue ? " expendable-item-header-opened" : ""}`}
                                    onClick={() => {
                                        if (!openedItemIssue) {
                                            setOpenedItemIssueHeight(itemIssueContentHeight.current.clientHeight);
                                        } else {
                                            setOpenedItemIssueHeight(0);
                                        }
                                        setOpenedItemIssue(!openedItemIssue);
                                    }}
                                >
                                    <span>Signaler un problème</span>
                                    <ion-icon name="chevron-forward-outline"></ion-icon>
                                </div>
                                <div className={`expendable-item-content${openedItemIssue ? " expendable-item-content-opened" : ""}`} style={{ maxHeight: openedItemIssueHeight }}>
                                    <div className="expendable-item-content-inner" ref={itemIssueContentHeight}>
                                        {formReportConfirmationMessage === null || formReportConfirmationMessage !== true ? (
                                            <form className="form-report" onSubmit={handleSubmitIssue(submitReport)}>
                                                <div className={errorsIssue.email ? "field field-error" : "field"}>
                                                    <label>
                                                        Email <small>(si vous désirez être recontacté.e)</small>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        ref={registerIssue({
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "invalid email address",
                                                            },
                                                        })}
                                                    />
                                                </div>

                                                <div className={errorsIssue.subject ? "field field-error" : "field"}>
                                                    <label>Sujet</label>
                                                    <input type="text" name="subject" ref={registerIssue({ required: true })} />
                                                </div>

                                                <div className={errorsIssue.message ? "field field-error" : "field"}>
                                                    <label>Message</label>
                                                    <textarea name="message" ref={registerIssue({ required: true })}></textarea>
                                                </div>

                                                {formReportConfirmationMessage === false ? (
                                                    <div className="confirmation-message confirmation-message-error">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                        <div className="confirmation-message-text">
                                                            <p>Une erreur s'est produite votre message n'a pas pu pêtre envoyé</p>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                <Button type="submit">Envoyer votre message</Button>
                                            </form>
                                        ) : (
                                            <div className="confirmation-message confirmation-message-success">
                                                <ion-icon name="checkmark-circle"></ion-icon>
                                                <div className="confirmation-message-text">
                                                    <p>Merci pour votre message, nous le traiterons au plus vite</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                            <li className="expendable-item">
                                <a href="https://pepio.io/terms-and-conditions/" target="_blank" className="expendable-item-header">
                                    <span>Termes et conditions</span>
                                    <ion-icon name="chevron-forward-outline"></ion-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </Content>
            </Container>

            <Footer />

            <Modal
                visible={stateDeleteConfirmationModal}
                handleClose={() => {
                    setStateDeleteConfirmationModal(false);
                }}
            >
                <h2 className="title">Etes vous certain de vouloir effacer votre profil?</h2>
                <div className="btn-wrapper">
                    <Button
                        small
                        className="warning"
                        onClick={async () => {
                            // console.log('delete account');
                            const response = await userHelper.deleteUserProfile();
                            // console.log('delete account response', response);
                        }}
                    >
                        Oui
                    </Button>
                    <Button
                        small
                        ghost
                        onClick={() => {
                            setStateDeleteConfirmationModal(false);
                        }}
                    >
                        Non
                    </Button>
                </div>
            </Modal>

            <Modal
                visible={updatePasswordModal}
                handleClose={() => {
                    setUpdatePasswordModal(false);
                }}
            >
                <ContentUpdatePassword>
                    <h2 className="title">Mot de passe</h2>
                    {updatePasswordSuccess ? (
                        <div className="success-message">
                            Votre mot de passe à été modifié avec succès.
                            <br />
                            Vous allez être déconnecté dans 5 secondes.
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit(updatePassword)}>
                            <div className="field">
                                <label>Votre mot de passe actuel</label>
                                <div className="field-wrapper">
                                    <input name="password_old" type={passwordVisibilityOld ? "text" : "password"} ref={register({ required: true })} />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setPasswordVisibilityOld(!passwordVisibilityOld);
                                        }}
                                    >
                                        {passwordVisibilityOld ? (
                                            <svg className="icon-eye" id="icon-eye-blocked" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                                <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                                                <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                                                <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                                            </svg>
                                        ) : (
                                            <svg className="icon-eye" id="icon-eye" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                                <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="field">
                                <label>Nouveau mot de passe</label>
                                <div className="field-wrapper">
                                    <input
                                        type={passwordVisibilityNew ? "text" : "password"}
                                        name="password_new"
                                        ref={register({
                                            validate: {
                                                minLength: (value) => {
                                                    // console.log('minLength', /.{8,}/.test(value));
                                                    setPasswordMinLength(/.{8,}/.test(value));
                                                    if (!formState.dirtyFields.password_new) {
                                                        setPasswordMinLength(false);
                                                        setPasswordContainAtLestOneNumber(false);
                                                        setPasswordContainAtLestOneSymbol(false);
                                                    }
                                                    return /.{8,}/.test(value);
                                                },
                                                containAtLestOneNumber: (value) => {
                                                    // console.log('containAtLestOneNumber', /\d/.test(value));
                                                    setPasswordContainAtLestOneNumber(/\d/.test(value));
                                                    if (!formState.dirtyFields.password_new) {
                                                        setPasswordMinLength(false);
                                                        setPasswordContainAtLestOneNumber(false);
                                                        setPasswordContainAtLestOneSymbol(false);
                                                    }
                                                    return /\d/.test(value);
                                                },
                                                containAtLestOneSymbol: (value) => {
                                                    // console.log('containAtLestOneSymbol', /[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value));
                                                    setPasswordContainAtLestOneSymbol(/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value));
                                                    if (!formState.dirtyFields.password_new) {
                                                        setPasswordMinLength(false);
                                                        setPasswordContainAtLestOneNumber(false);
                                                        setPasswordContainAtLestOneSymbol(false);
                                                    }
                                                    return /[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(value); /* eslint-disable-line */
                                                },
                                            },
                                        })}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setPasswordVisibilityNew(!passwordVisibilityNew);
                                        }}
                                    >
                                        {passwordVisibilityNew ? (
                                            <svg className="icon-eye" id="icon-eye-blocked" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                                <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                                                <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                                                <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                                            </svg>
                                        ) : (
                                            <svg className="icon-eye" id="icon-eye" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                                <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="password-rules">
                                <p className="password-rule">
                                    Minimum 8 caractères
                                    {formState.dirtyFields.password_new && passwordMinLength === true ? (
                                        <svg id="icon-checkmark-outline" className="valid" viewBox="0 0 20 20" width="20" height="20">
                                            <path d="M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM15.66 15.66c1.449-1.449 2.344-3.45 2.344-5.66 0-4.421-3.584-8.004-8.004-8.004-2.21 0-4.211 0.896-5.66 2.344v0c-1.449 1.449-2.344 3.45-2.344 5.66 0 4.421 3.584 8.004 8.004 8.004 2.21 0 4.211-0.896 5.66-2.344v0zM6.7 9.29l2.3 2.31 4.3-4.3 1.4 1.42-5.7 5.68-3.7-3.7 1.4-1.42z"></path>
                                        </svg>
                                    ) : null}
                                    {formState.dirtyFields.password_new && passwordMinLength === false ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="not-valid" viewBox="0 0 512 512" width="20" height="20">
                                            <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" />
                                        </svg>
                                    ) : null}
                                </p>
                                <p className="password-rule">
                                    Minimum 1 chiffre
                                    {formState.dirtyFields.password_new && passwordContainAtLestOneNumber === true ? (
                                        <svg id="icon-checkmark-outline" className="valid" viewBox="0 0 20 20" width="20" height="20">
                                            <path d="M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM15.66 15.66c1.449-1.449 2.344-3.45 2.344-5.66 0-4.421-3.584-8.004-8.004-8.004-2.21 0-4.211 0.896-5.66 2.344v0c-1.449 1.449-2.344 3.45-2.344 5.66 0 4.421 3.584 8.004 8.004 8.004 2.21 0 4.211-0.896 5.66-2.344v0zM6.7 9.29l2.3 2.31 4.3-4.3 1.4 1.42-5.7 5.68-3.7-3.7 1.4-1.42z"></path>
                                        </svg>
                                    ) : null}
                                    {formState.dirtyFields.password_new && passwordContainAtLestOneNumber === false ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="not-valid" viewBox="0 0 512 512" width="20" height="20">
                                            <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" />
                                        </svg>
                                    ) : null}
                                </p>
                                <p className="password-rule">
                                    Minimum 1 symbole
                                    {formState.dirtyFields.password_new && passwordContainAtLestOneSymbol === true ? (
                                        <svg id="icon-checkmark-outline" className="valid" viewBox="0 0 20 20" width="20" height="20">
                                            <path d="M2.93 17.070c-1.884-1.821-3.053-4.37-3.053-7.193 0-5.523 4.477-10 10-10 2.823 0 5.372 1.169 7.19 3.050l0.003 0.003c1.737 1.796 2.807 4.247 2.807 6.947 0 5.523-4.477 10-10 10-2.7 0-5.151-1.070-6.95-2.81l0.003 0.003zM15.66 15.66c1.449-1.449 2.344-3.45 2.344-5.66 0-4.421-3.584-8.004-8.004-8.004-2.21 0-4.211 0.896-5.66 2.344v0c-1.449 1.449-2.344 3.45-2.344 5.66 0 4.421 3.584 8.004 8.004 8.004 2.21 0 4.211-0.896 5.66-2.344v0zM6.7 9.29l2.3 2.31 4.3-4.3 1.4 1.42-5.7 5.68-3.7-3.7 1.4-1.42z"></path>
                                        </svg>
                                    ) : null}
                                    {formState.dirtyFields.password_new && passwordContainAtLestOneSymbol === false ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="not-valid" viewBox="0 0 512 512" width="20" height="20">
                                            <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="32" />
                                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M320 320L192 192M192 320l128-128" />
                                        </svg>
                                    ) : null}
                                </p>
                            </div>
                            <div className="field">
                                <label>Confirmation</label>
                                <div className="field-wrapper">
                                    <input
                                        type={passwordVisibilityNewConf ? "text" : "password"}
                                        name="password_new_conf"
                                        ref={register({
                                            required: true,
                                            validate: (value) => value === password_new.current || "The passwords do not match",
                                        })}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setPasswordVisibilityNewConf(!passwordVisibilityNewConf);
                                        }}
                                    >
                                        {passwordVisibilityNewConf ? (
                                            <svg className="icon-eye" id="icon-eye-blocked" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                                <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                                                <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                                                <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                                            </svg>
                                        ) : (
                                            <svg className="icon-eye" id="icon-eye" viewBox="0 0 32 32" width="32" height="32" style={{ display: "flex", width: "20px" }}>
                                                <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>

                            {passwordErrorMessage !== "" ? <p className="message-errors">{passwordErrorMessage}</p> : null}

                            <div className="btn-wrapper">
                                <Button name="submit_button" type="submit" disabled={!formState.isValid}>
                                    Sauvegarder
                                </Button>
                                <Button
                                    small
                                    buttonStyle="text"
                                    onClick={() => {
                                        setUpdatePasswordModal(false);
                                    }}
                                >
                                    Annuler
                                </Button>
                            </div>
                        </form>
                    )}
                </ContentUpdatePassword>
            </Modal>
        </>
    );
};

export default Settings;
