import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold } from "../../../../styled-common.js";
import { mq } from "../../../../variables";

export const QuestionsTextContainer = styled.div`
    min-height: 150px;

    ${mq("m")} {
        min-height: auto;
    }

    h1 {
        font-family: ${fontFamily};
        font-size: 24px;
        font-weight: ${fontFamilyWeightBold};
        color: ${colors.darkblue100};
    }
`;

export const AnswerQuestionsContainer = styled.div`
    display: block;
    // height: calc(100vh - 625px);
    overflow-y: scroll;
    overflow-x: hidden;

    ${mq("m")} {
        height: auto;

        ${props => props.fixed ? `
            padding-bottom: 110px !important;
        ` : ``}
    }
`;

export const Answer = styled.div`
    border-bottom: 1px dashed ${colors.darkBlue40};
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const AnswerContent = styled.label`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    &.active {
        // background-color: ${colors.darkBlue10};
    }
`;

export const Input = styled.input`
    position: absolute;
    left: -99999px;
    margin: 0;
    cursor: pointer;
    outline: none;

    /* &:focus {
		outline: none;
	} */

    /* &:checked {
		transition: width 0.2s ease-out, height 0.2s ease-out;

		&::before {
			opacity: 1;
			transition: opacity 1s ease;
		}
	} */

    & + div {
        transition: width 0.2s ease-out, height 0.2s ease-out;
        display: block;
        width: 30px;
        height: 30px;
        margin: 0;
        cursor: pointer;
        outline: none;

        ion-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            color: ${colors.white};
            position: absolute;
            right: 3px;
            top: 50%;
            transform: translate(0, -50%);
            opacity: 0;
            transition: opacity 0.4s ease;
            display: block;
            width: 24px;
            height: 24px;
            font-size: 18px;
        }

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            opacity: 1;
            transition: opacity 1s ease;
            display: block;
            width: 30px;
            height: 30px;
            border: 1px solid ${colors.blue};
            background: ${colors.lightgrey};
            transition: opacity 0.4s ease;
            border-radius: 50%;
        }

        &::after {
            content: "";
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translate(50%, -50%);
            opacity: 0;
            transition: opacity 1s ease;
            display: block;
            width: 16px;
            height: 16px;
            background: ${colors.white};
            transition: opacity 0.4s ease;
            border-radius: 50%;
        }
    }
    &:disabled + div {
		ion-icon {
			color: ${colors.darkBlue40};
		}
        &::before {
            background: ${colors.darkBlue10} !important;
			
			border: 1px solid ${colors.darkBlue20};
        }
    }
    &:checked + div {
        ion-icon {
            opacity: 1;
        }
        &::before {
            background: ${colors.blue};
        }
        &::after {
            // opacity: 1;
        }
    }
`;

export const Button = styled.button`
    display: block;
    align-items: center;
    justify-content: center;
    // height: 44px;
    margin: 40px 0 60px 0 !important;
    border-radius: 8px;
    line-height: 44px;
    font-weight: ${fontFamilyWeightBold};
    cursor: pointer;
    transition: all 0.4s ease-out;
    border: 2px solid ${colors.blue};
    background: ${colors.blue};
    color: ${colors.white};
    width: 215px !important;

    &:disabled,
    &[disabled] {
        background-color: ${colors.darkBlue40};
        border: 2px solid ${colors.darkBlue40};

        color: ${colors.darkBlue60};
    }

    ${mq("m")} {
        ${props => props.fixed ? `
            margin: 20px 0 30px 0 !important;
        ` : ``}
    }
`;

export const BtnsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    transition: all .3s ease-in-out;

    ${mq("m")} {
        justify-content: center;

        ${props => props.fixed ? `
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            background: #fff;
        ` : ``}
    }

    

    
`;
