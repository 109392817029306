import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Visual = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(50% - 60px);
  height: 100vh;
  margin: 0 30px 0 0;
  background-size: auto 80%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  overflow: hidden;

  img {
    position: absolute;
    display: block;
    top: 25%;
    right: 0;
    height: auto;
    min-height: 110%;
    min-width: 100%;
  }
`;

export const Content = styled.div`
  max-width: calc(50% - 30px);
  width: calc(1280px / 2);
  margin: 0 0 0 30px;
  text-align: center;

  h1 {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  p {
    font-size: 24px;
    line-height: 32px;
    color: ${colors.darkBlue80};
  }
`;
