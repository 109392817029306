//@format
//@flow
import React, { useState, useEffect } from "react";
import type { Node } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

import { useHistory, useLocation } from "react-router-dom";

import { Container, Visual, Content } from "./styled";
import { Button } from "../../styled-common";

import vip from "../../vip";

const SignUpVerification = (): Node => {
    const location = useLocation();
    const history = useHistory();

    const { register, setValue, handleSubmit, formState } = useForm({
        mode: "onChange",
        validateCriteriaMode: "all",
    });
    const stateUsername = location.state && location.state.username ? location.state.username : null;
    const [stateErrorMessage, setStateErrorMessage] = useState(null);
    const [stateResendMessage, setStateResendMessage] = useState(-1);
    const [stateResendMessageError, setStateResendMessageError] = useState(null);
    // console.log('stateUsername', stateUsername);

    useEffect(() => {
        if (!location.state || !location.state.username) {
            return history.push("/");
        }
    }, []);

    const onPressResend = () => {
        if (stateResendMessage === -1) {
            const username = stateUsername;

            Auth.resendSignUp(username)
                .then(() => {
                    // console.log('code resent successfully');
                    setStateResendMessageError(null);
                    let delay = 30;
                    setStateResendMessage(delay);
                    let interval = setInterval(() => {
                        delay = delay - 1;
                        setStateResendMessage(delay);
                        if (delay === 0) {
                            clearInterval(interval);
                            setStateResendMessage(-1);
                        }
                    }, 1000);
                })
                .catch((e) => {
                    // console.log(e);
                    setStateResendMessageError("Nous rencontrons un problème technique, veuillez essayer plus tard.");
                });
        }
    };

    const onPressVerification = (data) => {
        const username = stateUsername;
        // console.log('data', data);
        const code = data.verificationCode;

        Auth.confirmSignUp(username, code, {
            forceAliasCreation: true,
        })
            .then((dataResponse) => {
                // console.log('then verification', dataResponse);
                history.push("signin", { username: username, fromSignUp: true });
            })
            .catch((err) => {
                // console.log('err', err);
                setStateErrorMessage(err.message);
            });
    };

    return (
        <Container>
            <Visual>
                <img src={vip.visuals["login-verification"]} alt="" />
            </Visual>
            <Content>
                <img src={vip.logo} alt={vip.name} className="logo" />

                <h1>Validation</h1>
                <p>
                    Veuillez entrer le code de validation qui a été envoyé à{"\n"}
                    {stateUsername}
                </p>
                <div className="inner">
                    <form onSubmit={handleSubmit(onPressVerification)}>
                        <label>Code</label>
                        <div className="field">
                            <div className="field-wrapper">
                                <input
                                    type="text"
                                    name="verificationCode"
                                    onChange={(event) => {
                                        const text = event.currentTarget.value;
                                        setValue("verificationCode", text, true);
                                        // console.log('verificationCode', text, formState.isValid);
                                    }}
                                    ref={register({
                                        required: true,
                                        maxLength: 6,
                                        minLength: 6,
                                    })}
                                />
                            </div>
                        </div>

                        {stateErrorMessage ? <p className="message-errors">{stateErrorMessage === "Invalid verification code provided, please try again." ? "Code de vérification non valide." : stateErrorMessage}</p> : null}

                        <Button large type="submit" block disabled={!formState.isValid}>
                            Valider
                        </Button>
                    </form>

                    <Button buttonStyle="text" block onClick={onPressResend} disabled={stateResendMessage > -1}>
                        {stateResendMessage > -1 ? `Nouveau code envoyé (${stateResendMessage})` : "Renvoyer un code"}
                    </Button>
                    {stateResendMessageError ? <p className="message-errors">{stateResendMessageError}</p> : null}
                </div>
            </Content>
        </Container>
    );
};

export default SignUpVerification;
