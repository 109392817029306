import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import Header from '../../components/header';
import Footer from '../../components/footer';
import { Container, ContentHeader, Content } from './styled.js';

import vip from '../../vip';

const Faq = () => {

  const faqData = vip.faqData;
  
  const history = useHistory();

  const location = useLocation();
  
  const itemContentHeight = useRef({});

  const [loaded, setLoaded] = useState(false);
  const [openedItem, setOpenedItem] = useState([]);
  const [openedItemHeight, setOpenedItemHeight] = useState([]);

  useEffect(() => {
    prepareFaqData();
	}, []);

  const prepareFaqData = () => {
    const initialOpenedItemHeight = {};
    const initialOpenedItem = {};

    faqData.categories.map((category, indexCat) => {
      category.questions.map((item, index) => {
        initialOpenedItemHeight[`${indexCat}-${index}`] = 0;
        initialOpenedItem[`${indexCat}-${index}`] = false;
      });
    });
    setOpenedItemHeight(initialOpenedItemHeight);
    setOpenedItem(initialOpenedItem);
  }

  const renderListItems = () => {
    return (
      faqData.categories.map((category, indexCat) => {
        return (
          <div className="faq-category">
            <h2>{category.title}</h2>
            <ul className="expendable-list">
            {category.questions.map((item, index) => {
              return (
                
                <li key={`faq-item-${indexCat}-${index}`} className={`expendable-item${openedItem[`${indexCat}-${index}`] ? ' expendable-item-opened' : ''}`}>
                  
                  <div className={`expendable-item-header${openedItem[`${indexCat}-${index}`] ? ' expendable-item-header-opened' : ''}`}
                    onClick={() => {

                      if(!openedItem[`${indexCat}-${index}`]) {
                        
                        const newOpenedItemHeight = openedItemHeight;// .map((category) => {category.map(() => 0 )} );
                        newOpenedItemHeight[`${indexCat}-${index}`] = itemContentHeight.current[`${indexCat}-${index}`].clientHeight;
                        setOpenedItemHeight(newOpenedItemHeight);
                        const newOpenedItem = openedItem;//.map(() => false );
                        newOpenedItem[`${indexCat}-${index}`] = true;
                        setOpenedItem(newOpenedItem);
                        setLoaded(!loaded);
                      } else {
                        
                        const newOpenedItemHeight = openedItemHeight;
                        newOpenedItemHeight[`${indexCat}-${index}`] = 0;
                        setOpenedItemHeight(newOpenedItemHeight);
                        const newOpenedItem = openedItem;//.map(() => false );
                        newOpenedItem[`${indexCat}-${index}`] = false;
                        setOpenedItem(newOpenedItem);
                        setLoaded(!loaded);
                      }
                    }}
                  >
                  
                    <span>{item.title}</span>
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                  </div>
                  <div className={`expendable-item-content${openedItem[`${indexCat}-${index}`] ? ' expendable-item-content-opened' : ''}`} style={{maxHeight: openedItemHeight[`${indexCat}-${index}`]}}>
                    <div className="expendable-item-content-inner" ref={(element) => itemContentHeight.current[`${indexCat}-${index}`] = element} dangerouslySetInnerHTML={{__html: item.content}} />
                  </div>
                </li>
              );
            })}
            </ul>
          </div>
        );
      })
    )
  }

  return (
    <>
      <Header/>
      <Container>
        <ContentHeader>
          <div className="inner">
            <button
              className="btn-back"
              onClick={() => {
                // console.log('prev', history, location);
                // history.push(history.location.state.from);
                history.goBack();
              }}
            >
              <ion-icon name="chevron-back"></ion-icon>
              Retour
            </button>
            <h1 className="title" dangerouslySetInnerHTML={{__html: faqData.title}}/>
          </div>
        </ContentHeader>
        <Content>
          <div className="inner">
            
            
              {renderListItems()}
            
          </div>
        </Content>
      </Container>

      <Footer />

    </>
  );
};

export default Faq;