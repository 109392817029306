import styled from 'styled-components';
import { colors, fontFamily, fontFamilyWeightBold } from '../../styled-common.js';
import { mq } from '../../variables.js';


export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
`;

export const Visual = styled.div`
  position: relative;
  display: flex;
  width: calc(50% - 60px);
  margin: 0 30px 0 0;
  background-size: auto 80%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-color: ${colors.loginVisual};
  overflow: hidden;

  ${mq('m')} {
    display: none;
  }

  img {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 100%;
    max-width: calc(100% - 20px);
  }
`;

export const Content = styled.div`
  max-width: calc(50% - 30px);
  width: calc(1280px / 2 - 100px);
  /* margin: 0 0 0 30px; */
  margin: 0 auto;
  padding: 100px 50px;
  text-align: center;

  ${mq('m')} {
    width: 100%;
    max-width: 350px;
    padding: 70px 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    max-width: 140px;
  }

  .inner {
    text-align: left;
    margin-left: 35px;
    margin-right: 35px;

    ${mq('m')} {
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
    }

    button {
      margin-right: 70px;
      margin-left: 70px;
      width: calc(100% - 140px);
    }
  }

  h1 {
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};
  }

  h2 {
    font-family: ${fontFamily};
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkBlue100};
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.darkBlue80};

    a {
      color: ${colors.blue};
      text-decoration: underline;
      font-weight: ${fontFamilyWeightBold};
    }
  }

  .message-errors {
    font-size: 14px;
    color: ${colors.errorColor};
  }

  form {
    .field {
      margin-top: 40px;
      margin-bottom: 15px;

      &-wrapper {
        display: flex;
        position: relative;
        margin-bottom: 30px;
        width: 100%;
      }
    }

    label {
      display: inline-block;
      margin: 0 0 10px;
      color: ${colors.darkBlue80};
      font-size: 16px;

      border: 1px solid ${colors.darkBlue20};
      border-radius: 8px;
    }

    input[type="text"],
    input[type="password"] {
      width: calc(100% - 42px);
      height: 52px;
      padding: 0 20px;
      color: ${colors.darkBlue60};
      font-size: 14px;
      border: 0;
      background: transparent;
      font-family: ${fontFamily};
    }

    ::placeholder {
      color: ${colors.inputPlaceholder};
    }

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;

      + label {
        display: block;
        width: 100%;
        padding-right: 30px;
        padding-bottom: 0;
        margin-bottom: 0;
        padding: 12px 60px 12px 30px;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 12px;
          display: inline-block;
          width: 36px;
          height: 18px;
          transform: translate(0, -50%);
          border: 1px solid ${colors.darkBlue40};
          border-radius: 9px;
          background: ${colors.darkBlue40};
          transition: all .4s ease-in-out;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 31px;
          display: inline-block;
          width: 18px;
          height: 18px;
          border-radius: 9px;
          transform: translate(0, -50%);
          overflow: hidden;
          background: ${colors.white};
          transition: all .4s ease-in-out;
        }
      }

      &:checked + label::before {
        background: ${colors.successColor};
        border-color: ${colors.successColor};
      }

      &:checked + label::after {
        right: 13px;
      }
    }
  }
`;

export const Separator = styled.div`
  position: relative;
  margin: 35px 0;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: ${colors.darkBlue20};
  }

  span {
    position: relative;
    display: inline-block;
    padding: 3px 10px;
    background: white;
    color: ${colors.darkBlue40};
    font-size: 14px;
    line-height: 18px;
  }
`;
