import avatarPlaceholder from "../../../assets/avatar-lab-placeholder.png";
import visualCatFaces from "../../../assets/lab-categories/faces.png";
import visualCatDress from "../../../assets/lab-categories/dress.png";
import visualCatEyes from "../../../assets/lab-categories/eyes.png";
import visualCatEyebrows from "../../../assets/lab-categories/eyebrows.png";
import visualCatHair from "../../../assets/lab-categories/hair.png";
import visualCatMouth from "../../../assets/lab-categories/mouth.png";
import visualCatNose from "../../../assets/lab-categories/nose.png";
import visualCatEarings from "../../../assets/lab-categories/earings.png";
import visualCatFacialhair from "../../../assets/lab-categories/facialhair.png";
import visualCatAccessories from "../../../assets/lab-categories/accessories.png";

export const categoriesLayers = ["faces", "dress", "eyes", "eyebrows", "hair", "earings", "facialhair", "mouth", "accessories", "nose"];

export const categoriesMap = {
    faces: {
        label: "Visage",
        visual: visualCatFaces,
        layerNumber: 1,
        showRemove: false,
    },
    dress: {
        label: "Dress",
        visual: visualCatDress,
        layerNumber: 2,
        showRemove: false,
    },
    eyes: {
        label: "Yeux",
        visual: visualCatEyes,
        layerNumber: 3,
        showRemove: false,
    },
    eyebrows: {
        label: "Sourcils",
        visual: visualCatEyebrows,
        layerNumber: 4,
        showRemove: false,
    },
    hair: {
        label: "Cheveux",
        visual: visualCatHair,
        layerNumber: 5,
        showRemove: true,
    },
    earings: {
        label: "Oreilles",
        visual: visualCatEarings,
        layerNumber: 6,
        showRemove: true,
    },
    facialhair: {
        label: "Barbes",
        visual: visualCatFacialhair,
        layerNumber: 7,
        showRemove: true,
    },
    mouth: {
        label: "Bouche",
        visual: visualCatMouth,
        layerNumber: 8,
        showRemove: false,
    },
    accessories: {
        label: "Accessoires",
        visual: visualCatAccessories,
        layerNumber: 9,
        showRemove: true,
    },
    nose: {
        label: "Nez",
        visual: visualCatNose,
        layerNumber: 10,
        showRemove: false,
    },
};

export const defaultAvatar = {
    faces: "Skin_001_white",
    dress: "dress_004_blue",
    mouth: "mouth_002",
    nose: "Noze_005",
    eyes: "eye_004_black",
    eyebrows: "eyebrowns_003_black",
    hair: "hair_001_brown",
};
