//@format
//@flow
import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {Auth} from 'aws-amplify';

import { Container, Visual, Content } from './styled.js';
import { Button } from '../../styled-common';
import vip from "../../vip";

const SignIn = () : Node => {
  const location = useLocation();
  const history = useHistory();
  // console.log('location', location);
  const stateUsername = location.state && location.state.username ? location.state.username : null;
  const stateFromSignup = location.state && location.state.fromSignUp ? location.state.fromSignUp : null;

  const [statePassword, setStatePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetErrorMessage, setResetErrorMessage] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  useEffect(() => {
		if(!location.state || !location.state.username) {
			return history.push('/');
		}
	}, []);

  const onPressSignIn = (e) => {
    const username = stateUsername;
    const password = statePassword;

    Auth.signIn({
      username,
      password,
    })
      .then((user) => {
        // console.log('==> user (signin)', user);
      })
      .catch((err) => {
        switch (err.name) {
          case 'NotAuthorizedException':
            setErrorMessage('Mot de passe incorrect');
            break;
          case 'LimitExceededException':
            setErrorMessage('Nombre de tentative dépassée, veuillez réessyer plus tard.');
            break;
          default:
            setErrorMessage("Une erreur s'est produite, veuillez réessyer plus tard.");
            break;
        }
      });
  };

  const recoverPassword = () => {
    const username = stateUsername;
    // console.log('recoverPassword');
    Auth.forgotPassword(username)
      .then((data) => {
        // console.log('data forgot password', data);
        history.push('reset-password', {username: username});
        
      })
      .catch((err) => {
        switch (err.name) {
          case 'LimitExceededException':
            setResetErrorMessage('La limite de tentative de réinitialisation du mot de passe est dépassée, veuillez essayer après un certain temps.');
            break;
          default:
            setResetErrorMessage("Une erreur s'est produite, veuillez réessyer plus tard.");
            break;
        }
      });
  };

  return (
    <Container>
      <Visual>
        <img src={vip.visuals['login']} alt="" />
      </Visual>
      <Content>
        <img src={vip.logo} alt={vip.name} className="logo" />
        <h1>{(stateFromSignup) ? 'Se connecter' : 'Heureux de vous revoir'}</h1>
        <p>{stateUsername}</p>
        <div className="inner">
        <form onSubmit={(e) => {
            e.preventDefault();
            onPressSignIn();
          }}>
          <div className="field">
            <label>Votre mot de passe</label>
            <div className="field-wrapper">
              <input
                type={(passwordVisibility) ? 'text' : 'password'}
                onChange={(event) => {
                  const text = event.currentTarget.value;
                  setStatePassword(text);
                }}
                value={statePassword}
              />
              <button
                type="button"
                onClick={() => {
                  setPasswordVisibility(!passwordVisibility);
                }}
              >
                {passwordVisibility ? (
                  <svg className="icon-eye" id="icon-eye-blocked" viewBox="0 0 32 32" width="32" height="32" style={{'display':'flex','width': '20px'}}>
                    <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                    <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                    <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                  </svg>
                ) : (
                  <svg className="icon-eye" id="icon-eye" viewBox="0 0 32 32" width="32" height="32" style={{'display':'flex','width': '20px'}}>
                    <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                  </svg>
                )}
              </button>
            </div>
            {errorMessage !== '' ? <p className="message-errors">{errorMessage}</p> : null}
          </div>
          <Button
            type="submit"
            large
            block
          >
            Continuer
          </Button>

          <Button type="button" block buttonStyle="text" onClick={recoverPassword}>Mot de passe oublié</Button>
          {resetErrorMessage !== '' ? <p className="message-errors">{resetErrorMessage}</p> : null}
      </form>
      </div>
    </Content>
  </Container>
  );
};

export default SignIn;
