//@format
//@flow
/**
 * This component represent a small strip that contains date for a survey
 */
import React, { useState, useEffect, useRef } from "react";
import type { Node } from "react";
import avatarPlaceholder from "../../assets/avatar-placeholder.png";

// Fetch helpers
import * as feedHelper from "../../fetch/feed";

type Props = {
    postedMessage: null | string,
    postedMessageHtmlCb: Function,
    reportMessage: Function,
    refreshList: Function,
    onReplyTo: Function,
    setSelectedEmotionURI: Function,
    message: Message,
    messages?: null | Array<Message>,
    userId: null | number,
    user: null | PepioUser,
    isChild?: boolean,
    emotionsAssets: any,
    cognitoUserIdState: any,
    setEditingMessage: Function,
    allowNewComments?: boolean,
};

const FeedMessage = (props: Props): Node => {
    const [message, setMessage] = useState(props.message);
    const [subMessages, setSubMessages] = useState(props.messages);

    const postedMessageHtml = useRef(null);

    useEffect(() => {
        if (props.postedMessage === props.message.id) {
            props.postedMessageHtmlCb(postedMessageHtml);
        }

        setMessage(props.message);
        getSubMessages();
    }, [props]);

    const like = async () => {
        if (!(props.user?.status === "Banned" || props.user?.status === "Blocked")) {
            const result = await feedHelper.likeFeedMessage(props.userId, message.id);
            setMessage(result);
        }
    };

    const dislike = async () => {
        if (!(props.user?.status === "Banned" || props.user?.status === "Blocked")) {
            const result = await feedHelper.dislikeFeedMessage(props.userId, message.id);
            setMessage(result);
        }
    };

    const getSubMessages = async () => {
        if (props.message.childCount > 0) {
            const subMessagesData = await feedHelper.getFeedChildMessage(message.surveyId, message.id);
            setSubMessages(subMessagesData);
        }

        return null;
    };

    const deleteMessage = async (userId, messageId) => {
        await feedHelper.deleteFeedMessage(userId, messageId);
        props.refreshList();
    };

    const reportMessage = async (userId, messageId) => {
        await props.reportMessage(message.id);
    };
    const getTimeStamp = (): string => {
        const date = new Date();
        return date.getTime().toString();
    };

    if (message) {
        return (
            <>
                <div className={`comment-wrapper ${props.isChild ? "comment-child" : ""} ${props.postedMessage === props.message.id ? "comment-last-posted" : ""} ${message.userId === props.userId ? "comment-from-me" : ""}`} ref={postedMessageHtml}>
                    <div className="comment-meta">
                        <div className="comment-meta-left">
                            <div className="comment-author">
                                {message.pictureURI ? (
                                    <div className="comment-author-avatar">
                                        <img src={message.pictureURI + "?t=" + getTimeStamp()} alt="" />
                                    </div>
                                ) : (
                                    <div className="comment-author-avatar comment-author-placeholder">
                                        <img src={avatarPlaceholder} alt="" />
                                    </div>
                                )}
                                <div className="comment-author-username">
                                    <span>{message.userName}</span>
                                    <div className="comment-date">{message.messageDate}</div>
                                </div>
                            </div>
                        </div>

                        {props.cognitoUserIdState ? (
                            <div className="comment-meta-right">
                                {!(props.user?.status === "Banned" || props.user?.status === "Blocked") && message.userId !== props.userId && message.isAdmin === false && (
                                    <div className={message.reported ? "comment-report comment-reported" : "comment-report"}>
                                        <button
                                            disabled={message.reported}
                                            onClick={() => {
                                                reportMessage(props.userId, message.id);
                                            }}
                                        >
                                            <ion-icon name="flag"></ion-icon>
                                        </button>
                                    </div>
                                )}
                                {message.userId === props.userId && (
                                    <div className="comment-delete">
                                        <button
                                            onClick={() => {
                                                deleteMessage(props.userId, message.id);
                                            }}
                                        >
                                            <ion-icon name="trash-outline"></ion-icon>
                                        </button>
                                    </div>
                                )}
                                {message.userId === props.userId && (
                                    <div className="comment-edit">
                                        <button
                                            onClick={() => {
                                                props.setEditingMessage(message);
                                                props.setSelectedEmotionURI(message.emotionPictureURL);
                                            }}
                                        >
                                            <ion-icon name="pencil-outline"></ion-icon>
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className="comment-message" style={{ display: "flex", flexDirection: "column" }}>
                        {message && message.emotion !== null && (!message.emotionPictureURL || message.emotionPictureURL === "") ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="comment-message-emotion">
                                    <img src={props.emotionsAssets[message.emotion]} alt="" />
                                </div>
                            </div>
                        ) : null}

                        {message && message.emotionPictureURL !== null ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="comment-message-emotion">
                                    <img src={message.emotionPictureURL + "?t=" + getTimeStamp()} alt="" />
                                </div>
                            </div>
                        ) : null}
{message && message.message &&
                        <div className="comment-message-text" dangerouslySetInnerHTML={{ __html: `${(message && message.message) ? message.message.replaceAll("\n", "<br />") : message.message}${message.updated ? " (modifié)" : ""}` }}></div>
}
                    </div>
                    {props.cognitoUserIdState ? (
                        <div className="comment-actions">
                            {!(props.user?.status === "Banned" || props.user?.status === "Blocked" || props.allowNewComments === false) && (
                                <button className="comment-reply" onClick={() => props.onReplyTo(message.userName, props.isChild ? message.parentMessageId : message.id)}>
                                    <span>Répondre</span>
                                </button>
                            )}

                            {message.allowLikes && props.allowNewComments === true && (
                                <div className="comment-reaction">
                                    <button className={`comment-like${message.liked ? " active" : ""}${message.disLiked || props.user?.status === "Banned" || props.user?.status === "Blocked" ? " inactive" : ""}`} onClick={like}>
                                        <ion-icon name="thumbs-up-sharp"></ion-icon>
                                        <span>{message.likeCount}</span>
                                    </button>
                                    <button className={`comment-dislike${message.disLiked ? " active" : ""}${message.liked || props.user?.status === "Banned" || props.user?.status === "Blocked" ? " inactive" : ""}`} onClick={dislike}>
                                        <ion-icon name="thumbs-down-sharp"></ion-icon>
                                        <span>{message.dislikeCount}</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : null}
                </div>

                {subMessages && subMessages.length > 0
                    ? subMessages
                          .slice(0)
                          .reverse()
                          .map((subMessage, index) => {
                              return (
                                  <FeedMessage
                                      key={`sub-message-${props.message.id}-${index}`}
                                      postedMessage={props.postedMessage}
                                      postedMessageHtmlCb={props.postedMessageHtmlCb}
                                      user={props.user}
                                      userId={props.userId}
                                      message={subMessage}
                                      onReplyTo={props.onReplyTo}
                                      isChild={true}
                                      refreshList={props.refreshList}
                                      reportMessage={props.reportMessage}
                                      cognitoUserIdState={props.cognitoUserIdState}
                                      emotionsAssets={props.emotionsAssets}
                                      setEditingMessage={props.setEditingMessage}
                                      setSelectedEmotionURI={props.setSelectedEmotionURI}
                                  />
                              );
                          })
                    : null}
            </>
        );
    }
    return null;
};

export default FeedMessage;
