//@format
//@flow
import React from "react";
import type { Node } from 'react';
import { useHistory } from "react-router-dom";
import {
  Wrapper,
  Card,
  CardBody,
  CardTitle,
  CardPicture,
  CardCheck,
} from "./styled";

type Props = {
  question : QuestionObject,
  survey: Survey, 
  setCheckParticipation: Function,
  showModalNoResults: Function,
}
/**
 * This component represent a small strip that contains date for a survey
 */
const SurveyQuestionCard = (props:Props) : Node  => {
  const { question, survey, showModalNoResults } = props;

  const history = useHistory();

  return (
    <Wrapper>
      <Card
        onClick={() => {
          if (survey.status === "Active" && !survey.alreadyAswered) {
            props.setCheckParticipation(question.order);
          } else {
            if(survey.allowViewResult === false) {
              showModalNoResults(true);
            } else {
              history.push("/survey-results", {
                surveyId: survey.id,
                questionId: question.order,
              });
            } 
            
          }
        }}
      >
        <CardBody>
          <CardTitle withoutPicture={!question.imageUri}>
            <span>{question.text}</span>
            <CardCheck className={question.alreadyAnswered ? "active" : ""}>
              {question.alreadyAnswered ? (
                <ion-icon className="active" name="checkmark-circle"></ion-icon>
              ) : (
                <ion-icon name="checkmark-circle"></ion-icon>
              )}
            </CardCheck>
          </CardTitle>
          {question.imageUri ? (
            <CardPicture>
              {/* {survey.pictureUrl ? <img src={survey.pictureUrl} alt="" width={120} height={60} /> : <img src={picture} alt="" width={120} height={60} />} */}
              <img src={question.imageUri} alt="" width={120} height={60} />
            </CardPicture>
          ) : null}
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default SurveyQuestionCard;
