export default {
    title: "Termes d'utilisation Pepio",
    content: `
  <p>Veuillez lire attentivement ces conditions avant d'utiliser notre Service.</p>
  <h2>Définitions</h2>
  <p>Les termes utilisés dans ces conditions générales ont la même signification, qu'ils apparaissent au singulier ou au pluriel.</p>
  <p><em>Pepio</em> (désigné par "nous", "notre" ou "nos" dans le présent accord) désigne le consortium MiiL/UCLouvain, chemin des Sages 6, B-1348 Louvain-la-Neuve et La niche, rue de l'évêché 10, B-5000 Namur.</p>
  <p><em>Dispositif</em> désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.</p>
  <p><em>Service</em> désigne le site web, l’application IOS ou Androïd</p>
  <p><em>Termes et conditions</em> (également appelés "Conditions") signifie les termes et conditions qui forment l'accord complet entre vous et Pepio concernant l'utilisation du Service. </p>
  <p><em>Service de médias sociaux tiers</em> signifie tout Service ou contenu (y compris les données, informations, produits ou Services) fourni par un tiers qui peut être affiché, inclus ou mis à disposition par le Service.</p>
  <p>Le site web fait référence à Pepio, accessible à partir de http://pepio.io</p>
  <p>Vous désigne la personne qui accède ou utilise le Service, ou la société, ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.</p>
  <h2>Conditions d’utilisation</h2>
  <p>Ce sont les conditions générales qui régissent l'utilisation du Service et l'accord qui fonctionne entre vous et Pepio. Ces conditions générales définissent les droits et les obligations de tous les utilisateurs concernant l'utilisation du Service.</p>
  <p>Votre accès et votre utilisation du Service sont conditionnés par votre acceptation et votre respect des présentes conditions générales. Les présentes conditions générales s'appliquent à tous les visiteurs, utilisateurs et autres personnes qui accèdent ou utilisent le Service.</p>
  <p>En accédant ou en utilisant le Service, vous acceptez d'être lié par les présentes conditions générales. Si vous n'êtes pas d'accord avec une partie quelconque de ces conditions générales, vous ne pouvez pas accéder au Service.</p>
  <p>Vous déclarez être âgé de plus de 16 ans. Pepio n'autorise pas les personnes âgées de moins de 16 ans à utiliser le Service. Si vous désirez accéder au Service, vous devez nous fournir une autorisation parentale.</p>
  <p>Votre accès et votre utilisation du Service sont également conditionnés par votre acceptation et votre respect de la politique de confidentialité de Pepio. Notre politique de confidentialité décrit nos politiques et procédures sur la collecte et l'utilisation de vos informations personnelles lorsque vous utilisez le Service. Notre politique de confidentialité vous informe de vos droits en matière de confidentialité et de la façon dont la loi vous protège. Veuillez lire attentivement notre politique de confidentialité avant d'utiliser notre Service.</p>

  <p>Vous vous engagez à ne pas proposer dans l’espace de discussion des sites ou des liens, des messages, des textes, des images, des vidéos, des sons, des animations ou des programmes non conformes à la législation belge. Vous vous engagez par conséquent à ne pas utiliser les services notamment pour :</p>
  <ul>
  <li>publier un contenu constitutif de menace ou de harcèlement; d’incitation à la réalisation de crimes et délits; de provocation à la discrimination, à la haine ou à la violence en raison notamment du sexe, de la « race », de l’origine, de la nationalité ou du handicap; de diffamation ou d’injure; d’atteinte à la vie privée; de pornographie ou de nature à porter gravement atteinte à la dignité humaine;</li>
  <li>transmettre tout message dont le contenu violerait un droit de propriété intellectuelle (droit d’auteur, marque, brevet…);</li>
  <li>transmettre tout message à caractère publicitaire ou tout message promotionnel non autorisé (notamment se livrer à du ” spam ” ou toute autre forme de sollicitation);</li>
  <li>transmettre tout message contenant des virus informatiques ou tout autre code, dossier ou programme conçus pour interrompre, détruire ou limiter la fonctionnalité de tout logiciel, ordinateur ou outil de télécommunication sans que cette énumération ne soit limitative;</li>
  <li>perturber le flux normal des dialogues, accélérer le rythme de défilement de l’écran, de telle manière que les utilisateurs des services se trouvent dans l’impossibilité de suivre et de taper leur dialogue, ou commettre toute autre action ayant un effet perturbateur similaire entravant la capacité des utilisateurs de communiquer en temps réel, dans les espaces de libre échange;</li>
  <li>entraver ou perturber les services, les serveurs, les réseaux connectés aux sites de Pepio, ou refuser de se conformer aux conditions requises, aux procédures, aux règles générales ou aux dispositions réglementaires applicables aux réseaux connectés aux sites de Pepio;</li>
  <li>donner des informations renvoyant vers d’autres sites (que ce soit par la création de liens hyper textes, ou par la simple fourniture d’informations) dont le contenu serait susceptible de contrevenir à toute loi et réglementation en vigueur, et notamment serait susceptible de porter atteinte aux droits des personnes et/ou aux droits de propriété intellectuelle.</li>
  <li>collecter et stocker des données personnelles afférentes aux autres utilisateurs.</li>
  </ul>
  <p>En cas de non-respect de ces règles et usages, Pepio se réserve le droit de retirer tout ou partie de votre contenu, de suspendre votre accès aux services ou à vous en exclure, le tout sans préjudice de toute action en réparation ou en garantie que Pepio pourrait entamer contre vous.</p>

  <h2>Liens vers d'autres sites web</h2>
  <p>Notre Service peut contenir des liens vers des sites web ou des Services tiers qui ne sont pas détenus ou contrôlés par Pepio.</p>
  <p>Pepio n'a aucun contrôle et n'assume aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques des sites web ou Services de tiers. Vous reconnaissez et acceptez en outre que Pepio ne peut être tenue responsable, directement ou indirectement, de tout dommage ou perte causé ou prétendument causé par ou en relation avec l'utilisation ou la confiance accordée à un tel contenu, biens ou Services disponibles sur ou via ces sites web ou Services.</p>
  <p>Nous vous conseillons vivement de lire les conditions générales et les politiques de confidentialité de tous les sites web ou Services tiers que vous visitez.</p>
  <h2>Résiliation et suspension</h2>
  <p>Nous pouvons résilier ou suspendre votre accès immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris, sans limitation, si vous violez les présentes conditions générales.</p>
  <p>En cas de résiliation ou suspension, votre droit d'utiliser le Service cessera immédiatement.</p>
  <h2>Limitation de la responsabilité</h2>
  <p>Nonobstant tout dommage que vous pourriez subir, l'entière responsabilité de Pepio et de ses fournisseurs en vertu de toute disposition des présentes Conditions et votre recours exclusif pour tout ce qui précède sera limité.</p>
  <p>Dans la mesure maximale permise par la loi applicable, Pepio ou ses fournisseurs ne seront en aucun cas responsables de dommages spéciaux, accessoires, indirects ou consécutifs quels qu'ils soient (y compris, mais sans s'y limiter, les dommages pour perte de profits, perte de données ou d'autres informations, pour interruption d'activité, pour préjudice corporel, la perte de confidentialité découlant de ou liée de quelque manière que ce soit à l'utilisation ou à l'incapacité d'utiliser le Service, les logiciels et/ou le matériel de tiers utilisés avec le Service, ou autrement en relation avec une disposition des présentes Conditions), même si Pepio ou tout fournisseur a été informé de la possibilité de tels dommages et même si le recours échoue dans son objectif essentiel.</p>
  <p>Certains États n'autorisent pas l'exclusion des garanties implicites ou la limitation de la responsabilité pour les dommages accessoires ou indirects, ce qui signifie que certaines des limitations ci-dessus peuvent ne pas s'appliquer. Dans ces États, la responsabilité de chaque partie sera limitée dans la plus grande mesure permise par la loi.</p>
  <p>Le Service vous est fourni « tel quel » et « tel que disponible », avec tous les défauts et vices sans garantie d'aucune sorte. Dans la mesure maximale permise par la loi applicable, Pepio, en son nom propre et au nom de ses affiliés et de ses et de leurs concédants de licence et fournisseurs de Services respectifs, décline expressément toute garantie, qu'elle soit expresse, implicite, statutaire ou autre, concernant le Service, y compris toutes les garanties implicites de qualité marchande, d'adéquation à un usage particulier, de titre et de non-contrefaçon, et les garanties qui peuvent résulter de la négociation, des performances, de l'utilisation ou des pratiques commerciales. Sans limitation de ce qui précède, Pepio ne fournit aucune garantie ou engagement, et ne fait aucune déclaration de quelque nature que ce soit que le Service répondra à vos exigences, atteindra les résultats escomptés, sera compatible ou fonctionnera avec tout autre logiciel, application, système ou Service, fonctionnera sans interruption, répondra à toute norme de performance ou de fiabilité ou sera exempt d'erreurs ou que toute erreur ou défaut peut ou sera corrigé.</p>
  <p>Sans limiter ce qui précède, ni Pepio ni aucun de ses fournisseurs ne font de déclaration ou ne donnent de garantie d'aucune sorte, expresse ou implicite : (i) quant au fonctionnement ou à la disponibilité du Service, ou des informations, du contenu et des matériaux ou produits qui y sont inclus ; (ii) que le Service sera ininterrompu ou sans erreur ; (iii) quant à l'exactitude, la fiabilité ou l'actualité de toute information ou contenu fourni par le biais du Service ; ou (iv) que le Service, ses serveurs, le contenu ou les e-mails envoyés par ou au nom de Pepio sont exempts de virus, scripts, chevaux de Troie, vers, logiciels malveillants, bombes à retardement ou autres composants nuisibles.</p>
  <p>Certaines juridictions n'autorisent pas l'exclusion de certains types de garanties ou de limitations des droits statutaires applicables d'un consommateur, de sorte que tout ou partie des exclusions et limitations ci-dessus peuvent ne pas s'appliquer à vous. Mais dans un tel cas, les exclusions et limitations énoncées dans la présente section seront appliquées dans la plus large mesure possible en vertu de la loi applicable.</p>
  <h2>Droit applicable et règlement des litiges</h2>
  <p>Le droit applicable est le droit belge. Les lois belges, à l'exclusion de ses règles de conflit de lois, régissent les présentes conditions et votre utilisation du Service. Votre utilisation de l'application peut également être soumise à d'autres lois locales, étatiques, nationales ou internationales.</p>
  <p>Tout litige auquel pourra donner lieu l’exécution de la présente convention sera, à défaut de règlement amiable, porté devant les tribunaux de Bruxelles.</p>
  <p>Sauf dans les cas prévus dans les présentes, le fait de ne pas exercer un droit ou d'exiger l'exécution d'une obligation en vertu des présentes conditions n'affecte pas la capacité d'une partie à exercer ce droit ou à exiger cette exécution à tout moment par la suite et la renonciation à une violation ne constitue pas une renonciation à une violation ultérieure.</p>
  <h2>Modifications des présentes conditions générales</h2>
  <p>Nous nous réservons le droit, à notre seule discrétion, de modifier ou de remplacer ces conditions à tout moment. Si une révision est importante, nous ferons des efforts raisonnables pour fournir un préavis d'au moins 30 jours avant l'entrée en vigueur de toute nouvelle condition. Ce qui constitue un changement matériel sera déterminé à notre seule discrétion.</p>
  <p>En continuant à accéder ou à utiliser notre Service après l'entrée en vigueur de ces révisions, vous acceptez d'être lié par les conditions révisées. Si vous n'acceptez pas les nouvelles conditions, en tout ou en partie, veuillez cesser d'utiliser le Service.</p>
  <h2>Contactez-nous</h2>
  <p>Si vous avez des questions sur ces conditions générales, vous pouvez nous contacter:</p>
  <p>Par courrier électronique : <a href="mailto: hello@pepio.io">hello@pepio.io</a></p>
  <p><strong>En utilisant notre application, vous acceptez cette politique de confidentialité et nos conditions générales. Nous vous demandons de consulter occasionnellement cette page pour vous assurer que vous adhérez aux changements que nous pouvons apporter à cette politique et nos conditions.</strong></p>
  
  `,
};
