//@flow
//@format
import React, { useState, useEffect, useRef } from "react";
import type { Node } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import AsyncStorage from "@callstack/async-storage";
import * as userHelper from "../../fetch/user";

// import component
import Header from "../../components/header";
import Footer from "../../components/footer";
import FeedMessage from "../../components/feedMessage";
import SurveyHeader from "./components/surveyHeader";
import SurveyQuestions from "./components/surveyQuestions";
//import SurveyLeaderboard from "./components/surveyLeaderboard";
import { CommentsArea, CommentsWrapper, CommentForm, ReportMessageError, ModalContent, AvatarPreview } from "./styled";
import { gaSend } from "../../components/gaSend";

import Modal from "../../components/modal";

import { colors, Button } from "../../styled-common.js";

// import API fetcher
import * as feedHelper from "../../fetch/feed";
import * as surveysFetchHelper from "../../fetch/survey";
import * as user from "../../fetch/user";

import commentVisual from "../../assets/comment-visual.png";

import emo1 from "../../assets/emotions/1.png";
import emo2 from "../../assets/emotions/2.png";
import emo3 from "../../assets/emotions/3.png";
import emo4 from "../../assets/emotions/4.png";
import emo5 from "../../assets/emotions/5.png";

const emotionsAssets = [null, emo1, emo2, emo3, emo4, emo5];

type Props = {};

const SurveyDetails = (props: Props): Node => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const { register, handleSubmit, reset, getValues } = useForm();
    const [showNoResults, setShowNoResults] = useState(false);

    const surveyId = params && params.id ? parseInt(params.id) : location.state && location.state.surveyId ? location.state.surveyId : null;

    if (surveyId && !(params && params.id) && location.state && location.state.surveyId) {
        history.push("/survey-details/" + surveyId);
    }

    const [survey, setSurvey] = useState<null | Survey>(null);
    const [color, setColor] = useState(colors.questionsList);
    const [userId, setUserId] = useState(null);
    const [userPepio, setUserPepio] = useState<PepioUser | null>(null);
    const [avatarEmotions, setAvatarEmotions] = useState<any | AvatarEmotion>();
    const [parentCommentId, setParentCommentId] = useState(false);
    const [parentAuthorName, setParentAuthorName] = useState(false);
    const [feedMessagesList, setFeedMessagesList] = useState([]);
    const [cognitoUserIdState, setCognitoUserIdState] = useState(null);
    const [postedComment, setPostedComment] = useState(null);
    const [postedCommentHtml, setPostedCommentHtml] = useState(null);
    const [editingMessage, setEditingMessage] = useState(null);
    const [selectedEmotionURI, setSelectedEmotionURI] = useState<null | string>(null);

    const [loaded, setLoaded] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [reportMessageId, setReportMessageId] = useState(null);
    const [reportMessageError, setReportMessageError] = useState("");
    const [reportMessageErrorClassName, setReportMessageErrorClassName] = useState("hide");

    const commentForm = useRef(null);
    const commentsScrollable = useRef(null);

    const [checkParticipation, setCheckParticipation] = useState(null);

    const [selectedEmotion, setSelectedEmotion] = useState<null | string>(null);
    const [showedEmotionStrip, setShowedEmotionStrip] = useState(false);

    const [updatedMessage, setUpdatedMessage] = useState<string>("");
    const [typingMessage, setTypingMessage] = useState<string>("");
    const [messageLength, setMessageLength] = useState<number>(0);
    const [hasFocus, setHasFocus] = useState<boolean>(false);

    // console.log('SurveyDetail');

    // Component hook
    useEffect(() => {
        // console.log('SurveyDetail useEffect  start ');
        if (!surveyId) {
            return history.push("/");
        }

        // Fetch survey details
        async function fetchData() {
            let cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
            setCognitoUserIdState(cognitoUserId);

            // console.log('cognitoUserId', cognitoUserId, cognitoUserIdState);

            if (!cognitoUserId) {
                await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
                cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
                setCognitoUserIdState(cognitoUserId);

                // user is not logged in, we store the current survey Id for later redirection
                await AsyncStorage.setItem("@LoginFromSurvey", surveyId);
            } else {
                // clear the redirections to avoid loop
                await AsyncStorage.removeItem("@LoginFromSurvey");
            }
            const pepioUserId = await user.getUserId();
            const pepioUser = await user.getUser();

            setUserId(pepioUserId);
            setUserPepio(pepioUser);

            // Get user avatars (from api or cache)
            await AsyncStorage.removeItem("@PepioUserEmotions");
            const emotionPictures = await userHelper.getEmotions();
            setAvatarEmotions(emotionPictures);

            const result = await surveysFetchHelper.getSurveyforUser(surveyId, pepioUserId);

            if (result.color) {
                setColor(result.color);
            }

            // debug
            // result.hideQuestionsList = true;
            // result.skipIntro = true;
            // result.surveyCustomIdentifier = 'frite';

            setSurvey(result);
            gaSend(`channel: ${result.channelName} | survey: ${result.title}`);

            const feedMessages = await feedHelper.getFeedMessage(surveyId);

            setFeedMessagesList(feedMessages);
            setLoaded(true);
        }
        if (!loaded) {
            fetchData();
        }

        // console.log('commentsScrollable.current', commentsScrollable.current);
    }, [checkParticipation, loaded]);

    useEffect(() => {
        if (editingMessage) {
            setUpdatedMessage(editingMessage.message);
            setSelectedEmotion(editingMessage.emotion === 0 ? null : avatarEmotions[editingMessage.emotion]);
            setShowedEmotionStrip(true);
        } else {
            setShowedEmotionStrip(false);
            setUpdatedMessage("");
            setTypingMessage("");
            setSelectedEmotion(null);
            setSelectedEmotionURI(null);
        }
    }, [editingMessage]);

    const setReplyId = (authorName, parentId) => {
        setParentCommentId(parentId);
        setParentAuthorName(authorName);
    };

    const onSubmitFormNewComment = async (data) => {
        if (survey && userPepio && userPepio.status !== "Blocked" && userPepio.status !== "Banned" && (data.comment !== "" || selectedEmotion !== null)) {
            console.log("AA");
            if (editingMessage) {
                console.log("Submit update message (data)", data);
                const message = data.comment;
                const messageId = editingMessage.id;

                const submitResponse = await feedHelper.updateFeedMessage(messageId, message, !selectedEmotion ? 0 : selectedEmotion);

                if (submitResponse) {
                    // console.log('submitted comment response', submitResponse);
                    setTimeout(() => {
                        setPostedComment(submitResponse.id);
                        reset();
                        setEditingMessage(null);
                        setSelectedEmotion(null);
                        setUpdatedMessage("");
                        setTypingMessage("");
                        setSelectedEmotionURI(null);
                        setMessageLength(0);
                    }, 300);
                }
            } else {
                const surveyId = survey.id;
                const parentMessageId = parentCommentId ? parentCommentId : null;
                const message = data.comment;
                const submitResponse = await feedHelper.postFeedMessage(surveyId, parentMessageId, message, !selectedEmotion ? 0 : selectedEmotion);

                if (submitResponse) {
                    // console.log('submitted comment response', submitResponse);
                    setPostedComment(submitResponse.id);
                    reset();
                    setParentCommentId(false);
                    setParentAuthorName(false);
                    setSelectedEmotion(null);
                    setMessageLength(0);
                }
            }

            refreshList();
        }
    };

    const scrollToMessage = () => {
        if (commentsScrollable && commentsScrollable.current) {
            if (postedCommentHtml && postedCommentHtml.current) {
                postedCommentHtml.current.scrollIntoView();
                setPostedComment(null);
                setPostedCommentHtml(null);
            }
        }
    };

    const reportMessage = (messageId) => {
        if (userPepio && !(userPepio.status === "Banned" || userPepio.status === "Blocked")) {
            setReportMessageId(messageId);
            setModalReport(true);
        }
    };

    const submitReport = async (messageId) => {
        if (userPepio && !(userPepio.status === "Banned" || userPepio.status === "Blocked")) {
            const response = await feedHelper.reportFeedMessage(userId, messageId);

            // console.log('submitReport', response);

            if (response.status === 200) {
                refreshList();

                setReportMessageId(null);
                setModalReport(false);
            } else if (response.status === 403) {
                setReportMessageId(null);
                setModalReport(false);
                refreshList();
                setReportMessageError("Vous ne pouvez pas flagger ce commentaire. Votre limite de 2 flags par jour a été atteinte.");
                setTimeout(() => {
                    setReportMessageErrorClassName("");
                    setTimeout(() => {
                        setReportMessageErrorClassName("hide");
                    }, 5000);
                }, 200);
            }
        }
    };

    const refreshList = async () => {
        const feedMessages = await feedHelper.getFeedMessage(surveyId);
        setFeedMessagesList(feedMessages);
    };

    if (survey && loaded) {
        return (
            <>
                <Header />
                <div>
                    <div>
                        <SurveyHeader
                            fromLogin={location.state && location.state.fromLogin}
                            survey={survey}
                            feedHtmlElement={commentsScrollable}
                            cognitoUserIdState={cognitoUserIdState}
                            setCheckParticipation={setCheckParticipation}
                            checkParticipation={checkParticipation}
                            setLoaded={setLoaded}
                            showModalNoResults={setShowNoResults}
                        />
                        
                        <SurveyQuestions setCheckParticipation={setCheckParticipation} survey={survey} color={color} showModalNoResults={setShowNoResults} showQuestions={!survey.hideQuestionsList || survey.hideQuestionsList === false} />
                        
                        
                        {/* 
						{survey.type === "Survey" ? <SurveyQuestions setCheckParticipation={setCheckParticipation} survey={survey} color={color} /> : <SurveyLeaderboard survey={survey} color={color} />}
						*/}
                    </div>
                </div>

                {/* {// console.log('survey', survey)} */}

                {survey.allowComments ? (
                    <CommentsArea>
                        <h2 className="title">Espace de discussion</h2>
                        <div className="comments-sticky" style={{ position: "relative" }}>
                            <CommentsWrapper>
                                <div className="comments-wrapper" ref={commentsScrollable}>
                                    {feedMessagesList
                                        .slice(0)
                                        .reverse()
                                        .map((message, index) => {
                                            // console.log('message', message);
                                            return (
                                                <FeedMessage
                                                    key={`message-${message.id}-${index}`}
                                                    postedMessage={postedComment}
                                                    postedMessageHtmlCb={setPostedCommentHtml}
                                                    user={userPepio}
                                                    userId={userId}
                                                    message={message}
                                                    onReplyTo={setReplyId}
                                                    refreshList={refreshList}
                                                    reportMessage={reportMessage}
                                                    cognitoUserIdState={cognitoUserIdState}
                                                    allowNewComments={survey.allowNewComments}
                                                    emotionsAssets={emotionsAssets}
                                                    setEditingMessage={setEditingMessage}
                                                    setSelectedEmotionURI={setSelectedEmotionURI}
                                                />
                                            );
                                        })}
                                </div>
                            </CommentsWrapper>
                            {console.log('test', cognitoUserIdState, (cognitoUserIdState && userPepio) ? true : false)}
                            {console.log('cognitoUserIdState', cognitoUserIdState)}
							{console.log('userPepio', userPepio)}
                            {cognitoUserIdState && userPepio ? (
                                <CommentForm showedEmotionStrip={showedEmotionStrip} hasFocus={hasFocus}>
                                    <>
                                        {showedEmotionStrip ? (
                                            <div
                                                className="comment-form-overlay"
                                                onClick={() => {
                                                    setShowedEmotionStrip(false);
                                                    setHasFocus(false);
                                                    setSelectedEmotion(null);
                                                    setEditingMessage(null);
                                                    setUpdatedMessage("");
                                                    setTypingMessage("");
                                                    setSelectedEmotionURI(null);
                                                }}
                                            />
                                        ) : null}
                                        <form onSubmit={handleSubmit(onSubmitFormNewComment)}>
                                            {parentCommentId && parentAuthorName ? (
                                                <div className="in-reply">
                                                    <p>En réponse à {parentAuthorName}</p>
                                                    <button
                                                        onClick={() => {
                                                            setParentCommentId(null);
                                                            setParentAuthorName(null);
                                                        }}
                                                    >
                                                        <ion-icon name="close-outline" />
                                                    </button>
                                                </div>
                                            ) : null}

                                            {editingMessage ? (
                                                <div className="in-reply">
                                                    <p>Edition du message: </p>
                                                    <button
                                                        onClick={() => {
                                                            setEditingMessage(null);
                                                            setSelectedEmotion(null);
                                                            setSelectedEmotionURI(null);
                                                        }}
                                                    >
                                                        <ion-icon name="close-outline" />
                                                    </button>
                                                </div>
                                            ) : null}

                                            {showedEmotionStrip && userPepio.hasAvatar ? (
                                                <div className="emotions-strip">
                                                    {selectedEmotion === null ? (
                                                        <div className="emotions-strip-list" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedEmotion("love");
                                                                }}
                                                            >
                                                                <img src={avatarEmotions.love} alt="" style={{ width: "100%" }} />
                                                            </button>

                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedEmotion("surprise");
                                                                }}
                                                            >
                                                                <img src={avatarEmotions.surprise} alt="" style={{ width: "100%" }} />
                                                            </button>

                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedEmotion("smile");
                                                                }}
                                                            >
                                                                <img src={avatarEmotions.smile} alt="" style={{ width: "100%" }} />
                                                            </button>

                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedEmotion("laught");
                                                                }}
                                                            >
                                                                <img src={avatarEmotions.laught} alt="" style={{ width: "100%" }} />
                                                            </button>

                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedEmotion("sad");
                                                                }}
                                                            >
                                                                <img src={avatarEmotions.sad} alt="" style={{ width: "100%" }} />
                                                            </button>

                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedEmotion("terror");
                                                                }}
                                                            >
                                                                <img src={avatarEmotions.terror} alt="" style={{ width: "100%" }} />
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}

                                            {survey.allowNewComments === true ? (
                                                <>
                                                    {userPepio.status === "Blocked" || userPepio.status === "Banned" ? (
                                                        <div className="blocked-message">
                                                            <p>Votre compte a été suspendu par un administrateur. Vous ne pouvez pas participer à la discussion.</p>
                                                        </div>
                                                    ) : null}

                                                    <div className="field" style={{ display: "flex", flexDirection: "column" }}>
                                                        {(selectedEmotion && selectedEmotion != null && selectedEmotion !== "") || (selectedEmotionURI && selectedEmotionURI !== "") ? (
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setSelectedEmotion(null);
                                                                        setSelectedEmotionURI(null);
                                                                    }}
                                                                    style={{ position: "relative", marginBottom: 10 }}
                                                                >
                                                                    {selectedEmotionURI && selectedEmotionURI !== "" ? <img src={selectedEmotionURI} alt="" style={{ width: 120 }} /> : <img src={avatarEmotions[selectedEmotion]} alt="" style={{ width: 120 }} />}

                                                                    <ion-icon
                                                                        name="close"
                                                                        style={{ position: "absolute", bottom: -10, right: 0, borderRadius: 12, backgroundColor: "white", borderWidth: 1, borderColor: colors.darkBlue20, borderStyle: "solid", width: 24, height: 24 }}
                                                                    />
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                        <div style={{ display: "flex", justifyContent: "between", position: "relative" }}>
                                                            <textarea
                                                                maxLength={survey.isFeedLimited ? survey.messageMaxLength : undefined}
                                                                ref={register()}
                                                                placeholder="Ajouter un message"
                                                                name="comment"
                                                                disabled={userPepio.status === "Blocked" || userPepio.status === "Banned"}
                                                                onKeyDown={(e) => {
                                                                    editingMessage ? setUpdatedMessage(e.value) : setTypingMessage(e.value);
                                                                }}
                                                                onChange={(e) => {
                                                                    editingMessage ? setUpdatedMessage(e.value) : setTypingMessage(e.value);
                                                                    setMessageLength(e.target.value.length);
                                                                }}
                                                                onFocus={async (e) => {
                                                                    if (userPepio.hasAvatar) {
                                                                        setShowedEmotionStrip(true);
                                                                    }
                                                                    setHasFocus(true);
                                                                }}
                                                                onBlur={async (e) => {
                                                                    if (!showedEmotionStrip) {
                                                                        setHasFocus(false);
                                                                    } else if (!showedEmotionStrip) {
                                                                        setHasFocus(false);
                                                                    }
                                                                    // setMessageLength(0);
                                                                }}
                                                                value={editingMessage ? updatedMessage : typingMessage}
                                                            ></textarea>
                                                            <button type="submit" ref={commentForm} disabled={userPepio.status === "Blocked" || userPepio.status === "Banned"}>
                                                                <ion-icon name="arrow-up-circle"></ion-icon>
                                                            </button>

                                                            {survey.isFeedLimited && hasFocus && (
                                                                <div style={{ position: "absolute", bottom: 7, right: 16, color: colors.darkBlue60 }}>
                                                                    {survey.messageMaxLength - messageLength} caractère{survey.messageMaxLength - messageLength > 1 ? "s" : ""} restant{survey.messageMaxLength - messageLength > 1 ? "s" : ""}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {survey.hasCustomFeedMessage && (
                                                        <div style={{ paddingLeft: 5 }}>
                                                            <p style={{ fontSize: 12, color: colors.darkBlue60 }}>{survey.customMessage}</p>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="closed-message">
                                                    <p>La discussion est désormais fermée</p>
                                                </div>
                                            )}
                                        </form>
                                    </>
                                </CommentForm>
                            ) : (
                                <CommentForm className="not-connected">
                                    <p>Vous n'êtes pas connecté.e en tant qu'utilisateur.rice.</p>
                                    <p>Vous devez être identifié.e pour participer aux discussions.</p>

                                    <Button
                                        className="login"
                                        onClick={async () => {
                                            await AsyncStorage.setItem("@LoginFromSurvey", survey.id);
                                            history.push("/loginHome");
                                        }}
                                    >
                                        Me connecter
                                    </Button>
                                </CommentForm>
                            )}
                        </div>
                    </CommentsArea>
                ) : null}

                {/* {// console.log('postedCommentHtml', postedCommentHtml)} */}
                {postedCommentHtml && postedCommentHtml.current ? scrollToMessage() : null}

                <Footer />

                <Modal
                    visible={modalReport}
                    messageId={reportMessageId}
                    userId={userId}
                    submitReport={submitReport}
                    handleClose={() => {
                        setReportMessageId(null);
                        setModalReport(false);
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <h2>Flagger le message</h2>
                        <p>Une fois ce commentaire flaggé, notre équipe de modération prendra les mesures qu’elle jugera nécessaires.</p>
                        <div
                            className="btns-wrapper"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                wAuto
                                onClick={() => {
                                    submitReport(reportMessageId);
                                }}
                            >
                                Flagger le message
                            </Button>
                            <Button
                                wAuto
                                buttonStyle="text"
                                onClick={() => {
                                    setReportMessageId(null);
                                    setModalReport(false);
                                }}
                            >
                                Annuler
                            </Button>
                        </div>
                    </div>
                </Modal>

                {reportMessageError !== "" ? (
                    <ReportMessageError className={reportMessageErrorClassName}>
                        <button
                            className="close"
                            onClick={() => {
                                setReportMessageErrorClassName("hide");
                                setTimeout(() => {
                                    setReportMessageError("");
                                }, 850);
                            }}
                        >
                            <ion-icon name="close-outline"></ion-icon>
                        </button>
                        <p>{reportMessageError}</p>
                    </ReportMessageError>
                ) : null}

                {showNoResults ? (
                    <Modal
                        visible={showNoResults}
                        handleClose={() => {
                            setShowNoResults(false);
                        }}
                    >
                        <ModalContent>
                            <p style={{ textAlign: "center", fontWeight: "500" }}>{survey.resultMessage ? survey.resultMessage : "L'administrateur a décidé de ne pas montrer les résultats."}</p>
                            <Button className="centered-button" buttonStyle="text" onClick={() => setShowNoResults(false)}>
                                Fermer
                            </Button>
                        </ModalContent>
                    </Modal>
                ) : null}
            </>
        );
    }
    return null;
};

export default SurveyDetails;
