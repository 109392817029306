//@format
//@flow
import React from 'react';
import type { Node } from 'react';
import { useHistory } from "react-router-dom";

import { Container } from './styled';
import { Button } from '../../styled-common';
import vip from '../../vip';

const Page404 = () : Node => {
  const history = useHistory();
  const vipVisualNoResult = vip.visuals['no-result'];

  return (
    <Container>
      <img className="visual" src={vipVisualNoResult} alt='' />
      <p>
        Bon, bon, bon...<br />Apparemment, nous sommes perdu<br />
        <Button
          ghost
          onClick={() => {
            history.push('/');
          }}
        >Retour à la page d'accueil</Button>
      </p>
    </Container>
  );
};

export default Page404;
