//@flow
//@format
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import type { Node } from 'react';

//import * as Ably from 'ably';
import vip from "../../vip";
import * as surveyHelper from "../../fetch/survey";
import { Container, Visual, Content, Message, Score, ModalContent } from "./styled";
import { Button } from "../../styled-common.js";

import Modal from "../../components/modal";
import { gaSend } from "../../components/gaSend";

const Tanks = ():Node => {
    const history = useHistory();
    const location = useLocation();
    const surveyId = location.state && location.state.surveyId ? location.state.surveyId : "";
    const [survey, setSurvey] = useState(null);
    const [userScore, setUserScore] = useState(null);
    const [showNoResults, setShowNoResults] = useState(false);
    const [channelName, setChannelName] = useState(null);

    const vipCopy = vip.copy;

    useEffect(() => {
        if (!location.state || !location.state.surveyId) {
            return history.push("/");
        }

        // Get the survey
        async function fetchData() {
            const result = await surveyHelper.getSurveyforUser(surveyId);

            // debug
            // result.hideQuestionsList = true;
            // result.skipIntro = true;
            // result.surveyCustomIdentifier = 'frite';
            console.log('survey', result);
            setSurvey(result);
        }

        fetchData();
    }, [surveyId]);

    useEffect(() => {
        async function fetchData() {
            if (survey && survey.type === "BlindTest") {
                const _userScore = await surveyHelper.getUserScorePosition(survey.id);
                setUserScore(_userScore);
            }
        }

        fetchData();
    }, [survey]);

    if (survey) {
        return (
            <>
            <Container visual={survey.postSubmissionPageImage}>
                {gaSend(`channel: ${survey.channelName} | ${survey.type}: ${survey.title} | Thanks`)}
                <Content>
                    {survey.diffusionType !== "Live" ? (
                        <Message>

                            {(survey.postSubmissionPageText) ? (
                                <p style={{color:survey.textColor}}>{survey.postSubmissionPageText}</p>
                            ) : null}

                            {(survey.postSubmissionPageTitle) ? (
                                <h1 style={{color:survey.textColor}}>
                                    {survey.postSubmissionPageTitle}
                                </h1>
                            ) : (
                                <h1 style={{color:survey.textColor}}>
                                    {vipCopy["thanks-title"]}
                                    <br />
                                    Un grand merci!
                                </h1>
                            )}

                            {(survey.postSubmissionPageButtonText && survey.postSubmissionPageButtonUrl) ? (
                                <>
                                    <a
                                        className="btn"
                                        href={survey.postSubmissionPageButtonUrl}
                                        target="_blank"
                                        style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}}
                                    >
                                        {survey.postSubmissionPageButtonText}
                                    </a>
                                </>
                            ) : null}
                            
                            {((survey.allowViewResult === true || ( survey.resultMessage !== "" && survey.resultMessage !== null))) ? (
                                <button
                                    style={{backgroundColor:survey.buttonColor, borderColor:survey.buttonColor}}
                                    onClick={() => {
                                        // console.log('thanks', survey);
                                        
                                        if(survey.allowViewResult === false) {
                                            setShowNoResults(true);
                                        } else {
                                            history.push("/survey-results", { surveyId: surveyId });
                                        }
                                    }}>
                                    Voir les réponses
                                </button>
                            ) : null}
                            
                            <button
                                className="btn-back"
                                onClick={() => {
                                    history.push("/survey-details/"+surveyId, { surveyId: surveyId });
                                }}
                                style={{color:survey.textColor}}
                                >
                                {vipCopy["back-to-survey-page"]}
                            </button>
                        </Message>
                    ) : (
                        <Message>
                            <h1>Merci d’avoir participé au sondage</h1>

                            <button
                                onClick={() => {
                                    history.push("/survey-details/"+surveyId, { surveyId: surveyId });
                                }}>
                                Terminer
                            </button>
                        </Message>
                    )}

                    
                </Content>

                
                <Visual visual={survey.postSubmissionPageImage} />
                
            </Container>
            {showNoResults ? (
                <Modal
                    visible={showNoResults}
                    handleClose={() => {
                    setShowNoResults(false);
                    }}
                >
                    <ModalContent>
                    <p style={{textAlign: 'center', fontWeight: '500'}}>
                        {survey.resultMessage ? survey.resultMessage : "L'administrateur a décidé de ne pas montrer les résultats."}
                    </p>
                    <Button className="centered-button" buttonStyle="text" onClick={() => setShowNoResults(false)}>
                        Fermer
                    </Button>
                    </ModalContent>
                </Modal>
            ) : null}
            </>
        );
    }
    return null;
};

export default Tanks;
