import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import AsyncStorage from "@callstack/async-storage";

import vip from "../../vip";

import * as userHelper from "../../fetch/user";
import client from "../../fetch/guestClient";

import Modal from "../modal";

import { Wrapper, Container, Logo, MenuTrigger, Menu, Profile, Dropdown, DropdownBackdrop, SearchContent, NotificationsContent, Separator } from "./styled";

import Icon from "../icons/";

import avatarPlaceholder from "../../assets/avatar-placeholder.png";

const vipCopy = vip.copy;

const Header = (props) => {
	// const variations = vip();
	const history = useHistory();
	const { register, handleSubmit } = useForm();

	const [userData, setUserData] = useState(null);
	const [burgerMenu, setBurgerMenu] = useState(false);
	const [stateNotifications, setStateNotifications] = useState([]);
	const [stateNotificationModalOpened, setStateNotificationModalOpened] = useState(false);
	const [stateNotificationDropdownOpened, setStateNotificationDropdownOpened] = useState(false);
	const [stateSearchTerms, setStateSearchTerms] = useState(null);
	const [stateSearchLoading, setStateSearchLoading] = useState(false);
	const [stateSearchResults, setStateSearchResults] = useState([]);
	const [stateSearchModalOpened, setStateSearchModalOpened] = useState(false);
	const [stateSearchDropdownOpened, setStateSearchDropdownOpened] = useState(false);
	const [stateShowNewNotifications, setStateShowNewNotifications] = useState(false);

	const getUserData = async () => {
		const pepioUserId = await AsyncStorage.getItem("@PepioUserId");
		if (pepioUserId) {
			// console.log('====> HAVE PEPIOID');
			const cognitoUserId = await AsyncStorage.getItem("@CognitoUserId");
			const user = await userHelper.getUser();
			user.cognitoUserId = cognitoUserId;
			user.annonymous = cognitoUserId ? false : true;
			user.pictureUrl = user.pictureUrl + "?" + new Date().getTime();
			setUserData(user);
			// console.log('user (header)', user);
			getNotifications();

			if (user.status === "Archived") {
				await userHelper.deleteUserProfile();
			}
		} else {
			// console.log('====> NOT HAVE PEPIOID');
			await userHelper.getAndStoreAnnonymousProfileFromBackEnd();
			getUserData();
		}
	};

	useEffect(() => {
		getUserData();
	}, []);

	const toggleMenu = () => {
		if (burgerMenu) {
			setBurgerMenu(false);
			document.querySelector("body").classList.remove("menu-opened");
		} else {
			setBurgerMenu(true);
			document.querySelector("body").classList.add("menu-opened");
		}
	};

	const getNotifications = async () => {
		try {
			const notifications = await userHelper.getAppNotifications();
			if (notifications) {
				setStateNotifications(notifications);
				checkNewNotifications(notifications);
			}
		} finally {
		}
	};

	const checkNewNotifications = async (notifications) => {
		const allNotifications = notifications || stateNotifications;
		const newNotifications = [];
		const viewedNotificationsStorage = await AsyncStorage.getItem("@ViewedNotifications");
		let viewedNotifications = [];
		if (viewedNotificationsStorage) {
			viewedNotifications = viewedNotificationsStorage.split(",").map((notifId) => parseInt(notifId, 10));
		}

		allNotifications.map((notification) => {
			if (!viewedNotifications || (viewedNotifications && !viewedNotifications.includes(notification.id))) {
				newNotifications.push(notification.id);
			}
			return null;
		});

		setStateShowNewNotifications(newNotifications.length > 0);
	};

	const addViewedNotifications = async () => {
		const viewedNotifications = [];
		stateNotifications.map((notification) => {
			viewedNotifications.push(notification.id);
			return null;
		});
		await AsyncStorage.setItem("@ViewedNotifications", viewedNotifications);
		return null;
	};

	const handleClickNotificationsModal = () => {
		getNotifications();
		setStateNotificationModalOpened(true);
		addViewedNotifications();
		checkNewNotifications();
	};

	const handleClickNotificationsDropdown = () => {
		getNotifications();
		setStateSearchDropdownOpened(false);
		setStateNotificationDropdownOpened(!stateNotificationDropdownOpened);
		addViewedNotifications();
		checkNewNotifications();
	};

	const handleClickSearchModal = () => {
		setStateSearchModalOpened(true);
	};

	const handleClickSearchDropdown = () => {
		setStateNotificationDropdownOpened(false);
		setStateSearchDropdownOpened(!stateSearchDropdownOpened);
	};

	const handleSubmitSearch = async (data) => {
		// console.log("search data", data);
		setStateSearchTerms(data.terms);
		const dataSubmitted = {
			terms: data.terms_dropdown || data.terms_modal,
		};

		setStateSearchLoading(true);

		const response = await client.post("Search/Search", dataSubmitted);
		if (response.status === 200) {
			// console.log("search response", response);

			setStateSearchResults(response.data);
			setStateSearchLoading(false);
		}
	};

	const goto = (type, value) => {
		if (type === "Survey") {
			history.push("/survey-details/"+value, { surveyId: value });
			history.go();
		}

		if (type === "Channel") {
			history.push("/channel-content/"+value, { channelId: value });
			history.go();
		}
	};

	const renderSearchContent = (wrapper) => {
		return (
			<SearchContent wrapper={wrapper}>
				<h2>Rechercher</h2>
				<p>{vipCopy["search-text"]}</p>

				<form className="form-search" onSubmit={handleSubmit(handleSubmitSearch)}>
					<div className="field">
						<label>Search</label>
						<input type="text" name={`terms_${wrapper}`} placeholder="Veuillez taper min. 3 caractères" ref={register({ minLength: 3 })} />
						<div className="border" />
					</div>
					<button type="submit">
						<ion-icon name="search"></ion-icon>
					</button>
				</form>
				{stateSearchTerms !== null ? (
					<div className="search-results">
						{stateSearchResults.length > 0 ? (
							<ul>
								{stateSearchResults.map((result, index) => (
									<li
										key={index}
										onClick={() => {
											setStateSearchModalOpened(false);
											setStateSearchDropdownOpened(false);
											goto(result.searchResultType, result.id);
										}}
									>
										<div className="search-item">
											<div className="picture">{result.pictureUrl ? <img src={result.pictureUrl} alt="" /> : null}</div>
											<div className="message">
												<h3 className="title">{result.title}</h3>
												<div className="description">{result.description}</div>
											</div>
										</div>
									</li>
								))}
							</ul>
						) : (
							<p>{stateSearchLoading ? "Chargement" : "Aucun résultat"}</p>
						)}
					</div>
				) : null}

				<div className="search-qr">
					<Separator>
						<span>ou</span>
					</Separator>

					<h2>
						<ion-icon name="qr-code-outline"></ion-icon>
						Qr code
					</h2>

					<p>Afin de scanner le QR code veuillez sortir de votre navigateur et utiliser la caméra de votre appareil</p>
				</div>
			</SearchContent>
		);
	};

	const renderNotificationsContent = (wrapper) => {
		return (
			<NotificationsContent wrapper={wrapper}>
				<h2>Notifications</h2>

				{stateNotifications.length > 0 ? (
					<ul>
						{stateNotifications
							.sort(function (a, b) {
								return new Date(b.dateTime) - new Date(a.dateTime);
							})
							.map((notification, index) => (
								<li
									key={index}
									onClick={() => {
										setStateNotificationModalOpened(false);
										setStateNotificationDropdownOpened(false);
										goto(notification.notificationType, notification.id);
									}}
								>
									<div className="picture">{notification.pictureUrl ? <img src={notification.pictureUrl} alt="" /> : null}</div>
									<div className="message">{notification.message}</div>
									<div className="age">{notification.age}</div>
								</li>
							))}
					</ul>
				) : (
					<p>Aucune notification</p>
				)}
			</NotificationsContent>
		);
	};

	return (
		<>
			<header>
				<Wrapper>
					<Container>
						<Logo
							onClick={() => {
								history.push("/home");
							}}
						>
							<img src={vip.logo} alt={vip.name} />
						</Logo>
						{userData ? (
							<>
								<div className="space-right">
									<button className="search" onClick={handleClickSearchModal}>
										<Icon name="search" />
									</button>
									<button className={`${stateShowNewNotifications ? "notifications active" : "notifications"}`} onClick={handleClickNotificationsModal}>
										<Icon name="notifications" />
									</button>
									<Menu className={burgerMenu ? "opened" : ""}>
										<ul>
											<li>
												<button className="search" onClick={handleClickSearchDropdown}>
													{/* <img src={search} alt="" /> */}
													<Icon name="search" />
												</button>
												<DropdownBackdrop visible={stateSearchDropdownOpened} onClick={handleClickSearchDropdown} />
												<Dropdown visible={stateSearchDropdownOpened}>{renderSearchContent("dropdown")}</Dropdown>
											</li>
											<li>
												<button className={`${stateShowNewNotifications > 0 ? "notifications active" : "notifications"}`} onClick={handleClickNotificationsDropdown}>
													<Icon name="notifications" />
												</button>
												<DropdownBackdrop visible={stateNotificationDropdownOpened} onClick={handleClickNotificationsDropdown} />
												<Dropdown visible={stateNotificationDropdownOpened}>{renderNotificationsContent("dropdown")}</Dropdown>
											</li>
											<li className="separated-before login-button-wrapper">
												{userData && userData.annonymous ? (
													
													<Profile>
														<span className="profil-trigger profil-trigger-annonymous">
															Se connecter
															<span className="chevron">
																<ion-icon name="chevron-down-outline"></ion-icon>
															</span>
														</span>
														<div className="profile-menu">
															<ul>
																<li>
																	<button
																		onClick={() => {
																			document.querySelector("body").classList.remove("menu-opened");
																			history.push("/loginHome");
																		}}
																	>
																		<ion-icon name="log-in-outline"></ion-icon>
																		<span>Se connecter</span>
																	</button>
																</li>
																<li>
																	<button
																		onClick={() => {
																			document.querySelector("body").classList.remove("menu-opened");
																			history.push("/loginHome");
																		}}
																	>
																		<ion-icon name="person"></ion-icon>
																		<span>S'inscrire</span>
																	</button>
																</li>
																<li>
																	<button
																		onClick={() => {
																			document.querySelector("body").classList.remove("menu-opened");
																			history.push("/settings");
																		}}
																	>
																		<ion-icon name="settings"></ion-icon>
																		<span>Paramètres</span>
																	</button>
																</li>
															</ul>
														</div>
													</Profile>
												) : (
													<Profile>
														<span className="profil-trigger">
															<span className="avatar">
																<img src={props.avatar ? props.avatar : userData && userData.pictureUrl && userData.pictureUrl.indexOf("null") ? userData.pictureUrl : avatarPlaceholder} alt="" />
															</span>

															{props.username ? props.username : userData && userData.userName ? userData.userName : "Mon profil"}
															<span className="chevron">
																<ion-icon name="chevron-down-outline"></ion-icon>
															</span>
														</span>
														<div className="profile-menu">
															<ul>
																<li>
																	<button
																		onClick={() => {
																			document.querySelector("body").classList.remove("menu-opened");
																			history.push("/my-profile");
																		}}
																	>
																		<ion-icon name="person"></ion-icon>
																		<span>Mon profil</span>
																	</button>
																</li>
																<li>
																	<button
																		onClick={() => {
																			document.querySelector("body").classList.remove("menu-opened");
																			history.push("/settings");
																		}}
																	>
																		<ion-icon name="settings"></ion-icon>
																		<span>Paramètres du compte</span>
																	</button>
																</li>
																<li>
																	<button
																		onClick={() => {
																			document.querySelector("body").classList.remove("menu-opened");
																			userHelper.signOutUser();
																		}}
																	>
																		<ion-icon name="log-out"></ion-icon>
																		<span>Déconnexion</span>
																	</button>
																</li>
															</ul>
														</div>
													</Profile>
												)}
											</li>
											{/* {userData && userData.annonymous ?
												<li>
													<Button
														className="settings"
														buttonStyle="text"
														onClick={() => {
															document.querySelector("body").classList.remove("menu-opened");
															history.push("/settings");
														}}
													>
														<span className="desktop-only"><ion-icon name="settings"></ion-icon></span>
														<span className="mobile-only">Paramètres</span>
													</Button>
												</li>
											: null } */}
										</ul>
									</Menu>
									<MenuTrigger opened={burgerMenu} onClick={toggleMenu}>
										<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
											<g data-name="Layer 2" id="Layer_2">
												<path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z" />
												<path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
												<path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
											</g>
										</svg>
									</MenuTrigger>
								</div>
							</>
						) : null}
					</Container>
				</Wrapper>
			</header>

			<Modal
				visible={stateSearchModalOpened}
				handleClose={() => {
					setStateSearchModalOpened(false);
				}}
			>
				{renderSearchContent("modal")}
			</Modal>

			<Modal
				visible={stateNotificationModalOpened}
				handleClose={() => {
					setStateNotificationModalOpened(false);
				}}
			>
				{renderNotificationsContent("modal")}
			</Modal>
		</>
	);
};

export default Header;
