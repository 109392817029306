import styled from "styled-components";
import { colors, fontFamily, fontFamilyWeightBold, fontFamilyWeightRegular } from "../../styled-common.js";
import vip from '../../vip';
import { mq } from "../../variables.js";

export const ContentHeader = styled.div`
  max-width: 100%;
  padding: 64px 0 90px;
  background: ${colors.darkBlue10};

  .inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
  }

  h1.title {
    max-width: 610px;
    margin-left: 100px;
    font-family: ${fontFamily};
    font-size: 36px;
    font-weight: ${fontFamilyWeightBold};
    color: ${colors.darkBlue100};

    ${mq('m')} {
      margin-left: 0;
    }
  }

  p {
    max-width: 610px;
    margin-left: 100px;
    font-family: ${fontFamily};
    font-size: 18px;
    font-weight: ${fontFamilyWeightRegular};
    color: ${colors.darkBlue100};

    ${mq('m')} {
      margin-left: 0;
    }
  }
`;

export const Container = styled.div`

`;

export const Content = styled.div`
    max-width: calc(100% - 60px);
    width: 1280px;
    margin: -70px auto 70px;
    min-height: calc(100vh - 400px);
    line-height: 1.4em;

    .inner {
        max-width: 610px;
        margin-left: 100px;
        flex-direction: row;
        display: flex;

        ${mq('m')} {
          margin-left: 0;
          flex-direction: column;
        }
    }

    video {
        width: 100%;
        max-width: 100%;
        
    }

    

    .sharing-popup {
        display: flex;
        align-items: center;
        padding: 15px 0;
        margin-left: 50px;
        height: 30px;

        ${mq('m')} {
          margin-left: 0;
          padding: 30px 0;
        }

        h2 {
            display: inline-block;
            font-family: ${fontFamily};
            font-size: 24px;
            font-weight: ${fontFamilyWeightBold};
            color: ${colors.darkBlue100};
            padding: 0;
            margin: 0;
        }

        button {
          background: transparent none;
          border: 0;
          padding: 0;
        }

        a,
        button,
        span {
            margin-left: 20px;
            color: ${colors.blue};
        }

        svg {
            display: block;
            width: 30px;
            margin-bottom: 0;
        }
      }

`;