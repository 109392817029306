import React, {useEffect, useState} from 'react';

// import components
// import SectionHeader from '../../components/sectionHeader';
import ChannelCard from '../../components/channelCard';
//import * as RootNavigation from '../../navigator/rootNavigation';
import * as userHelper from '../../fetch/user';
import * as channelHelper from '../../fetch/channel';

import { useHistory } from "react-router-dom";

import { Wrapper, Container, List, NoResult } from './styled';

import loading from '../../assets/loading.svg';

import vip from '../../vip';

const ChannelList = (props) => {
  const [subScribedChannels, setSubScribedChannels] = useState([]);
  const history = useHistory();
  const vipVisualNoResult = vip.visuals['no-result'];
  const vipCopy = vip.copy;

  // Refresh user to get channel subscriptions
  useEffect(() => {
    async function fetchData() {
      await userHelper.refreshProfileFromBackEnd();
      const user = await userHelper.getUser();
      // console.log('user (useEffect)', user);
      setSubScribedChannels((user && user.subscribedChannels) ? user.subscribedChannels : []);
    }
    fetchData();
  }, []);

  const toggleSubscription = async (channelId, channelName) => {
    let response = null;
    if (subScribedChannels && !subScribedChannels.includes(channelId)) {
      response = await channelHelper.subscribeToChannel(channelId, channelName);
    } else {
      response = await channelHelper.unsubscribeToChannel(channelId, channelName);
    }

    // console.log('response', response);

    await userHelper.refreshProfileFromBackEnd();
    const user = await userHelper.getUser();
    setSubScribedChannels(user.subscribedChannels);
  };

  return (
    <Wrapper>
      <Container>
        {props.loading ? (
          <div className="loading-wrapper">
            <img src={loading} alt="loading" />
          </div>
        ) : (
          <>
            {(!props.channels || props.channels.length < 1) ? (
              <NoResult>
                <img className="visual" src={vipVisualNoResult} alt='' />
                <p>
                  Bon, bon, bon...<br />Apparemment, il n’y a rien dans cette catégorie
                </p>
              </NoResult>
            ) : (
              <>
                <h1>
                  {vipCopy.creators}
                </h1>
                <List>
                  {props.channels.map((item) => (
                      <ChannelCard
                        key={item.id}
                        subscribed={subScribedChannels ? subScribedChannels.includes(item.id) : false}
                        category={item.category}
                        name={item.name}
                        description={item.shortDescription}
                        mainImage={item.pictureUrl}
                        logo={item.logoUrl}
                        onPress={() => {
                          history.push('/channel-content/'+item.id, {channelId: item.id});
                        }}
                        onToggleSubscription={() => {
                          toggleSubscription(item.id, item.name);
                        }}
                      />
                    )
                  )}
                </List>
                </>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
};

export default ChannelList;
