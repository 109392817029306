//@format
//@flow
import React from 'react';
import type { Node } from 'react';
import { useHistory } from "react-router-dom";

import { Container } from './styled';
import { Button } from '../../styled-common';

import vip from '../../vip';

const PageError = () : Node => {
  const history = useHistory();
  const vipVisualNoResult = vip.visuals['no-result'];

  return (
    <Container>
      <img className="visual" src={vipVisualNoResult} alt='' />
      <p>
        Bon, bon, bon...<br />Apparemment, quelque chose n'a pas fonctionné.<br />
        Veuillez réessayer, si le problème persiste n'hésitez pas à nous contacter <a href="mailto: hello@pepio.io">hello@pepio.io</a>
        <Button
          ghost
          onClick={() => {
            history.push('/');
          }}
        >Retour à la page d'accueil</Button>
      </p>
    </Container>
  );
};

export default PageError;
