//@format
//@flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import { Card } from './styled';


type Props={
  onPress:Function,
  onToggleSubscription:Function,
  mainImage:string,
  category:string,
  name:string,
  description:string,
  logo:string,
  subscribed:boolean,
}
/*
 * ******************************************************************
 *  Channel Card
 *  this component render the default channel card
 * ******************************************************************
 */
const ChannelCard = (props : Props) : Node => {
  // console.log('props', props);


  return (
    <Card onClick={() => {
      props.onPress();
    }}>
      <div>
        <div className="visual">
          <img src={props.mainImage} alt="" />
        </div>
        <div className="content">
          
            
            <p className="category">{props.category ? props.category : ' '}</p>
                
          <h3 className="title">{props.name}</h3>
          <p className="description">{props.description}</p>
        </div>
        {(props.logo) ? (
        <div className="logo">
          {/* <Image style={styles.logo} source={{uri: props.logo}} resizeMode="contain" /> */}
          <img src={props.logo} alt="" />
        </div>
        ) : null}

        <div 
          className="follow"
          onClick={(e) => {
            e.stopPropagation();
            props.onToggleSubscription();
          }}
        >
        {(!props.subscribed) ? (
          <>
            <span>S’abonner</span>
            <ion-icon name="add-circle-outline"></ion-icon>
          </>
        ) : (
          <>
            <span>Abonné</span>
            <ion-icon name="checkmark-circle"></ion-icon>
          </>
        )}
        </div>
      </div>
    </Card>
  );
};

export default ChannelCard;
