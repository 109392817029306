import styled from "styled-components";
import vip from './vip';
import { mq } from './variables';


export const colors = {
	...vip.colors,
  successBackground: "#D3FFFA",
  successColor: "#00D3B9",
  errorBackground: "#FFDBE4",
  errorColor: "#D91544",
  warningBackground: "#FFF2D1",
  warningColor: "#EDBF49",
};

export const fontFamily = vip.fontFamily;
export const fontFamilyWeightBold = vip.fontFamilyWeightBold;
export const fontFamilyWeightRegular = vip.fontFamilyWeightRegular;
export const fontLink = vip.fontLink;

export const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 30px;
`;

export const Top = styled.div``;

export const Button = styled.button`
	display: inline-flex;
	align-items: center;
	justify-content: center;

	// height: ${(props) => (props.large ? "72px" : "44px")};
	margin: 0 0 20px 0;
	border-radius: 8px;
	// line-height: ${(props) => (props.large ? "72px" : "44px")};
	font-weight: ${fontFamilyWeightBold};
	cursor: pointer;
	transition: all 0.4s ease-out;
	border: 2px solid ${colors.blue};
	background: ${colors.blue};
	color: ${colors.white};

	${(props) =>
		props.block ?
		`width: 100%;` : ``
};

	${(props) =>
		props.large
			? `
    height: 60px;
    // line-height: 60px;
    font-size: 16px;
    font-weight: ${fontFamilyWeightBold};
  `
			: props.small
			? `
    height: 26px;
    // line-height: 26px;
    font-size: 15px;
    font-weight: ${fontFamilyWeightBold};
  `
			: `
    height: 44px;
    // line-height: 44px;
    font-size: 16px;
  `};

	svg {
		margin-right: 15px;
	}

	&:hover {
		background: ${colors.darkBlue80};
		border-color: ${colors.darkBlue80};
  }
  
  &[disabled] {
    background: ${colors.darkBlue40};
    border-color: ${colors.darkBlue40};
		color: ${colors.darkBlue60};
  }

	${(props) =>
		props.ghost
			? `
    background-color: ${colors.white};
    color: ${colors.blue};
    border-color: ${colors.blue};
    border-width: 1px;

    &:hover {
      background-color: ${colors.blue};
      color: ${colors.white};
      border-color: ${colors.blue};
    }
  `
      : null}
      
  ${(props) =>
		props.buttonStyle === "login"
      ? `
      ${mq('m')} {
        background-color: ${colors.white};
        color: ${colors.blue};
        border-color: ${colors.blue};
        border-width: 1px;

        &:hover {
          background-color: ${colors.blue};
          color: ${colors.white};
          border-color: ${colors.blue};
        }
      }

      ${mq('m', true)} {
        background-color: transparent;
        color: ${colors.blue};
        text-decoration: none;
        border-color: transparent;
        border-width: 1px;

        &:hover {
          background-color: transparent;
          color: ${colors.darkBlue80};
          border-color: transparent;
        }
      }
      
    `
			: null}

	${(props) =>
		props.buttonStyle === "facebook"
			? `
    background-color: ${colors.blue};
    color: ${colors.white};
    border-color: ${colors.blue};
    border-width: 1px;

    span {
      display: inline-block;
      width: 230px;
      white-space: nowrap;
      text-align: left;
    }

    &:hover {
      background-color: ${colors.blue};
      color: ${colors.white};
      border-color: ${colors.blue};
    }
  `
			: null}

  ${(props) =>
		props.buttonStyle === "apple"
			? `
    background-color: ${colors.black};
    color: ${colors.white};
    border-color: ${colors.black};
    border-width: 1px;

    span {
      display: inline-block;
      width: 230px;
      white-space: nowrap;
      text-align: left;
    }

    &:hover {
      background-color: ${colors.black};
      color: ${colors.white};
      border-color: ${colors.black};
    }
  `
			: null}

  ${(props) =>
		props.buttonStyle === "google"
			? `
    background-color: ${colors.white};
    color: ${colors.darkBlue60};
    border-color: ${colors.darkBlue20};
    border-width: 1px;

    span {
      display: inline-block;
      width: 230px;
      white-space: nowrap;
      text-align: left;
    }

    &:hover {
      background-color: ${colors.white};
      color: ${colors.darkBlue60};
      border-color:  ${colors.darkBlue20};
    }
  `
			: null}

${(props) =>
		props.buttonStyle === "text"
			? `
    background-color: transparent;
    color: ${colors.blue};
    text-decoration: none;
    border-color: transparent;
    border-width: 1px;

    &:hover {
      background-color: transparent;
      color: ${colors.darkBlue80};
      border-color: transparent;
    }

    &[disabled] {
      background-color: transparent !important;
      border-color: transparent !important;
      color: ${colors.darkBlue60} !important;
      cursor: default;
    }
  `
			: null}

${(props) =>
		props.wAuto
			? `
    width: auto;
  `
			: null}
`;
